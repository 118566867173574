import { get, deleteRequest, post } from 'utils/api';

export const getChargerBooking = (data) => {
  return get('/charger_bookings', data);
};

export const getChargerBookingById = (id) => {
  return get(`/charger_bookings/${id}`);
};

export const deleteChargerBooking = (id) => {
  return deleteRequest(`/charger_bookings/${id}`);
};

export const addStartRemote = (data) => {
  const chargerId = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/remote_start_transaction/${chargerId}`, data);
};

export const stopTransaction = (data) => {
  const bookingId = data.id;
  delete data['id'];
  return post(`/util/stoptransaction/${bookingId}`, data);
};

export const chargerBooking = {
  getChargerBooking,
  getChargerBookingById,
  deleteChargerBooking,
  addStartRemote,
  stopTransaction,
};
