import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import ReactPagination from 'components/general/ReactPagination';
import { Modal } from 'react-bootstrap';
import { DELETE_EMAIL, FETCH_EMAIL, GET_EMAIL } from 'actions/email';
import EmailForm from './EmailForm';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

const EmailList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  // const [allEmailData, setAllEmailData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [emailId, setEmailId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const page = useSelector((state) => state.email.page);
  const totalData = useSelector((state) => state.email.total);
  const limit = useSelector((state) => state.email.limit);
  const totalPages = useSelector((state) => state.email.totalPages);
  const emailList = useSelector((state) => state.email.email);
  const isLoader = useSelector((state) => state.email.isLoading);

  const getEmail = useCallback(
    (data = {}) => {
      if (searchText) {
        const emailData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_EMAIL.REQUEST, payload: emailData });
      } else {
        dispatch({ type: FETCH_EMAIL.REQUEST, payload: data });
      }
    },
    [searchText]
  );

  const getEmailDetail = useCallback((id) => {
    dispatch({ type: GET_EMAIL.REQUEST, payload: id });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setEmailId('');
  };

  const deleteEmail = useCallback(
    () =>
      dispatch({
        type: DELETE_EMAIL.REQUEST,
        payload: emailId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getEmail({ page: 1 });
          }
        },
      }),
    [emailId]
  );

  useEffect(() => {
    getEmail();
  }, []);

  const handleSearch = () => {
    getEmail({});
  };

  // useEffect(() => {
  //   setAllEmailData(
  //     _.filter(
  //       emailList,
  //       (item) =>
  //         startsWith(_.get(item, 'name', ''), searchText) ||
  //         startsWith(_.get(item, 'slug', ''), searchText) ||
  //         startsWith(_.get(item, 'subject', ''), searchText)
  //     )
  //   );
  // }, [searchText, emailList]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getEmail(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getEmail(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getEmail(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseFormEmail = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.emailTemplateList')} />
        <div className="page-content-wrapper scrollable">
          <div className="emailList-page">
            <Card>
              <div className="emailList-page-wrapper">
                <Row className="emailList-search-box">
                  <Col xl={10} lg={9} md={8} className="emailList-search-inputBox">
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="emailList-search-boxButton">
                    <Button
                      className="emailList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      {t('button.addEmail')}
                    </Button>
                  </Col>
                </Row>
                <div className="emailList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span> {t('emailForm.nameLabel')}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span> {t('emailForm.slugLabel')}</span>
                              <span className="ico" onClick={() => handleSorting('slug', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('emailForm.subjectLabel')}</span>
                              <span className="ico" onClick={() => handleSorting('subject', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th className="action-col">
                            <span>{t('emailForm.action')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(emailList) ? (
                          <tr>
                            <td colSpan={4} className="border-0">
                              <div className="empty-data-block">{t('emailForm.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(emailList, (item, key) => {
                            return (
                              <>
                                <tr key={`email-${key}`}>
                                  <td>{_.get(item, 'name', '')}</td>
                                  <td>{_.get(item, 'slug', '')}</td>
                                  <td>{_.get(item, 'subject', '')}</td>
                                  <td>
                                    <span
                                      className="emailList-table-editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getEmailDetail(_.get(item, 'id', ''));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 title="Edit" />
                                    </span>
                                    <span
                                      className="emailList-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setEmailId(_.get(item, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine title="Delete" />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(emailList) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <EmailForm onClose={handleCloseFormEmail} isEdit={isEdit} onSuccess={getEmail} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="email" onClose={handleCloseDeleteModel} onRemove={deleteEmail} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default EmailList;
