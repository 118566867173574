import { get, patch } from 'utils/api';

export const fetchChargeCoin = (data) => {
  return get(`/tenantSetting`, data);
};

export const updateChargeCoin = (data) => {
  const { id, key_name, key_value } = data.results;
  const item = { key_name, key_value };
  return patch(`/tenantSetting/${id}`, item);
};

export const deleteChargeCoinLevel = (data) => {
  const { id, key_name, key_value } = data.results;
  const item = { key_name, key_value };
  return patch(`/tenantSetting/${id}`, item);
};

export const updateChargeCoinLevel = (data) => {
  const { id, key_name, key_value } = data.results;
  const item = { key_name, key_value };
  return patch(`/tenantSetting/${id}`, item);
};

export const chargeCoin = {
  fetchChargeCoin,
  updateChargeCoin,
  deleteChargeCoinLevel,
  updateChargeCoinLevel,
};
