import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_COMMUNICATION_MANAGER,
  FETCH_COMMUNICATION_MANAGER,
  DELETE_COMMUNICATION_MANAGER,
  UPDATE_COMMUNICATION_MANAGER,
} from 'actions/communicationManager';

function* fetchCommunicationManager(action) {
  try {
    const response = yield call(API.fetchCommunicationManager, action.payload);
    yield put({ type: FETCH_COMMUNICATION_MANAGER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_COMMUNICATION_MANAGER.FAIL, payload: { error: e } });
  }
}

function* createCommunicationManager(action) {
  try {
    const response = yield call(API.createCommunicationManager, action.payload);
    yield put({ type: CREATE_COMMUNICATION_MANAGER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_COMMUNICATION_MANAGER.FAIL, payload: { error: e } });
  }
}

function* updateCommunicationManager(action) {
  try {
    const response = yield call(API.updateCommunicationManager, action.payload);
    yield put({ type: UPDATE_COMMUNICATION_MANAGER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_COMMUNICATION_MANAGER.FAIL, payload: { error: e } });
  }
}

function* deleteYourCommunicationManager(action) {
  try {
    const response = yield call(API.deleteYourCommunicationManager, action.payload);
    yield put({ type: DELETE_COMMUNICATION_MANAGER.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_COMMUNICATION_MANAGER.FAIL, payload: { error: e } });
  }
}

function* communicationManager() {
  yield takeLatest(FETCH_COMMUNICATION_MANAGER.REQUEST, fetchCommunicationManager);
  yield takeLatest(CREATE_COMMUNICATION_MANAGER.REQUEST, createCommunicationManager);
  yield takeLatest(DELETE_COMMUNICATION_MANAGER.REQUEST, deleteYourCommunicationManager);
  yield takeLatest(UPDATE_COMMUNICATION_MANAGER.REQUEST, updateCommunicationManager);
}

export default communicationManager;
