import React, { useEffect, useRef } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import PropTypes from 'prop-types';
import { ObjectId } from 'bson';
import { BASE_URL, CHART_IDS } from './chartConstants';
import './Chartstyle.css';

const TotalRevenue = ({ rolePermission, currentTenantId, currentStation, isExternal }) => {
  const sdk = new ChartsEmbedSDK({
    baseUrl: BASE_URL,
  });
  const chartDiv = useRef(null);

  TotalRevenue.propTypes = {
    rolePermission: PropTypes.object.isRequired,
    currentTenantId: PropTypes.string.isRequired,
    currentStation: PropTypes.array.isRequired,
    isExternal: PropTypes.bool.isRequired,
  };

  const filter = {
    tenant: { $eq: new ObjectId(currentTenantId) },
  };

  if (currentStation && currentStation.length > 0) {
    filter['chargers.charging_station'] = { $in: currentStation.map((id) => new ObjectId(id)) };
  }

  if (isExternal) {
    filter['chargers.is_external_charger'] = isExternal;
  }

  const chart = sdk.createChart({
    chartId: CHART_IDS.TOTAL_REVENUE,
    height: 58,
    // width: 445,
    showAttribution: false,
    padding: 0,
    theme: 'dark',
    background: '$primary-bg-color',
    rolePermissions: {
      getWidgetTotalRevenue: rolePermission, // set the role permission
    },
    filter,
    autoRefresh: false,
    maxDataAge: 300,
  });

  useEffect(() => {
    async function renderChart() {
      try {
        await chart.render(chartDiv.current);
        const data = await chart.getData(); // Get the chart data

        if (data.documents.length === 0) {
          chartDiv.current.innerHTML = "<div class='datanot-found'>0</div>";
        }
      } catch (err) {
        console.log('Error during Charts rendering.', err);
      }
    }

    renderChart();
  }, [isExternal]);

  return <div ref={chartDiv} style={{ height: '40px', overflowY: 'hidden', transform: 'translateX(-16px) ' }}></div>;
};

export default TotalRevenue;
