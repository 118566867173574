import React, { useCallback, useEffect, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { CREATE_OCPI_PARTNER_GROUP, GET_OCPI_PARTNER_GROUP, UPDATE_OCPI_PARTNER_GROUP } from 'actions/ocpiPartnerGroup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { CREATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const AddPartnerGroup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigateTo } = useContext(NavContext);
  const { partnerGroupId } = useParams();
  const partnerGroupDetail = useSelector((state) => state.ocpiPartnerGroup.partnerGroups);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  let initialValues =
    partnerGroupId && !_.isEmpty(partnerGroupDetail)
      ? {
          ...partnerGroupDetail,
        }
      : {
          name: '',
        };

  _.forEach(partnerGroupDetail, (data) => {
    if (_.get(data, 'id', '') === partnerGroupId) {
      initialValues = { name: _.get(data, 'name', '') };
    }
  });

  const getUserGroupDetails = useCallback(
    (id) => {
      dispatch({ type: GET_OCPI_PARTNER_GROUP.REQUEST, payload: id });
    },
    [dispatch]
  );

  useEffect(() => {
    if (partnerGroupId) {
      getUserGroupDetails(partnerGroupId);
    }
  }, [partnerGroupId, getUserGroupDetails]);

  const addUserGroup = useCallback((data) => {
    dispatch({
      type: CREATE_OCPI_PARTNER_GROUP.REQUEST,
      payload: data,
      cb: (res) => {
        navigateTo('/ocpiPartnerGroup');
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          setTimeout(() => {}, 1000);
        }
      },
    });
  }, []);

  const editUserGroupDetail = useCallback((data) => {
    const groupData = {
      id: partnerGroupId,
      name: data.name,
    };
    dispatch({
      type: UPDATE_OCPI_PARTNER_GROUP.REQUEST,
      payload: groupData,
      cb: (res) => {
        navigateTo('/ocpiPartnerGroup');
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/ocpiPartnerGroup');
          }, 1000);
        }
      },
    });
  }, []);

  const PartnerGroupSchema = Yup.object().shape({
    name: Yup.string().required(t('userGroupForm.name')),
  });

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${partnerGroupId ? 'Edit' : 'Add'} Partner Group`} backTo="/OCPIPartnerGroup" />
        <div className="page-content-wrapper scrollable">
          <div className="user-group-form-page-main">
            <Card className="user-group-form-card">
              <div className="user-group-form__block">
                <Formik
                  enableReinitialize={!!partnerGroupId && !!partnerGroupDetail}
                  initialValues={initialValues}
                  validationSchema={PartnerGroupSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (partnerGroupId) {
                      // edit user group api call....
                      const editPartnerGroupData = _.omit(values, ['tenant']);
                      editUserGroupDetail(editPartnerGroupData);
                    } else {
                      // add user group api call...
                      addUserGroup(values);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="user-group-form__inner">
                        <Row>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              label={t('addPartnerGroup.userGroupName')}
                              placeholder={t('addPartnerGroup.userGroupName')}
                              type="name"
                              value={values.name}
                              name="name"
                              onChange={handleChange}
                              error={errors.name && touched.name ? errors.name : null}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="user-group-form-save--btn--block">
                        <Button type="submit" className="user-group-form-save-btn save--btn" disabled={isSubmitting}>
                          {partnerGroupId ? 'Update' : 'Add'} {t('Partner Group')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default AddPartnerGroup;
