import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_VOUCHERS,
  FETCH_VOUCHERS_SUCCESS,
  FETCH_VOUCHERS_FAIL,
  DELETE_VOUCHER,
  DELETE_VOUCHER_SUCCESS,
  DELETE_VOUCHER_FAIL,
  UPDATE_VOUCHER,
  UPDATE_VOUCHER_SUCCESS,
  UPDATE_VOUCHER_FAIL,
  CREATE_VOUCHER,
  CREATE_VOUCHER_SUCCESS,
  CREATE_VOUCHER_FAIL,
  GET_VOUCHER,
  GET_VOUCHER_SUCCESS,
  GET_VOUCHER_FAIL,
  VOUCHER_LOGS,
  VOUCHER_LOGS_SUCCESS,
  VOUCHER_LOGS_FAIL,
  DOWNLOAD_VOUCHER_LOGS,
  DOWNLOAD_VOUCHER_LOGS_SUCCESS,
  DOWNLOAD_VOUCHER_LOGS_FAIL,
} from 'actions/voucher';

function* fetchVouchers(action) {
  try {
    const response = yield call(API.getVouchers, action.payload);
    yield put({ type: FETCH_VOUCHERS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_VOUCHERS_FAIL, payload: { error: e } });
  }
}

function* getVoucher(action) {
  try {
    const response = yield call(API.getVoucherById, action.payload);
    yield put({ type: GET_VOUCHER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_VOUCHER_FAIL, payload: { error: e } });
  }
}

function* createVoucher(action) {
  try {
    const response = yield call(API.addVoucher, action.payload);
    yield put({ type: CREATE_VOUCHER_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_VOUCHER_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateVoucher(action) {
  try {
    const response = yield call(API.updateVoucher, action.payload);
    yield put({ type: UPDATE_VOUCHER_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VOUCHER_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* deleteVoucher(action) {
  try {
    const response = yield call(API.deleteVoucher, action.payload);
    yield put({ type: DELETE_VOUCHER_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_VOUCHER_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* fetchVoucherLogs(action) {
  try {
    const response = yield call(API.getVoucherLogs, action.payload);
    yield put({ type: VOUCHER_LOGS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: VOUCHER_LOGS_FAIL, payload: { error: e } });
  }
}

function* downloadVoucherLogs(action) {
  try {
    const response = yield call(API.downloadVoucherLogs, action.payload);
    yield put({ type: DOWNLOAD_VOUCHER_LOGS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_VOUCHER_LOGS_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* voucher() {
  yield takeLatest(FETCH_VOUCHERS, fetchVouchers);
  yield takeLatest(GET_VOUCHER, getVoucher);
  yield takeLatest(CREATE_VOUCHER, createVoucher);
  yield takeLatest(UPDATE_VOUCHER, updateVoucher);
  yield takeLatest(DELETE_VOUCHER, deleteVoucher);
  yield takeLatest(VOUCHER_LOGS, fetchVoucherLogs);
  yield takeLatest(DOWNLOAD_VOUCHER_LOGS, downloadVoucherLogs);
}

export default voucher;
