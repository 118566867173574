import { deleteRequest, get, post } from 'utils/api';

export const getNotification = (data) => {
  return get(`/notifications`, data);
};

export const updateFirmwareNotification = (data) => {
  const id = data.data.id;
  delete data['data']['id'];
  return post(`/chargers/actions/update_firmware/${id}`, data);
};

export const pushNotification = (data) => {
  return post(`/util/send_push_notification`, data);
};

export const deleteNotification = (id) => {
  return deleteRequest(`/notifications/${id}`);
};

export const notification = {
  getNotification,
  updateFirmwareNotification,
  pushNotification,
  deleteNotification,
};
