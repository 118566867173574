import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const Loader = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), 300);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {show && (
        <div className="loader">
          <div className="spinner"></div>
          <p>{t('placeHolder.loading')}</p>
        </div>
      )}
    </>
  );
};
export default Loader;
