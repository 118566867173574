import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CHANGE_STATION_STATUS,
  CREATE_CHARGING_STATION,
  DELETE_CHARGING_STATION,
  FETCH_CHARGING_STATION,
  GET_CHARGING_STATION,
  UPDATE_CHARGING_STATION,
  FETCH_REVIEWS,
  UPDATE_CONTACT_PERSON,
  GET_STATION_BY_CITY,
  GET_STATION_WIDGETS,
  GET_CONSUMPTION,
  GET_CHARGER_STATION,
} from 'actions/chargingStation';

function* fetchChargingStation(action) {
  try {
    const response = yield call(API.getAllChargingStation, action.payload);
    yield put({ type: FETCH_CHARGING_STATION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CHARGING_STATION.FAIL, payload: { error: e } });
  }
}

function* createChargingStation(action) {
  try {
    const response = yield call(API.addChargingStation, action.payload);
    yield put({ type: CREATE_CHARGING_STATION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CHARGING_STATION.FAIL, payload: { error: e } });
  }
}

function* getChargingStation(action) {
  try {
    const response = yield call(API.getChargingStation, action.payload);
    yield put({ type: GET_CHARGING_STATION.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGING_STATION.FAIL, payload: { error: e } });
  }
}

function* getStationByCity(action) {
  try {
    const response = yield call(API.getStationByCity, action.payload);
    yield put({ type: GET_STATION_BY_CITY.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_STATION_BY_CITY.FAIL, payload: { error: e } });
  }
}
function* consumption(action) {
  try {
    const response = yield call(API.consumption, action.payload);
    yield put({ type: GET_CONSUMPTION.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CONSUMPTION.FAIL, payload: { error: e } });
  }
}
function* getChargerStation(action) {
  try {
    const response = yield call(API.getChargerStation, action.payload);
    yield put({ type: GET_CHARGER_STATION.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGER_STATION.FAIL, payload: { error: e } });
  }
}
function* deleteChargingStation(action) {
  try {
    const response = yield call(API.deleteChargingStation, action.payload);
    yield put({ type: DELETE_CHARGING_STATION.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CHARGING_STATION.FAIL, payload: { error: e } });
  }
}

function* updateChargingStation(action) {
  try {
    const response = yield call(API.updateChargingStation, action.payload);
    yield put({ type: UPDATE_CHARGING_STATION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CHARGING_STATION.FAIL, payload: { error: e } });
  }
}

function* updateContactPerson(action) {
  try {
    const response = yield call(API.updateContactPerson, action.payload);
    yield put({ type: UPDATE_CONTACT_PERSON.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CONTACT_PERSON.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* changeStationStatus(action) {
  try {
    yield call(API.updateChargingStation, action.payload);
    yield put({ type: CHANGE_STATION_STATUS.SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: CHANGE_STATION_STATUS.FAIL, payload: { error: e } });
  }
}

function* fetchStationReview(action) {
  try {
    const response = yield call(API.getStationReview, action.payload);
    yield put({ type: FETCH_REVIEWS.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_REVIEWS.FAIL, payload: { error: e } });
  }
}

function* getwidgets(action) {
  try {
    const response = yield call(API.getwidgets, action.payload);
    yield put({ type: GET_STATION_WIDGETS.SUCCESS, payload: response });
  } catch (e) {
    yield put({ type: GET_STATION_WIDGETS.FAIL, payload: { error: e } });
  }
}

function* chargingStation() {
  yield takeLatest(FETCH_CHARGING_STATION.REQUEST, fetchChargingStation);
  yield takeLatest(CREATE_CHARGING_STATION.REQUEST, createChargingStation);
  yield takeLatest(GET_CHARGING_STATION.REQUEST, getChargingStation);
  yield takeLatest(DELETE_CHARGING_STATION.REQUEST, deleteChargingStation);
  yield takeLatest(UPDATE_CHARGING_STATION.REQUEST, updateChargingStation);
  yield takeLatest(CHANGE_STATION_STATUS.REQUEST, changeStationStatus);
  yield takeLatest(FETCH_REVIEWS.REQUEST, fetchStationReview);
  yield takeLatest(UPDATE_CONTACT_PERSON.REQUEST, updateContactPerson);
  yield takeLatest(GET_STATION_BY_CITY.REQUEST, getStationByCity);
  yield takeLatest(GET_STATION_WIDGETS.REQUEST, getwidgets);
  yield takeLatest(GET_CONSUMPTION.REQUEST, consumption);
  yield takeLatest(GET_CHARGER_STATION.REQUEST, getChargerStation);
}

export default chargingStation;
