import { get, post, deleteRequest, patch, downloadFile} from 'utils/api';

export const getelectricityBillReport = (data) => {
  return get(`/electricitybilldetails`, data);
};

export const addelectricityBillReport = (data) => {
  const { bill_details, ...rest } = data;
  const modifiedData = { 
    ...rest, 
    station: bill_details 
  };
  return post(`/electricitybilldetails`, modifiedData);
};


export const deleteelectricityBillReport = (data) => {
  const id = data.id;
  return deleteRequest(`/electricitybilldetails/${id}`);
};

export const updateelectricityBillReport = (data) => {
  const id = data.id;
  delete data['bill_details']
  delete data['id']
  return patch(`/electricitybilldetails/${id}`,data);
};

export const uploadelectricityBillReport = (data) => {
  return post(`/electricitybilldetails/bulkupload`,data);
};

export const downloadelectricityBillReport = (data) => {
  return downloadFile(`/electricitybilldetails/downloadexcel`,data);
};

// export const updateChargeCoin = (data) => {
//   const { id, key_name, key_value } = data.results;
//   const item = { key_name, key_value };
//   return patch(`/tenantSetting/${id}`, item);
// };


// export const updateChargeCoinLevel = (data) => {
//   const { id, key_name, key_value } = data.results;
//   const item = { key_name, key_value };
//   return patch(`/tenantSetting/${id}`, item);
// };

export const electricityBillReport = {
    getelectricityBillReport,
    addelectricityBillReport,
    deleteelectricityBillReport,
    updateelectricityBillReport,
    uploadelectricityBillReport,
    downloadelectricityBillReport
//   updateChargeCoin,
//   deleteChargeCoinLevel,
//   updateChargeCoinLevel,
};
