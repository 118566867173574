import React, { useCallback, useContext, useEffect, useState } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import InformationBlock from 'components/general/InformationBlock';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { FETCH_ACCOUNT_DELETE_REQUEST, APPROVED_ACCOUNT_DELETE_REQUEST, REJECTED_ACCOUNT_DELETE_REQUEST } from 'actions/accountDeleteRequest';
import { useDispatch, useSelector } from 'react-redux';
import ReactPagination from 'components/general/ReactPagination';
import Button from 'components/inputs/Button';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { FaRegUser } from 'react-icons/fa';
import { roundOfDigit } from 'components/common/utils';
import { FaRupeeSign } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import RequestModal from './RequestModal';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const AccountDeleteRequest = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAllowed } = useContext(NavContext);

  const [searchText, setSearchText] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showApproveModel, setShowApproveModel] = useState(false);
  const [showRejectModel, setShowRejectModel] = useState(false);
  const [id, setId] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');

  const requestedUserList = useSelector((state) => state.accountDeleteRequest.accountDeleteRequest);
  const isLoader = useSelector((state) => state.accountDeleteRequest.isLoading);
  const page = useSelector((state) => state.accountDeleteRequest.page);
  const totalData = useSelector((state) => state.accountDeleteRequest.total);
  const limit = useSelector((state) => state.accountDeleteRequest.limit);
  const totalPages = useSelector((state) => state.accountDeleteRequest.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getRequestedUser = useCallback(
    (data = {}) => {
      if (searchText) {
        const userData = {
          ...data,
          status: ['Pending'],
          search: searchText,
        };
        dispatch({
          type: FETCH_ACCOUNT_DELETE_REQUEST.REQUEST,
          payload: userData,
        });
      } else {
        dispatch({
          type: FETCH_ACCOUNT_DELETE_REQUEST.REQUEST,
          payload: { ...data, status: ['Pending'] },
        });
      }
    },
    [searchText]
  );

  const approveRequestedUser = useCallback((data) => {
    dispatch({
      type: APPROVED_ACCOUNT_DELETE_REQUEST.REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('User Request Approved');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      },
    });
  });

  const rejectRequestedUser = useCallback((data) => {
    dispatch({
      type: REJECTED_ACCOUNT_DELETE_REQUEST.REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('User Request Rejected');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      },
    });
  });

  useEffect(() => {
    getRequestedUser();
  }, []);

  const dataFilter = useCallback(() => {
    if (searchText) {
      const filterDateData = {
        search: searchText,
      };
      getRequestedUser(filterDateData);
    } else {
      getRequestedUser();
    }
  }, [searchText]);

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getRequestedUser(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getRequestedUser(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (searchText) {
        const data = {
          search: searchText,
          page: page.selected + 1,
        };
        getRequestedUser(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getRequestedUser(data);
      }
    },
    [sortByItem, searchText]
  );

  const handleCloseApproveModel = () => {
    setShowApproveModel(false);
  };
  const handleCloseRejectModel = () => {
    setShowRejectModel(false);
  };

  const handleSearch = () => {
    getRequestedUser({});
  };

  const formatPhoneNumber = (item) => {
    return `+${_.get(item, 'customer.country_code')} ${_.get(item, 'customer.phone')}`;
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const tooltip = document.getElementsByClassName('more-button-tooltip')[0];
      if (tooltip && !tooltip.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  const toggleTooltip = (content) => {
    setShowTooltip(!showTooltip);
    if (content) {
      setTooltipContent(content);
    }
  };

  const renderRfidData = (infoTitle, remainingNumbers, tooltipId) => {
    return (
      <>
        {infoTitle}
        {remainingNumbers.length > 0 && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id={tooltipId} className="more-button-tooltip">
                {remainingNumbers.join(', ')}
              </Tooltip>
            }
            show={showTooltip && tooltipContent === tooltipId}
            onHide={() => toggleTooltip('')}
          >
            <span className="more-button" onClick={() => toggleTooltip(tooltipId)}>
              {t('customerProfile.more')}
            </span>
          </OverlayTrigger>
        )}
      </>
    );
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.accountDeleteRequest')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="account-delete-request-page__main">
              <div className="account-details--block">
                <Card style={{ border: 'none', borderRadius: 'none' }}>
                  <div className="account-details-search--block" style={{ border: 'none', borderRadius: 'none' }}>
                    <Row>
                      <Col xl={11} md={11}>
                        <SearchBox
                          preIcon={<BsSearch />}
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleSearch();
                            }
                          }}
                        />
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="accountList-add-boxButton" onClick={dataFilter}>
                          <Button className="accountList-add-box-Btn">{t('button.search')}</Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
              <div
                className="new-account-list"
                style={{ backgroundColor: 'none', transform: 'translateY(-30px)', border: 'none', borderRadius: 'none' }}
              >
                <Card style={{ backgroundColor: 'none', transform: 'translateY(-30px)', border: 'none', borderRadius: 'none' }}>
                  <div className="account-list-table">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('deletedUsersList.userName')}</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('deletedUsersList.phoneNo')}</span>
                                <span className="ico" onClick={() => handleSorting('phone', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('deletedUsersList.walletBalance')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('deletedUsersList.rfidAssign')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('deletedUsersList.status')}</span>
                              </div>
                            </th>
                            {isAllowed('modifyAccountDeleteRequests') && (
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('deletedUsersList.actions')}</span>
                                </div>
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td className="account-cell border-0">
                                <Skeleton height={15} width={100} />
                              </td>
                            </tr>
                          ) : _.isEmpty(requestedUserList) ? (
                            <tr>
                              <td className="account-cell border-0">
                                <div className="empty-data-block">{t('deletedUsersList.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            _.map(requestedUserList, (item, key) => {
                              const rfidAssign = _.map(item.customer.rfid, (data) => _.get(data, 'code', ''));
                              const isApproved = _.get(item, 'status', '') === 'Approved';
                              const isRejected = _.get(item, 'status', '') === 'Rejected';

                              return (
                                <tr key={`account-${key}`}>
                                  <td>
                                    <div className="avatar-with-name">
                                      {isLoader ? <Skeleton circle="true" height={15} width={15} /> : <FaRegUser size={25} color="#3c7cdd" />}
                                      <span className="account-name">
                                        {isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer.name', 'Guest User')}
                                      </span>
                                    </div>
                                  </td>

                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : (
                                      <div>
                                        <span>{formatPhoneNumber(item)}</span>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <div className="wallet-rupee-icon">
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} key="skeleton" />
                                      ) : (
                                        <>
                                          <FaRupeeSign size={12} />
                                          {roundOfDigit(_.get(item, 'customer.wallet_balance'), 2)}
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    {!_.isEmpty(item.customer.rfid)
                                      ? renderRfidData(_.head(rfidAssign), _.tail(rfidAssign), `rfid-tooltip-${key}`)
                                      : '-'}
                                  </td>
                                  <td>
                                    <div>{isLoader ? <Skeleton height={15} width={100} key="skeleton" /> : _.get(item, 'status', '')}</div>
                                  </td>
                                  {isAllowed('modifyAccountDeleteRequests') && (
                                    <td>
                                      <div className="app-icons">
                                        <span>
                                          <Button
                                            disabled={isApproved || isRejected}
                                            className="accountList-approve-btn"
                                            onClick={() => {
                                              setShowApproveModel(true);
                                              setId(_.get(item, '_id', ''));
                                            }}
                                          >
                                            {t('button.approve')}
                                          </Button>
                                        </span>
                                        <span>
                                          <Button
                                            disabled={isApproved || isRejected}
                                            className="accountList-reject-btn"
                                            onClick={() => {
                                              setShowRejectModel(true);
                                              setId(_.get(item, '_id', ''));
                                            }}
                                          >
                                            {t('button.reject')}
                                          </Button>
                                        </span>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(requestedUserList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </Card>
              </div>
            </div>
            {showApproveModel && (
              <Modal show={showApproveModel} centered onHide={handleCloseApproveModel}>
                <RequestModal
                  subTitle={'Approve'}
                  title="approve"
                  onClose={handleCloseApproveModel}
                  requestModal={() => {
                    approveRequestedUser({ id });
                    setShowApproveModel(false);
                  }}
                />
              </Modal>
            )}

            {showRejectModel && (
              <Modal show={showRejectModel} centered onHide={handleCloseRejectModel}>
                <RequestModal
                  subTitle={'Reject'}
                  title="reject"
                  onClose={handleCloseRejectModel}
                  requestModal={() => {
                    rejectRequestedUser({ id });
                    setShowRejectModel(false);
                  }}
                />
              </Modal>
            )}
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};
export default AccountDeleteRequest;
