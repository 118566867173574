import { FETCH_LIVE_CHARGING_SESSION } from 'actions/liveChargingSession';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  liveChargingSessions: [],
  sessionDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const liveChargingSessionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_LIVE_CHARGING_SESSION.REQUEST: {
      return { ...state, isLoading: true, sessionDetail: {} };
    }

    case FETCH_LIVE_CHARGING_SESSION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        liveChargingSessions: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FETCH_LIVE_CHARGING_SESSION.FAIL: {
      toast.error('Failed to fetch live charging session data');
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};

export default liveChargingSessionReducer;
