import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  GET_ELECTRICITY_BILL,
  ADD_ELECTRICITY_BILL,
  DELETE_ELECTRICITY_BILL,
  UPDATE_ELECTRICITY_BILL,
  UPLOAD_ELECTRICITY_BILL,
  DOWNLOAD_ELECTRICITY_BILL,
} from 'actions/electricityBillReport';

function* getelectricityBillReport(action) {
  try {
    const response = yield call(API.getelectricityBillReport, action.payload);
    yield put({ type: GET_ELECTRICITY_BILL.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_ELECTRICITY_BILL.FAIL, payload: { error: e } });
  }
}

function* addelectricityBillReport(action) {
  try {
    const response = yield call(API.addelectricityBillReport, action.payload);
    yield put({ type: ADD_ELECTRICITY_BILL.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: ADD_ELECTRICITY_BILL.FAIL, payload: { error: e } });
  }
}

function* deleteelectricityBillReport(action) {
  try {
    const response = yield call(API.deleteelectricityBillReport, action.payload);
    yield put({ type: DELETE_ELECTRICITY_BILL.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_ELECTRICITY_BILL.FAIL, payload: { error: e } });
  }
}

function* updateelectricityBillReport(action) {
  try {
    const response = yield call(API.updateelectricityBillReport, action.payload);
    yield put({ type: UPDATE_ELECTRICITY_BILL.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_ELECTRICITY_BILL.FAIL, payload: { error: e } });
  }
}

function* uploadelectricityBillReport(action) {
  try {
    const response = yield call(API.uploadelectricityBillReport, action.payload);
    yield put({ type: UPLOAD_ELECTRICITY_BILL.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_ELECTRICITY_BILL.FAIL, payload: { error: e } });
  }
}

function* downloadelectricityBillReport(action) {
  try {
    const response = yield call(API.downloadelectricityBillReport, action.payload);
    yield put({ type: DOWNLOAD_ELECTRICITY_BILL.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_ELECTRICITY_BILL.FAIL, payload: { error: e } });
  }
}

// function* uploadNoLoadReport(action) {
//     try {
//         const response = yield call(API.uploadNoLoadReport, action.payload);
//         yield put({ type: UPLOAD_NO_LOAD_REPORT.SUCCESS, payload: response.data });
//         action.cb && action.cb(response);
//     } catch (e) {
//         yield put({ type: UPLOAD_NO_LOAD_REPORT.FAIL, payload: { error: e } });
//         action.cb && action.cb(e);
//     }
// }

// function* downloadNoLoadReport(action) {
//     try {
//         const response = yield call(API.downloadNoLoadReport, action.payload);
//         yield put({ type: DOWNLOAD_NO_LOAD_REPORT.SUCCESS, payload: response.data });
//         action.cb && action.cb(response);
//     } catch (e) {
//         yield put({ type: DOWNLOAD_NO_LOAD_REPORT.FAIL, payload: { error: e } });
//         action.cb && action.cb(e);
//     }
// }

function* electricityBillReport() {
  // yield takeLatest(UPLOAD_NO_LOAD_REPORT, uploadNoLoadReport);
  // yield takeLatest(FETCH_NO_LOAD_REPORT, fetchNoLoadReport);
  // yield takeLatest(DOWNLOAD_NO_LOAD_REPORT, downloadNoLoadReport);
  yield takeLatest(GET_ELECTRICITY_BILL.REQUEST, getelectricityBillReport);
  yield takeLatest(ADD_ELECTRICITY_BILL.REQUEST, addelectricityBillReport);
  yield takeLatest(DELETE_ELECTRICITY_BILL.REQUEST, deleteelectricityBillReport);
  yield takeLatest(UPDATE_ELECTRICITY_BILL.REQUEST, updateelectricityBillReport);
  yield takeLatest(UPLOAD_ELECTRICITY_BILL.REQUEST, uploadelectricityBillReport);
  yield takeLatest(DOWNLOAD_ELECTRICITY_BILL.REQUEST, downloadelectricityBillReport);
}

export default electricityBillReport;
