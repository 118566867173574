import { get, post, patch, deleteRequest } from 'utils/api';

export const getVehicleModel = (data) => {
  return get(`/evcatalogs`, data);
};

export const getVehicleModelById = (id) => {
  return get(`/evcatalogs/${id}`);
};

export const addVehicleModel = (data) => {
  return post(`/evcatalogs`, data);
};

export const updateVehicleModel = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/evcatalogs/${id}`, data);
};

export const deleteVehicleModel = (id) => {
  return deleteRequest(`/evcatalogs/${id}`);
};

export const vehicleModel = {
  getVehicleModel,
  getVehicleModelById,
  addVehicleModel,
  updateVehicleModel,
  deleteVehicleModel,
};
