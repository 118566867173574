import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_CHARGE_COIN, UPDATE_CHARGE_COIN, UPDATE_CHARGE_COIN_LEVEL, DELETE_CHARGE_COIN_LEVEL } from '../actions/chargeCoin';

function* fetchChargeCoin(action) {
  try {
    const response = yield call(API.fetchChargeCoin, action.payload);
    yield put({ type: FETCH_CHARGE_COIN.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CHARGE_COIN.FAIL, payload: { error: e } });
  }
}

function* updateChargeCoin(action) {
  try {
    const response = yield call(API.updateChargeCoin, action.payload);
    yield put({ type: UPDATE_CHARGE_COIN.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CHARGE_COIN.FAIL, payload: { error: e } });
  }
}

function* updateChargeCoinLevel(action) {
  try {
    const response = yield call(API.updateChargeCoinLevel, action.payload);
    yield put({ type: UPDATE_CHARGE_COIN_LEVEL.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CHARGE_COIN_LEVEL.FAIL, payload: { error: e } });
  }
}

function* deleteChargeCoinLevel(action) {
  try {
    const response = yield call(API.deleteChargeCoinLevel, action.payload);
    yield put({ type: DELETE_CHARGE_COIN_LEVEL.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CHARGE_COIN_LEVEL.FAIL, payload: { error: e } });
  }
}

function* chargeCoin() {
  yield takeLatest(FETCH_CHARGE_COIN.REQUEST, fetchChargeCoin);
  yield takeLatest(UPDATE_CHARGE_COIN.REQUEST, updateChargeCoin);
  yield takeLatest(DELETE_CHARGE_COIN_LEVEL.REQUEST, deleteChargeCoinLevel);
  yield takeLatest(UPDATE_CHARGE_COIN_LEVEL.REQUEST, updateChargeCoinLevel);
}

export default chargeCoin;
