import { get, patch } from 'utils/api';

export const getAllMasterSetting = (data) => {
  return get(`/masterSetting`, data);
};

export const getMasterSettingById = (id) => {
  return get(`/masterSetting/${id}`);
};

export const updateMasterSetting = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/masterSetting/${id}`, data);
};

export const masterSetting = {
  getAllMasterSetting,
  getMasterSettingById,
  updateMasterSetting,
};
