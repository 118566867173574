import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import * as _ from 'lodash';
import Select from 'components/inputs/Select';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_VEHICLE_MAKE, UPDATE_VEHICLE_MAKE } from 'actions/vehicleMake';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

const MakeForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const makeDetail = useSelector((state) => state.vehicleMake.vehicleMakeDetail);
  const isLoading = useSelector((state) => state.vehicleMake.isLoading);
  const token = cookie.get('token');

  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name', '');

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type', '');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, imgUrlData.url);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const addVehicleMake = useCallback((data) => {
    dispatch({
      type: CREATE_VEHICLE_MAKE.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateVehicleMake = useCallback((data) => {
    dispatch({
      type: UPDATE_VEHICLE_MAKE.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const MakeSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('makeForm.name'))
      .strict(true)
      .trim(t('makeForm.space'))
      .matches(/^[A-Za-z0-9\s]+$/, t('makeForm.matches'))
      .max(100, t('makeForm.maxlimit')),
    type: Yup.string().required(t('makeForm.type')),
    logo: Yup.mixed().nullable().required(t('makeForm.logo')),
  });

  const initialValues = isEdit
    ? { ...makeDetail }
    : {
        name: '',
        type: '',
        logo: '',
      };

  return (
    <React.Fragment>
      <div className="make-form-page__main">
        <Card>
          <div className="make-form-header__block">
            <div className="make-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('makeForm.title')}
              </span>
            </div>
            <div className="close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="make-form-body__block">
            <div className="make-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={MakeSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateVehicleMake(values);
                  } else {
                    addVehicleMake(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('makeForm.evMakeName')}
                          placeholder={t('makeForm.evMakeName')}
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Select
                          isRequired
                          label={t('makeForm.vehicleType')}
                          placeholder={t('placeHolder.selectVehicleType')}
                          options={[
                            { label: 'Bus', value: 'bus' },
                            { label: 'Car', value: 'car' },
                            { label: 'Two wheeler', value: 'two wheeler' },
                            { label: 'Three wheeler', value: 'three wheeler' },
                            { label: 'Other', value: 'other' },
                          ]}
                          name="type"
                          value={values.type}
                          onChange={(val) => setFieldValue('type', val)}
                          error={errors.type && touched.type ? errors.type : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          type="file"
                          label={t('makeForm.addEVModellogo')}
                          name="logo"
                          accept="image/*"
                          onChange={(e) => handleImage(e.currentTarget.files[0], 'logo', setFieldValue)}
                          error={errors.logo && touched.logo ? errors.logo : null}
                          image={values.logo}
                        />
                      </Col>
                    </Row>
                    <div className="form_btn--block">
                      <Button className="form--btn cancel_btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form--btn">
                        {isEdit ? 'Update' : 'Create'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
MakeForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default MakeForm;
