import { get, post, deleteRequest, patch } from 'utils/api';

export const getPartnerGroup = (data) => {
  return get(`/ocpipartner_group`, data);
};

export const getPartnerGroupById = (id) => {
  return get(`/ocpipartner_group/${id}`);
};

export const addPartnerGroup = (data) => {
  return post(`/ocpipartner_group/`, data);
};

export const updatePartnerGroup = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/ocpipartner_group/${id}`, data);
};

export const deletePartnerGroup = (data) => {
  const id = data.id;
  return deleteRequest(`/ocpipartner_group/${id}`);
};

export const deletePartner = (data) => {
  const id = data.user_group;
  const party = data.id;
  return deleteRequest(`/ocpipartner_group/${id}/${party}`);
};

export const allUsersInPartnerGroup = (data) => {
  const id = data.user_group;
  return get(`/ocpipartner_group/${id}`);
};

// export const addPartyInPartnerGroup = (data) => {
//   console.log("monogoooo",data)
//   return post(`/ocpipartner_group/${data}`);
// };

// export const addPartyInPartnerGroup = (data) => {
//   return post(`/ocpipartner_group`, data);
// };

export const addPartyInPartnerGroup = (data) => {
  const id = data.user_group;
  const party = data.id;
  delete data['user_group'];
  return post(`/ocpipartner_group/${id}/${party}`,data);
};

export const createBulkUsersToPartnerGroup = (data) => {
  const userData = data.userData;
  const id = data.id;
  delete data['id'];
  delete data['userData'];
  return post(`/ocpipartner_group/bulkupload/${id}`, userData);
};

export const ocpiPartnerGroup = {
  getPartnerGroup,
  getPartnerGroupById,
  addPartnerGroup,
  updatePartnerGroup,
  deletePartnerGroup,
  allUsersInPartnerGroup,
  createBulkUsersToPartnerGroup,
  addPartyInPartnerGroup,
  deletePartner
};