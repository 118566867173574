import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PubNub from 'pubnub';
// import { Form, Formik } from 'formik';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';

// Icons
// import { IoSearchOutline } from 'react-icons/io5';
// import { FaFilter } from 'react-icons/fa';
import { BsFilter } from 'react-icons/bs';
// import {BsSearch } from 'react-icons/bs';
import { AiFillCaretDown } from 'react-icons/ai';
// import { MdClear } from 'react-icons/md';

// Components
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import DropDown from 'components/inputs/DropDown';
// import Button from 'components/inputs/Button';
import DateTimePicker from 'components/inputs/DateTimePicker';
// import SearchBox from 'components/general/SearchBox';
// import Select from 'components/inputs/Select';
// import OffCanvas from 'components/inputs/OffCanvas';
import ToggleSwitch from 'components/inputs/ToggleSwitch';
import AlertItem from 'components/general/AlertItem';
import Footer from 'components/general/Footer';
import ReactPagination from 'components/general/ReactPagination';
import { SkeletonTheme } from 'react-loading-skeleton';

// Constants and Actions
import { FETCH_ALERTS } from 'actions/alerts';
// import { UPDATE_STATUS_CODE } from 'components/common/constant';
import pop from 'assets/audio/Pop.mp3';

// Configuration options
const ALERT_TYPES = [
  { label: 'All Alerts', value: 'All' },
  { label: 'Booked Slot', value: 'Attempting on an Already Booked Slot' },
  { label: 'Charging Interruption', value: 'Charging Interruption' },
  { label: 'Incorrect Attempts', value: 'Incorrect Attempt' },
  { label: 'Low Wallet Balance', value: 'Low Wallet Balance' },
  // { label: 'Start Charging Issue', value: 'Start Charging Issue' },
];

// const SEARCH_OPTIONS = [
//   { label: 'OCPP ID', value: 'ocppId' },
//   { label: 'Charger ID', value: 'chargerId' },
//   { label: 'Station Name', value: 'charging_station_name' },
//   { label: 'Booking ID', value: 'bookingId' },
//   { label: 'User ID', value: 'userId' },
// ];

// const CHARGING_TYPES = [
//   { label: 'Auto Charge', value: 'autocharge' },
//   { label: 'RFID', value: 'rfid' },
//   { label: 'Mobile APP', value: 'mobile' },
// ];

// const BOOLEAN_OPTIONS = [
//   { label: 'Yes', value: 'true' },
//   { label: 'No', value: 'false' },
// ];

const Alerts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const audioRef = useRef(new Audio(pop));

  // Redux state
  const { alerts: allAlerts, page, total: totalData, limit, totalPages, isLoading: isLoader } = useSelector((state) => state.alerts);
  const currentUserName = useSelector((state) => _.get(state, 'profile.userProfile.name', ''));

  // Local state
  const [localAlerts, setLocalAlerts] = useState([]);
  const [isRealTimeActive, setIsRealTimeActive] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [fieldValue, setFieldValue] = useState('All Alerts');
  const [type, setType] = useState('');
  // const [searchText, setSearchText] = useState('');
  // const [showSearchBar, setShowSearchBar] = useState(false);
  // const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  // const [advanceFilterData, setAdvanceFilterData] = useState({});
  // const [selectedOption, setSelectedOption] = useState('charging_station_name');

  // PubNub initialization
  const pubnub = React.useMemo(
    () =>
      new PubNub({
        publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
        userId: 'myUniqueUserId',
        logVerbosity: false,
        ssl: true,
      }),
    []
  );

  // Fetch alerts with filters
  const getAlerts = useCallback(
    (value = {}) => {
      const baseParams = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        ...value,
        ...(isRealTimeActive && { limit: 99999 }),
        // ...(searchText && {
        //   search: { [selectedOption]: searchText },
        // }),
      };

      dispatch({
        type: FETCH_ALERTS,
        payload: baseParams,
        // cb: (res) => {
        //   if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
        //     setShowAdvanceFilterPopup(false);
        //   }
        // },
      });
    },
    [dispatch, startDate, endDate, isRealTimeActive]
  );

  // Real-time updates handling
  const handleNewAlert = useCallback((newAlert) => {
    if (!_.isObject(newAlert)) {
      console.warn('Received invalid alert:', newAlert);
      return;
    }

    setLocalAlerts((prev) => [newAlert, ...prev]);

    if (Notification.permission === 'granted') {
      new Notification('New Alert', {
        body: newAlert.message || 'You have a new alert!',
      });
    }

    if (newAlert.color === 'red') {
      audioRef.current?.play().catch(console.error);
    }
  }, []);

  // Separate effect for PubNub subscription
  useEffect(() => {
    if (!isRealTimeActive) return;

    const listener = {
      message: ({ message }) => handleNewAlert(message),
    };

    pubnub.addListener(listener);
    pubnub.subscribe({
      channels: ['charger-notification-alert'],
    });

    // Cleanup function
    return () => {
      pubnub.removeListener(listener);
      pubnub.unsubscribe({
        channels: ['charger-notification-alert'],
      });
    };
  }, [pubnub, handleNewAlert, isRealTimeActive]);

  // Update filters effect
  useEffect(() => {
    const filters = {
      ...(type && type !== 'All' && { alertType: type }),
      ...(startDate &&
        endDate && {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        }),
      // ...advanceFilterData,
    };

    getAlerts(filters);
  }, [getAlerts, type, startDate, endDate]);

  // Sync local alerts with redux state
  useEffect(() => {
    setLocalAlerts(allAlerts);
  }, [allAlerts]);

  // Pagination handler
  const handlePageClick = (page) => {
    const filters = {
      page: page.selected + 1,
      ...(type && type !== 'All' && { alertType: type }),
      ...(startDate &&
        endDate && {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        }),
    };
    getAlerts(filters);
  };

  // Advanced filter form
  // const renderAdvancedFilterForm = ({ values, handleSubmit, setFieldValue, resetForm }) => (
  //   <Form onSubmit={handleSubmit}>
  //     <div className="mb-5">
  //       <Select
  //         label={t('filters.ocpiBooking')}
  //         options={BOOLEAN_OPTIONS}
  //         placeholder={t('placeHolder.selectAnyone')}
  //         name="is_ocpi_based_booking"
  //         value={values.is_ocpi_based_booking}
  //         onChange={(val) => setFieldValue('is_ocpi_based_booking', val)}
  //       />
  //       <Select
  //         label={t('filters.isEmspBasedBooking')}
  //         options={BOOLEAN_OPTIONS}
  //         placeholder={t('placeHolder.selectAnyone')}
  //         name="is_emsp_based_booking"
  //         value={values.is_emsp_based_booking}
  //         onChange={(val) => setFieldValue('is_emsp_based_booking', val)}
  //       />
  //       <Select
  //         label={t('placeHolder.selectChargingType')}
  //         options={CHARGING_TYPES}
  //         placeholder={t('placeHolder.selectChargingType')}
  //         name="charging_type"
  //         value={values.charging_type}
  //         onChange={(val) => setFieldValue('charging_type', val)}
  //       />
  //     </div>
  //     <div className="canvas-btn__block">
  //       <Button type="submit" className="apply--btn w-100">
  //         {t('button.applyFilters')}
  //       </Button>
  //       <div
  //         className="reset--block"
  //         onClick={() => {
  //           setAdvanceFilterData({});
  //           getAlerts();
  //           resetForm();
  //           setShowAdvanceFilterPopup(false);
  //         }}
  //       >
  //         <MdClear size={24} className="reset--icon" />
  //         {t('button.clear')}
  //       </div>
  //     </div>
  //   </Form>
  // );

  return (
    <LayoutContainer>
      <AdminHeader title={t('header.alerts')} />
      <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
        <div className="page-content-wrapper scrollable">
          <div className="notification-details-page__main">
            <Card>
              <Row className="align-items-center">
                {/* <Col xl={showSearchBar ? 4 : 'auto'} md={showSearchBar ? 12 : 'auto'}>
                  {showSearchBar ? (
                    <div className="search-box-option">
                      <Select options={SEARCH_OPTIONS} value={selectedOption} onChange={setSelectedOption} placeholder="Search Option" />
                      <SearchBox
                        preIcon={<BsSearch />}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && getAlerts()}
                      />
                    </div>
                  ) : (
                    <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />
                  )}
                </Col> */}
                <Col>
                  <div className="session-heading-right-content">
                    <Row>
                      <Col xl="auto" md="auto">
                        <DropDown
                          popupClass="main-menu bottom scrollable"
                          trigger={
                            <div className="log-filter-block">
                              <div className="log-filter-icon">
                                <BsFilter />
                                <span>{fieldValue}</span>
                              </div>
                              <AiFillCaretDown />
                            </div>
                          }
                          triggerClass="log-filter-dropdown"
                        >
                          {ALERT_TYPES.map((item, index) => (
                            <ul key={index}>
                              <li
                                className="item"
                                onClick={() => {
                                  setFieldValue(item.label);
                                  setType(item.value);
                                }}
                              >
                                {item.label}
                              </li>
                            </ul>
                          ))}
                        </DropDown>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <DateTimePicker
                          onChangeOfStartDate={setStartDate}
                          onChangeOfEndDate={setEndDate}
                          initialValueOfStartDate={moment().startOf('day')}
                          initialValueOfEndDate={moment().endOf('day')}
                        />
                      </Col>
                      {/* <Col xl="auto" md="auto">
                        <Button className="log-search-button" onClick={() => getAlerts()} disabled={isLoader}>
                          {t('button.search')}
                        </Button>
                      </Col> */}
                      {/* <Col xl="auto" md="auto">
                        <Button onClick={() => setShowAdvanceFilterPopup(true)}>
                          <FaFilter />
                        </Button>
                      </Col> */}
                      <Col xl="auto" md="auto" className="d-flex align-items-start mt-3">
                        <div className="d-flex gap-3">
                          <ToggleSwitch id="toggle-switch" checked={isRealTimeActive} onChange={() => setIsRealTimeActive((prev) => !prev)} />
                          {t('liveChargingForm.realTimeUpdate')}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="general-notification__main">
                {isLoader ? (
                  <div className="loader--block">Loading...</div>
                ) : _.isEmpty(localAlerts) ? (
                  <div className="empty-data-block">{t('alerts.noData')}</div>
                ) : (
                  localAlerts.map((alert, index) => <AlertItem key={`notification-${index}`} alert={alert} />)
                )}
              </div>
              {!isLoader && !_.isEmpty(localAlerts) && (
                <ReactPagination
                  currentPage={page}
                  limit={limit}
                  total={totalData}
                  handlePageClick={handlePageClick}
                  totalPages={totalPages}
                  marginPagesDisplayed={1}
                />
              )}
            </Card>
          </div>
        </div>
      </SkeletonTheme>
      {currentUserName !== 'CZ Tenant Admin' && <Footer />}

      {/* {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={() => setShowAdvanceFilterPopup(false)}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={{
              is_ocpi_based_booking: '',
              is_emsp_based_booking: '',
              charging_type: '',
              ...(!_.isEmpty(advanceFilterData) && {
                is_ocpi_based_booking: advanceFilterData.booking_details?.is_ocpi_based_booking?.toString() || '',
                is_emsp_based_booking: advanceFilterData.booking_details?.is_emsp_based_booking?.toString() || '',
                charging_type: advanceFilterData.booking_details?.charging_type || '',
              }),
            }}
            onSubmit={(values, { setSubmitting }) => {
              const { is_ocpi_based_booking, is_emsp_based_booking, charging_type, ...rest } = values;

              const filteredValues = {
                ...rest,
                booking_details: {
                  ...(is_ocpi_based_booking && {
                    is_ocpi_based_booking: is_ocpi_based_booking === 'true',
                  }),
                  ...(is_emsp_based_booking && {
                    is_emsp_based_booking: is_emsp_based_booking === 'true',
                  }),
                  ...(charging_type && {
                    charging_type,
                  }),
                },
              };

              getAlerts(filteredValues);
              setAdvanceFilterData(filteredValues);
              setSubmitting(false);
              setShowAdvanceFilterPopup(false);
            }}
          >
            {renderAdvancedFilterForm}
          </Formik>
        </OffCanvas>
      )} */}
    </LayoutContainer>
  );
};

export default Alerts;
