import { DOWNLOAD_JOB_VOUCHER } from 'actions/dataReport';

const initialValues = {
  isLoading: true,
  voucherDetail: '',
};

const JobVoucherReducer = (state = initialValues, { type, payload }) => {
  switch (type) {
    case DOWNLOAD_JOB_VOUCHER.REQUEST: {
      return { ...state, isLoading: true };
    }

    case DOWNLOAD_JOB_VOUCHER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        voucherDetail: payload,
      };
    }

    case DOWNLOAD_JOB_VOUCHER.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};

export default JobVoucherReducer;
