import {
  CREATE_CHARGER,
  FETCH_CHARGER,
  GET_CHARGER,
  FETCH_CHARGER_BY_STATION,
  UPDATE_CHARGER,
  CHANGE_CHARGER_STATUS,
  CHANGE_CHARGER_IS_ACTIVE_STATUS,
  FETCH_CHARGERS_LIST,
  DELETE_CHARGER,
  FETCH_CHARGERS_BY_STATIONS,
} from 'actions/charger';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: true,
  chargers: [],
  stationChargers: [],
  chargerDetails: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const chargerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHARGER.REQUEST:
    case CREATE_CHARGER.REQUEST:
    case GET_CHARGER.REQUEST:
    case FETCH_CHARGER_BY_STATION.REQUEST:
    case FETCH_CHARGERS_LIST.REQUEST:
    case UPDATE_CHARGER.REQUEST:
    case CHANGE_CHARGER_STATUS.REQUEST:
    case CHANGE_CHARGER_IS_ACTIVE_STATUS.REQUEST:
    case FETCH_CHARGERS_BY_STATIONS.REQUEST: {
      return { ...state, isLoading: true };
    }
    case FETCH_CHARGER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        chargers: payload,
      };
    }
    case FETCH_CHARGER_BY_STATION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stationChargers: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FETCH_CHARGERS_LIST.SUCCESS: {
      return {
        ...state,
        chargersList: payload.results,
        isLoading: false,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_CHARGER.SUCCESS: {
      return {
        ...state,
        chargerDetails: payload,
        isLoading: false,
      };
    }
    case CREATE_CHARGER.SUCCESS: {
      toast.success('Charger added successfully');
      // if (_.get(payload, 'charging_station.id', '')) {
      //   setTimeout(() => {
      //     const stationId = payload.charging_station.id;
      //     window.location.href = `/stations/${stationId}`;
      //   }, 1000);
      // } else {
      //   setTimeout(() => {
      //     window.location.href = '/stations';
      //   }, 1000);
      // }

      return {
        ...state,
        chargers: _.concat(state.chargers, payload),
        isLoading: false,
      };
    }
    case DELETE_CHARGER.SUCCESS: {
      toast.success('Charger deleted successfully');
      // const { stationId } = payload;
      // setTimeout(() => {
      //   window.location.href = `/stations/${stationId}`;
      // }, 1000);
      return {
        ...state,
        chargers: state.chargers.filter((data) => data.id !== payload.chargerId),
      };
    }
    case UPDATE_CHARGER.SUCCESS: {
      toast.success('Charger updated successfully');
      // setTimeout(() => {
      //   const chargerId = payload.id;
      //   window.location.href = `/charger/${chargerId}`;
      // }, 1000);
      return {
        ...state,
        chargers: state.chargers.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }
    case CHANGE_CHARGER_STATUS.SUCCESS: {
      // toast.success('Charger status changed successfully', payload);
      toast.success(`Charger is ${payload.is_enabled === true ? 'Enabled' : 'Disabled'} successfully`, payload);
      return {
        ...state,
        chargerDetails: payload,
        isLoading: false,
      };
    }
    case CHANGE_CHARGER_IS_ACTIVE_STATUS.SUCCESS: {
      toast.success(`Charger Published successfully`);
      return {
        ...state,
        chargerDetails: payload,
      };
    }
    case FETCH_CHARGERS_BY_STATIONS.SUCCESS: {
      return {
        ...state,
        chargerDetails: payload.results,
      };
    }

    case FETCH_CHARGER.FAIL:
    case FETCH_CHARGERS_LIST.FAIL:
    case GET_CHARGER.FAIL:
    case CREATE_CHARGER.FAIL:
    case DELETE_CHARGER.FAIL:
    case FETCH_CHARGER_BY_STATION.FAIL:
    case UPDATE_CHARGER.FAIL:
    case CHANGE_CHARGER_STATUS.FAIL:
    case CHANGE_CHARGER_IS_ACTIVE_STATUS.FAIL:
    case FETCH_CHARGERS_BY_STATIONS.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default chargerReducer;
