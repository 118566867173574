import React, { useState } from 'react';
import Card from 'components/inputs/Card';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Setting from './Setting';
import Levels from './Levels';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import Footer from 'components/general/Footer';

const ChargeCoin = () => {
  const [tab, setTab] = useState('setting');
  const { t } = useTranslation();

  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  return (
    <React.Fragment>
      <LayoutContainer className="layout-container-main-page">
        <AdminHeader title={t('header.chargeCoinManagement')} />
        <div className="page-content-wrapper scrollable">
          <div className="setting-page__main">
            <Card>
              <div>
                <div className="tab-block">
                  <div className="tab-nav-block">
                    <a
                      href="#"
                      className={`tab-nav__item setting-font ${tab === 'setting' ? 'active' : ''}`}
                      onClick={() => setTab('setting')}
                      data-content="tab-profile"
                    >
                      {t('chargeCoinManagement.settings')}
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item setting-font ${tab === 'levels' ? 'active' : ''}`}
                      onClick={() => setTab('levels')}
                      data-content="tab-role"
                    >
                      {t('chargeCoinManagement.levels')}
                    </a>
                  </div>
                  <div className="tab-content-block">
                    <div className="tab-content__item active" id="setting-tab">
                      {tab === 'setting' && <Setting />}
                      {tab === 'levels' && <Levels />}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          {/* )} */}
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default ChargeCoin;
