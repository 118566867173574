/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { useFormik } from 'formik';
import { Row, Col } from 'react-bootstrap';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { SET_CHARGING_PROFILE } from 'actions/chargerOcpp';
import TextInput from 'components/inputs/Input';
import { useTranslation } from 'react-i18next';
import { GET_STATION_CHARGER_OPERATIONS, MANAGE_STATION_CHARGER_OPERATIONS } from 'components/common/constant';

const ClearChargingProfileForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    Scema: Yup.string()
      .required(t('clearChargingProfileForm.json'))
      .test({
        name: 'valid-json',
        message: 'Invalid JSON format',
        test: (value) => {
          try {
            JSON.parse(value);
            return true;
          } catch (e) {
            return false;
          }
        },
      }),
  });
  const { values, setFieldValue, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      Scema: '',
    },
    validationSchema: schema,
    onSubmit: (values) => onSubmit(values),
  });

  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const onSubmit = useCallback((values) => {
    const formData = {
      Cid: chargerId,
      ...JSON.parse(values.Scema),
    };
    console.log(formData);
    dispatch({
      type: SET_CHARGING_PROFILE.REQUEST,
      payload: { data: formData },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            //setSubmitting(false);
            toast.success('Set Charging Profile Successfull');
            //submitForm();
          }
        }
      },
    });
  }, []);

  const getStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const manageStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );
  const handleButtonClick = (schemaType) => {
    // Convert schemaType to lowercase for case-insensitive comparison
    const lowercaseSchemaType = schemaType.toLowerCase();

    let jsonData = {};

    if (lowercaseSchemaType === 'chargepointmaxprofile') {
      jsonData = {
        connectorId: 1,
        csChargingProfiles: {
          chargingProfileId: 1,
          stackLevel: 1,
          chargingProfilePurpose: 'ChargePointMaxProfile', // Capitalize the first letter
          chargingProfileKind: 'Absolute',
          chargingSchedule: {
            startSchedule: '2023-04-25T10:00:00Z',
            chargingRateUnit: 'W',
            chargingSchedulePeriod: [
              {
                startPeriod: 0,
                limit: 150000,
              },
            ],
          },
        },
      };
    } else if (lowercaseSchemaType === 'txdefaultprofile') {
      jsonData = {
        connectorId: 1,
        csChargingProfiles: {
          chargingProfileId: 1,
          stackLevel: 1,
          chargingProfilePurpose: 'TxDefaultProfile', // Capitalize the first letter
          chargingProfileKind: 'Absolute',
          chargingSchedule: {
            startSchedule: '2023-04-25T10:00:00Z',
            chargingRateUnit: 'W',
            chargingSchedulePeriod: [
              {
                startPeriod: 0,
                limit: 150000,
              },
            ],
          },
        },
      };
    } else if (lowercaseSchemaType === 'txprofile') {
      jsonData = {
        connectorId: 1,
        csChargingProfiles: {
          chargingProfileId: 1,
          transactionId: 52626,
          stackLevel: 1,
          chargingProfilePurpose: 'TxProfile', // Capitalize the first letter
          chargingProfileKind: 'Absolute',
          chargingSchedule: {
            startSchedule: '2023-04-25T10:00:00Z',
            chargingRateUnit: 'W',
            chargingSchedulePeriod: [
              {
                startPeriod: 0,
                limit: 150000,
              },
            ],
          },
        },
      };
    }

    const jsonString = JSON.stringify(jsonData, null, 2);
    setFieldValue('Scema', jsonString);
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">{t('clearChargingProfileForm.formTitle')}</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <form onSubmit={handleSubmit}>
              <div className="server-flex">
                <Button type="button" onClick={() => handleButtonClick('chargePointMaxProfile')}>
                  {t('clearChargingProfileForm.chargePointMaxProfile')}
                </Button>
                <Button type="button" onClick={() => handleButtonClick('txDefaultProfile')}>
                  {t('clearChargingProfileForm.txDefaultProfile')}
                </Button>
                <Button type="button" onClick={() => handleButtonClick('txProfile')}>
                  {t('clearChargingProfileForm.txProfile')}
                </Button>
              </div>
              <div className="serverOperation-form__inner">
                <Row>
                  <Col>
                    <TextInput
                      as="textarea"
                      rows="15"
                      inputClass="scrollable"
                      placeholder="Set Charging Profile JSON"
                      label="Set Charging Profile"
                      name="Scema"
                      id="jsonTextarea"
                      value={values.Scema}
                      onChange={(e) => setFieldValue('Scema', e.target.value)}
                    />
                    {errors.Scema && touched.Scema && <div style={{ color: 'red' }}>{errors.Scema}</div>}
                  </Col>
                </Row>
              </div>
              <div className="serverOperation-save--btn--block">
                {!_.isEmpty(manageStationChargerOperations) ||
                (!_.isEmpty(getStationChargerOperations) && !_.isEmpty(manageStationChargerOperations)) ? (
                  <Button type="submit" className="serverOperation-save-btn save--btn">
                    {t('clearChargingProfileForm.button')}
                  </Button>
                ) : (
                  <Button type="submit" className="serverOperation-save-btn save--btn">
                    {t('clearChargingProfileForm.button')}
                  </Button>
                )}
              </div>
            </form>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default ClearChargingProfileForm;
