/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import { /*Row, Col,*/ Modal } from 'react-bootstrap';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { BsUpload } from 'react-icons/bs';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiSort } from 'react-icons/bi';
import { CREATE_BULK_RFID_FOR_FLEET, DELETE_RFID_BY_FLEET, FETCH_RFID_FOR_FLEET, GET_RFID } from 'actions/rfid';
import moment from 'moment';
import DeleteModal from 'components/general/DeleteModal';
import { CREATE_STATUS_CODE, DELETE_STATUS_CODE } from 'components/common/constant';
import RfidEditList from './RfidEditList';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ReactPagination from 'components/general/ReactPagination';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { GiSwipeCard } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';

const RfidList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { fleetId } = useParams();

  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [id, setId] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [pageWiseRfidData, setPageWiseRfidData] = useState([]);

  const isLoader = useSelector((state) => state.rfidGroup.isLoading);
  const allRfid = useSelector((state) => state.rfid.fleetRfids);
  const page = useSelector((state) => state.rfid.page);
  const totalData = useSelector((state) => state.rfid.total);
  const limit = useSelector((state) => state.rfid.limit);
  const totalPages = useSelector((state) => state.rfid.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  //Functionality of showing data pagination wise
  const rfidGroupData = allRfid.filter(({ id: id1 }) => pageWiseRfidData.some(({ id: id2 }) => id2 === id1));

  const getRfid = useCallback(
    (data = {}) => {
      dispatch({
        type: FETCH_RFID_FOR_FLEET.REQUEST,
        payload: { ...data, id: fleetId },
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              setPageWiseRfidData(res.data.results);
            }
          }
        },
      });
    },
    [pageWiseRfidData, fleetId]
  );

  const sampleRfidList = [
    {
      card_number: '',
      code: '',
      date_of_issuance: '',
      expiry_date: '',
    },
  ];

  const getRfidDetail = useCallback((id) => {
    dispatch({ type: GET_RFID.REQUEST, payload: id });
  }, []);

  const deleteRfid = useCallback(
    () =>
      dispatch({
        type: DELETE_RFID_BY_FLEET.REQUEST,
        payload: id,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getRfid({ page: 1 });
          }
        },
      }),
    [id, fleetId]
  );

  const addBulkRfid = useCallback((data) => {
    dispatch({
      type: CREATE_BULK_RFID_FOR_FLEET.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          toast.success('RFID added successfully');
          fileDownload(res.data, `${'RFID'}.xlsx`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      },
    });
  }, []);

  useEffect(() => {
    getRfid();
  }, [fleetId]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getRfid(data);
      }
    },
    [sortByItem, fleetId]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getRfid(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getRfid(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const upload = () => {
    //upload a excel file and get data of uploaded excel
    const fileUpload = document.getElementById('file');
    const regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.xls|.xlsx)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      // let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = () => {
            processExcel(reader.result);
          };
          reader.readAsBinaryString(fileUpload.files[0]);
        }
      } else {
        toast.error('This browser does not support HTML5.');
      }
    } else {
      toast.error('Please upload a valid Excel file.');
    }
  };

  const processExcel = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
    _.map(excelRows, (data) => {
      data.date_of_issuance = data.date_of_issuance ? moment((data.date_of_issuance - 25569) * 86400000).format('YYYY/MM/DD') : '';
      data.expiry_date = data.expiry_date ? moment((data.expiry_date - 25569) * 86400000).format('YYYY/MM/DD') : '';
    });
    const result = excelRows.map((e) => ({ ...e, ...{ user: fleetId } }));
    addBulkRfid(result);
  };

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setId('');
  };

  const handleCloseFormRfid = () => {
    setShowForm(false);
  };

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const downloadSampleRfid = (file, fileName) => {
    // download static Excel file
    const ws = XLSX.utils.json_to_sheet(file);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="rfid-page-main">
            <Card className="rfid-form-card">
              <div className="rfid-form__block">
                <div className="import-rfid--block">
                  <div className="upload-file-block">
                    <input onChange={upload} type="file" id="file" className="upload-file--input" />
                    <label className="upload-file-inner" htmlFor="file">
                      <div className="import-btn__block">
                        <div className="import_btn">
                          <span className="rfid-upload-file-box-icon">
                            <BsUpload />
                          </span>
                          <span className="rfid-upload-file-box-text">{t('rfidEditList.importData')}</span>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="download-sample--block">
                    <div className="download-sample-file" onClick={() => downloadSampleRfid(sampleRfidList, 'rfidList')}>
                      <GiSwipeCard color="#58d37d" size={20} />
                      {t('rfidEditList.download')}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <div className="rfid-btn-block">
              {
                <Button
                  className="rfid-btn"
                  onClick={() => {
                    setShowForm(true);
                    setIsEdit(false);
                  }}
                >
                  {t('button.addRFID')}
                </Button>
              }
            </div>
            <Card className="rfid-form-card">
              <div className="rfidList-table">
                <div className="table-responsive">
                  <table className="record-list-table">
                    <thead>
                      <tr>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.srNo')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.rfidCardNo')}</span>
                            <span className="ico" onClick={() => handleSorting('card_number', sortByItem)}>
                              <BiSort size={15} />
                            </span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.rfidTag')}</span>
                            <span className="ico" onClick={() => handleSorting('code', sortByItem)}>
                              <BiSort size={15} />
                            </span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.dateOfIssuance')}</span>
                            <span className="ico" onClick={() => handleSorting('date_of_issuance', sortByItem)}>
                              <BiSort size={15} />
                            </span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.expiryDate')}</span>
                            <span className="ico" onClick={() => handleSorting('expiry_date', sortByItem)}>
                              <BiSort size={15} />
                            </span>
                          </div>
                        </th>
                        <th className="action-col">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('rfidEditList.action')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoader ? (
                        <tr>
                          <td colSpan={10} className="border-0">
                            <div className="loader--block">
                              <Skeleton height={15} width={100} />
                            </div>
                          </td>
                        </tr>
                      ) : _.isEmpty(rfidGroupData) ? (
                        <tr>
                          <td colSpan={6} className="border-0">
                            <div className="empty-data-block">{t('rfidEditList.noData')}</div>
                          </td>
                        </tr>
                      ) : (
                        !(isLoader || _.isUndefined(isLoader)) &&
                        _.map(rfidGroupData, (item, key) => {
                          const serial_num = limit * (page - 1) + key;
                          return (
                            <tr key={`rfid-${serial_num}`}>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}.</td>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'card_number', '')}</td>
                              <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'code', '')}</td>
                              <td>
                                {isLoader ? (
                                  <Skeleton height={15} width={100} />
                                ) : item.date_of_issuance ? (
                                  moment(_.get(item, 'date_of_issuance')).format('DD MMM YYYY')
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                {isLoader ? (
                                  <Skeleton height={15} width={100} />
                                ) : item.expiry_date ? (
                                  moment(_.get(item, 'expiry_date')).format('DD MMM YYYY')
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                <div key={`action-${key}`} className="rfid">
                                  <span className="list-icon">
                                    <ul>
                                      <li
                                        className="item"
                                        onClick={() => {
                                          setShowForm(true);
                                          getRfidDetail(_.get(item, 'id', ''));
                                          setIsEdit(true);
                                        }}
                                      >
                                        {isLoader ? <Skeleton height={15} width={100} circle="true" /> : <FiEdit2 size={22} color="#3c7cdd" />}
                                      </li>
                                      <li
                                        className="item"
                                        onClick={() => {
                                          setShowDeleteModal(true);
                                          setId(_.get(item, 'id', ''));
                                        }}
                                      >
                                        {isLoader ? (
                                          <Skeleton height={15} width={100} circle="true" />
                                        ) : (
                                          <RiDeleteBinLine size={22} color="#be210b" />
                                        )}
                                      </li>
                                    </ul>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(rfidGroupData) && (
                <ReactPagination
                  currentPage={page}
                  limit={limit}
                  total={totalData}
                  handlePageClick={(pageVal) => handlePageClick(pageVal)}
                  totalPages={totalPages}
                  marginPagesDisplayed={1}
                />
              )}
            </Card>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
      {showForm && (
        <Modal show={showForm} size="xl" centered>
          <RfidEditList onClose={handleCloseFormRfid} onSuccess={(data = {}) => getRfid(data)} isEdit={isEdit} />
        </Modal>
      )}
      {showDeleteModal && (
        <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
          <DeleteModal title="RFID" onClose={handleCloseDeleteModel} onRemove={deleteRfid} />
        </Modal>
      )}
    </React.Fragment>
  );
};
export default RfidList;
