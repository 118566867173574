import React, { useEffect, useContext, useState } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import CheckBox from 'components/inputs/CheckBox';
import { GET_MODULE, UPDATE_MODULE } from 'actions/module';
import { useParams } from 'react-router-dom';
import Button from 'components/inputs/Button';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { FETCH_ALL_PERMISSION } from 'actions/permissions';
import { UPDATE_STATUS_CODE } from 'components/common/constant';

const ConfigureModule = () => {
  const { navigateTo } = useContext(NavContext);
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const moduleDetail = useSelector((state) => state.module.moduleDetail);
  const permissions = useSelector((state) => state.permissions.allPermissions);

  const updateModulePermissions = () => {
    const PermissionData = {
      ...moduleDetail,
      permissions: checkedPermissions,
      id: moduleId,
    };
    dispatch({
      type: UPDATE_MODULE.REQUEST,
      payload: PermissionData,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          navigateTo('/modules');
        }
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: FETCH_ALL_PERMISSION.REQUEST,
    });
  }, []);

  useEffect(() => {
    dispatch({ type: GET_MODULE.REQUEST, payload: moduleId });
  }, [moduleId]);

  useEffect(() => {
    if (moduleDetail?.permissions?.length > 0) {
      setCheckedPermissions(moduleDetail?.permissions.map((value) => value.id));
    }

    return () => {
      setCheckedPermissions([]);
    };
  }, [moduleDetail]);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.adminConfigureDetails')} backTo="/modules" />
        <div className="page-content-wrapper scrollable">
          <div>
            <div className="configurationmodule-table">
              <div className="table-responsive">
                <table className="record-list-table">
                  <thead>
                    <tr>
                      <th colSpan={2}>
                        <div className="sorting">
                          <span>{moduleDetail?.name || ''}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(permissions) ? (
                      <tr>
                        <td colSpan={3} className="border-0">
                          <div className="empty-data-block">{t('configurationModule.noPermission')}</div>
                        </td>
                      </tr>
                    ) : (
                      _.map(permissions, (item, key) => {
                        return (
                          <>
                            <tr key={`permission-${key}`}>
                              <td>{_.get(item, 'name', '')}</td>
                              <td>
                                <CheckBox
                                  name="permission"
                                  value={item.id}
                                  checked={_.includes(checkedPermissions, item.id)}
                                  className="configure-check-box"
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    if (isChecked) {
                                      // add to list
                                      setCheckedPermissions(_.uniq([...checkedPermissions, item.id]));
                                    } else {
                                      // remove from list
                                      setCheckedPermissions(_.uniq(checkedPermissions.filter((data) => data !== item.id)));
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          </>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="configureRole-btn-block">
              <Button type="submit" className="configureRole-form-btn  create-btn" onClick={updateModulePermissions}>
                {t('button.saveUpdate')}
              </Button>
              <Button className="configureRole-form-btn cancel_btn" onClick={() => navigateTo('/modules')}>
                {t('button.cancel')}
              </Button>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default ConfigureModule;
