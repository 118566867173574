import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Modal, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_CITY, FETCH_CITY, GET_CITY } from 'actions/address';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import CityForm from './CityForm';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

const CityList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  // const [allCityData, setAllCityData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [cityId, setCityId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseCityData, setPageWiseCityData] = useState([]);

  const cityList = useSelector((state) => state.city.city);
  const isLoader = useSelector((state) => state.city.isLoading);
  const page = useSelector((state) => state.city.page);
  const totalData = useSelector((state) => state.city.total);
  const limit = useSelector((state) => state.city.limit);
  const totalPages = useSelector((state) => state.city.totalPages);

  //Functionality of showing data pagination wise
  const cityData = cityList.filter(({ id: id1 }) => pageWiseCityData.some(({ id: id2 }) => id2 === id1));

  const getCity = useCallback(
    (data = {}) => {
      if (searchText) {
        const searchCityData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_CITY.REQUEST,
          payload: searchCityData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseCityData(res.data.results);
              }
            }
          },
        });
      } else {
        dispatch({
          type: FETCH_CITY.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseCityData(res.data.results);
              }
            }
          },
        });
      }
    },
    [pageWiseCityData, searchText]
  );

  const getCityDetail = useCallback((id) => {
    dispatch({ type: GET_CITY.REQUEST, payload: id });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setCityId('');
  };

  const deleteCity = useCallback(
    () =>
      dispatch({
        type: DELETE_CITY.REQUEST,
        payload: cityId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getCity({ page: 1 });
          }
        },
      }),
    [cityId]
  );

  useEffect(() => {
    getCity();
  }, []);

  const handleSearch = () => {
    getCity({});
  };

  // useEffect(() => {
  //   setAllCityData(
  //     _.filter(
  //       cityData,
  //       (item) =>
  //         startsWith(_.get(item, 'name', ''), searchText) ||
  //         startsWith(_.get(item, 'city_code', ''), searchText) ||
  //         startsWith(_.get(item, 'state.name', ''), searchText) ||
  //         startsWith(_.get(item, 'state.country.name', ''), searchText)
  //     )
  //   );
  // }, [searchText, cityList, pageWiseCityData]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getCity(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getCity(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getCity(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseFormCity = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.city')} />
        <div className="page-content-wrapper scrollable">
          <div className="cityList-page">
            <Card>
              <div className="cityList-page-wrapper">
                <Row className="cityList-search-box">
                  <Col xl={10} lg={9} md={8} className="cityList-search-inputBox">
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="cityList-search-boxButton">
                    <Button
                      className="cityList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      {t('button.addCity')}
                    </Button>
                  </Col>
                </Row>
                <div className="cityList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('cityForm.cityName')}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('cityForm.cityCode')}</span>
                              <span className="ico" onClick={() => handleSorting('city_code', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('dataReport.stateName')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('areaForm.countryName')}</span>
                            </div>
                          </th>
                          <th className="action-col">
                            <div className="sorting">
                              <span>{t('cityForm.action')}</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(cityData) ? (
                          <tr>
                            <td colSpan={5} className="border-0">
                              <div className="empty-data-block">{t('cityForm.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(cityData, (item, key) => {
                            return (
                              <>
                                <tr key={key}>
                                  <td>{_.get(item, 'name')}</td>
                                  <td>{_.get(item, 'city_code')}</td>
                                  <td>{_.get(item, 'state.name')}</td>
                                  <td>{_.get(item, 'state.country.name')}</td>
                                  <td>
                                    <span
                                      className="cityList-table_editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getCityDetail(_.get(item, 'id'));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 title="Edit" />
                                    </span>
                                    <span
                                      className="cityList-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setCityId(_.get(item, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine title="Delete" />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(cityData) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                  // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <CityForm onClose={handleCloseFormCity} isEdit={isEdit} onSuccess={getCity} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="city" onClose={handleCloseDeleteModel} onRemove={deleteCity} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default CityList;
