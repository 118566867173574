export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export let BASE_URL = '';
export let CHART_IDS = {};

if (REACT_APP_ENVIRONMENT === 'production') {
  BASE_URL = 'https://charts.mongodb.com/charts-project-0-ypyhz';
  CHART_IDS = Object.freeze({
    // Production Chart ID's
    BOOKING_MONTHLY: '98c76f93-eda2-4a0d-ae9f-5b2a2d5d279b',
    BOOKING_WEEKLY: '7443e52c-35f2-40a9-b281-bf951cbca183',
    CHARGERSTATUS: '224f5b21-7a7f-4ba4-9b58-6e1f83688a36',
    ENERGYSOLD_MONTHLY: 'fba7c492-8bc0-4019-861d-9322d9a4784e',
    ENERGYSOLD_WEEKLY: 'e902ba52-af61-44f3-83f8-1e357dd8f793',
    SESSIONS_TODAY: '6438d1f3-2306-4477-8df1-eb108452947d',
    TOTAL_CHARGESPOTS: 'e5ac3813-1fcd-40b0-b110-857380dc46ac',
    TOTAL_REVENUE: '6438fd6b-0ee5-4637-87c0-65a48b476e7f',
    TOTAL_USERS: '643e2914-54ed-403b-8039-04ffb14fe0e3',
    CHARGER_TYPES: 'c35cd55c-7475-432c-8572-08db89c0ebc0',
    UNITS_TODAY: '4332c894-70f2-4046-890a-44fba775c8c7',
    HEATMAP: '6455dab0-53b8-411f-8d67-984ac0aeb821',
  });
} else if ((REACT_APP_API_URL).includes('uat')) {
  BASE_URL = 'https://charts.mongodb.com/charts-project-0-ulbdz';
  // UAT CHART ID's
  CHART_IDS = Object.freeze({
    BOOKING_MONTHLY: '66cf071f-aad8-4c9d-8f1a-d9aaff11d284',
    BOOKING_WEEKLY: '66cf071f-aad8-45ee-8e15-d9aaff11d282',
    CHARGERSTATUS: '66cf071f-aad8-47e5-8b9d-d9aaff11d272',
    ENERGYSOLD_MONTHLY: '66cf071f-aad8-4227-8651-d9aaff11d280',
    ENERGYSOLD_WEEKLY: '66cf071f-aad8-4af0-89f4-d9aaff11d27e',
    SESSIONS_TODAY: '66cf071f-aad8-47d8-8f89-d9aaff11d276',
    TOTAL_CHARGESPOTS: '66cf071f-aad8-4e00-89c1-d9aaff11d274',
    TOTAL_REVENUE: '66cf071f-aad8-4337-8dbb-d9aaff11d278',
    TOTAL_USERS: '66cf071f-aad8-43b1-8102-d9aaff11d286',
    CHARGER_TYPES: '66cf071f-aad8-4b7c-8f20-d9aaff11d27c',
    UNITS_TODAY: '66cf071f-aad8-4ffa-8115-d9aaff11d27a',
    HEATMAP: '66cf071f-aad8-4529-8cd9-d9aaff11d28a',
  });
} else {
  BASE_URL = 'https://charts.mongodb.com/charts-project-0-ulbdz';
  // DEV CHART ID's
  CHART_IDS = Object.freeze({
    BOOKING_MONTHLY: '66cda6ed-445b-4c14-8f07-73f9c834713d',
    BOOKING_WEEKLY: '66cda6ed-445b-4db3-8eb2-73f9c834713b',
    CHARGERSTATUS: '66cda6ed-445b-4afe-8b9a-73f9c834712b',
    ENERGYSOLD_MONTHLY: '66cda6ed-445b-44bf-8078-73f9c8347139',
    ENERGYSOLD_WEEKLY: '66cda6ed-445b-4347-8581-73f9c8347137',
    SESSIONS_TODAY: '66cda6ed-445b-4fcb-8f9f-73f9c834712f',
    TOTAL_CHARGESPOTS: '66cda6ed-445b-4dad-837a-73f9c834712d',
    TOTAL_REVENUE: '66cda6ed-445b-4d6f-8915-73f9c8347131',
    TOTAL_USERS: '66cda6ed-445b-41e8-8a9d-73f9c834713f',
    CHARGER_TYPES: '66cda6ed-445b-4d3e-838d-73f9c8347135',
    UNITS_TODAY: '66cda6ed-445b-4add-8d7e-73f9c8347133',
    HEATMAP: '66cda6ed-445b-4410-8a89-73f9c8347143',
  });
}
