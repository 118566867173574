import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useState, useEffect, useCallback } from 'react';
import AdminHeader from 'components/header';
import { Col, Row, Modal } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsSearch, BsFilter } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { FETCH_RESERVE_SLOTS, DELETE_RESERVE_SLOTS } from 'actions/reserveSlots';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import ReserveYourSlot from './ReserveYourSlot';
import { startsWith } from 'components/common/utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';

const ReserveSlotsList = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reserveSlotId, setReserveSlotId] = useState('');
  // const [updateReserveSlotForm, setUpdateReserveSlotForm] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  // const [reserveSlotId, setReserveSlotId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [reserveSlotModal, setReserveSlotModal] = useState({
    isVisible: false,
    payload: null,
  });

  const dispatch = useDispatch();
  const reserveSlots = useSelector((state) => state.reserveSlots.reserveSlots);
  const isLoader = useSelector((state) => state.reserveSlots.isLoading);
  const page = useSelector((state) => state.reserveSlots.page);
  const totalData = useSelector((state) => state.reserveSlots.total);
  const limit = useSelector((state) => state.reserveSlots.limit);
  const totalPages = useSelector((state) => state.reserveSlots.totalPages);
  const [allReserveSlots, setAllReserveSolts] = useState([]);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getReserveSlots = useCallback((data) => {
    /* if (searchText) {
        const searchReserveSlotData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_RESERVE_SLOTS,  
          payload: searchReserveSlotData,
        });
      } else {
        dispatch({
          type: FETCH_RESERVE_SLOTS,
          payload: data ? data : {},
        });
      } */
    dispatch({
      type: FETCH_RESERVE_SLOTS.REQUEST,
      payload: data ? data : {},
    });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setReserveSlotId('');
  };

  const deleteReserveSlot = useCallback(
    () =>
      dispatch({
        type: DELETE_RESERVE_SLOTS.REQUEST,
        payload: reserveSlotId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getReserveSlots({ page: 1 });
          }
        },
      }),
    [reserveSlotId]
  );

  useEffect(() => {
    getReserveSlots();
  }, []);

  const handleSearch = () => {
    getReserveSlots({});
  };

  useEffect(() => {
    if (!searchText) {
      setAllReserveSolts(reserveSlots);
      return;
    }
    setAllReserveSolts(_.filter(allReserveSlots, (item) => startsWith(_.get(item, 'charging_station.name', ''), searchText)));
  }, [searchText, reserveSlots]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getReserveSlots(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getReserveSlots(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getReserveSlots(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  // const stationList = allReserveSlots.filter((x) => userProfileStations.indexOf(x.id) !== -1);

  function convertUTCToDDMMYYYY(utcDate) {
    const date = new Date(utcDate);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getUTCFullYear();

    const formattedDate = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
    return formattedDate;
  }

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.reserveSlots')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="user-group-page_main">
              <div className="user-group-main-block">
                <Card>
                  <div className="user-group-block">
                    <div className="user-group-search-box">
                      <Row>
                        <Col xl={8}>
                          <SearchBox
                            preIcon={<BsSearch />}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                handleSearch();
                              }
                            }}
                          />
                        </Col>
                        <Col xl={1}>
                          <Button className="reserve-slot-list-btn">
                            <BsFilter size={26} />
                          </Button>
                        </Col>
                        <Col xl={3}>
                          <Button className="user-group-btn" onClick={() => setReserveSlotModal({ isVisible: true, payload: null })}>
                            {t('button.reserveSlots')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <div className="user-group-table">
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('reserveSlots.stationName')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('reserveSlots.startDate')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('reserveSlots.endDate')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('reserveSlots.reservedHours')}</span>
                                  <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('reserveSlots.availableHours')}</span>
                                  <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div>
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('reserveSlots.actions')}</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoader ? (
                              <tr>
                                <td colSpan={10} className="border-0">
                                  <div className="loader--block">
                                    <Skeleton height={15} width={100} />
                                  </div>
                                </td>
                              </tr>
                            ) : _.isEmpty(allReserveSlots) ? (
                              <tr>
                                <td colSpan={8} className="border-0">
                                  <div className="empty-data-block">{t('reserveSlots.noData')}</div>
                                </td>
                              </tr>
                            ) : (
                              !(isLoader || _.isUndefined(isLoader)) &&
                              allReserveSlots.map((data) => {
                                const timefrom = moment(data.timeTo, 'HH:mm');
                                const timeto = moment(data.timeFrom, 'HH:mm');

                                const durationhrs = timefrom.diff(timeto, 'minutes');
                                return (
                                  <tr key={`reserve-slot-${_.get(data, 'id')}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'charging_station.name')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : convertUTCToDDMMYYYY(_.get(data, 'startDate'))}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : convertUTCToDDMMYYYY(_.get(data, 'endDate'))}</td>

                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : `${Math.round(durationhrs / 60)} hrs`}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : `${Math.abs(Math.round(durationhrs / 60 - 24))} hrs`}</td>
                                    <td>
                                      <span
                                        className="user-group-table-editIcon"
                                        onClick={() => {
                                          setReserveSlotModal({
                                            ...reserveSlotModal,
                                            isVisible: true,
                                            payload: data,
                                          });
                                        }}
                                      >
                                        {isLoader ? <Skeleton height={15} width={100} circle="true" /> : <FiEdit2 title="Edit" />}
                                      </span>
                                      <span
                                        className="user-group-table-deleteIcon"
                                        onClick={() => {
                                          setShowDeleteModal(true);
                                          setReserveSlotId(data.id);
                                        }}
                                      >
                                        {isLoader ? <Skeleton height={15} width={100} circle="true" /> : <RiDeleteBinLine title="Delete" />}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })

                              /* _.map(reserveSlots, (data, index) => {
                              const serial_num = limit * (page - 1) + index;
                              return (
                                <tr key={`user-group-${serial_num}`}>

                                  <td>Station Name 01</td>
                                  <td>11 hrs</td>
                                  <td>13 hrs</td>
                                  <td>
                                    <span
                                      className="user-group-table-editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 title="Edit" />
                                    </span>
                                    <span
                                      className="user-group-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                      }}
                                    >
                                      <RiDeleteBinLine title="Delete" />
                                    </span>
                                  </td>
                                </tr>
                              );
                            }) */
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allReserveSlots) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                    )}

                    <Modal show={reserveSlotModal.isVisible} size="xl" centered>
                      <ReserveYourSlot
                        onClose={() => {
                          setReserveSlotModal({ ...reserveSlotModal, isVisible: false });
                        }}
                        payload={reserveSlotModal.payload}
                      />
                    </Modal>

                    {showDeleteModal && (
                      <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                        <DeleteModal title="reserve slot" onClose={handleCloseDeleteModel} onRemove={deleteReserveSlot} />
                      </Modal>
                    )}

                    {/* {updateReserveSlotForm && (
                    <Modal show={updateReserveSlotForm} size="xl" centered>
                      <ReserveYourSlot
                        onClose={() => {
                          setUpdateReserveSlotForm(false);
                        }}
                        title={'Update Your Reserved Slot'}
                        reserveSlotId={reserveSlotId}
                      />
                    </Modal>
                  )} */}

                    {/* <Modal show={reserveSlotModal.isVisible} centered>
                    <DeleteModal
                      title="Reserve slot"
                      onClose={setShowDeleteModal({ ...showDeleteModal, isVisible: false })}
                      onRemove={deleteReserveSlot(showDeleteModal.payload)}
                    />
                  </Modal> */}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default ReserveSlotsList;
