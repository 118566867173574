import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_RFID_GROUP } from 'actions/rfidGroup';
import { FETCH_RFID, UPDATE_RFID } from 'actions/rfid';
import * as _ from 'lodash';
import Select from 'components/inputs/Select';
import { FETCH_MASTER_SETTING } from 'actions/masterSetting';
import { GET_RFIDS, MANAGE_RFIDS, UPDATE_STATUS_CODE } from 'components/common/constant';
import { FETCH_RFID_REQUEST, UPDATE_RFID_REQUEST } from 'actions/rfidRequest';
import { useTranslation } from 'react-i18next';

const RfidRequestModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { onClose = _.noop() } = props;
  const [searchText, setSearchText] = useState('');
  const [selectedUserGroup, setSelectedUserGroup] = useState('');

  const allRfidGroup = useSelector((state) => state.rfidGroup.rfidGroup);
  const getAllRfid = useSelector((state) => state.rfid.rfid);
  const page = useSelector((state) => state.rfidRequest.rfid);
  const rfidRequestDetail = useSelector((state) => state.rfidRequest.rfidRequestDetail);
  const allMasterSetting = useSelector((state) => state.masterSetting.masterSettings);
  const rfidGroupPage = useSelector((state) => state.rfidGroup.page);
  const rfidPage = useSelector((state) => state.rfid.page);
  const rfidGroupTotalPage = useSelector((state) => state.rfidGroup.totalPages);
  const rfidTotalPage = useSelector((state) => state.rfid.totalPages);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const getRfidByGroup = useCallback(
    (data = {}) => {
      setSelectedUserGroup(data.group ? data.group : '');
      if (searchText) {
        const rfidByGroupData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_RFID.REQUEST, payload: rfidByGroupData });
      } else {
        dispatch({ type: FETCH_RFID.REQUEST, payload: data });
      }
    },
    [searchText, selectedUserGroup]
  );

  const getAllRfidRequest = useCallback((data = {}) => {
    dispatch({ type: FETCH_RFID_REQUEST.REQUEST, payload: data });
  }, []);

  const updateRfid = useCallback((data = {}) => {
    dispatch({
      type: UPDATE_RFID.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const updateRfidRequest = useCallback((data = {}) => {
    dispatch({
      type: UPDATE_RFID_REQUEST.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          getAllRfidRequest({ page });
        }
      },
    });
  }, []);

  const RfidGroups = useCallback((data = {}) => {
    dispatch({ type: FETCH_RFID_GROUP.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    if (searchText) {
      getRfidByGroup({ group: selectedUserGroup });
    }
  }, [searchText, selectedUserGroup]);

  useEffect(() => {
    RfidGroups();
    dispatch({ type: FETCH_MASTER_SETTING.REQUEST });
  }, []);

  const getRfidRequestPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_RFIDS),
      (data) => data.name
    );

  const manageRfidRequestPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_RFIDS),
      (data) => data.name
    );

  const RfidRequestSchema = Yup.object().shape({
    group: Yup.string().required(t('rfidRequestModal.group')),
    card_number: Yup.string().required(t('rfidRequestModal.cardNumber')),
  });

  const lowBalance = _.filter(allMasterSetting, (item) => item.key_name === 'rfid_settings');
  const lowBalanceAmount = _.map(lowBalance, (item) => _.get(item, 'key_value.low_balance_amt', ''));
  const allRfid = _.filter(getAllRfid, (item) => item['user'] === undefined || item['user'] === null);

  return (
    <React.Fragment>
      <div className="rfid-request-details-page__main">
        <Card className="rfid-request-details-page-inner">
          <div className="rfid-request-details-header__block">
            <div className="rfid-request-details-header-block_inner">
              <span>{`Assign RFID`}</span>
            </div>
            <span className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </span>
          </div>
          <div className="rfid-request-details-body__block">
            <div className="rfid-request-details-body-block__inner">
              <div className="content-wrapper">
                <span className="rfid-request-details-data">{t('rfidRequestModal.name')}</span>
                <span className="rfid-request-details-name">
                  {_.get(rfidRequestDetail, 'first_name', '') + ' ' + _.get(rfidRequestDetail, 'last_name', '')}
                </span>
              </div>
            </div>
            <div className="rfid-request-balance--block">
              <span className="rfid-request-balance-text">
                {t('rfidRequestModal.minLowBalance')} {lowBalanceAmount && `₹${lowBalanceAmount.toString()}`}
              </span>
            </div>
            <div className="rfid-request-details--block">
              <Formik
                // enableReinitialize={true}
                initialValues={{
                  group: '',
                  user: '',
                  card_number: '',
                }}
                validationSchema={RfidRequestSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const selectedRfidId = _.get(values, 'card_number', '');
                  const customerId = _.get(rfidRequestDetail, 'customer_involved.id', '');
                  const firstname = _.get(rfidRequestDetail, 'first_name', '');
                  const lastname = _.get(rfidRequestDetail, 'last_name', '');
                  const assignRfidData = _.omit(values, 'card_number');
                  const data = { ...assignRfidData, user: customerId, id: selectedRfidId, first_name: firstname, last_name: lastname };
                  updateRfid(data);
                  const rfidRequestData = { status: 'Approved', id: _.get(rfidRequestDetail, 'id', '') };
                  setTimeout(() => {
                    updateRfidRequest(rfidRequestData);
                  }, 1000);
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={12}>
                        <Select
                          isRequired
                          label={t('rfidRequestModal.rfidGroup')}
                          options={_.map(allRfidGroup, (group) => {
                            return { label: group.name, value: group.id };
                          })}
                          placeholder={t('placeHolder.selectRFIDCardNo')}
                          name="group"
                          value={_.get(values, 'group')}
                          onMenuScrollDown={true}
                          getDataOnScrollDown={RfidGroups}
                          page={rfidGroupPage}
                          totalPage={rfidGroupTotalPage}
                          onChange={(val) => {
                            getRfidByGroup({ group: val });
                            setFieldValue(`group`, val);
                            setFieldValue(`card_number`, '');
                          }}
                          error={errors.group && touched.group ? errors.group : null}
                        />
                      </Col>
                      <Col lg={12}>
                        <Select
                          isRequired
                          label={t('rfidRequestModal.rfidCardNo')}
                          options={
                            values.group &&
                            _.map(
                              _.filter(allRfid, (data) => data.card_number),
                              (item) => {
                                return { label: _.get(item, 'card_number'), value: item.id };
                              }
                            )
                          }
                          placeholder={t('placeHolder.selectRFIDCardNo')}
                          name="card_number"
                          value={values.card_number}
                          onMenuScrollDown={true}
                          getDataOnScrollDown={(data) => getRfidByGroup({ ...data, group: _.get(values, 'group') })}
                          page={rfidPage}
                          totalPage={rfidTotalPage}
                          onInputChange={(text) => setSearchText(text)}
                          onChange={(val) => {
                            setFieldValue('card_number', val);
                          }}
                          error={errors.card_number && touched.card_number ? errors.card_number : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      {!_.isEmpty(manageRfidRequestPermissions) ||
                      (!_.isEmpty(getRfidRequestPermissions) && !_.isEmpty(manageRfidRequestPermissions)) ? (
                        <Button type="submit" className="form_btn">
                          {t('button.confirm')}
                        </Button>
                      ) : (
                        <Button className="form_btn">{t('button.confirm')}</Button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
RfidRequestModal.propTypes = {
  onClose: PropTypes.func,
};
export default RfidRequestModal;
