import { FETCH_OEM_ERROR_CODE, GET_OEM_ERROR_CODE, CREATE_OEM_ERROR_CODE, UPDATE_OEM_ERROR_CODE, DELETE_OEM_ERROR_CODE } from 'actions/oemErrorCode';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  oemErrorCodes: [],
  oemErrorCodeDetail: {},
  ocppErrorCodes: [],
  ocppErrorCodeDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const oemErrorCodeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_OEM_ERROR_CODE.REQUEST:
    case GET_OEM_ERROR_CODE.REQUEST:
    case CREATE_OEM_ERROR_CODE.REQUEST:
    case DELETE_OEM_ERROR_CODE.REQUEST: {
      return { ...state, isLoading: true, oemErrorCodeDetail: {} };
    }

    case UPDATE_OEM_ERROR_CODE.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_OEM_ERROR_CODE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        oemErrorCodes: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_OEM_ERROR_CODE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        oemErrorCodeDetail: payload,
      };
    }

    case CREATE_OEM_ERROR_CODE.SUCCESS: {
      toast.success('Oem error code added successfully');
      return {
        ...state,
        isLoading: false,
        oemErrorCodes: _.concat(state.oemErrorCodes, payload),
      };
    }

    case UPDATE_OEM_ERROR_CODE.SUCCESS: {
      toast.success(' Oem error code updated successfully');
      return {
        ...state,
        oemErrorCodes: state.oemErrorCodes.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_OEM_ERROR_CODE.SUCCESS: {
      toast.success('Oem error code deleted successfully');
      return {
        ...state,
        isLoading: false,
        oemErrorCodes: state.oemErrorCodes.filter((data) => data.id !== payload),
      };
    }

    case FETCH_OEM_ERROR_CODE.FAIL:
    case GET_OEM_ERROR_CODE.FAIL:
    case CREATE_OEM_ERROR_CODE.FAIL:
    case UPDATE_OEM_ERROR_CODE.FAIL:
    case DELETE_OEM_ERROR_CODE.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default oemErrorCodeReducer;
