import { get, post, deleteRequest, patch, downloadFile } from 'utils/api';

export const getRfid = (data) => {
  return get(`/rfids`, data);
};

export const getRfidById = (id) => {
  return get(`/rfids/${id}`);
};

export const addRfid = (data) => {
  return post(`/rfids`, data);
};

export const updateRfid = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/rfids/${id}`, data);
};

export const deleteRfid = (id) => {
  return deleteRequest(`/rfids/${id}`);
};

export const addBulkRfid = (data) => {
  return downloadFile(`/rfids/bulk_add`, data);
};

export const getRfidForFleet = (data) => {
  const id = data.id;
  delete data['id'];
  return get(`/rfids/getFleetOwnerRfids/${id}`, data);
};

export const addBulkRfidForFleet = (data) => {
  return downloadFile(`/rfids/fleet_management/bulk_add`, data);
};

export const updateVehicleRfid = (id) => {
  return get(`/rfids/unassign/${id}`);
};

export const deleteRfidByFleet = (id) => {
  return deleteRequest(`/rfids/deletefleetrfid/${id}`);
};

export const rfid = {
  getRfid,
  getRfidById,
  addRfid,
  updateRfid,
  deleteRfid,
  addBulkRfid,
  addBulkRfidForFleet,
  getRfidForFleet,
  updateVehicleRfid,
  deleteRfidByFleet,
};
