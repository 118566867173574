import { get, post, deleteRequest, patch, downloadFile } from 'utils/api';
import { VRN_UPDATE } from './endpointURLs';

export const getVehicle = (data) => {
  return get(`/vehicles`, data);
};
export const getVehicleById = (id) => {
  return get(`/vehicles/${id}`);
};

export const addVehicle = (data) => {
  return post(`/vehicles`, data);
};

export const addMultipleVehicle = (data) => {
  return post(`/vehicles/multipleVehicle`, data);
};

export const updateVehicle = (data) => {
  const id = data.id;
  delete data['id'];

  return patch(`/vehicles/${id}`, data);
};

export const deleteVehicle = (id) => {
  return deleteRequest(`/vehicles/${id}`);
};

export const downloadExcelVehicle = (data) => {
  const ownershipType = data.ownership_type;
  delete data['ownership_type'];
  return downloadFile(`/vehicles/viewVehicles?ownership_type=${ownershipType}`, data);
};

export const updateVRN = (data) => {
  return post(`${VRN_UPDATE}`, data);
};

export const vehicle = {
  getVehicle,
  getVehicleById,
  addVehicle,
  addMultipleVehicle,
  updateVehicle,
  deleteVehicle,
  downloadExcelVehicle,
  updateVRN,
};
