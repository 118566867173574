import { FETCH_VEHICLE_MAKE, GET_VEHICLE_MAKE, CREATE_VEHICLE_MAKE, UPDATE_VEHICLE_MAKE, DELETE_VEHICLE_MAKE } from 'actions/vehicleMake';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  vehicleMakes: [],
  vehicleMakeDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const vehicleMakeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_VEHICLE_MAKE.REQUEST:
    case GET_VEHICLE_MAKE.REQUEST:
    case CREATE_VEHICLE_MAKE.REQUEST:
    case DELETE_VEHICLE_MAKE.REQUEST: {
      return { ...state, isLoading: true, vehicleMakeDetail: {} };
    }

    case UPDATE_VEHICLE_MAKE.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_VEHICLE_MAKE.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          vehicleMakes: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          vehicleMakes: _.concat(state.vehicleMakes, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_VEHICLE_MAKE.SUCCESS: {
      return {
        ...state,
        vehicleMakeDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_VEHICLE_MAKE.SUCCESS: {
      toast.success('EV make added successfully');
      return {
        ...state,
        vehicleMakes: _.concat(state.vehicleMakes, payload),
        isLoading: false,
      };
    }

    case UPDATE_VEHICLE_MAKE.SUCCESS: {
      toast.success('EV make updated successfully');
      return {
        ...state,
        vehicleMakes: state.vehicleMakes.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_VEHICLE_MAKE.SUCCESS: {
      toast.success('EV make deleted successfully');
      return {
        ...state,
        isLoading: false,
        vehicleMakes: state.vehicleMakes.filter((data) => data.id !== payload),
      };
    }

    case FETCH_VEHICLE_MAKE.FAIL:
    case GET_VEHICLE_MAKE.FAIL:
    case CREATE_VEHICLE_MAKE.FAIL:
    case UPDATE_VEHICLE_MAKE.FAIL:
    case DELETE_VEHICLE_MAKE.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default vehicleMakeReducer;
