import { FETCH_AREA, GET_AREA, CREATE_AREA, UPDATE_AREA, DELETE_AREA } from 'actions/address';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  areas: [],
  areaDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const areaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_AREA.REQUEST:
    case GET_AREA.REQUEST:
    case CREATE_AREA.REQUEST:
    case DELETE_AREA.REQUEST: {
      return { ...state, isLoading: true, areaDetail: {} };
    }

    case UPDATE_AREA.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_AREA.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          areas: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          areas: _.concat(state.areas, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_AREA.SUCCESS: {
      return {
        ...state,
        areaDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_AREA.SUCCESS: {
      toast.success('Area added successfully');
      return {
        ...state,
        areas: _.concat(state.areas, payload),
        isLoading: false,
      };
    }

    case UPDATE_AREA.SUCCESS: {
      toast.success('Area updated successfully');
      return {
        ...state,
        isLoading: false,
        areas: state.areas.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_AREA.SUCCESS: {
      toast.success('Area deleted successfully');
      return {
        ...state,
        isLoading: false,
        areas: state.areas.filter((data) => data.id !== payload),
      };
    }

    case FETCH_AREA.FAIL:
    case GET_AREA.FAIL:
    case CREATE_AREA.FAIL:
    case UPDATE_AREA.FAIL:
    case DELETE_AREA.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default areaReducer;
