import { get, post, deleteRequest, patch } from 'utils/api';

export const getVehicleMake = (data) => {
  return get(`/evmasterDatas`, data);
};

export const getVehicleMakeById = (id) => {
  return get(`/evmasterDatas/${id}`);
};
export const addVehicleMake = (data) => {
  return post(`/evmasterDatas`, data);
};

export const updateVehicleMake = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/evmasterDatas/${id}`, data);
};

export const deleteVehicleMake = (id) => {
  return deleteRequest(`/evmasterDatas/${id}`);
};

export const vehicleMake = {
  getVehicleMake,
  getVehicleMakeById,
  addVehicleMake,
  updateVehicleMake,
  deleteVehicleMake,
};
