import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const HasDropDown = (props) => {
  const { trigger, style, triggerClass, popupClass } = props;

  const [isOpen, setIsOpen] = useState('');
  const [stayOpen, setStayOpen] = useState(false);
  const nextRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', onClickDropDown);
  }, []);

  useEffect(() => {
    document.removeEventListener('mousedown', onClickDropDown);
  }, []);

  useEffect(() => {
    const clickedOutside = (evt) => {
      if (isOpen && nextRef.current && !nextRef.current.contains(evt.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', clickedOutside);
    return () => {
      document.removeEventListener('mousedown', clickedOutside);
    };
  }, [isOpen]);

  function onClickDropDown(e) {
    const current = document.getElementById('has-dropdown');
    const input = document.querySelector('#new-status');

    if (e.target.contains(current) && !(input === e.target)) setIsOpen(false);
    else if (e.target !== current && !current.contains(e.target)) {
      if (stayOpen) setStayOpen(false);
      else setIsOpen(false);
    } else if (input === e.target) {
      setIsOpen(true);
    }
  }

  const handlePopupClick = (event) => {
    if (event.target.classList && event.target.classList.contains('has-dropdown-link')) {
      setIsOpen(!isOpen);
      return true;
    }
    if (event.target.classList && event.target.classList.contains('has-dropdown-stayopen-link')) {
      event.preventDefault();
      return false;
    }
    setIsOpen(!isOpen);
    event.preventDefault();
    return false;
  };

  const children = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {});
  });

  return (
    <div
      id={'has-dropdown'}
      className={`has-dropdown ${isOpen ? 'open' : ''} ${triggerClass ? triggerClass : ''}`}
      onClick={handlePopupClick}
      ref={nextRef}
    >
      {trigger}
      {isOpen && (
        <div className={`popup ${popupClass || ''}`} style={style}>
          <div className="popup-container">{children}</div>
        </div>
      )}
    </div>
  );
};

HasDropDown.propTypes = {
  trigger: PropTypes.object,
  children: PropTypes.node,
  style: PropTypes.object,
  triggerClass: PropTypes.string,
  popupClass: PropTypes.string,
};

export default HasDropDown;
