import { deleteRequest, get, patch, post } from 'utils/api';

export const getAmenity = (data) => {
  return get('/amenity', data);
};

export const getAmenityById = (id) => {
  return get(`/amenity/${id}`);
};

export const addAmenity = (data) => {
  return post(`/amenity`, data);
};

export const updateAmenity = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/amenity/${id}`, data);
};

export const deleteAmenity = (id) => {
  return deleteRequest(`/amenity/${id}`);
};

export const amenity = {
  getAmenity,
  getAmenityById,
  addAmenity,
  updateAmenity,
  deleteAmenity,
};
