import { HMIL_CUSTOM_CHARGING_REPORT } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  HMILCustomChargingReport: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const HMILCustomChargingReportreducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case HMIL_CUSTOM_CHARGING_REPORT.REQUEST: {
      return { ...state, isLoading: true };
    }

    case HMIL_CUSTOM_CHARGING_REPORT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        HMILCustomChargingReport: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case HMIL_CUSTOM_CHARGING_REPORT.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default HMILCustomChargingReportreducer;
