import { FETCH_CONNECTOR_TYPE, GET_CONNECTOR_TYPE, CREATE_CONNECTOR_TYPE, UPDATE_CONNECTOR_TYPE, DELETE_CONNECTOR_TYPE } from 'actions/connectorType';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  connectorTypes: [],
  connectorTypeDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const connectorTypeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CONNECTOR_TYPE.REQUEST:
    case GET_CONNECTOR_TYPE.REQUEST:
    case CREATE_CONNECTOR_TYPE.REQUEST:
    case DELETE_CONNECTOR_TYPE.REQUEST: {
      return { ...state, isLoading: true, connectorTypeDetail: {} };
    }

    case UPDATE_CONNECTOR_TYPE: {
      return { ...state, isLoading: true };
    }

    case FETCH_CONNECTOR_TYPE.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          connectorTypes: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          connectorTypes: _.concat(state.connectorTypes, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_CONNECTOR_TYPE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        connectorTypeDetail: payload,
      };
    }

    case CREATE_CONNECTOR_TYPE.SUCCESS: {
      toast.success('Connector added successfully');
      return {
        ...state,
        isLoading: false,
        connectorTypes: _.concat(state.connectorTypes, payload),
      };
    }

    case UPDATE_CONNECTOR_TYPE.SUCCESS: {
      toast.success('Connector updated successfully');
      return {
        ...state,
        connectorTypes: state.connectorTypes.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_CONNECTOR_TYPE.SUCCESS: {
      toast.success('Connector deleted successfully');
      return {
        ...state,
        isLoading: false,
        connectorTypes: state.connectorTypes.filter((data) => data.id !== payload),
      };
    }

    case FETCH_CONNECTOR_TYPE.FAIL:
    case GET_CONNECTOR_TYPE.FAIL:
    case CREATE_CONNECTOR_TYPE.FAIL:
    case UPDATE_CONNECTOR_TYPE.FAIL:
    case DELETE_CONNECTOR_TYPE.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default connectorTypeReducer;
