import { createActionTypes } from 'utils/action/actionTypes';

export const CREATE_OCPI_PARTNER_GROUP = createActionTypes('CREATE_OCPI_PARTNER_GROUP');
export const FETCH_OCPI_PARTNER_GROUP = createActionTypes('FETCH_OCPI_PARTNER_GROUP');
export const GET_OCPI_PARTNER_GROUP = createActionTypes('GET_OCPI_PARTNER_GROUP');
export const DELETE_OCPI_PARTNER_GROUP = createActionTypes('DELETE_OCPI_PARTNER_GROUP');
export const DELETE_OCPI_PARTNER = createActionTypes('DELETE_OCPI_PARTNER');
export const UPDATE_OCPI_PARTNER_GROUP = createActionTypes('UPDATE_OCPI_PARTNER_GROUP');
export const ALL_USERS_IN_OCPI_PARTNER_GROUP = createActionTypes('ALL_USERS_IN_OCPI_PARTNER_GROUP');
export const ADD_PARTY_IN_OCPI_PARTNER_GROUP = createActionTypes('ADD_PARTY_IN_OCPI_PARTNER_GROUP');
export const CREATE_BULK_USERS_TO_OCPI_PARTNER_GROUP = createActionTypes('CREATE_BULK_USERS_TO_OCPI_PARTNER_GROUP');
