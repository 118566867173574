import { FETCH_OWNERSHIP_TYPE, GET_OWNERSHIP_TYPE, CREATE_OWNERSHIP_TYPE, UPDATE_OWNERSHIP_TYPE, DELETE_OWNERSHIP_TYPE } from 'actions/ownershipType';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  ownershipTypes: [],
  ownershipTypeDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const ownershipTypeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_OWNERSHIP_TYPE.REQUEST:
    case GET_OWNERSHIP_TYPE.REQUEST:
    case CREATE_OWNERSHIP_TYPE.REQUEST:
    case DELETE_OWNERSHIP_TYPE.REQUEST: {
      return { ...state, isLoading: true, ownershipTypeDetail: {} };
    }

    case UPDATE_OWNERSHIP_TYPE.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_OWNERSHIP_TYPE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ownershipTypes: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_OWNERSHIP_TYPE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ownershipTypeDetail: payload,
      };
    }

    case CREATE_OWNERSHIP_TYPE.SUCCESS: {
      toast.success('Access type added successfully');
      return {
        ...state,
        isLoading: false,
        ownershipTypes: _.concat(state.ownershipTypes, payload),
      };
    }

    case UPDATE_OWNERSHIP_TYPE.SUCCESS: {
      toast.success('Access type updated successfully');
      return {
        ...state,
        ownershipTypes: state.ownershipTypes.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_OWNERSHIP_TYPE.SUCCESS: {
      toast.success('Access type deleted successfully');
      return {
        ...state,
        isLoading: false,
        ownershipTypes: state.ownershipTypes.filter((data) => data.id !== payload),
      };
    }

    case FETCH_OWNERSHIP_TYPE.FAIL:
    case GET_OWNERSHIP_TYPE.FAIL:
    case CREATE_OWNERSHIP_TYPE.FAIL:
    case UPDATE_OWNERSHIP_TYPE.FAIL:
    case DELETE_OWNERSHIP_TYPE.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default ownershipTypeReducer;
