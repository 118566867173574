import React, { useCallback } from 'react';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { CLEAR_CHARGING_PROFILE } from 'actions/chargerOcpp';
import TextInput from 'components/inputs/Input';
import { useTranslation } from 'react-i18next';
import { GET_STATION_CHARGER_OPERATIONS, MANAGE_STATION_CHARGER_OPERATIONS } from 'components/common/constant';

const ClearChargingProfileForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const handleSubmit = useCallback((data, submitform) => {
    dispatch({
      type: CLEAR_CHARGING_PROFILE.REQUEST,
      payload: { data },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Clear Charging Profile Successfull');
            submitform();
          }
        }
      },
    });
  }, []);

  const schema = Yup.object().shape({
    Scema: Yup.string()
      .required(t('clearCharging.json'))
      .test({
        name: 'valid-json',
        message: 'Invalid JSON format',
        test: (value) => {
          try {
            JSON.parse(value);
            return true;
          } catch (e) {
            return false;
          }
        },
      }),
  });

  const getStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const manageStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const initialValues = {
    Scema: '{"id":2,"connectorId":1,"chargingProfilePurpose":"TxnProfile","stackLevel":1}',
  };

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">{t('clearCharging.formTitle')}</div>
      <div className="serverOperation-content-wrapper">
        <Card className="serverOperation-form-main">
          <div className="serverOperation-form__block">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting, submitform }) => {
                const formData = {
                  chargerId: chargerId,
                  ...JSON.parse(values.Scema),
                };
                console.log(formData);
                handleSubmit(formData, submitform);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="serverOperation-form__inner">
                    <Row>
                      <Col>
                        <TextInput
                          as="textarea"
                          rows="15"
                          inputClass="scrollable"
                          placeholder={t('clearCharging.clearChargingProfileJSON')}
                          label={t('clearCharging.clearChargingProfile')}
                          name="Scema"
                          value={values.Scema}
                          onChange={(e) => setFieldValue('Scema', e.target.value)}
                        />
                        {errors.Scema && touched.Scema && <div style={{ color: 'red' }}>{errors.Scema}</div>}
                      </Col>
                    </Row>
                  </div>
                  <div className="serverOperation-save--btn--block">
                    {!_.isEmpty(manageStationChargerOperations) ||
                    (!_.isEmpty(getStationChargerOperations) && !_.isEmpty(manageStationChargerOperations)) ? (
                      <Button type="submit" className="serverOperation-save-btn save--btn" disabled={isSubmitting}>
                        {t('clearCharging.button')}
                      </Button>
                    ) : (
                      <Button className="serverOperation-save-btn save--btn">{t('clearCharging.button')}</Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default ClearChargingProfileForm;
