import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_ELECTRICITY_SUPPLIER,
  GET_ELECTRICITY_SUPPLIER,
  CREATE_ELECTRICITY_SUPPLIER,
  UPDATE_ELECTRICITY_SUPPLIER,
  DELETE_ELECTRICITY_SUPPLIER,
} from 'actions/electricitySupplier';

function* fetchElectricitySupplier(action) {
  try {
    const response = yield call(API.getElectricitySuppliers, action.payload);
    yield put({ type: FETCH_ELECTRICITY_SUPPLIER.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_ELECTRICITY_SUPPLIER.FAIL, payload: { error: e } });
  }
}

function* getElectricitySupplierById(action) {
  try {
    const response = yield call(API.getElectricitySuppliersById, action.payload);
    yield put({ type: GET_ELECTRICITY_SUPPLIER.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_ELECTRICITY_SUPPLIER.FAIL, payload: { error: e } });
  }
}

function* createElectricitySupplier(action) {
  try {
    const response = yield call(API.addElectricitySuppliers, action.payload);
    yield put({ type: CREATE_ELECTRICITY_SUPPLIER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_ELECTRICITY_SUPPLIER.FAIL, payload: { error: e } });
  }
}

function* updateElectricitySupplier(action) {
  try {
    const response = yield call(API.updateElectricitySuppliers, action.payload);
    yield put({ type: UPDATE_ELECTRICITY_SUPPLIER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_ELECTRICITY_SUPPLIER.FAIL, payload: { error: e } });
  }
}

function* deleteElectricitySupplier(action) {
  try {
    const response = yield call(API.deleteElectricitySuppliers, action.payload);
    yield put({ type: DELETE_ELECTRICITY_SUPPLIER.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_ELECTRICITY_SUPPLIER.FAIL, payload: { error: e } });
  }
}

function* electricitySupplier() {
  yield takeLatest(FETCH_ELECTRICITY_SUPPLIER.REQUEST, fetchElectricitySupplier);
  yield takeLatest(GET_ELECTRICITY_SUPPLIER.REQUEST, getElectricitySupplierById);
  yield takeLatest(CREATE_ELECTRICITY_SUPPLIER.REQUEST, createElectricitySupplier);
  yield takeLatest(UPDATE_ELECTRICITY_SUPPLIER.REQUEST, updateElectricitySupplier);
  yield takeLatest(DELETE_ELECTRICITY_SUPPLIER.REQUEST, deleteElectricitySupplier);
}

export default electricitySupplier;
