import { GET_CITY_BY_STATE } from 'actions/address';
import * as _ from 'lodash';

const initialState = {
  isLoading: false,
  cityByStates: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const cityByStateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CITY_BY_STATE.REQUEST: {
      return { ...state, isLoading: true };
    }

    case GET_CITY_BY_STATE.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          cityByStates: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          cityByStates: _.concat(state.cityByStates, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_CITY_BY_STATE.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default cityByStateReducer;
