import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { MdOutlineLogout } from 'react-icons/md';
//import { useSelector } from 'react-redux';
//import { useHistory } from 'react-router-dom';
import { cookie } from 'utils/cookies/cookies';
//import { NavContext } from 'components/privateRoute/PrivateRoute';
import { useTranslation } from 'react-i18next';
const LogoutModal = (props) => {
  const { onClose = _.noop(), onRemove = _.noop() } = props;
  //const history = useHistory();
  // const { navigateTo } = useContext(NavContext);
  const { t } = useTranslation();
  const userRole = cookie.get('user-role');
  const currentUserRole = cookie.get('appUserRole');
  const currentUserToken = cookie.get('appToken');
  const handleClick = () => {
    cookie.set('token', currentUserToken);
    cookie.set('user-role', currentUserRole);
    setTimeout(() => {
      window.location.href = '/tenantAdmin';
    }, 1000);
  };
  return (
    <React.Fragment>
      <div className="logout-modal-main_page">
        <div className="logout-modal--inner">
          <div className="logout-modal---block_title">
            <div className="inner--block_title">
              <div className="title">
                <MdOutlineLogout />
              </div>
              <p className="sub-title">{t('logout.title')}</p>
            </div>
          </div>
          <div className="logout-modal-input-box__block">
            <Row>
              <Col lg={4}>
                <Button className="logout-model--btn cancel--btn" onClick={onClose}>
                  {t('button.cancel')}
                </Button>
              </Col>

              {userRole === 'tenant_admin' && currentUserRole === 'super_admin' && (
                <Col lg={4}>
                  <Button
                    className="logout-model--btn cancel--btn"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {t('button.superAdmin')}
                  </Button>
                </Col>
              )}
              <Col lg={4}>
                <Button className="logout-model--btn  remove--btn" onClick={onRemove}>
                  {t('button.logout')}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
LogoutModal.propTypes = {
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
};
export default LogoutModal;
