import React from 'react';
import PropTypes from 'prop-types';

const ToggleSwitch = ({ containerClassName, labelClassName, className, id, name, checked, onChange, children, disabled, ...rest }) => {
  return (
    <div className={`d-inline-block ${containerClassName || ''}`}>
      <label className="switch" htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={`form-check-input ${className || ''}`}
          {...rest}
        />
        <span className="slider round"></span>
      </label>
      <label className={`form-check-label ${labelClassName || ''}`} htmlFor={id}>
        {children}
      </label>
    </div>
  );
};

ToggleSwitch.propTypes = {
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string, // Include the name prop
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
};

export default ToggleSwitch;
