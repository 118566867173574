import { post } from 'utils/api';

export const getAlerts = (data) => {
  const page = data.page ? data.page : 1;
  const limit = data.limit ? data.limit : 10;

  delete data['page'];
  delete data['limit'];
  return post(`/notifications/charging-notification-alert?page=${parseInt(page)}&limit=${parseInt(limit)}`, data);
};

export const alerts = {
  getAlerts,
};
