import { FETCH_PERMISSION, GET_PERMISSION, CREATE_PERMISSION, UPDATE_PERMISSION, DELETE_PERMISSION, FETCH_ALL_PERMISSION } from 'actions/permissions';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  permissions: [],
  permissionDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const permissionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PERMISSION.REQUEST:
    case FETCH_ALL_PERMISSION.REQUEST:
    case GET_PERMISSION.REQUEST:
    case CREATE_PERMISSION.REQUEST:
    case DELETE_PERMISSION.REQUEST: {
      return { ...state, isLoading: true, permissionDetail: {} };
    }

    case UPDATE_PERMISSION.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_PERMISSION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        permissions: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FETCH_ALL_PERMISSION.SUCCESS: {
      return {
        ...state,
        allPermissions: payload,
      };

      // for security empty all permissions on page unmount
    }

    case GET_PERMISSION.SUCCESS: {
      return {
        ...state,
        permissionDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_PERMISSION.SUCCESS: {
      toast.success('Permission added successfully');
      return {
        ...state,
        permissions: _.concat(state.permissions, payload),
        isLoading: false,
      };
    }

    case UPDATE_PERMISSION.SUCCESS: {
      toast.success('Permission updated successfully');
      return {
        ...state,
        isLoading: false,
        permissions: state.permissions.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_PERMISSION.SUCCESS: {
      toast.success('permission deleted successfully');
      return {
        ...state,
        isLoading: false,
        permissions: state.permissions.filter((data) => data.id !== payload),
      };
    }

    case FETCH_PERMISSION.FAIL:
    case FETCH_ALL_PERMISSION.FAIL:
    case GET_PERMISSION.FAIL:
    case CREATE_PERMISSION.FAIL:
    case UPDATE_PERMISSION.FAIL:
    case DELETE_PERMISSION.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default permissionReducer;
