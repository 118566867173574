import { FETCH_TENANT_SETTING, GET_TENANT_SETTING, UPDATE_TENANT_SETTING } from 'actions/tenantSetting';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  tenantSettings: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const tenantSettingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TENANT_SETTING:
    case GET_TENANT_SETTING:
    case UPDATE_TENANT_SETTING: {
      return { ...state, isLoading: true };
    }

    case FETCH_TENANT_SETTING.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        tenantSettings: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_TENANT_SETTING.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case UPDATE_TENANT_SETTING.SUCCESS: {
      toast.success('Tenant settings updated successfully');
      return {
        ...state,
        isLoading: false,
        tenantSettings: state.tenantSettings.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case FETCH_TENANT_SETTING.FAIL:
    case GET_TENANT_SETTING.FAIL:
    case UPDATE_TENANT_SETTING.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default tenantSettingReducer;
