import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { GENERATE_QR_CODE } from 'actions/generateQrCode';

function* getQrCode(action) {
  try {
    const response = yield call(API.getQrCode, action.payload);
    yield put({ type: GENERATE_QR_CODE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GENERATE_QR_CODE.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* generateQrCode() {
  yield takeLatest(GENERATE_QR_CODE.REQUEST, getQrCode);
}

export default generateQrCode;
