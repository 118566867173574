import {
  CREATE_COMMUNICATION_MANAGER,
  FETCH_COMMUNICATION_MANAGER,
  GET_COMMUNICATION_MANAGER,
  DELETE_COMMUNICATION_MANAGER,
  UPDATE_COMMUNICATION_MANAGER,
} from 'actions/communicationManager.js';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  communicationManager: [],
  communicationManagerDetails: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const communicationManagerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COMMUNICATION_MANAGER.REQUEST:
    case CREATE_COMMUNICATION_MANAGER.REQUEST:
    case GET_COMMUNICATION_MANAGER.REQUEST:
    case DELETE_COMMUNICATION_MANAGER.REQUEST: {
      return { ...state, isLoading: true, communicationManagerDetails: {} };
    }

    case UPDATE_COMMUNICATION_MANAGER: {
      return { ...state, isLoading: true };
    }

    case FETCH_COMMUNICATION_MANAGER.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          communicationManager: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          // communicationManager: _.concat(state.communicationManager, payload.results),
          communicationManager: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_COMMUNICATION_MANAGER.SUCCESS: {
      return {
        ...state,
        communicationManagerDetails: payload,
        isLoading: false,
      };
    }

    case CREATE_COMMUNICATION_MANAGER.SUCCESS: {
      toast.success(' Notification creaded successfully');
      return {
        ...state,
        communicationManager: _.concat(state.communicationManager, payload),
        isLoading: false,
      };
    }

    case UPDATE_COMMUNICATION_MANAGER.SUCCESS: {
      toast.success('Notification updated successfully');
      console.log(state.communicationManager);
      return {
        ...state,
        isLoading: false,
        // communicationManager: state.communicationManager.filter((data) => data.id === payload.reserveSlotId ? payload : data),
        communicationManager: state.communicationManager.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_COMMUNICATION_MANAGER.SUCCESS: {
      toast.success('Notification deleted successfully');
      return {
        ...state,
        isLoading: false,
        communicationManager: state.communicationManager.filter((data) => data.id !== payload),
      };
    }

    case FETCH_COMMUNICATION_MANAGER.FAIL:
    case CREATE_COMMUNICATION_MANAGER.FAIL:
    case GET_COMMUNICATION_MANAGER.FAIL:
    case DELETE_COMMUNICATION_MANAGER.FAIL:
    case UPDATE_COMMUNICATION_MANAGER.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default communicationManagerReducer;
