import React, { useCallback, useState, useContext } from 'react';
import { cookie } from 'utils/cookies/cookies';
import { Modal } from 'react-bootstrap';
import LogoutModal from './LogoutModal';
// import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Import useSelector from react-redux
import { SUPER_ADMIN, SUPPORT_ADMIN, NETWORK_ADMIN, FLEET_OWNER } from 'components/common/constant';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const Logout = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(true);
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const userRole = useSelector((state) => state.profile.userProfile.role); // Get user role from Redux store

  const onClickRemove = useCallback(() => {
    const sessionTenantId = new URLSearchParams(window.location.search).get('tenant');
    if (sessionTenantId) {
      localStorage.removeItem(`${sessionTenantId}_token`);
      localStorage.removeItem(`${sessionTenantId}_tenant_data`);
      window.location.href = '/login';
    } else {
      cookie.clean();
      window.location.href = '/login';
    }
  }, []);

  const handleCloseLogoutModel = () => {
    setShowLogoutModal(false);

    // Check if the user is a superadmin and redirect to dashboard
    if (userRole === SUPER_ADMIN || FLEET_OWNER) {
      navigateTo('/dashboard'); // Replace with your dashboard route
    } else if (userRole === SUPPORT_ADMIN || userRole === NETWORK_ADMIN) {
      navigateTo('/tenantAdmin');
    } else {
      navigateTo('/stations');
    }
  };

  return (
    <div className="logout-main__page">
      {showLogoutModal && (
        <Modal show={showLogoutModal} centered onHide={handleCloseLogoutModel}>
          <LogoutModal onClose={handleCloseLogoutModel} onRemove={onClickRemove} />
        </Modal>
      )}
    </div>
  );
};

export default Logout;
