import React, { useCallback, useState } from 'react';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import { VERIFY_OTP, RESEND_OTP } from 'actions/otp';
import { useDispatch } from 'react-redux';
import ErrorMessage from 'components/inputs/ErrorMessage';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { MdRefresh } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
const OtpConfirmationModal = (props) => {
  const { phoneData, onClose, isVerifyOtp, onSubmitOTP } = props;
  const dispatch = useDispatch();
  const [otpValue, setOtpValue] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showOtpError, setShowOtpError] = useState('');
  const { t } = useTranslation();
  const verifyOtp = useCallback((data) => {
    dispatch({
      type: VERIFY_OTP.REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            isVerifyOtp(true);
            onClose();
          } else {
            setErrorMsg(res.data.message);
            isVerifyOtp(false);
          }
        }
      },
    });
  }, []);

  const resendOtp = useCallback(() => {
    dispatch({
      type: RESEND_OTP.REQUEST,
      payload: phoneData,
      cb: (res) => {
        if (res && res.status === 201) {
          toast.success('OTP resend successfully');
        }
      },
    });
  }, []);

  const handleVerifyOtp = () => {
    if (_.isEmpty(otpValue) || _.size(otpValue) < 4) {
      setShowOtpError('please Enter OTP');
    } else {
      if (onSubmitOTP) {
        onSubmitOTP(otpValue);
      } else {
        verifyOtp({ ...phoneData, otp: otpValue });
      }
    }
  };

  return (
    <React.Fragment>
      <div className="otp-input-main__page">
        <Card>
          <div className="otp-input--content">
            <div className="otp-input--wrapper">
              <div className="otp-input-block_title">
                <div className="inner-block-title">
                  <span className="title">{t('optModel.title')}</span>
                </div>
              </div>
              <div className="otp-input__box">
                <div className="otp-input-text">
                  <span>{t('optModel.para1')}</span>
                  <span>{t('optModel.para2')}</span>
                </div>
                <div className="otp-input-box--wrapper">
                  <div className="otp-input-box__block">
                    <OtpInput
                      inputStyle="otp--box"
                      value={otpValue}
                      onChange={(code) => setOtpValue(code)}
                      separator={<span style={{ width: '1.5rem' }} />}
                      isInputNum={true}
                      shouldAutoFocus={true}
                    />
                  </div>
                  {!errorMsg && showOtpError && <ErrorMessage>{showOtpError}</ErrorMessage>}
                  {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
                </div>
              </div>
              <div className="otp--btn">
                <Button className="submit--btn" onClick={handleVerifyOtp} disabled={_.size(otpValue) < 4}>
                  {t('button.submit')}
                </Button>
              </div>
              <div className="resend-otp__block">
                <div className="resend-otp__button" onClick={resendOtp}>
                  <MdRefresh className="resend--icon" /> {t('button.resendOTP')}
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
OtpConfirmationModal.propTypes = {
  onClose: PropTypes.func,
  phoneData: PropTypes.func,
  isVerifyOtp: PropTypes.func,
  onSubmitOTP: PropTypes.func,
};
export default OtpConfirmationModal;
