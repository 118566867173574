import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_INVOICE,
  DOWNLOAD_INVOICE,
  DOWNLOAD_CHARGE_RCT,
  DOWNLOAD_SINGLE_INVOICE,
  DOWNLOAD_BULK_INVOICE,
  FETCH_INVOICE_WIDGETS,
} from '../actions/invoice';

function* fetchInvoice(action) {
  try {
    const response = yield call(API.getAllInvoice, action.payload);
    yield put({ type: FETCH_INVOICE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_INVOICE.FAIL, payload: { error: e } });
  }
}
function* fetchInvoiceWidgets(action) {
  try {
    const response = yield call(API.getInvoiceWidgets, action.payload);
    yield put({ type: FETCH_INVOICE_WIDGETS.SUCCESS, payload: response });
    action.cs && action.cs(response);
  } catch (e) {
    yield put({ type: FETCH_INVOICE_WIDGETS.FAIL, payload: { error: e } });
  }
}

function* downloadInvoice(action) {
  try {
    const response = yield call(API.downloadInvoice, action.payload);
    yield put({ type: DOWNLOAD_INVOICE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_INVOICE.FAIL, payload: { error: e } });
  }
}

function* downloadSingleInvoice(action) {
  try {
    const response = yield call(API.downloadSingleInvoice, action.payload);
    yield put({ type: DOWNLOAD_SINGLE_INVOICE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_SINGLE_INVOICE.FAIL, payload: { error: e } });
  }
}

function* downloadBulkInvoice(action) {
  try {
    const response = yield call(API.downloadBulkInvoice, action.payload);
    yield put({ type: DOWNLOAD_BULK_INVOICE.SUCCESS, payload: response.data });
    action.cb && action.cb(response.data);
  } catch (e) {
    yield put({ type: DOWNLOAD_BULK_INVOICE.FAIL, payload: { error: e } });
  }
}

function* downloadChargeRct(action) {
  try {
    const response = yield call(API.downloadChargeRct, action.payload);
    yield put({ type: DOWNLOAD_CHARGE_RCT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CHARGE_RCT.FAIL, payload: { error: e } });
  }
}

function* invoice() {
  yield takeLatest(FETCH_INVOICE.REQUEST, fetchInvoice);
  yield takeLatest(FETCH_INVOICE_WIDGETS.REQUEST, fetchInvoiceWidgets);
  yield takeLatest(DOWNLOAD_INVOICE.REQUEST, downloadInvoice);
  yield takeLatest(DOWNLOAD_SINGLE_INVOICE.REQUEST, downloadSingleInvoice);
  yield takeLatest(DOWNLOAD_BULK_INVOICE.REQUEST, downloadBulkInvoice);
  yield takeLatest(DOWNLOAD_CHARGE_RCT.REQUEST, downloadChargeRct);
}

export default invoice;
