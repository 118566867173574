import {
  FETCH_RFID,
  GET_RFID,
  CREATE_RFID,
  UPDATE_RFID,
  DELETE_RFID,
  FETCH_RFID_FOR_FLEET,
  UPDATE_VEHICLE_RFID,
  DELETE_RFID_BY_FLEET,
} from 'actions/rfid';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  rfid: [],
  fleetRfids: [],
  rfidDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const rfidReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RFID.REQUEST:
    case GET_RFID.REQUEST:
    case CREATE_RFID.REQUEST:
    case DELETE_RFID.REQUEST:
    case FETCH_RFID_FOR_FLEET.REQUEST:
    case DELETE_RFID_BY_FLEET.REQUEST: {
      return { ...state, isLoading: true, rfidDetail: {} };
    }

    case UPDATE_RFID.REQUEST: {
      return { ...state, isLoading: true };
    }

    case UPDATE_VEHICLE_RFID.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_RFID.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          rfid: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          rfid: _.concat(state.rfid, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case FETCH_RFID_FOR_FLEET.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          fleetRfids: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          fleetRfids: _.concat(state.fleetRfids, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_RFID.SUCCESS: {
      return {
        ...state,
        rfidDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_RFID.SUCCESS: {
      toast.success('RFID added successfully');
      return {
        ...state,
        rfid: _.concat(state.rfid, payload),
        isLoading: false,
      };
    }

    case UPDATE_RFID.SUCCESS: {
      toast.success('RFID updated successfully');
      return {
        ...state,
        isLoading: false,
        rfid: state.rfid.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case UPDATE_VEHICLE_RFID.SUCCESS: {
      toast.success('RFID Unassigned successfully');
      return {
        ...state,
        rfidDetail: payload,
        isLoading: false,
      };
    }

    case DELETE_RFID.SUCCESS: {
      toast.success('RFID deleted successfully');
      return {
        ...state,
        isLoading: false,
        rfid: state.rfid.filter((data) => data.id !== payload),
      };
    }

    case DELETE_RFID_BY_FLEET.SUCCESS: {
      toast.success('RFID deleted successfully');
      return {
        ...state,
        isLoading: false,
        rfid: state.rfid.filter((data) => data.id !== payload),
      };
    }

    case FETCH_RFID.FAIL:
    case GET_RFID.FAIL:
    case CREATE_RFID.FAIL:
    case UPDATE_RFID.FAIL:
    case DELETE_RFID.FAIL:
    case FETCH_RFID_FOR_FLEET.FAIL:
    case UPDATE_VEHICLE_RFID.FAIL:
    case DELETE_RFID_BY_FLEET.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default rfidReducer;
