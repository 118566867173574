import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { CREATE_SCHEDULE, UPDATE_SCHEDULE } from 'actions/schedule';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { BiTrash } from 'react-icons/bi';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import './Schedule.css';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const BookingSchedule = ({ onClose = () => {}, payload }) => {
  const dispatch = useDispatch();
  const { navigateTo } = useContext(NavContext);
  const { t } = useTranslation();
  const title = payload ? t('bookingSchedule.updateBooking') : t('bookingSchedule.bookingSchedule');

  const initialValues = payload
    ? {
        bookings: [
          {
            timeInterval: payload.timeInterval,
            timeFrom: payload.timeFrom,
            timeTo: payload.timeTo,
          },
        ],
      }
    : {
        bookings: [
          {
            timeInterval: '',
            timeFrom: '',
            timeTo: '',
          },
        ],
      };

  const updateSchedule = useCallback((id, data) => {
    dispatch({
      type: UPDATE_SCHEDULE.REQUEST,
      payload: { id, data },
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/schedulingSetting');
          }, 1000);
        }
      },
    });
  }, []);

  return (
    <React.Fragment>
      <div className="reserve-form-page__main">
        <Card>
          <div className="reserve-form-header__block">
            &nbsp;
            <div className="reserve-header-name">
              <div className="reserve-solt-text-align">{title}</div>
            </div>
            <div className="reserve-close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
            &nbsp;
          </div>
          <div className="reserve-form-body__block">
            <div className="reserve-form--block">
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  const { bookings } = values;
                  if (payload) {
                    const id = payload.id;
                    updateSchedule(id, bookings);
                  } else {
                    bookings.forEach((value) => {
                      const { timeInterval, timeTo, timeFrom } = value;
                      dispatch({
                        type: CREATE_SCHEDULE.REQUEST,
                        payload: { timeInterval, timeTo, timeFrom },
                        cb: (res) => {
                          if (_.get(res, 'status') === CREATE_STATUS_CODE) {
                            setTimeout(() => {
                              navigateTo('/schedulingSetting');
                            }, 1000);
                          }
                        },
                      });
                    });
                  }
                  onClose();
                }}
              >
                {({ values, handleSubmit, isSubmitting, setFieldValue, setValues }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={11}>
                        <Row cols={6}>
                          <Col>
                            <span className="font-color">{t('bookingSchedule.timeInterval')}</span>
                            <div>&nbsp;</div>
                          </Col>
                          <Col>
                            <span className="font-color">{t('bookingSchedule.selectTimeFrom')}</span>
                          </Col>
                          <Col>
                            <span className="font-color">{t('bookingSchedule.selectTimeTo')}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {values
                      ? values.bookings.map((booking, index) => {
                          return (
                            <Row key={index}>
                              <Col md={11} xs={10}>
                                <Row cols={_.isNull(payload) ? 3 : 4}>
                                  <Col md={12} lg={4}>
                                    <TextInput
                                      placeholder={t('bookingSchedule.timeInterval')}
                                      name="timeInterval"
                                      value={booking.timeInterval}
                                      onChange={(val) => {
                                        setFieldValue(`bookings.${index}.timeInterval`, val.target.value);
                                      }}
                                    />
                                  </Col>
                                  <Col xs={12} md={12} lg={4}>
                                    <div className="time_sec">
                                      <span className="custome_timedate">
                                        <input
                                          className="ctd_selecter"
                                          type="time"
                                          value={booking.timeFrom}
                                          onChange={(val) => {
                                            setFieldValue(`bookings.${index}.timeFrom`, val.target.value);
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </Col>
                                  <Col xs={12} md={12} lg={4}>
                                    <div className="time_sec">
                                      <span className="custome_timedate">
                                        <input
                                          className="ctd_selecter"
                                          type="time"
                                          value={booking.timeTo}
                                          onChange={(val) => {
                                            setFieldValue(`bookings.${index}.timeTo`, val.target.value);
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              {_.isNull(payload) && (
                                <Col md={1} xs={1}>
                                  <Button
                                    className="field--btn"
                                    onClick={() => {
                                      const newBookings = values.bookings.filter((item, i) => i !== index);
                                      setValues({ ...values, bookings: newBookings });
                                    }}
                                  >
                                    <BiTrash size={26} />
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          );
                        })
                      : null}

                    <div className="reserve-slot-btns-sec">
                      <div className="reserve-slot-submit-btn">
                        <Button type="submit" disabled={isSubmitting} style={{ padding: `10px 80px` }} onClick={handleSubmit}>
                          {t('button.submit')}
                        </Button>
                      </div>
                      {_.isNull(payload) && (
                        <div className="reserve-slot-add-btn">
                          <Button
                            className="field--btn field--btn-prime"
                            onClick={() => {
                              setValues({
                                ...values,
                                bookings: [...values.bookings, { timeInterval: '', timeTo: '', timeFrom: '' }],
                              });
                            }}
                          >
                            <AiFillPlusCircle size={26} />
                          </Button>
                        </div>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
BookingSchedule.propTypes = {
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
  onSuccess: PropTypes.func,
  title: PropTypes.string,
  payload: PropTypes.string,
};
export default BookingSchedule;
