import { get, post, deleteRequest, patch } from 'utils/api';
import { CHARGING_STATIONS, CS_WIDGETS, USERS, CUSTOMER_REVIEWS } from './endpointURLs';

export const getAllChargingStation = (data) => {
  return get(`${CHARGING_STATIONS}`, data);
};

export const getChargingStation = (id) => {
  return get(`${CHARGING_STATIONS}/${id}?isPrivate=true`);
};

export const getChargerStation = (data) => {
  const page = data.page ? data.page : 1;
  const limit = data.limit ? data.limit : 10;
  const id = data.id;
  delete data['page'];
  delete data['limit'];
  return get(`${CHARGING_STATIONS}/chargers/${id}?page=${parseInt(page)}&limit=${parseInt(limit)}`, data);
};

export const getChargersList = (data) => {
  const page = data.page ? data.page : 1;
  const limit = data.limit ? data.limit : 10;
  const id = data.id;
  delete data['page'];
  delete data['limit'];
  return get(`${CHARGING_STATIONS}/chargers_list/${id}?page=${parseInt(page)}&limit=${parseInt(limit)}`, data);
};


export const consumption = (data) => {
  return post(`${CHARGING_STATIONS}/consumption/${data.stationID}`, {
    charger: data.charger ? [data.charger] : '',
  });
};

export const getStationByCity = (data) => {
  return post(`${CHARGING_STATIONS}/withfilter?request_all=${true}`, data);
};

export const addChargingStation = (data) => {
  return post(`${CHARGING_STATIONS}`, data);
};

export const deleteChargingStation = (id) => {
  return deleteRequest(`${CHARGING_STATIONS}/${id}`);
};

export const updateChargingStation = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`${CHARGING_STATIONS}/${id}`, data);
};

export const getStationReview = (data) => {
  const id = data.id;
  delete data['id']
  return get(`${CUSTOMER_REVIEWS}/stationreview/${id}`, data);
};

export const updateContactPerson = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`${USERS}/${id}`, data);
};

export const getwidgets = (data) => {
  return post(`${CHARGING_STATIONS}/${CS_WIDGETS}`, data);
};

export const chargingStation = {
  getAllChargingStation,
  getChargingStation,
  getStationByCity,
  addChargingStation,
  deleteChargingStation,
  updateChargingStation,
  getStationReview,
  updateContactPerson,
  getwidgets,
  consumption,
  getChargerStation,
  getChargersList
};
