import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_CHARGER,
  FETCH_CHARGER,
  GET_CHARGER,
  DELETE_CHARGER,
  FETCH_CHARGER_BY_STATION,
  FETCH_CHARGERS_LIST,
  UPDATE_CHARGER,
  CHANGE_CHARGER_STATUS,
  CHANGE_CHARGER_IS_ACTIVE_STATUS,
  FETCH_CHARGERS_BY_STATIONS,
} from 'actions/charger';

function* fetchCharger(action) {
  try {
    const response = yield call(API.getCharger, action.payload);
    yield put({ type: FETCH_CHARGER.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CHARGER.FAIL, payload: { error: e } });
  }
}

function* getCharger(action) {
  try {
    const response = yield call(API.getChargerById, action.payload);
    yield put({ type: GET_CHARGER.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGER.FAIL, payload: { error: e } });
  }
}

function* createCharger(action) {
  try {
    const response = yield call(API.addCharger, action.payload);
    yield put({ type: CREATE_CHARGER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CHARGER.FAIL, payload: { error: e } });
  }
}

function* fetchStationChargers(action) {
  try {
    const response = yield call(API.getChargersByStation, action.payload);
    yield put({ type: FETCH_CHARGER_BY_STATION.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CHARGER_BY_STATION.FAIL, payload: { error: e } });
  }
}

function* fetchChargersList(action) {
  try {
    const response = yield call(API.getChargersList, action.payload);
    yield put({ type: FETCH_CHARGERS_LIST.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CHARGERS_LIST.FAIL, payload: { error: e } });
  }
}

function* deleteCharger(action) {
  try {
    const response = yield call(API.deleteCharger, action.payload.chargerId);
    yield put({ type: DELETE_CHARGER.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CHARGER.FAIL, payload: { error: e } });
  }
}

function* updateCharger(action) {
  try {
    const response = yield call(API.updateCharger, action.payload);
    yield put({ type: UPDATE_CHARGER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CHARGER.FAIL, payload: { error: e } });
  }
}

function* changeChargerStatus(action) {
  try {
    const response = yield call(API.updateCharger, action.payload);
    yield put({ type: CHANGE_CHARGER_STATUS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHANGE_CHARGER_STATUS.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* changeChargerIsActiveStatus(action) {
  try {
    const response = yield call(API.updateCharger, action.payload);
    yield put({ type: CHANGE_CHARGER_IS_ACTIVE_STATUS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHANGE_CHARGER_IS_ACTIVE_STATUS.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* fetchChargersByStations(action) {
  try {
    const response = yield call(API.fetchChargersByStations, action.payload);
    yield put({ type: FETCH_CHARGERS_BY_STATIONS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CHARGERS_BY_STATIONS.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* charger() {
  yield takeLatest(FETCH_CHARGER.REQUEST, fetchCharger);
  yield takeLatest(GET_CHARGER.REQUEST, getCharger);
  yield takeLatest(CREATE_CHARGER.REQUEST, createCharger);
  yield takeLatest(DELETE_CHARGER.REQUEST, deleteCharger);
  yield takeLatest(FETCH_CHARGER_BY_STATION.REQUEST, fetchStationChargers);
  yield takeLatest(UPDATE_CHARGER.REQUEST, updateCharger);
  yield takeLatest(CHANGE_CHARGER_STATUS.REQUEST, changeChargerStatus);
  yield takeLatest(CHANGE_CHARGER_IS_ACTIVE_STATUS.REQUEST, changeChargerIsActiveStatus);
  yield takeLatest(FETCH_CHARGERS_LIST.REQUEST, fetchChargersList);
  yield takeLatest(FETCH_CHARGERS_BY_STATIONS.REQUEST, fetchChargersByStations);
}
export default charger;
