import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { AiOutlinePlus } from 'react-icons/ai';
import { FiMinus } from 'react-icons/fi';
import PropTypes from 'prop-types';

const Collapse = (props) => {
  const {
    defaultActiveKey,
    eventKey,
    title,
    as,
    wrapperClass,
    onClick,
    onChange,
    onSelect,
    CollapseClassName,
    ToggleClassName,
    accordionContentWrapper,
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <div className={`accordion-main ${wrapperClass ? wrapperClass : ''}`}>
      <Accordion defaultActiveKey={defaultActiveKey ? defaultActiveKey : ''} onClick={onClick} onChange={onChange} onSelect={onSelect}>
        <div className={`accordion--block`}>
          <Accordion.Item eventKey={eventKey}>
            <Accordion.Header as={as} onClick={() => setOpen(!open)} className={`accordion-header ${ToggleClassName ? ToggleClassName : ''}`}>
              <div className="accordion-header--block">
                <span className="accordion-icon">
                  {open ? <FiMinus color={'#ffffff'} size={20} /> : <AiOutlinePlus color={'#ffffff'} size={19} />}
                </span>
                <span className="accordion-title">{title}</span>
              </div>
            </Accordion.Header>
            <Accordion.Body className={CollapseClassName ? CollapseClassName : ''}>
              <div className={`accordion-content ${accordionContentWrapper ? accordionContentWrapper : ''}`}>{props.children}</div>
            </Accordion.Body>
          </Accordion.Item>
        </div>
      </Accordion>
    </div>
  );
};

Collapse.propTypes = {
  defaultActiveKey: PropTypes.string,
  /**
   * defaultActiveKey:
   * For: At initial time, any collapse default shown.
   */
  eventKey: PropTypes.string,
  /**
   * eventKey:
   * Required
   * Value: It contains a unique key.
   * The key is same for both Accordion Toggle and Accordion Collapse.
   */
  title: PropTypes.string,
  /**
   * title:
   * Required
   * Value: It contains the name of specific component.
   */
  as: PropTypes.element,
  /**
   * as:
   * Default value: button
   * For: Set a custom element.
   */
  wrapperClass: PropTypes.string,
  /**
   * wrapperClass:
   * Give the class name of main parent class.
   */
  onClick: PropTypes.func,
  /**
   * onClick:
   * For: A callback function for when component is clicked.
   */
  onChange: PropTypes.func,
  /**
   * onChange:
   * For: Whenever changes occur in the component.
   */
  onSelect: PropTypes.func,
  /**
   * onSelect:
   * For:
   */
  CollapseClassName: PropTypes.string,
  ToggleClassName: PropTypes.string,
  accordionContentWrapper: PropTypes.string,
  children: PropTypes.node,
};
export default Collapse;
