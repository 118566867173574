import { get, post, patch, deleteRequest } from 'utils/api';

export const getOems = (data) => {
  return get('/oems', data);
};

export const getOemById = (id) => {
  return get(`/oems/${id}`);
};

export const addOem = (data) => {
  return post(`/oems`, data);
};

export const updateOem = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/oems/${id}`, data);
};

export const updateOemFirmware = (data) => {
  return post(`/oems/updatefirmware`, data);
};

export const deleteOem = (id) => {
  return deleteRequest(`/oems/${id}`);
};

export const oem = {
  getOems,
  getOemById,
  addOem,
  updateOem,
  deleteOem,
  updateOemFirmware,
};
