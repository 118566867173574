import { get, post, patch, deleteRequest } from 'utils/api';

export const getModulesSorted = () => {
  return get(`/modules/sorted`);
};

export const getAllModule = (data) => {
  return get(`/modules`, data);
};

export const getModuleById = (id) => {
  return get(`/modules/${id}`);
};

export const addModule = (data) => {
  return post(`/modules`, data);
};

export const updateModuleSuper = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/modules/${id}`, data);
};

export const deleteModule = (id) => {
  return deleteRequest(`/modules/${id}`);
};

export const module = {
  getModulesSorted,
  getAllModule,
  getModuleById,
  addModule,
  updateModuleSuper,
  deleteModule,
};
