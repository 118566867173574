import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_ALERTS, FETCH_ALERTS_SUCCESS, FETCH_ALERTS_FAILURE } from 'actions/alerts';

function* fetchAlerts(action) {
  try {
    const response = yield call(API.getAlerts, action.payload);
    yield put({ type: FETCH_ALERTS_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_ALERTS_FAILURE, payload: { error: e } });
  }
}

function* alerts() {
  yield takeLatest(FETCH_ALERTS, fetchAlerts);
}
export default alerts;
