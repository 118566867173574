import { get, post, patch, deleteRequest } from 'utils/api';

export const getOffers = (data) => {
  return get(`/offers`, data);
};

export const getOffersById = (id) => {
  return get(`/offers/${id}`);
};
export const addOffers = (data) => {
  return post(`/offers`, data);
};

export const updateOffers = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/offers/${id}`, data);
};

export const deleteOffers = (id) => {
  return deleteRequest(`/offers/${id}`);
};

export const offers = {
  getOffers,
  getOffersById,
  addOffers,
  updateOffers,
  deleteOffers,
};
