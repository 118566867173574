import React, { useCallback, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BOOKING_HISTORY, CALENDAR_VIEW } from 'actions/dataReport';
import { useDispatch, useSelector } from 'react-redux';
import { roundOfDigit } from 'components/common/utils';
import * as _ from 'lodash';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import ReactPagination from 'components/general/ReactPagination';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';

const PublicUsers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState('');

  const bookingHistoryList = useSelector((state) => state.bookingHistory.bookingHistories);
  const calendarViewList = useSelector((state) => state.calendarView.calendarView);
  const page = useSelector((state) => state.bookingHistory.page);
  const isLoader = useSelector((state) => state.bookingHistory.isLoading);
  const totalData = useSelector((state) => state.bookingHistory.total);
  const limit = useSelector((state) => state.bookingHistory.limit);
  const totalPages = useSelector((state) => state.bookingHistory.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const bookingHistory = useCallback((value, data) => {
    const bookingData = {
      ...data,
      from: moment(value).startOf('day').utc(),
      to: moment(value).endOf('day').utc(),
    };
    dispatch({ type: BOOKING_HISTORY.REQUEST, payload: bookingData });
  }, []);

  const calendarView = useCallback((data = {}) => {
    dispatch({ type: CALENDAR_VIEW.REQUEST, payload: data });
  }, []);

  const handleCloseTable = () => {
    setShowModal(false);
  };

  const events = _.map(calendarViewList, (item) => {
    return { title: item.total, date: item._id.date };
  });

  const handlePageClick = useCallback(
    (page) => {
      const data = {
        page: page.selected + 1,
      };
      bookingHistory(date, data);
    },
    [page, date]
  );

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.publicUsers')} />
        <div className="page-content-wrapper scrollable">
          <div className="publicUsers-page">
            <Card>
              <div className="App">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  events={events}
                  eventClick={(e) => {
                    setDate(e.event.start);
                    bookingHistory(e.event.start);
                    setShowModal(true);
                  }}
                  eventDidMount={(info) => {
                    if (info.event._def.title !== '') {
                      const a = info.el.getElementsByClassName('fc-event-title');
                      a[0].innerHTML = info.event.title + '<br>' + '<span style="font-weight:400">' + 'bookings' + '</span>';
                    }
                  }}
                  showNonCurrentDates={false}
                  fixedWeekCount={false}
                  datesSet={(event) => {
                    const calenderDate = {
                      from: moment(event.view.activeStart).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                      to: moment(event.view.activeEnd).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                    };
                    calendarView(calenderDate);
                  }}
                  // dateClick={(e) => {
                  //   setDate(e.dateStr);
                  //  bookingHistory(date)
                  //   setShowModal(true)
                  // }}
                />
              </div>
            </Card>
          </div>
        </div>
        {showModal && (
          <Modal show={showModal} size="xl" centered>
            <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
              <div className="public-user-model_main_content">
                <div className="title_name">{isLoader ? <Skeleton height={15} width={100} /> : t('publicUsers.title')}</div>
                <div className="table-close-btn">
                  {isLoader ? <Skeleton height={15} width={100} circle="true" /> : <MdClose size={30} color="#be210b" onClick={handleCloseTable} />}
                </div>
              </div>
              <div className="public-user-table">
                <div className="table-responsive scrollable">
                  <table className="record-list-table ">
                    <thead>
                      <tr>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('publicUsers.srNo')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('publicUsers.bookingId')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('publicUsers.name')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('publicUsers.mobileNo')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('publicUsers.otp')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('publicUsers.stationName')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('publicUsers.chargerID')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('publicUsers.bookingDate')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('publicUsers.bookingSlotTime')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('publicUsers.bookingAmount')}</span>
                          </div>
                        </th>
                        <th>
                          <div className="sorting">
                            <span>{isLoader ? <Skeleton height={15} width={100} /> : t('publicUsers.status')}</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.isEmpty(bookingHistoryList) ? (
                        <tr>
                          <td colSpan={10} className="border-0">
                            <div className="public-user-empty-data-block">{t('publicUsers.noData')}</div>
                          </td>
                        </tr>
                      ) : (
                        _.map(bookingHistoryList, (item, key) => {
                          const serial_num = limit * (page - 1) + key;
                          return (
                            <>
                              <tr key={`booking-history-list-${serial_num}`}>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'id', '')}</td>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_user_booked.name', 'Guest User')}</td>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_user_booked.phone', '')}</td>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'idTag', '')}</td>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.name', '')}</td>
                                <td>
                                  {isLoader ? (
                                    <Skeleton height={15} width={100} />
                                  ) : (
                                    <>
                                      {_.get(item, 'charger.charger_id', '')} ({_.get(item, 'connectorId', '')})
                                    </>
                                  )}
                                </td>
                                <td>
                                  {isLoader ? (
                                    <Skeleton height={15} width={100} />
                                  ) : _.get(item, 'schedule_datetime') ? (
                                    moment(_.get(item, 'schedule_datetime')).format('DD-MM-YYYY')
                                  ) : (
                                    ''
                                  )}
                                </td>
                                <td>
                                  {isLoader ? (
                                    <Skeleton height={15} width={100} />
                                  ) : _.get(item, 'schedule_datetime') ? (
                                    moment(_.get(item, 'schedule_datetime')).format('HH:mm')
                                  ) : (
                                    ''
                                  )}
                                </td>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'estimated_amount', ''), 2)}</td>
                                <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'status')}</td>
                              </tr>
                            </>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                {!_.isEmpty(bookingHistoryList) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </div>
            </SkeletonTheme>
          </Modal>
        )}
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};
export default PublicUsers;
