import { FETCH_PERMISSION, GET_PERMISSION, CREATE_PERMISSION, UPDATE_PERMISSION, DELETE_PERMISSION, FETCH_ALL_PERMISSION } from 'actions/permissions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchPermission(action) {
  try {
    const response = yield call(API.getPermission, action.payload);
    yield put({ type: FETCH_PERMISSION.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_PERMISSION.FAIL, payload: { error: e } });
  }
}

function* fetchAllPermission(action) {
  try {
    const response = yield call(API.getAllPermission, action.payload);
    yield put({ type: FETCH_ALL_PERMISSION.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_ALL_PERMISSION.FAIL, payload: { error: e } });
  }
}

function* getPermissionById(action) {
  try {
    const response = yield call(API.getPermissionById, action.payload);
    yield put({ type: GET_PERMISSION.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_PERMISSION.FAIL, payload: { error: e } });
  }
}

function* createPermission(action) {
  try {
    const response = yield call(API.addPermission, action.payload);
    yield put({ type: CREATE_PERMISSION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_PERMISSION.FAIL, payload: { error: e } });
  }
}

function* updatePermission(action) {
  try {
    const response = yield call(API.updatePermission, action.payload);
    yield put({ type: UPDATE_PERMISSION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_PERMISSION.FAIL, payload: { error: e } });
  }
}

function* deletePermission(action) {
  try {
    const response = yield call(API.deletePermission, action.payload);
    yield put({ type: DELETE_PERMISSION.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_PERMISSION.FAIL, payload: { error: e } });
  }
}

function* permissions() {
  yield takeLatest(FETCH_PERMISSION.REQUEST, fetchPermission);
  yield takeLatest(FETCH_ALL_PERMISSION.REQUEST, fetchAllPermission);
  yield takeLatest(GET_PERMISSION.REQUEST, getPermissionById);
  yield takeLatest(CREATE_PERMISSION.REQUEST, createPermission);
  yield takeLatest(UPDATE_PERMISSION.REQUEST, updatePermission);
  yield takeLatest(DELETE_PERMISSION.REQUEST, deletePermission);
}
export default permissions;
