import { FETCH_CHARGER_STATE, GET_CHARGER_STATE, CREATE_CHARGER_STATE, UPDATE_CHARGER_STATE, DELETE_CHARGER_STATE } from 'actions/chargerState';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchChargerState(action) {
  try {
    const response = yield call(API.getChargerState, action.payload);
    yield put({ type: FETCH_CHARGER_STATE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CHARGER_STATE.FAIL, payload: { error: e } });
  }
}

function* getChargerStateById(action) {
  try {
    const response = yield call(API.getChargerStateById, action.payload);
    yield put({ type: GET_CHARGER_STATE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGER_STATE.FAIL, payload: { error: e } });
  }
}

function* createChargerState(action) {
  try {
    const response = yield call(API.addChargerState, action.payload);
    yield put({ type: CREATE_CHARGER_STATE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CHARGER_STATE.FAIL, payload: { error: e } });
  }
}

function* updateChargerState(action) {
  try {
    const response = yield call(API.updateChargerState, action.payload);
    yield put({ type: UPDATE_CHARGER_STATE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CHARGER_STATE.FAIL, payload: { error: e } });
  }
}

function* deleteChargerState(action) {
  try {
    const response = yield call(API.deleteChargerState, action.payload);
    yield put({ type: DELETE_CHARGER_STATE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CHARGER_STATE.FAIL, payload: { error: e } });
  }
}

function* chargerState() {
  yield takeLatest(FETCH_CHARGER_STATE.REQUEST, fetchChargerState);
  yield takeLatest(GET_CHARGER_STATE.REQUEST, getChargerStateById);
  yield takeLatest(CREATE_CHARGER_STATE.REQUEST, createChargerState);
  yield takeLatest(UPDATE_CHARGER_STATE.REQUEST, updateChargerState);
  yield takeLatest(DELETE_CHARGER_STATE.REQUEST, deleteChargerState);
}

export default chargerState;
