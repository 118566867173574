import React, { useCallback, useEffect, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { CREATE_USER_GROUP, GET_USER_GROUP, UPDATE_USER_GROUP } from 'actions/userGroup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import CheckBox from 'components/inputs/CheckBox';

const UserGroupForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigateTo } = useContext(NavContext);

  const { userGroupId } = useParams();

  const userGroupDetail = useSelector((state) => state.userGroup.userGroupDetail);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');
  const currentTenantId = _.get(profileData, 'id', '');

  const initialValues =
    userGroupId && !_.isEmpty(userGroupDetail)
      ? {
          ...userGroupDetail,
        }
      : {
          name: '',
          is_chargecoin_discount_eligible: false,
          is_cashback_discount_eligible: false,
        };

  const getUserGroupDetails = useCallback((id) => {
    dispatch({ type: GET_USER_GROUP.REQUEST, payload: id });
  }, []);

  useEffect(() => {
    if (userGroupId) {
      getUserGroupDetails(userGroupId);
    }
  }, [userGroupId]);

  const addUserGroup = useCallback((data) => {
    dispatch({
      type: CREATE_USER_GROUP.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/userGroupManagement');
          }, 1000);
        }
      },
    });
  }, []);

  const editUserGroupDetail = useCallback((data) => {
    dispatch({
      type: UPDATE_USER_GROUP.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/userGroupManagement');
          }, 1000);
        }
      },
    });
  }, []);

  const UserGroupSchema = Yup.object().shape({
    name: Yup.string().required(t('userGroupForm.name')),
  });

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${userGroupId ? 'Edit' : 'Add'} User Group`} backTo="/userGroupManagement" />
        <div className="page-content-wrapper scrollable">
          <div className="user-group-form-page-main">
            <Card className="user-group-form-card">
              <div className="user-group-form__block">
                <Formik
                  enableReinitialize={!!userGroupId}
                  initialValues={initialValues}
                  validationSchema={UserGroupSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (userGroupId) {
                      // edit user group api call....
                      const editUserGroupData = _.omit(values, ['tenant']);
                      editUserGroupDetail(editUserGroupData);
                    } else {
                      //add user group api call...
                      addUserGroup(values);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="user-group-form__inner">
                        <Row>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              label={t('addUserGroupList.userGroupName')}
                              placeholder={t('addUserGroupList.userGroupName')}
                              type="name"
                              value={values.name}
                              name="name"
                              onChange={handleChange}
                              error={errors.name && touched.name ? errors.name : null}
                            />
                          </Col>
                          {(currentTenantId === '62987db08f88870e6524d06a' || currentTenantId === '6251713fec1698610d3ee508') && (
                            <Col lg={'auto'}>
                              <div className="isChargecoinDiscountEligible-field-main--block">
                                <div className="isChargecoinDiscountEligible-checkbox-input__block">
                                  <label htmlFor="is_chargecoin_discount_eligible" className="isChargecoinDiscountEligible--label">
                                    {t('addUserGroupList.isChargecoinDiscountEligible')}
                                  </label>
                                  <CheckBox
                                    name="is_chargecoin_discount_eligible"
                                    checked={values.is_chargecoin_discount_eligible}
                                    onChange={(e) => setFieldValue('is_chargecoin_discount_eligible', e.target.checked)}
                                  />
                                </div>
                              </div>
                              <div className="isCashbackDiscountEligible-field-main--block">
                                <div className="isCashbackDiscountEligible-checkbox-input__block">
                                  <label htmlFor="is_cashback_discount_eligible" className="isCashbackDiscountEligible--label">
                                    {t('addUserGroupList.isCashbackDiscountEligible')}
                                  </label>
                                  <CheckBox
                                    name="is_cashback_discount_eligible"
                                    checked={values.is_cashback_discount_eligible}
                                    onChange={(e) => setFieldValue('is_cashback_discount_eligible', e.target.checked)}
                                  />
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                      <div className="user-group-form-save--btn--block">
                        <Button type="submit" className="user-group-form-save-btn save--btn" disabled={isSubmitting}>
                          {userGroupId ? 'Update' : 'Add'} {t('button.userGroup')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default UserGroupForm;
