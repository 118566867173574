import React, { useState } from 'react';
import { DatePickerInput } from 'rc-datepicker';
import PropTypes from 'prop-types';
import 'rc-datepicker/lib/style.css';

const DatePicker = (props) => {
  const [selectedFromDate, setSelectedFromDate] = useState(null);

  const handleStartDateChange = (date) => {
    setSelectedFromDate(date);
    if (props.onChangeOfStartDate) {
      props.onChangeOfStartDate(date);
    }
  };

  const {
    showOnInputClick,
    placeholderOfStartDate,
    defaultValueOfStartDate,
    maxDateOfStartDate,
    minDateOfStartDate,
    classNameOfStartDate,
    placeholderOfEndDate,
    defaultValueOfEndDate,
    maxDateOfEndDate,
    minDateOfEndDate,
    classNameOfEndDate,
  } = props;

  const endDateMinDate = selectedFromDate || minDateOfEndDate;

  return (
    <div className="dataPicker-input-group">
      <div className={`input-field `}>
        <DatePickerInput
          {...props}
          placeholder={placeholderOfStartDate || 'From...'}
          displayFormat="DD/MM/YYYY"
          returnFormat="YYYY-MM-DD"
          locale="en_US"
          showOnInputClick={showOnInputClick || true}
          defaultValue={defaultValueOfStartDate || ''}
          maxDate={maxDateOfStartDate || ''}
          minDate={minDateOfStartDate || ''}
          onChange={handleStartDateChange}
          autoClose={true}
          className={`${classNameOfStartDate ? classNameOfStartDate : ''}`}
          position="bottom"
        />
      </div>
      <div className={`input-field has-data post-icon-input`}>
        <DatePickerInput
          {...props}
          placeholder={placeholderOfEndDate || 'To...'}
          displayFormat="DD/MM/YYYY"
          returnFormat="YYYY-MM-DD"
          showOnInputClick={showOnInputClick || true}
          defaultValue={defaultValueOfEndDate || ''}
          minDate={endDateMinDate || ''}
          maxDate={maxDateOfEndDate || ''}
          onChange={props.onChangeOfEndDate || function () {}}
          autoClose={true}
          className={`${classNameOfEndDate ? classNameOfEndDate : ''}`}
          position="bottom"
          // disabled={selectedFromDate === null} // Disable the "To" date picker if no "From" date is selected
        />
      </div>
    </div>
  );
};


DatePicker.propTypes = {
  placeholderOfStartDate: PropTypes.string,
  showOnInputClick: PropTypes.bool,
  defaultValueOfStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChangeOfStartDate: PropTypes.func,
  maxDateOfStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  minDateOfStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  classNameOfStartDate: PropTypes.string,
  placeholderOfEndDate: PropTypes.string,
  defaultValueOfEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChangeOfEndDate: PropTypes.func,
  maxDateOfEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  minDateOfEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  classNameOfEndDate: PropTypes.string,
};

export default DatePicker;
