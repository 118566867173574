import { get, patch } from 'utils/api';

export const getAccountDeleteRequest = (data) => {
  return get(`/deleted_users`, data);
};

export const approveAccountDeleteRequest = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/deleted_users/approved/${id}`, data);
};

export const rejectAccountDeleteRequest = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/deleted_users/rejected/${id}`, data);
};

export const accountDeleteRequest = {
  getAccountDeleteRequest,
  approveAccountDeleteRequest,
  rejectAccountDeleteRequest,
};
