import { post, downloadFile } from 'utils/api';

export const getCapacityUtilisation = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/capacityutilization?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/capacityutilization?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/capacityutilization?page=${parseInt(page)}`, data);
  }
};

export const downloadCapacityUtilisation = (data) => {
  return downloadFile(`/report/capacityutilization`, data);
};

export const CapacityUtilisation = {
  getCapacityUtilisation,
  downloadCapacityUtilisation,
};
