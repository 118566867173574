import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import PropTypes from 'prop-types';

import PrivateRoute from 'components/privateRoute/PrivateRoute';
import Login from 'view/login/index';
import ForgotPassword from 'view/forgotPassword';
import AccessDenied from 'view/AccessDenied/AccessDenied';
import Loader from 'components/general/Loader';
import { routes } from 'utils/routes';

export const MessagePopup = ({ title, description }) => {
  return (
    <div>
      <h5>{title}</h5>
      {description && <p className="m-0">{description}</p>}
    </div>
  );
};
MessagePopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

const App = () => {
  const flattenRoutes = (routesObj) => {
    return Object.values(routesObj).flatMap((route) => {
      if (Array.isArray(route.path)) {
        return route.path.map((path) => ({ ...route, path }));
      }
      return route;
    });
  };

  const routesArray = flattenRoutes(routes);

  return (
    <React.Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/accessdenied" element={<AccessDenied />} />
            <Route path="/*" element={<PrivateRoute routes={routesArray} />} />
          </Routes>
        </React.Suspense>
        <ToastContainer position="top-right" theme="colored" />
        <Toaster />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
