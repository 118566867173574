import { FETCH_ALERTS, FETCH_ALERTS_SUCCESS, FETCH_ALERTS_FAILURE } from 'actions/alerts';

const initialState = {
  isLoading: false,
  alerts: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};
const alertsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ALERTS: {
      return { ...state, isLoading: true };
    }
    case FETCH_ALERTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        alerts: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FETCH_ALERTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default alertsReducer;
