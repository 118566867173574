import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_TAGS, CREATE_TAGS, DELETE_TAGS, UPDATE_TAGS, GET_TAG } from 'actions/tagmanagement';

function* getTags(action) {
  try {
    const response = yield call(API.getTags, action.payload);
    yield put({ type: FETCH_TAGS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_TAGS.FAIL, payload: { error: e } });
  }
}
function* createTags(action) {
  try {
    const response = yield call(API.addTags, action.payload);
    yield put({ type: CREATE_TAGS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_TAGS.FAIL, payload: { error: e } });
  }
}

function* deleteTags(action) {
  try {
    const response = yield call(API.deleteTags, action.payload);
    yield put({ type: DELETE_TAGS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_TAGS.FAIL, payload: { error: e } });
  }
}

function* updateTags(action) {
  try {
    const response = yield call(API.updateTags, action.payload);
    yield put({ type: UPDATE_TAGS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_TAGS.FAIL, payload: { error: e } });
  }
}

function* getTagsbyID(action) {
  try {
    const response = yield call(API.getTagsbyId, action.payload);
    yield put({ type: GET_TAG.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_TAG.FAIL, payload: { error: e } });
  }
}

function* tagmanagement() {
  yield takeLatest(FETCH_TAGS.REQUEST, getTags),
    yield takeLatest(CREATE_TAGS.REQUEST, createTags),
    yield takeLatest(DELETE_TAGS.REQUEST, deleteTags),
    yield takeLatest(UPDATE_TAGS.REQUEST, updateTags),
    yield takeLatest(GET_TAG.REQUEST, getTagsbyID);
}

export default tagmanagement;
