import { MANAGE_ROLE } from 'actions/role';

const initialState = {
  isLoading: false,
  manageRoles: [],
  excludedModules: [],
};

const manageRoleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MANAGE_ROLE.REQUEST: {
      return { ...state, isLoading: true };
    }

    case MANAGE_ROLE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        manageRoles: payload.results,
        excludedModules: payload.excluded_modules,
      };
    }

    case MANAGE_ROLE.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default manageRoleReducer;
