import { FETCH_LIVE_CHARGING_SESSION } from 'actions/liveChargingSession';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { toast } from 'react-toastify';

function* fetchLiveChargingSessions(action) {
  try {
    const response = yield call(API.getLiveChargingSessions, action.payload);
    yield put({ type: FETCH_LIVE_CHARGING_SESSION.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_LIVE_CHARGING_SESSION.FAIL, payload: { error: e } });
    toast.error('Failed to fetch live charging session data');
  }
}

function* LiveChargingSessionSaga() {
  yield takeLatest(FETCH_LIVE_CHARGING_SESSION.REQUEST, fetchLiveChargingSessions);
}

export default LiveChargingSessionSaga;
