import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
const EditOemModal = (props) => {
  const { onClose = _.noop(), editChargeSpotModel = _.noop(), title, subTitle } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="oem-modal-main_page">
        <div className="oem-modal--inner">
          <div className="oem-modal---block_title">
            <div className="inner--block_title">
              <div className="title">This charger {subTitle} used in chargeSpot model.</div>
              <p className="sub-title">Are you sure want to edit {title} ?</p>
            </div>
          </div>
          <div className="oem-modal-input-box__block">
            <Row>
              <Col lg={6}>
                <Button className="oem-model--btn cancel--btn" onClick={onClose}>
                  {t('button.cancel')}
                </Button>
              </Col>
              <Col lg={6}>
                <Button className="oem-model--btn  remove--btn" onClick={editChargeSpotModel}>
                  {t('button.editChargeSpotModel')}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
EditOemModal.propTypes = {
  onClose: PropTypes.func,
  editChargeSpotModel: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
export default EditOemModal;
