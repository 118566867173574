import { downloadFiles, downloadFile } from 'utils/api';

export const addBulkMeterValues = (data) => {
  return downloadFile(`/util/bulk_generate_invoice`, data);
};

export const BulkBookingTime = (data) => {
  return downloadFiles(`/charger_bookings/bulk_charger_bookings`, data);
};

export const bulkStopTransaction = (data) => {
  return downloadFile(`/util/bulk_stoptransaction`, data);
};

export const updateBulkNegativeValues = {
    addBulkMeterValues,
    BulkBookingTime,
    bulkStopTransaction,
  };