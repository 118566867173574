import { post, get, patch, deleteRequest } from 'utils/api';

export const createSchedule = (data) => {
  return post(`/schedul_setting`, data);
};

export const fetchSchedule = (data) => {
  return get(`/schedul_setting`, data);
};

export const updateSchedule = (data) => {
  const id = data.id;
  const { timeInterval, timeTo, timeFrom } = data.data[0];
  const dataObj = { timeInterval, timeTo, timeFrom };
  return patch(`/schedul_setting/${id}`, dataObj);
};

export const deleteYourSchedule = (id) => {
  return deleteRequest(`/schedul_setting/${id}`);
};

export const schedule = {
  createSchedule,
  fetchSchedule,
  updateSchedule,
  deleteYourSchedule,
};
