import React from 'react';
import { Button as ReactButton } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Button = (props) => {
  const { type, name, size, variant, onClick, disabled, className } = props;

  return (
    <ReactButton
      {...props}
      type={type || 'button'}
      name={name}
      onClick={onClick}
      disabled={disabled || false}
      className={`primary-button ${className ? className : ''}`}
      size={size}
      variant={variant || 'danger'}
    >
      {props.children}
    </ReactButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};
export default Button;
