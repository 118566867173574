import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';
import * as _ from 'lodash';
import { DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT, CUSTOMER_WALLET_TRANSACTION_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { roundOfDigit } from 'components/common/utils';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import { FiMinimize2 } from 'react-icons/fi';
import { BiRupee } from 'react-icons/bi';
import { FiFileText } from 'react-icons/fi';
import Footer from 'components/general/Footer';
import TextInput from 'components/inputs/Input';

const CustomerWalletTransactionReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});

  const customerWalletTransactionReportList = useSelector((state) => state.customerWalletTransactionReport.customerWalletTransactionReport);
  const isLoader = useSelector((state) => state.customerWalletTransactionReport.isLoading);
  const page = useSelector((state) => state.customerWalletTransactionReport.page);
  const totalData = useSelector((state) => state.customerWalletTransactionReport.total);
  const limit = useSelector((state) => state.customerWalletTransactionReport.limit);
  const totalPages = useSelector((state) => state.customerWalletTransactionReport.totalPages);
  const topup = useSelector((state) => state.customerWalletTransactionReport.topup);
  const amountAdded = useSelector((state) => state.customerWalletTransactionReport.amountAdded);
  const advanceBalance = useSelector((state) => state.customerWalletTransactionReport.advanceBalance);
  const amountByCMS = useSelector((state) => state.customerWalletTransactionReport.amountByCMS);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');

  const customerWalletTransactionReport = useCallback(
    (value) => {
      if (!_.isEmpty(userProfileStations)) {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
        };
        dispatch({
          type: CUSTOMER_WALLET_TRANSACTION_REPORT.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: CUSTOMER_WALLET_TRANSACTION_REPORT.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      const filterDateData = {
        ...advanceFilterData,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      customerWalletTransactionReport(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      customerWalletTransactionReport(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData]);

  useEffect(() => {
    if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        charging_station: userProfileStations,
      };
      customerWalletTransactionReport(data);
    } else {
      customerWalletTransactionReport();
    }
  }, []);

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && customerWalletTransactionReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        customerWalletTransactionReport(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && customerWalletTransactionReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        customerWalletTransactionReport(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        const filterDateData = {
          ...advanceFilterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        customerWalletTransactionReport(filterDateData);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        customerWalletTransactionReport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        customerWalletTransactionReport(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData]
  );

  // Download Excel Functionality
  const downloadcustomerWalletTransactionReport = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      const data = {
        ...advanceFilterData,
        excel: true,
        report: 'CustomerWalletTransaction',
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      dispatch({
        type: DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Customer Wallet Transaction Report'}.xlsx`);
            }
          }
        },
      });
    } else {
      const data = {
        excel: true,
        report: 'CustomerWalletTransaction',
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      dispatch({
        type: DOWNLOAD_CUSTOMER_WALLET_TRANSACTION_REPORT.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Customer Wallet Transaction Report'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadcustomerWalletTransactionReport();
  };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { phone: '', wallet_type: '', type: '' };

  const allWalletType = [
    { label: 'Book', value: 'Book' },
    { label: 'Booking Cancellation', value: 'Booking Cancellation' },
    { label: 'Auto Cancellation', value: 'Auto Cancellation' },
    { label: 'CMS Credit', value: 'CMS CREDIT' },
    { label: 'Refund', value: 'Refund' },
    { label: 'Wallet Topup', value: 'WalletTopup' },
    { label: 'Cash Back', value: 'Cashback' },
    { label: 'Extra Unit Consumed', value: 'ExtraUnits' },
  ];

  const allPaymentType = [
    { label: 'Debit', value: 'debit' },
    { label: 'Credit', value: 'credit' },
  ];

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('CMSWalletTransactionReport.walletTransactionReport')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="status-blocks">
              <div className="status-block">
                <div className="breating-area">
                  {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <FiMinimize2 className="icon-inner-color" size={35} />}
                </div>
                <div className="status-info">
                  <h1>{isLoader ? <Skeleton height={15} width={100} /> : topup ? topup : '0'}</h1>
                  <p>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.totalWalletTopup')}</p>
                </div>
              </div>

              <div className="status-block">
                <div className="breating-area">
                  {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <BiRupee className="icon-inner-color" size={35} />}
                </div>
                <div className="status-info">
                  <h1>{isLoader ? <Skeleton height={15} width={100} /> : amountAdded ? roundOfDigit(amountAdded, 2) : '0'}</h1>
                  <p>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.totalAmountAdded')}</p>
                </div>
              </div>

              <div className="status-block">
                <div className="breating-area">
                  {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <FiFileText className="icon-inner-color" size={35} />}
                </div>
                <div className="status-info">
                  <h1>{isLoader ? <Skeleton height={15} width={100} /> : advanceBalance ? roundOfDigit(advanceBalance, 2) : '0'}</h1>
                  <p>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.advanceBalanceThisTime')}</p>
                </div>
              </div>

              <div className="status-block">
                <div className="breating-area">
                  {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <BiRupee className="icon-inner-color" size={35} />}
                </div>
                <div className="status-info">
                  <h1>{isLoader ? <Skeleton height={15} width={100} /> : amountByCMS ? roundOfDigit(amountByCMS, 2) : '0'}</h1>
                  <p>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.totalAmountByCMS')}</p>
                </div>
              </div>
            </div>
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col xl={'auto'} md={'auto'}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment().startOf('month')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.userName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.phoneNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.type')}</span>
                                <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.walletType')}</span>
                                <span className="ico" onClick={() => handleSorting('wallet_type', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.bookingId')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.message')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.razorPayID')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.previousAmount')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.transactionAmount')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.updatedBalance')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('CMSWalletTransactionReport.dateTime')}</span>
                                <span className="ico" onClick={() => handleSorting('updatedAt', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={11} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(customerWalletTransactionReportList) ? (
                            <tr>
                              <td colSpan={12} className="border-0">
                                <div className="empty-data-block">{t('CMSWalletTransactionReport.noHistoryFound')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(customerWalletTransactionReportList, (item, key) => {
                              const serial_num = limit * (page - 1) + key;
                              return (
                                <>
                                  <tr key={`wallet-transaction-report-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'user.name', 'Guest User')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'user.phone', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'type', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'wallet_type', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'booking.id', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'message', '-')}</td>
                                    <td>
                                      {isLoading ? (
                                        <Skeleton height={15} width={100} />
                                      ) : _.get(item, 'invoice.razorpay_order_id') ? (
                                        _.get(item, 'invoice.razorpay_order_id')
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'current_balance', '-'), 2)}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'amount', '-'), 2)}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        <>
                                          {' '}
                                          {_.get(item, 'type', '') === 'credit'
                                            ? roundOfDigit(_.get(item, 'current_balance', 0) + _.get(item, 'amount', ''), 2)
                                            : roundOfDigit(_.get(item, 'current_balance', 0) - _.get(item, 'amount', ''), 2)}
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : item.updatedAt ? (
                                        moment(item.updatedAt).format('DD/MM/YYYY  H:mm:ss')
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(customerWalletTransactionReportList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.phone && delete values['phone'];
              !values.wallet_type && delete values['wallet_type'];
              !values.type && delete values['type'];
              if (values.phone && _.isEmpty(values.type) && _.isEmpty(values.wallet_type)) {
                const customerWalletTransactionReportData = {
                  ...values,
                  wallet_type: '',
                  type: '',
                };
                customerWalletTransactionReport(customerWalletTransactionReportData);
              } else if (values.phone && !_.isEmpty(values.type) && _.isEmpty(values.wallet_type)) {
                const customerWalletTransactionReportData = {
                  ...values,
                  wallet_type: '',
                };
                customerWalletTransactionReport(customerWalletTransactionReportData);
              } else if (values.phone && _.isEmpty(values.type) && !_.isEmpty(values.wallet_type)) {
                const customerWalletTransactionReportData = {
                  ...values,
                  type: '',
                };
                customerWalletTransactionReport(customerWalletTransactionReportData);
              } else {
                customerWalletTransactionReport(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm, handleChange }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <TextInput
                      label={t('CMSWalletTransactionReport.enterPhone')}
                      placeholder={t('CMSWalletTransactionReport.enterPhone')}
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                    />
                    <Select
                      label={t('CMSWalletTransactionReport.selectType')}
                      options={[
                        ..._.map(allPaymentType, (item) => {
                          return { label: item.label, value: item.value };
                        }),
                      ]}
                      placeholder={t('CMSWalletTransactionReport.selectType')}
                      name="type"
                      isMulti
                      value={values.type}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        setFieldValue('type', val);
                      }}
                    />
                    <Select
                      label={t('CMSWalletTransactionReport.selectWalletType')}
                      options={[
                        ..._.map(allWalletType, (item) => {
                          return { label: item.label, value: item.value };
                        }),
                      ]}
                      placeholder={t('CMSWalletTransactionReport.selectWalletType')}
                      name="wallet_type"
                      isMulti
                      value={values.wallet_type}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        setFieldValue('wallet_type', val);
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      customerWalletTransactionReport();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default CustomerWalletTransactionReport;
