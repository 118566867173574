import { FETCH_OCPP_ERROR } from 'actions/ocppErrorCode';
// import * as _ from 'lodash';

const initialState = {
  ocppErrorList: [],
};

const ocppErrorCodeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_OCPP_ERROR.REQUEST: {
      return { ...state };
    }

    case FETCH_OCPP_ERROR.SUCCESS: {
      return {
        ...state,
        ocppErrorList: payload,
      };
    }

    case FETCH_OCPP_ERROR.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default ocppErrorCodeReducer;
