import { deleteRequest, download, get } from 'utils/api';

export const getActivityLog = (data) => {
  return get(`/charger_logs`, data);
};

export const deleteActivityLog = (id) => {
  return deleteRequest(`/charger_logs/${id}`);
};

export const downloadChargerLogData = (data) => {
  return download(`/charger_logs`, data);
};

export const getUserActivity = (data) => {
  return get(`/user_activity`, data);
};

export const downloadTabularMeterValue = (data) =>{
  return download('/charger_bookings/metervaluesAll' , data)
}

export const activityLog = {
  getActivityLog,
  deleteActivityLog,
  downloadChargerLogData,
  getUserActivity,
  downloadTabularMeterValue
};
