import { get, post, patch, deleteRequest } from 'utils/api';

export const getElectricitySuppliers = (data) => {
  return get('/electricity_supplier', data);
};

export const getElectricitySuppliersById = (id) => {
  return get(`/electricity_supplier/${id}`);
};
export const addElectricitySuppliers = (data) => {
  return post(`/electricity_supplier`, data);
};

export const updateElectricitySuppliers = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/electricity_supplier/${id}`, data);
};

export const deleteElectricitySuppliers = (id) => {
  return deleteRequest(`/electricity_supplier/${id}`);
};

export const electricitySupplier = {
  getElectricitySuppliers,
  getElectricitySuppliersById,
  addElectricitySuppliers,
  updateElectricitySuppliers,
  deleteElectricitySuppliers,
};
