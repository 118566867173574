import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import ReactPagination from 'components/general/ReactPagination';
import { Col, Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { Formik, Form } from 'formik';
import { FaFilter } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/inputs/Select';
import { FETCH_COUNTRY, FETCH_STATE, GET_CITY_BY_STATE } from 'actions/address';
import { useParams } from 'react-router';
import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import { FETCH_TAGS } from 'actions/tagmanagement';
import { useTranslation } from 'react-i18next';
import { GET_LOCATION, REMOVE_LOCATION } from 'actions/partnerManagement';
import { SkeletonTheme } from 'react-loading-skeleton';
import CheckBox from 'components/inputs/CheckBox';
import OffCanvas from 'components/inputs/OffCanvas';
import { MdClear } from 'react-icons/md';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import RequestModal from '../RequestModal';
import { toast } from 'react-toastify';

const StationAccessList = () => {
  const { navigateTo } = useContext(NavContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { ocpiId } = useParams();

  const [showStationModel, setShowStationModel] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [stationSelection, setStationSelection] = useState({});

  const [cityLimit, setCityLimit] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeState, setChangeState] = useState(false);
  const [changeCity, setChangeCity] = useState(false);
  const [changeStation, setChangeStation] = useState(false);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [currentTab, setCurrentTab] = useState('includedStations');

  const partnerStationData = useSelector((state) => state.partnerLocation.partnerLocation);
  const isLoader = useSelector((state) => state.partnerLocation.isLoading);
  const page = useSelector((state) => state.partnerLocation.page);
  const totalData = useSelector((state) => state.partnerLocation.total);
  const limit = useSelector((state) => state.partnerLocation.limit);
  const totalPages = useSelector((state) => state.partnerLocation.totalPages);

  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const alltags = useSelector((state) => state.tagmanagement.tags);
  const totalStateData = useSelector((state) => state.state.total);
  const allCityByState = useSelector((state) => state.cityByState.cityByStates);
  const totalCityData = useSelector((state) => state.cityByState.total);
  const allStationByCity = useSelector((state) => state.stationByCity.stationByCities);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityByStatePage = useSelector((state) => state.cityByState.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityByStateTotalPage = useSelector((state) => state.cityByState.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const stationList =
    userProfileStations && userProfileStations.length > 0
      ? allStationByCity.filter((x) => userProfileStations.indexOf(x.id) !== -1)
      : allStationByCity;

  const initialValues = !_.isEmpty(advanceFilterData)
    ? { ...advanceFilterData }
    : { country: '', state: '', city: '', charging_station: '', tags: '' };

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByStateName = useCallback((data = {}) => {
    dispatch({ type: GET_CITY_BY_STATE, payload: data });
  }, []);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      access_type: 'Public',
    };
    dispatch({ type: GET_STATION_BY_CITY, payload: stationData });
  }, []);

  const getTags = useCallback((data = {}) => {
    dispatch({ type: FETCH_TAGS, payload: data });
  });

  const getLocationDetail = useCallback(
    (data) => {
      const locationData = {
        ...data,
        id: ocpiId,
        is_included_station: currentTab === 'includedStations',
      };
      dispatch({
        type: GET_LOCATION,
        payload: locationData,
      });
    },
    [currentTab, partnerStationData]
  );

  const setTab = (tab) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (showAdvanceFilterPopup) {
      getStateByCountry({ country_name: country, limit: totalStateData });
    }
    if (cityLimit) {
      getCityByStateName({ state_names: state, limit: totalCityData });
    }
  }, [country, state, totalStateData, totalCityData, cityLimit]);

  useEffect(() => {
    getAllCountry();
    getTags({ limit: 999 });
  }, []);

  useEffect(() => {
    getLocationDetail();
  }, [currentTab]);

  useEffect(() => {
    setSelectAll(false);
    const shouldSelectAll = partnerStationData?.every((station) => {
      const selection = stationSelection[station.id];
      return selection?.stationChecked === true && Object.values(selection.chargers).every(Boolean);
    });
    setSelectAll(shouldSelectAll);
  }, [partnerStationData, stationSelection, currentTab]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const updatedSelection = {};
    partnerStationData.forEach((station) => {
      updatedSelection[station.id] = {
        stationChecked: newSelectAll,
        chargers: station.chargers.reduce((acc, charger) => {
          acc[charger.id] = newSelectAll;
          return acc;
        }, {}),
      };
    });

    setStationSelection((prev) => ({ ...prev, ...updatedSelection }));
  };

  const handleStationSelect = (stationId) => {
    const stationChecked = !stationSelection[stationId]?.stationChecked;

    setStationSelection((prev) => {
      const updatedSelection = {
        ...prev,
        [stationId]: {
          stationChecked,
          chargers: partnerStationData
            .find((station) => station.id === stationId)
            .chargers.reduce((acc, charger) => {
              acc[charger.id] = stationChecked;
              return acc;
            }, {}),
        },
      };
      updateSelectAll(updatedSelection);
      return updatedSelection;
    });
  };

  const handleChargerSelect = (stationId, chargerId) => {
    const station = partnerStationData.find((station) => station.id === stationId);
    if (!station) return;

    const stationChargers = stationSelection[stationId]?.chargers || {};
    const chargerChecked = !stationChargers[chargerId];
    const updatedChargers = { ...stationChargers, [chargerId]: chargerChecked };
    const allChargersSelected = station.chargers.every((charger) => updatedChargers[charger.id]);
    const updatedSelection = {
      ...stationSelection,
      [stationId]: {
        stationChecked: allChargersSelected,
        chargers: updatedChargers,
      },
    };

    setStationSelection((prev) => ({ ...prev, ...updatedSelection }));
    updateSelectAll(updatedSelection);
  };

  const updateSelectAll = (updatedSelection) => {
    const allChecked = partnerStationData.every((station) => {
      const selection = updatedSelection[station.id];
      return selection?.stationChecked === true && Object.values(selection.chargers).every(Boolean);
    });
    setSelectAll(allChecked);
  };

  const handlePageClick = useCallback(
    (page) => {
      if (!_.isEmpty(advanceFilterData)) {
        if (advanceFilterData.charging_station === 'All' && advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
          const filterData = {
            ...advanceFilterData,
            charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
            state: '',
            city: '',
          };
          const data = {
            ...filterData,
            page: page.selected + 1,
          };
          getLocationDetail(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
          };
          getLocationDetail(data);
        } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
          };
          getLocationDetail(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '', state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
          };
          getLocationDetail(data);
        } else if (advanceFilterData.state === 'All') {
          const filterData = { ...advanceFilterData, state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
          };
          getLocationDetail(data);
        } else if (advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
          };
          getLocationDetail(data);
        } else if (advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
          };
          getLocationDetail(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
          };
          getLocationDetail(data);
        }
      } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const filterDateData = {
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        getLocationDetail(filterDateData);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getLocationDetail(data);
      }
    },
    [advanceFilterData, currentTab, partnerStationData]
  );

  const removeLocation = useCallback(() => {
    let stationIds = [];
    let chargerIds = [];

    Object.keys(stationSelection).forEach((stationId) => {
      const selection = stationSelection[stationId];
      if (selection.stationChecked) {
        stationIds.push(stationId);
      } else {
        const selectedChargers = Object.entries(selection.chargers)
          .filter(([, checked]) => checked)
          .map(([chargerId]) => chargerId);

        if (selectedChargers.length > 0) {
          chargerIds.push(...selectedChargers);
        }
      }
    });

    if (stationIds.length > 0 || chargerIds.length > 0) {
      dispatch({
        type: REMOVE_LOCATION,
        payload: {
          station: stationIds.length > 0 ? stationIds : undefined,
          charger: chargerIds.length > 0 ? chargerIds : undefined,
          id: ocpiId,
          is_included_station: currentTab === 'includedStations',
        },
        cb: (res) => {
          if (_.get(res, 'status') === 200) {
            toast.success(`Successfully removed ${stationIds.length > 0 ? 'station(s)' : ''} ${chargerIds.length > 0 ? 'charger(s)' : ''}`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        },
      });
    }
  }, [stationSelection, ocpiId, currentTab, dispatch, getLocationDetail]);

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);
  const handleCloseStationModel = () => {
    setShowStationModel(false);
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.stationAccessToPatner')} backTo="/OCPI-Partner" />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="station-wise-filter_page__main">
              <Card>
                <div>
                  <div className="tab-block">
                    <div className="tab-nav-block" style={{ border: 'none' }}>
                      <Col lg={8} md={'auto'}>
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'includedStations' ? 'active' : ''}`}
                          onClick={() => {
                            setTab('includedStations');
                          }}
                          data-content="tab-bookings"
                        >
                          {t('stationAccessToPatner.includedStations')}
                        </a>
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'excludedStations' ? 'active' : ''}`}
                          onClick={() => {
                            setTab('excludedStations');
                          }}
                          data-content="tab-transactions"
                        >
                          {t('stationAccessToPatner.excludedStations')}
                        </a>
                      </Col>
                      <Col lg={2} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button" style={{ padding: '1rem 5rem' }}>
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => navigateTo(`/stationAccess/${ocpiId}`)}>
                            <Button className="report-search-button" style={{ padding: '1rem 5rem' }}>
                              <span>{t('stationAccessToPatner.addStation')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
                {!_.isEmpty(partnerStationData) && !(isLoader || _.isUndefined(isLoader)) && (
                  <div className="select-all-checkbox-input__block">
                    <label className="select-all--label">{t('stationAccessToPatner.selectAll')}</label>
                    <CheckBox checked={selectAll} onChange={handleSelectAll} />
                  </div>
                )}
                <div className="station-access-list__main">
                  <div className="station-access-list--block">
                    {isLoader ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                        <span className="ps-2">{t('placeHolder.loading')}</span>
                      </div>
                    ) : _.isEmpty(partnerStationData) ? (
                      <div className="empty-data-block">{t('stationAccessToPatner.noData')}</div>
                    ) : (
                      !(isLoader || _.isUndefined(isLoader)) &&
                      _.map(partnerStationData, (item) => {
                        return (
                          <ChargerDataItem
                            key={item.id}
                            station={item}
                            stationChecked={stationSelection[item.id]?.stationChecked || false}
                            chargers={stationSelection[item.id]?.chargers || {}}
                            onStationSelect={() => handleStationSelect(item.id)}
                            onChargerSelect={(chargerId) => handleChargerSelect(item.id, chargerId)}
                          />
                        );
                      })
                    )}
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(partnerStationData) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
                {!_.isEmpty(partnerStationData) && !(isLoader || _.isUndefined(isLoader)) && (
                  <div className="charger-list--button" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => setShowStationModel(true)} style={{ padding: '1rem 5rem' }}>
                      {t('button.remove')}
                    </Button>
                  </div>
                )}
              </Card>
            </div>

            {showStationModel && (
              <Modal show={showStationModel} centered onHide={handleCloseStationModel}>
                <RequestModal
                  subTitle={t('stationAccessToPatner.modelTitle')}
                  title={t('stationAccessToPatner.modelTitle')}
                  onClose={handleCloseStationModel}
                  requestModal={() => {
                    removeLocation();
                    setShowStationModel(false);
                  }}
                />
              </Modal>
            )}
          </div>
        </SkeletonTheme>
      </LayoutContainer>

      {/* Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.country && delete values['country'];
              !values.state && delete values['state'];
              !values.city && delete values['city'];
              !values.charging_station && delete values['charging_station'];

              if (values.charging_station === 'All' && values.state === 'All' && values.city === 'All') {
                const locationData = {
                  ...values,
                  state: '',
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                getLocationDetail(locationData);
              } else if (values.state === 'All' && values.charging_station === 'All') {
                const locationData = {
                  ...values,
                  state: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                getLocationDetail(locationData);
              } else if (values.city === 'All' && values.charging_station === 'All') {
                const locationData = {
                  ...values,
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                getLocationDetail(locationData);
              } else if (values.state === 'All' && values.city === 'All') {
                const locationData = { ...values, state: '', city: '' };
                getLocationDetail(locationData);
              } else if (values.city === 'All') {
                const locationData = { ...values, city: '' };
                getLocationDetail(locationData);
              } else if (values.state === 'All') {
                const locationData = { ...values, state: '' };
                getLocationDetail(locationData);
              } else if (values.charging_station === 'All') {
                const locationData = { ...values, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                getLocationDetail(locationData);
              } else {
                getLocationDetail(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
              onCloseAdvanceFilterPopup();
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label={t('filters.country')}
                    options={_.map(allCountries, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectCountry')}
                    name="country"
                    value={values.country}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllCountry}
                    page={countryPage}
                    totalPage={countryTotalPage}
                    onChange={(val) => {
                      getStateByCountry({ country_name: val, limit: 999 });
                      setCountry(val);
                      setFieldValue(`country`, val);
                      setFieldValue(`state`, '');
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.state')}
                    options={
                      !changeState
                        ? values.country &&
                          !_.isEmpty(allState) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allState, (state) => {
                              return { label: state.name, value: state.name };
                            }),
                          ]
                        : values.country && !_.isEmpty(allState) && [{ label: 'All', value: 'All' }]
                    }
                    className="filter-select-box"
                    placeholder={t('placeHolder.selectState')}
                    isMulti
                    name="state"
                    value={values.state}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                    page={statePage}
                    totalPage={stateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getCityByStateName({ state_names: '', limit: 999 });
                        setChangeState(true);
                        setFieldValue(`state`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeState(false);
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`state`, '');
                      } else {
                        getCityByStateName({ state_names: val, limit: 999 });
                        setState(val);
                        setCityLimit(true);
                        setChangeState(false);
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`state`, val);
                      }
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={t('filters.city')}
                    options={
                      !changeCity
                        ? !_.isEmpty(values.state) &&
                          !_.isEmpty(allCityByState) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allCityByState, (state) => {
                              return { label: state.name, value: state.name };
                            }),
                          ]
                        : !_.isEmpty(values.state) && values.country && !_.isEmpty(allCityByState) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder={t('placeHolder.selectCity')}
                    name="city"
                    isMulti
                    value={values.city}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getCityByStateName({ ...data, state_names: values.state === 'All' ? '' : values.state })}
                    page={cityByStatePage}
                    totalPage={cityByStateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getAllStation({ city_array: '' });
                        setChangeCity(true);
                        setFieldValue(`city`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue(`city`, '');
                      } else {
                        getAllStation({ city_array: val });
                        setChangeCity(false);
                        setChangeStation(false);
                        setFieldValue('city', val);
                      }
                      setFieldValue(`charging_station`, '');
                    }}
                  />
                  <div className="canvas-selection__block">
                    <Select
                      label={t('filters.station')}
                      options={
                        !changeStation
                          ? (!_.isEmpty(_.get(values, 'city')) && !_.isEmpty(stationList) && _.get(values, 'state') === 'All') ||
                            _.get(stationList, 'city') === _.get(values, 'city')
                            ? [
                                // { label: 'All', value: 'All' },
                                ..._.map(stationList, (station) => {
                                  return { label: station?.name, value: station?.id };
                                }),
                              ]
                            : [
                                // { label: 'All', value: 'All' },
                                ..._.map(
                                  stationList?.filter((o) => o.state === values.state[0]),
                                  (station) => {
                                    return { label: station?.name, value: station?.id };
                                  }
                                ),
                              ]
                          : !_.isEmpty(_.get(values, 'city')) &&
                            !_.isEmpty(_.get(values, 'state')) &&
                            values.country &&
                            !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder={t('placeHolder.selectStation')}
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charging_station`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue(`charging_station`, val);
                        }
                      }}
                    />
                    <Select
                      label={t('filters.tags')}
                      isMulti
                      options={_.map(alltags, (item) => {
                        return { label: item.name, value: item.id };
                      })}
                      className="filter-select-box"
                      placeholder={t('placeHolder.selectTags')}
                      name="tags"
                      value={values.tags}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={getTags}
                      onChange={(val) => {
                        setFieldValue(`tags`, val);
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      getLocationDetail();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </React.Fragment>
  );
};

const ChargerDataItem = (props) => {
  const { station, stationChecked, chargers, onStationSelect, onChargerSelect } = props;

  return (
    <div className="station-access--list">
      <div className="list-info">
        <div className="station-checkbox-input__block">
          <label className="station--label">{_.get(station, 'name', '')}</label>
          <CheckBox checked={stationChecked} onChange={onStationSelect} />
        </div>
        <div className="charger-list-info">
          {_.map(_.get(station, 'chargers', []), (data, index) => {
            return (
              <>
                <div className="charger-checkbox-input__block" key={`charger-${index}`}>
                  <label className="charger--label">{_.get(data, 'charger_id', '')}</label>
                  <CheckBox checked={chargers[data.id] || false} onChange={() => onChargerSelect(data.id)} />
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

ChargerDataItem.propTypes = {
  station: PropTypes.object,
  chargers: PropTypes.array,
  onStationSelect: PropTypes.func,
  onChargerSelect: PropTypes.func,
  stationChecked: PropTypes.bool,
};
export default StationAccessList;
