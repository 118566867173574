import React from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

const CustomSwitch = (props) => {
  const {
    className,
    label,
    disabled,
    wrapperClass,
    onChange,
    boxShadow,
    activeBoxShadow,
    onColor,
    handleDiameter,
    onHandleColor,
    id,
    checked,
    width,
    height,
    onClick,
  } = props;

  return (
    <div className={`switch__main ${wrapperClass ? wrapperClass : ''} `}>
      <label className="switch--label">
        <div className={'switch--wrapper'} onClick={onClick}>
          <Switch
            {...props}
            checked={checked || false}
            onChange={onChange || _.noop}
            className={`${className ? className : ''}`}
            width={width || 25}
            height={height || 15}
            disabled={disabled}
            onColor={onColor || '#B0BEC5'}
            onHandleColor={onHandleColor || '#2693e6'}
            handleDiameter={handleDiameter || 11}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow={boxShadow}
            activeBoxShadow={activeBoxShadow}
            id={id}
          />
        </div>
        <span className="switch-text">{label}</span>
      </label>
    </div>
  );
};

CustomSwitch.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  onColor: PropTypes.string,
  onHandleColor: PropTypes.string,
  activeBoxShadow: PropTypes.string,
  wrapperClass: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  boxShadow: PropTypes.string,
  value: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  handleDiameter: PropTypes.number,
  onClick: PropTypes.func,
};
export default CustomSwitch;
