import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_RESERVE_SLOTS,
  FETCH_RESERVE_SLOTS,
  GET_RESERVE_SLOTS,
  DELETE_RESERVE_SLOTS,
  UPDATE_RESERVE_SLOTS,
  ALL_USERS_IN_RESERVE_SLOTS,
  CREATE_BULK_USERS_TO_RESERVE_SLOTS,
} from 'actions/reserveSlots';

function* getReserveSlots(action) {
  try {
    const response = yield call(API.getReserveSlots, action.payload);
    yield put({ type: FETCH_RESERVE_SLOTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_RESERVE_SLOTS.FAIL, payload: { error: e } });
  }
}

function* getReserveSlotsById(action) {
  try {
    const response = yield call(API.getUserGroupById, action.payload);
    yield put({ type: GET_RESERVE_SLOTS.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_RESERVE_SLOTS.FAIL, payload: { error: e } });
  }
}

// function* createReserveSlot(action) {
//   try {
//     const response = yield call(API.addUserGroup, action.payload);
//     yield put({ type: CREATE_RESERVE_SLOTS.SUCCESS, payload: response.data });
//     action.cb && action.cb(response);
//   } catch (e) {
//     yield put({ type: CREATE_RESERVE_SLOTS.FAIL, payload: { error: e } });
//   }
// }

function* createPrivateReserveSlot(action) {
  try {
    const response = yield call(API.addReserveSlot, action.payload);
    yield put({ type: CREATE_RESERVE_SLOTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_RESERVE_SLOTS.FAIL, payload: { error: e } });
  }
}

function* updateReserveSlot(action) {
  try {
    const response = yield call(API.updateReserveSlots, action.payload);
    yield put({ type: UPDATE_RESERVE_SLOTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_RESERVE_SLOTS.FAIL, payload: { error: e } });
  }
}

function* deleteReserveSlot(action) {
  try {
    const response = yield call(API.deleteYourReserveSlot, action.payload);
    yield put({ type: DELETE_RESERVE_SLOTS.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_RESERVE_SLOTS.FAIL, payload: { error: e } });
  }
}

function* allUsersInUserGroup(action) {
  try {
    const response = yield call(API.allUsersInUserGroup, action.payload);
    yield put({ type: ALL_USERS_IN_RESERVE_SLOTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: ALL_USERS_IN_RESERVE_SLOTS.FAIL, payload: { error: e } });
  }
}

function* createBulkUsersToUserGroup(action) {
  try {
    const response = yield call(API.createBulkUsersToUserGroup, action.payload);
    yield put({ type: CREATE_BULK_USERS_TO_RESERVE_SLOTS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_BULK_USERS_TO_RESERVE_SLOTS.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* userGroup() {
  yield takeLatest(FETCH_RESERVE_SLOTS.REQUEST, getReserveSlots);
  yield takeLatest(CREATE_RESERVE_SLOTS.REQUEST, createPrivateReserveSlot);
  yield takeLatest(GET_RESERVE_SLOTS.REQUEST, getReserveSlotsById);
  yield takeLatest(DELETE_RESERVE_SLOTS.REQUEST, deleteReserveSlot);
  yield takeLatest(UPDATE_RESERVE_SLOTS.REQUEST, updateReserveSlot);
  yield takeLatest(ALL_USERS_IN_RESERVE_SLOTS.REQUEST, allUsersInUserGroup);
  yield takeLatest(CREATE_BULK_USERS_TO_RESERVE_SLOTS.REQUEST, createBulkUsersToUserGroup);
}

export default userGroup;
