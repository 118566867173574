import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import * as _ from 'lodash';

const TextInput = (props) => {
  const {
    label,
    name,
    value,
    type,
    pre_icon,
    pre_text,
    post_icon,
    post_text,
    placeholder,
    autoComplete,
    wrapperclass,
    onChange,
    size,
    disabled,
    required,
    isInvalid,
    isValid,
    error,
    labelClass,
    inputClass,
    isRequired,
    image,
  } = props;

  return (
    <div className={`form-input-group ${wrapperclass ? wrapperclass : ''}`}>
      {label && (
        <label className={`input-label ${labelClass ? labelClass : ''}`} htmlFor={name}>
          {label}
          {isRequired && <span className="require--star">*</span>}
        </label>
      )}
      <InputGroup>
        {(pre_text || pre_icon) && (
          <InputGroup.Text className={`icon-group pre-group ${pre_text ? 'text-group' : ''}`}>
            {pre_text && pre_text}
            {pre_icon && pre_icon}
          </InputGroup.Text>
        )}
        {!_.isEmpty(image) && (
          <div className="uploaded-image">
            <img src={image} alt="logo" />
          </div>
        )}
        <FormControl
          id={name}
          type={type || 'text'}
          name={name}
          value={value}
          size={size || 'sm'}
          placeholder={placeholder}
          onChange={onChange}
          autoComplete={autoComplete}
          className={`text-input ${inputClass ? inputClass : ''} ${pre_icon ? 'pre-icon-input' : ''} ${pre_text ? 'pre-text-input' : ''} 
            ${post_icon ? 'post-icon-input' : ''} ${post_text ? 'post-text-input' : ''}`}
          disabled={disabled}
          isValid={isValid}
          required={required}
          isInvalid={isInvalid}
          {...props}
        />
        {(post_icon || post_text) && (
          <InputGroup.Text className={`icon-group post-group ${post_text ? 'text-group' : ''}`}>
            {post_icon && post_icon}
            {post_text && post_text}
          </InputGroup.Text>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </InputGroup>
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  pre_icon: PropTypes.node,
  pre_text: PropTypes.string,
  post_icon: PropTypes.node,
  post_text: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.bool,
  wrapperclass: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  error: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  isRequired: PropTypes.bool,
  image: PropTypes.string,
};

export default TextInput;
