import { get, post, deleteRequest, patch } from 'utils/api';

export const getCoupons = (data) => {
  return get(`/coupon`, data);
};

export const addCoupon = (data) => {
  return post(`/coupon`, data);
};

export const updateCoupon = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/coupon/${id}`, data);
};

export const deleteCoupon = (id) => {
  return deleteRequest(`/coupon/${id}`);
};

export const getCouponById = (id) => {
  return get(`/coupon/${id}`);
};

export const validateCoupon = (data) => {
  return post('/coupon/CMSValidateCoupon?limit=100', data);
};

export const coupon = {
  getCoupons,
  addCoupon,
  updateCoupon,
  deleteCoupon,
  getCouponById,
  validateCoupon,
};
