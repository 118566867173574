import React, { useState, useCallback, useEffect, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Modal, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { DELETE_OEM, FETCH_OEM, GET_OEM } from 'actions/oem';
// import { useHistory } from 'react-router-dom';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BsEye } from 'react-icons/bs';
import SingleOemList from './SingleOemList';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const OemList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const [searchText, setSearchText] = useState('');
  const [showModal, setShowModal] = useState(false);
  // const [allOemData, setAllOemData] = useState([]);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [chargeSpotId, setChargeSpotId] = useState('');
  const [pageWiseOemData, setPageWiseOemData] = useState([]);

  const page = useSelector((state) => state.oem.page);
  const totalData = useSelector((state) => state.oem.total);
  const limit = useSelector((state) => state.oem.limit);
  const oemList = useSelector((state) => state.oem.oems);
  const isLoader = useSelector((state) => state.oem.isLoading);
  const totalPages = useSelector((state) => state.oem.totalPages);
  const oemData = oemList.filter(({ id: id1 }) => pageWiseOemData.some(({ id: id2 }) => id2 === id1));

  const deleteChargeSpot = useCallback(
    () =>
      dispatch({
        type: DELETE_OEM.REQUEST,
        payload: chargeSpotId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getOem({ page: 1 });
          }
        },
      }),
    [chargeSpotId]
  );

  const getOem = useCallback(
    (data = {}) => {
      if (searchText) {
        const searchOemData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_OEM.REQUEST,
          payload: searchOemData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseOemData(res.data.results);
              }
            }
          },
        });
      } else
        dispatch({
          type: FETCH_OEM.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseOemData(res.data.results);
              }
            }
          },
        });
    },
    [pageWiseOemData, searchText]
  );

  const getChargeSpotList = useCallback((id) => {
    dispatch({ type: GET_OEM.REQUEST, payload: id });
  }, []);

  useEffect(() => {
    getOem();
  }, []);

  const handleSearch = () => {
    getOem({});
  };

  // useEffect(() => {
  //   setAllOemData(
  //     _.filter(oemData, (item) => startsWith(_.get(item, 'name', ''), searchText) || startsWith(_.get(item, 'vendor.name', ''), searchText))
  //   );
  // }, [searchText, oemList, pageWiseOemData]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getOem(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getOem(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getOem(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setChargeSpotId('');
  };

  const handleCloseChargeSpot = () => {
    setShowModal(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.chargespotModel')} />
        <div className="page-content-wrapper scrollable">
          <div className="oemList-page">
            <Card>
              <div className="oemList-page-wrapper">
                <Row className="oemList-search-box">
                  <Col xl={9} md={7} className="oemList-search-inputBox">
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  <Col xl={3} md={5} className="oemList-search-boxButton">
                    <Button className="oemList-search-box-Btn" onClick={() => navigateTo('/addChargeSpot')}>
                      {t('button.addChargeSpotModel')}
                    </Button>
                  </Col>
                </Row>
                <div className="oemList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('oemForm.chargeSpotModelName')}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('oemForm.oemName')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('oemForm.dimension')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('oemForm.noOfPlugs')}</span>
                              <span className="ico" onClick={() => handleSorting('plugs', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th className="action-col">
                            <div className="sorting">
                              <span>{t('oemForm.action')}</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(oemData) ? (
                          <tr>
                            <td colSpan={6} className="border-0">
                              <div className="empty-data-block">{t('oemForm.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(oemData, (item, key) => {
                            return (
                              <>
                                <tr key={`oem-${key}`}>
                                  <td>{_.get(item, 'name', '')}</td>
                                  <td>{_.get(item, 'vendor.name', '')}</td>
                                  <td className="dimension-data__block">
                                    <div className="dimension--data">{_.get(item, 'dimension_w_h_d', '')}</div>
                                  </td>
                                  <td>{_.filter(_.get(item, 'plugs', '')).length}</td>
                                  <td>
                                    <span className="oemList-table-editIcon" onClick={() => navigateTo(`/editChargeSpot/${_.get(item, 'id', '')}`)}>
                                      <FiEdit2 title="Edit" />
                                    </span>
                                    <span
                                      className="oemList-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setChargeSpotId(_.get(item, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine title="Delete" />
                                    </span>
                                    <span
                                      className="oemList-eyeIcon"
                                      onClick={() => {
                                        setShowModal(true);
                                        getChargeSpotList(_.get(item, 'id'));
                                      }}
                                    >
                                      <BsEye size={16} />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(oemData) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                      // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        {showModal && (
          <Modal show={showModal} size="lg" centered className="scrollable">
            <SingleOemList onClose={handleCloseChargeSpot} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="Charge Spot" onClose={handleCloseDeleteModel} onRemove={deleteChargeSpot} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default OemList;
