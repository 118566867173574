import { get, patch, post, deleteRequest } from 'utils/api';

export const createSchedulingSetting = (data) => {
  return post(`/tenantSetting`, data);
};

export const getAllSchedulingSetting = (data) => {
  return get(`/tenantSetting`, data);
};

export const getSchedulingSettingById = (id) => {
  return get(`/tenantSetting/${id}`);
};

export const updateSchedulingSetting = (data) => {
  const { id, key_name, key_value } = data.schedulingSetting;
  const item = { key_name, key_value };
  return patch(`/tenantSetting/${id}`, item);
};

export const deleteSchedulingSetting = (data) => {
  const { id, key_name, key_value } = data.schedulingSetting;
  const item = { key_name, key_value };
  return deleteRequest(`/tenantSetting/${id}`, item);
};

export const schedulingSetting = {
  createSchedulingSetting,
  getAllSchedulingSetting,
  getSchedulingSettingById,
  updateSchedulingSetting,
  deleteSchedulingSetting,
};
