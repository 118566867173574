import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useEffect, useCallback, useState, useContext } from 'react';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
import { DOWNLOAD_CUSTOMER_BOOKING, FETCH_CUSTOMER_BOOKING } from 'actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactPagination from 'components/general/ReactPagination';
import { BiSort } from 'react-icons/bi';
import { FaChargingStation } from 'react-icons/fa';
import { roundOfDigit } from 'components/common/utils';
import moment from 'moment';
import DropDown from 'components/inputs/DropDown';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsFilter } from 'react-icons/bs';
import { AiFillCaretDown } from 'react-icons/ai';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { SiMicrosoftexcel } from 'react-icons/si';
import Button from 'components/inputs/Button';
import fileDownload from 'js-file-download';
// import { useHistory } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { useTranslation } from 'react-i18next';
const CustomerBooking = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const { t } = useTranslation();
  const { fleetId } = useParams();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [startDate, setStartDate] = useState(moment().startOf('week').utc());
  const [endDate, setEndDate] = useState(moment().endOf('day').utc());
  const [fieldValue, setFieldValue] = useState('all');

  const customerBookingList = useSelector((state) => state.customerBooking.customerBooking);
  const page = useSelector((state) => state.customerBooking.page);
  const totalData = useSelector((state) => state.customerBooking.total);
  const limit = useSelector((state) => state.customerBooking.limit);
  const totalPages = useSelector((state) => state.customerBooking.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);

  const getCustomerBooking = useCallback(
    (data) => {
      const bookingData = {
        ...data,
        id: fleetId,
      };
      dispatch({ type: FETCH_CUSTOMER_BOOKING.REQUEST, payload: bookingData });
    },
    [fleetId]
  );
  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
      };
      getCustomerBooking(filterDateData);
    }
  }, [startDate, endDate, sortByItem]);

  useEffect(() => {
    getCustomerBooking(); // Call getCustomerBooking when the component first loads
    dateFilter(); // Call dateFilter after invoking getCustomerBooking
  }, []);

  const handleSearchClick = () => {
    if (fieldValue && startDate && endDate) {
      const filterDateData = {
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
        status: fieldValue,
      };
      getCustomerBooking(filterDateData);
    } else if (fieldValue) {
      const filterDateType = {
        status: fieldValue,
      };
      getCustomerBooking(filterDateType);
      dateFilter(); // Call dateFilter after invoking getCustomerBooking
    } else {
      getCustomerBooking();
    }
  };
  const handlePageClick = (page) => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, page, filterDateData);
    } else if (page && fieldValue && startDate && endDate) {
      const filterData = {
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
        status: fieldValue,
        page: page.selected + 1,
      };
      const allStatus = {
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
        page: page.selected + 1,
      };
      fieldValue === 'all' ? getCustomerBooking(allStatus) : endDate && getCustomerBooking(filterData);
    } else if (page && startDate && endDate) {
      const filterData = {
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
        page: page.selected + 1,
      };
      endDate && getCustomerBooking(filterData);
    } else if (page && fieldValue) {
      const filterDateType = {
        status: fieldValue,
        page: page.selected + 1,
      };
      const allStatus = {
        page: page.selected + 1,
      };
      fieldValue === 'all' ? getCustomerBooking(allStatus) : getCustomerBooking(filterDateType);
    } else if (page) {
      const data = {
        page: page.selected + 1,
      };
      getCustomerBooking(data);
    }
  };

  const getSortByItem = (name, order, page, filterDateData) => {
    const sortedOrder = order || 'asc';
    if (page && startDate && endDate && fieldValue) {
      const data = {
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
        status: fieldValue,
      };
      const allStatus = {
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      fieldValue === 'all' ? getCustomerBooking(allStatus) : endDate && getCustomerBooking(data);
    } else if (page && startDate && endDate) {
      const data = {
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      endDate && getCustomerBooking(data);
    } else if (page && fieldValue) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        status: fieldValue,
        page: page.selected + 1,
      };
      const allStatus = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      fieldValue === 'all' ? getCustomerBooking(allStatus) : getCustomerBooking(data);
    } else if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getCustomerBooking(data);
    } else if (filterDateData) {
      const data = {
        ...filterDateData,
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      endDate && getCustomerBooking(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getCustomerBooking(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const filterData = [
    { label: 'all', value: 'all' },
    { label: 'completed', value: 'completed' },
    { label: 'scheduled', value: 'scheduled' },
    { label: 'cancelled', value: 'cancelled' },
    { label: 'in_progress', value: 'in_progress' },
  ];

  const downloadCustomerBooking = useCallback(
    (data = {}) => {
      if (fieldValue && startDate && endDate) {
        const bookingData = {
          id: fleetId,
          excel: true,
          from: moment(startDate).utc().toDate(),
          to: moment(endDate).utc().toDate(),
          status: fieldValue,
          ...data,
        };
        const allData = {
          id: fleetId,
          excel: true,
          from: moment(startDate).utc().toDate(),
          to: moment(endDate).utc().toDate(),
          ...data,
        };
        const customerBookingData = fieldValue === 'all' ? allData : bookingData;
        dispatch({
          type: DOWNLOAD_CUSTOMER_BOOKING.REQUEST,
          payload: customerBookingData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Customer Booking'}.xlsx`);
              }
            }
          },
        });
      } else if (startDate && endDate) {
        const customerBookingData = {
          id: fleetId,
          excel: true,
          from: moment(startDate).utc().toDate(),
          to: moment(endDate).utc().toDate(),
          ...data,
        };
        dispatch({
          type: DOWNLOAD_CUSTOMER_BOOKING.REQUEST,
          payload: customerBookingData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Customer Booking'}.xlsx`);
              }
            }
          },
        });
      } else if (fieldValue) {
        const bookingData = {
          id: fleetId,
          excel: true,
          status: fieldValue,
          ...data,
        };
        const allData = {
          id: fleetId,
          excel: true,
          ...data,
        };
        const customerBookingData = fieldValue === 'all' ? allData : bookingData;
        dispatch({
          type: DOWNLOAD_CUSTOMER_BOOKING.REQUEST,
          payload: customerBookingData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Customer Booking'}.xlsx`);
              }
            }
          },
        });
      } else {
        const customerBookingData = {
          id: fleetId,
          excel: true,
          ...data,
        };
        dispatch({
          type: DOWNLOAD_CUSTOMER_BOOKING.REQUEST,
          payload: customerBookingData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Customer Booking'}.xlsx`);
              }
            }
          },
        });
      }
    },
    [startDate, endDate, fieldValue]
  );

  const downloadFile = () => {
    downloadCustomerBooking();
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <Card className="customerBooking-table">
            <Row className="customerBooking-table-heading-row">
              <Col xl={2} lg={6} md={6}>
                <div className="customerBooking-table-heading">{t('header.bookingDetails')}</div>
              </Col>
              <Col xl={2} lg={6} md={6}>
                <DropDown
                  popupClass="main-menu bottom scrollable"
                  trigger={
                    <>
                      <div className="customerBooking-filter-block">
                        <div className="customerBooking-filter-icon">
                          <BsFilter />
                          <span className="customerBooking-filter-label">{_.startCase(fieldValue)}</span>
                        </div>
                        <div className="customerBooking-arrow__icon">
                          <AiFillCaretDown />
                        </div>
                      </div>
                    </>
                  }
                  triggerClass="customerBooking-filter-dropdown"
                >
                  {_.map(filterData, (item, index) => (
                    <ul key={`${index}`}>
                      <li
                        className="item"
                        onClick={() => {
                          setFieldValue(item.value);
                        }}
                      >
                        {_.startCase(item.label)}
                      </li>
                    </ul>
                  ))}
                </DropDown>
              </Col>
              <Col xl={6} lg={9} md={9}>
                <DateTimePicker
                  onChangeOfStartDate={(item) => setStartDate(item)}
                  onChangeOfEndDate={(item) => setEndDate(item)}
                  initialValueOfStartDate={moment().startOf('week')}
                  initialValueOfEndDate={moment().endOf('day')}
                />
              </Col>
              <Col xl={1} lg={2} md={2}>
                <div className="customerBooking-search-box" onClick={handleSearchClick}>
                  <Button className="customerBooking-search-button">{t('button.search')}</Button>
                </div>
              </Col>
              <Col xl={1} lg={1} md={1}>
                {isLoading ? (
                  <div className="loader--block">
                    <Spinner as="span" animation="border" size="sm" role="status" />
                  </div>
                ) : (
                  <div className="customerBooking-excel-icon" onClick={downloadFile}>
                    <SiMicrosoftexcel size={35} color={'#5dad5d'} />
                  </div>
                )}
              </Col>
            </Row>
            <div className="customerBooking-list-table">
              <div className="table-responsive">
                <table className="record-list-table">
                  <thead>
                    <tr>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.srNo')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.stationName')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.ocppId')}</span>
                          <span className="ico" onClick={() => handleSorting('connectorId', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.status')}</span>
                          <span className="ico" onClick={() => handleSorting('status', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.bookingAmount')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.estimatedDiscount')}</span>
                          <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.userPaid')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.actualDiscount')}</span>
                          <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.serviceCharge')}</span>
                          <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.taxableAmount')}</span>
                          <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.gst')}</span>
                          <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.totalAmount')}</span>
                          <span className="ico" onClick={() => handleSorting('estimated_amount', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.extraAmount')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.refundAmount')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.stopReason')}</span>
                          <span className="ico" onClick={() => handleSorting('stop_reason', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.otp')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.rfid')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.sessionConsumption')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.slotTime')}</span>
                          <span className="ico" onClick={() => handleSorting('schedule_datetime', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.startChargingTime')}</span>
                          <span className="ico" onClick={() => handleSorting('booking_start', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.stopChargingTime')}</span>
                          <span className="ico" onClick={() => handleSorting('booking_stop', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoading ? <Skeleton height={15} width={100} /> : t('bookingDetails.sessionDuration')}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(customerBookingList) ? (
                      <tr>
                        <td colSpan={10} className="border-0">
                          <div className="empty-data-block">{t('bookingDetails.noData')}</div>
                        </td>
                      </tr>
                    ) : (
                      _.map(customerBookingList, (item, index) => {
                        const serial_num = limit * (page - 1) + index;
                        const otp = _.get(item, 'is_rfid_based_booking') === false ? _.get(item, 'idTag') : '-';
                        const rfid = _.get(item, 'is_rfid_based_booking') === true ? _.get(item, 'idTag') : '-';
                        const unitConsumed = isNaN(_.get(item, 'meterstop') - _.get(item, 'meterstart'))
                          ? '-'
                          : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3);
                        // const refundAmount = isNaN(_.get(item, 'estimated_amount') - _.get(item, 'invoice.amount'))
                        //   ? '-'
                        //   : roundOfDigit(_.get(item, 'estimated_amount') - _.get(item, 'invoice.amount'), 2);
                        // const unitConsumed = isNaN(_.get(item, 'meterstop') - _.get(item, 'meterstart') / 1000)
                        //   ? 0
                        //   : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3);

                        const bookingStart = moment(_.get(item, 'booking_start', '-')).toISOString();
                        const bookingStop = moment(_.get(item, 'booking_stop', '-')).toISOString();
                        // const bookingDuration = isNaN(moment(bookingStop).diff(moment(bookingStart)))
                        //   ? '00:00:00'
                        //   : moment(bookingStop).diff(moment(bookingStart));

                        function calculateTimeDifference(start, end) {
                          const diffInMilliseconds = Math.abs(end - start);
                          const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
                            .toString()
                            .padStart(2, '0');
                          const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60))
                            .toString()
                            .padStart(2, '0');
                          const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000)
                            .toString()
                            .padStart(2, '0');

                          return { hours, minutes, seconds };
                        }
                        const startDate = new Date(bookingStart);
                        const endDate = new Date(bookingStop);
                        const timeDifference = calculateTimeDifference(startDate, endDate);

                        const usedamount = roundOfDigit(_.get(item, 'invoice.amount', ''), 2);
                        const paidamount = roundOfDigit(_.get(item, 'estimated_amount', ''), 2);
                        const estimated_chargecoins = roundOfDigit(_.get(item, 'chargecoins_estimated', ''), 2);
                        const refund = paidamount - usedamount;
                        const bookingamount = estimated_chargecoins + paidamount;
                        const taxableAmount =
                          roundOfDigit(_.get(item, 'invoice.service_charge', ''), 2) - roundOfDigit(_.get(item, 'invoice.chargecoins_used', ''), 2);
                        const gst = roundOfDigit(_.get(item, 'invoice.gst', ''), 2);

                        function calculateRefundAmount(status, refund) {
                          if (status === 'in_progress') {
                            return '-';
                          } else if (refund <= 0) {
                            return '-';
                          } else {
                            return Math.round(refund * 100) / 100 + roundOfDigit(_.get(item, 'invoice.chargecoins_used', ''), 2);
                          }
                        }

                        function calculateActualDiscount(status) {
                          if (status === 'in_progress' || status === 'cancelled' || status === 'scheduled' || status === 'rescheduled') {
                            return 0;
                          } else {
                            return roundOfDigit(_.get(item, 'invoice.chargecoins_used', ''), 2);
                          }
                        }

                        return (
                          <tr key={`booking-data-item-${index}`}>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                            <td
                              className="station-link"
                              onClick={() =>
                                navigateTo(`/stations/${_.get(item, 'charger.charging_station.id', '')}`, {
                                  backTo: history.location.pathname.slice(1),
                                  activeTab: 'reservations',
                                })
                              }
                            >
                              {_.get(item, 'charger.charging_station.name') && (
                                <span className="item-icon">
                                  {isLoading ? <Skeleton circle="true" height={20} width={20} /> : <FaChargingStation size={20} />}
                                </span>
                              )}
                              <span>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.name', '-')}</span>
                            </td>
                            <td
                              className="station-link"
                              onClick={() =>
                                navigateTo(`/charger/${_.get(item, 'charger.id', '')}`, {
                                  backTo: history.location.pathname.slice(1),
                                })
                              }
                            >
                              {isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charger_id', '')}(
                              {_.get(item, 'connectorId', '')})
                            </td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'status', '-')}</td>
                            {/* <td>{`${roundOfDigit(_.get(item, 'invoice.amount', ''), 2)} - ${unitConsumed} kWh - ${chargingDurationTime}`}</td> */}
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(bookingamount, 2)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(estimated_chargecoins, 2)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(bookingamount - estimated_chargecoins, 2)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : calculateActualDiscount(item.status)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'invoice.service_charge', ''), 2)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(taxableAmount, 2)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(gst, 2)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(taxableAmount + gst, 2)}</td>

                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : roundOfDigit(roundOfDigit(_.get(item, 'invoice.amount', ''), 2) - bookingamount, 2) <= 0 ? (
                                '-'
                              ) : (
                                roundOfDigit(roundOfDigit(_.get(item, 'invoice.amount', ''), 2) - bookingamount, 2)
                              )}
                            </td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : calculateRefundAmount(item.status, refund)}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : _.get(item, 'stop_reason', '-')}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : otp}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : rfid}</td>
                            <td>{isLoading ? <Skeleton height={15} width={100} /> : roundOfDigit(unitConsumed, 2)}</td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : item.schedule_datetime ? (
                                moment(item.schedule_datetime).format('DD/MM/YYYY H:mm:ss')
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : item.booking_start ? (
                                moment(item.booking_start).format('DD/MM/YYYY H:mm:ss')
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : item.booking_stop ? (
                                moment(item.booking_stop).format('DD/MM/YYYY H:mm:ss')
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {isLoading ? (
                                <Skeleton height={15} width={100} />
                              ) : (
                                <>
                                  {timeDifference.hours}:{timeDifference.minutes}:{timeDifference.seconds}
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {!_.isEmpty(customerBookingList) && (
              <ReactPagination
                currentPage={page}
                limit={limit}
                total={totalData}
                handlePageClick={(pageVal) => handlePageClick(pageVal)}
                totalPages={totalPages}
                marginPagesDisplayed={1}
              />
            )}
          </Card>
        </SkeletonTheme>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default CustomerBooking;
