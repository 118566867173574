import React, { useState, useEffect } from 'react';
import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoCallOutline, IoWalletOutline } from 'react-icons/io5';
import { AiOutlineAndroid, AiOutlineMail } from 'react-icons/ai';
import InformationBlock from 'components/general/InformationBlock';
import { GiSwipeCard } from 'react-icons/gi';
import { RiWalletLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { roundOfDigit } from 'components/common/utils';
import { BsFillPlugFill /*BsCashCoin*/ } from 'react-icons/bs';
// import { FiActivity } from 'react-icons/fi';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
const CustomerProfile = () => {
  const customerProfileList = useSelector((state) => state.customerProfile.customerProfile);
  const phoneNumber = `${_.get(customerProfileList, 'country_code', ' ')}${_.get(customerProfileList, 'phone', ' ')}`;
  const isLoader = useSelector((state) => state.customerProfile.isLoading);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const { t } = useTranslation();
  const rfidNumbers = _.map(customerProfileList.rfid, (item) => item.code);
  const rfidCardNumbers = _.map(customerProfileList.rfid, (data) => _.get(data, 'card_number', 'NA'));
  const customerGstins = _.map(customerProfileList.customer_gst_array, (gstin) => _.get(gstin, 'gstin', 'NA'));

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const tooltip = document.getElementsByClassName('more-button-tooltip')[0];
      if (tooltip && !tooltip.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleTooltip = (content) => {
    setShowTooltip(!showTooltip);
    if (content) {
      setTooltipContent(content);
    }
  };

  const renderRfidInformationBlock = (icon, infoTitle, remainingNumbers, tooltipId, infoText) => {
    return (
      <Col xl={4} md={6} className="customerProfile-details--wrapper">
        <InformationBlock
          icon={icon}
          infoTitle={
            <>
              {infoTitle}
              {remainingNumbers.length > 0 && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={tooltipId} className="more-button-tooltip">
                      {remainingNumbers.join(', ')}
                    </Tooltip>
                  }
                  show={showTooltip && tooltipContent === tooltipId}
                  onHide={() => toggleTooltip('')}
                >
                  <span className="more-button" onClick={() => toggleTooltip(tooltipId)}>
                    {t('customerProfile.more')}
                  </span>
                </OverlayTrigger>
              )}
            </>
          }
          infoText={infoText}
        />
      </Col>
    );
  };

  const appType =
    _.get(customerProfileList, 'androidToken') && _.get(customerProfileList, 'iosToken')
      ? 'Android/IOS'
      : (_.get(customerProfileList, 'androidToken') ? t('customerProfile.android') : t('customerProfile.na')) ||
        (_.get(customerProfileList, 'iosToken') ? t('customerProfile.iOS') : t('customerProfile.na'));
  return (
    <div className="customerProfile-details__main">
      <div className="customerProfile-details-inner">
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="customerProfile--content">
            <Row>
              <Col xl={4} md={6} className="customerProfile-details--wrapper">
                <InformationBlock
                  icon={isLoader ? <Skeleton circle="true" height={35} width={35} /> : <IoCallOutline />}
                  infoTitle={isLoader ? <Skeleton circle="true" height={35} width={100} /> : !_.isEmpty(phoneNumber) ? `+${phoneNumber}` : ''}
                  infoText={isLoader ? <Skeleton circle="true" height={35} width={100} /> : t('customerProfile.phoneNo')}
                />
              </Col>
              <Col xl={4} md={6} className="customerProfile-details--wrapper">
                <InformationBlock
                  icon={isLoader ? <Skeleton circle="true" height={35} width={35} /> : <AiOutlineMail />}
                  infoTitle={isLoader ? <Skeleton circle="true" height={35} width={100} /> : _.get(customerProfileList, 'email', 'NA')}
                  infoText={isLoader ? <Skeleton circle="true" height={35} width={100} /> : t('customerProfile.emailAddress')}
                />
              </Col>
              {!_.isEmpty(customerProfileList.customer_gst_array) &&
                renderRfidInformationBlock(<AiOutlineMail />, _.head(customerGstins), _.tail(customerGstins), 'rfid-tooltip', 'GSTIN')}
              {(!_.isEmpty(customerProfileList.customer_gst) || customerProfileList.customer_gst) &&
                _.isEmpty(customerProfileList.customer_gst_array) && (
                  <Col xl={4} md={6} className="customerProfile-details--wrapper">
                    <InformationBlock
                      icon={isLoader ? <Skeleton circle="true" height={35} width={35} /> : <AiOutlineMail />}
                      infoTitle={isLoader ? <Skeleton circle="true" height={35} width={100} /> : _.get(customerProfileList, 'customer_gst', 'NA')}
                      infoText={isLoader ? <Skeleton circle="true" height={35} width={100} /> : t('customerProfile.gstNo')}
                    />
                  </Col>
                )}
              {customerProfileList.role === 'fleet_member' ? null : (
                <Col xl={4} md={6} className="customerProfile-details--wrapper">
                  <InformationBlock
                    icon={isLoader ? <Skeleton circle="true" height={35} width={35} /> : <IoWalletOutline />}
                    infoTitle={
                      isLoader ? (
                        <Skeleton circle="true" height={35} width={100} />
                      ) : (
                        roundOfDigit(_.get(customerProfileList, 'wallet_balance', ''), 2)
                      )
                    }
                    infoText={isLoader ? <Skeleton circle="true" height={35} width={100} /> : t('customerProfile.walletBalance')}
                  />
                </Col>
              )}
              <Col xl={4} md={6} className="customerProfile-details--wrapper">
                <InformationBlock
                  icon={isLoader ? <Skeleton circle="true" height={35} width={35} /> : <AiOutlineAndroid />}
                  infoTitle={isLoader ? <Skeleton circle="true" height={35} width={100} /> : appType}
                  infoText={isLoader ? <Skeleton circle="true" height={35} width={100} /> : t('customerProfile.appType')}
                />
              </Col>
              {!_.isEmpty(customerProfileList.rfid) &&
                renderRfidInformationBlock(<GiSwipeCard />, _.head(rfidNumbers), _.tail(rfidNumbers), 'rfid-tooltip', 'RFID Number')}
              {!_.isEmpty(customerProfileList.rfid) &&
                renderRfidInformationBlock(
                  <GiSwipeCard />,
                  _.head(rfidCardNumbers),
                  _.tail(rfidCardNumbers),
                  'rfid-card-tooltip',
                  'RFID Card Number'
                )}
              <Col xl={4} md={6} className="customerProfile-details--wrapper">
                <InformationBlock
                  icon={isLoader ? <Skeleton circle="true" height={35} width={35} /> : <BsFillPlugFill />}
                  infoTitle={isLoader ? <Skeleton circle="true" height={35} width={100} /> : _.get(customerProfileList, 'total_session', 'NA')}
                  infoText={isLoader ? <Skeleton circle="true" height={35} width={100} /> : t('customerProfile.totalSessions')}
                />
              </Col>
              {customerProfileList.role === 'fleet_member' ? null : (
                <Col xl={4} md={6} className="customerProfile-details--wrapper">
                  <InformationBlock
                    icon={isLoader ? <Skeleton circle="true" height={35} width={35} /> : <RiWalletLine />}
                    infoTitle={isLoader ? <Skeleton circle="true" height={35} width={100} /> : _.get(customerProfileList, 'total_spent', 'NA')}
                    infoText={isLoader ? <Skeleton circle="true" height={35} width={100} /> : t('customerProfile.totalSpent')}
                  />
                </Col>
              )}
              {/* <Col xl={4} md={6} className="customerProfile-details--wrapper">
                <InformationBlock
                  icon={isLoader ? <Skeleton circle="true" height={35} width={35} /> : <BsCashCoin />}
                  infoTitle={isLoader ? <Skeleton circle="true" height={35} width={100} /> : _.get(customerProfileList, 'charge_coin', 'NA')}
                  infoText={isLoader ? <Skeleton circle="true" height={35} width={100} /> : 'Charge Coins'}
                />
              </Col>
              <Col xl={4} md={6} className="customerProfile-details--wrapper">
                <InformationBlock
                  icon={isLoader ? <Skeleton circle="true" height={35} width={35} /> : <FiActivity />}
                  infoTitle={isLoader ? <Skeleton circle="true" height={35} width={100} /> : _.get(customerProfileList, 'currentLevel.name', 'NA')}
                  infoText={isLoader ? <Skeleton circle="true" height={35} width={100} /> : 'Current Level'}
                />
              </Col> */}
            </Row>
          </div>
        </SkeletonTheme>
      </div>
    </div>
  );
};

export default CustomerProfile;
