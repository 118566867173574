import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/inputs/Select';
import { FETCH_COUNTRY, FETCH_STATE, GET_AREA_BY_CITY, GET_CITY_BY_STATE } from 'actions/address';
import { UPDATE_USER } from 'actions/user';
import { useParams } from 'react-router';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { toast } from 'react-toastify';
import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import { GET_CUSTOMER } from 'actions/customer';
// import { MdRefresh } from 'react-icons/md';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { FETCH_TAGS } from 'actions/tagmanagement';
import { useTranslation } from 'react-i18next';
const StationWiseAccess = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userId } = useParams();
  const { navigateTo } = useContext(NavContext);
  const [changeState, setChangeState] = useState(false);
  const [changeCity, setChangeCity] = useState(false);
  const [changeArea, setChangeArea] = useState(false);
  const [stateData, setStateData] = useState('');
  const [cityData, setCityData] = useState('');
  const [countryData, setCountryData] = useState('');
  // const [stateName, setStateName] = useState([]);

  const isLoading = useSelector((state) => state.user.isLoading);
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const allCityByState = useSelector((state) => state.cityByState.cityByStates);
  const allAreaByCity = useSelector((state) => state.areaByCity.areaByCities);
  const allFilterStations = useSelector((state) => state.stationByCity.stationByCities);
  const allTagManagement = useSelector((state) => state.tagmanagement.tags);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityByStateTotalPage = useSelector((state) => state.cityByState.totalPages);
  const cityByStatePage = useSelector((state) => state.cityByState.page);
  const areaByCityTotalPage = useSelector((state) => state.areaByCity.totalPages);
  const areaByCityPage = useSelector((state) => state.areaByCity.page);
  const userDetail = useSelector((state) => state.customer.customerDetail);

  // const allStation = useSelector((state) => state.chargingStation.chargingStations);

  const allStation = _.map(allFilterStations, (item) => item.id);
  const userProfileStations = userDetail.charging_stations;
  const stationList = !_.isEmpty(userProfileStations) ? allFilterStations.filter((x) => userProfileStations.indexOf(x.id) !== -1) : [];
  const allStations = _.isEqual(userProfileStations, allStation);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY.REQUEST, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE.REQUEST, payload: data });
  }, []);

  const getCityByStateName = useCallback((data = {}) => {
    dispatch({ type: GET_CITY_BY_STATE.REQUEST, payload: data });
  }, []);

  const getAreaByCity = useCallback((data = {}) => {
    dispatch({ type: GET_AREA_BY_CITY.REQUEST, payload: data });
  }, []);

  const getStations = useCallback((data = {}) => {
    dispatch({ type: GET_STATION_BY_CITY.REQUEST, payload: data });
  }, []);
  const getTags = useCallback((data = {}) => {
    dispatch({ type: FETCH_TAGS.REQUEST, payload: data });
  });

  const getCustomerDetail = useCallback((id) => {
    dispatch({ type: GET_CUSTOMER.REQUEST, payload: id });
  });

  // const getAllStation = useCallback((data = {}) => {
  //   const stationData = {
  //     ...data,
  //     request_all: true,
  //   };
  //   dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  // }, []);

  useEffect(() => {
    getStations();
    getAllCountry();
    getCustomerDetail(userId);
    getTags({ limit: 999 });
  }, []);

  const updateUser = useCallback(
    (data) => {
      const userData = {
        charging_stations: data.charging_stations ? data.charging_stations : [],
        tags: data.tags ? data.tags : [],
        id: userId,
      };
      dispatch({
        type: UPDATE_USER.REQUEST,
        payload: userData,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('User Updated successfully');
            setTimeout(() => {
              navigateTo(`/accessManagement`);
            }, 1000);
          }
        },
      });
    },
    [userId]
  );

  const initialValues = !_.isEmpty(stationList)
    ? {
        charging_stations: allStations ? 'All' : _.map(stationList, (item) => item.id),
      }
    : { country: '', state: '', city: '', area: '', charging_stations: '', tags: '' };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.stationWiseFilter')} backTo="/accessManagement" />
        <div className="page-content-wrapper scrollable">
          <div className="station-wise-filter_page__main">
            <Card className="station-wise-filter_page__main--card">
              <Formik
                enableReinitialize={userProfileStations !== undefined}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  !values.country && delete values['country'];
                  !values.state && delete values['state'];
                  !values.city && delete values['city'];
                  !values.area && delete values['area'];
                  !values.charging_stations && delete values['charging_stations'];
                  !values.tags && delete values['tags'];
                  if (
                    values.tags === 'All' &&
                    values.charging_stations === 'All' &&
                    values.state === 'All' &&
                    values.city === 'All' &&
                    values.area === 'All'
                  ) {
                    const filterData = { ...values, state: '', city: '', charging_stations: allStation, area: '', tags: 'All' };
                    const data = _.omit(filterData, ['city', 'state', 'area']);
                    updateUser(data);
                  } else if (values.charging_stations === 'All' && values.state === 'All' && values.city === 'All' && values.tags === 'All') {
                    const filterData = { ...values, state: '', city: '', charging_stations: allStation, tags: 'All' };
                    const data = _.omit(filterData, ['city', 'state']);
                    updateUser(data);
                  } else if (values.state === 'All' && values.charging_stations === 'All' && values.area === 'All' && values.tags === 'All') {
                    const filterData = { ...values, state: '', charging_stations: allStation, area: '', tags: 'All' };
                    const data = _.omit(filterData, ['area', 'state']);
                    updateUser(data);
                  } else if (values.city === 'All' && values.charging_stations === 'All' && values.tags === 'All') {
                    const filterData = { ...values, city: '', charging_stations: allStation, tags: 'All' };
                    const data = _.omit(filterData, ['city']);
                    updateUser(data);
                  } else if (values.state === 'All' && values.charging_stations === 'All' && values.tags === 'All') {
                    const filterData = { ...values, state: '', charging_stations: allStation, tags: 'All' };
                    const data = _.omit(filterData, ['state']);
                    updateUser(data);
                  } else if (values.charging_stations === 'All' && values.area === 'All' && values.tags === 'All') {
                    const filterData = { ...values, charging_stations: allStation, area: '', tags: 'All' };
                    const data = _.omit(filterData, ['area']);
                    updateUser(data);
                  } else if (values.charging_stations === 'All' && values.tags === 'All') {
                    const filterData = { ...values, charging_stations: allStation, tags: 'All' };
                    updateUser(filterData);
                  } else if (values.tags === 'All') {
                    const filterData = { ...values, tags: 'All' };
                    updateUser(filterData);
                  } else if (values.charging_stations === 'All') {
                    const filterData = { ...values, charging_stations: allStation };
                    updateUser(filterData);
                  } else {
                    updateUser(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-5">
                      <Row>
                        <Col lg={6} md={12}>
                          <Select
                            label={t('filters.country')}
                            options={_.map(allCountries, (item) => {
                              return { label: item.name, value: item.name };
                            })}
                            className="filter-select-box"
                            placeholder={t('placeHolder.selectCountry')}
                            name="country"
                            value={values.country}
                            onMenuScrollDown={true}
                            getDataOnScrollDown={getAllCountry}
                            page={countryPage}
                            totalPage={countryTotalPage}
                            onChange={(val) => {
                              setCountryData(val);
                              getStateByCountry({ country_name: val });
                              getStations({ country: val });
                              setFieldValue(`country`, val);
                              setFieldValue(`state`, '');
                              setFieldValue(`city`, '');
                              setFieldValue(`area`, '');
                              setFieldValue(`charging_stations`, '');
                            }}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <Select
                            label={t('filters.state')}
                            options={
                              !changeState
                                ? values.country &&
                                  !_.isEmpty(allState) && [
                                    { label: 'All', value: 'All' },
                                    ..._.map(allState, (state) => {
                                      return { label: state.name, value: state.name };
                                    }),
                                  ]
                                : values.country && !_.isEmpty(allState) && [{ label: 'All', value: 'All' }]
                            }
                            className="filter-select-box"
                            placeholder={t('placeHolder.selectState')}
                            isMulti
                            name="state"
                            value={values.state}
                            onMenuScrollDown={true}
                            getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                            page={statePage}
                            totalPage={stateTotalPage}
                            onChange={(val) => {
                              if (_.includes(val, 'All')) {
                                getCityByStateName({ state_names: '' });
                                // getStations({ state_array: '' });
                                getStations({ country: countryData });
                                setChangeState(true);
                                setFieldValue(`state`, 'All');
                              } else if (_.isEmpty(val)) {
                                setChangeState(false);
                                setChangeCity(false);
                                setFieldValue(`state`, '');
                              } else {
                                setStateData(val);
                                getCityByStateName({ state_names: val });
                                getStations({ state_array: val });
                                setChangeState(false);
                                setChangeCity(false);
                                setChangeArea(false);
                                setFieldValue(`state`, val);
                              }
                              setFieldValue(`city`, '');
                              setFieldValue(`area`, '');
                              setFieldValue(`charging_stations`, '');
                            }}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <Select
                            label={t('filters.city')}
                            options={
                              !changeCity
                                ? !_.isEmpty(values.state) &&
                                  !_.isEmpty(allCityByState) && [
                                    { label: 'All', value: 'All' },
                                    ..._.map(allCityByState, (city) => {
                                      return { label: city.name, value: city.name };
                                    }),
                                  ]
                                : !_.isEmpty(values.state) && values.country && !_.isEmpty(allCityByState) && [{ label: 'All', value: 'All' }]
                            }
                            placeholder={t('placeHolder.selectCity')}
                            name="city"
                            isMulti
                            value={values.city}
                            onMenuScrollDown={true}
                            getDataOnScrollDown={(data) => getCityByStateName({ ...data, state_names: values.state === 'All' ? '' : values.state })}
                            page={cityByStatePage}
                            totalPage={cityByStateTotalPage}
                            onChange={(val) => {
                              if (_.includes(val, 'All')) {
                                getAreaByCity({ city_names: '' });
                                // getStations({ city_array: '' });
                                getStations({ state_array: stateData });
                                setChangeCity(true);
                                setFieldValue(`city`, 'All');
                              } else if (_.isEmpty(val)) {
                                setFieldValue(`city`, '');
                                getStations({ state_array: stateData });
                                setChangeCity(false);
                              } else {
                                setCityData(val);
                                getAreaByCity({ city_names: val });
                                getStations({ city_array: val });
                                setChangeCity(false);
                                setChangeArea(false);
                                setFieldValue('city', val);
                              }
                              setFieldValue('area', '');
                              setFieldValue(`charging_stations`, '');
                            }}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <Select
                            label={t('filters.area')}
                            options={
                              !changeArea
                                ? !_.isEmpty(values.city) &&
                                  !_.isEmpty(allAreaByCity) && [
                                    { label: 'All', value: 'All' },
                                    ..._.map(allAreaByCity, (area) => {
                                      return { label: area.name, value: area.name };
                                    }),
                                  ]
                                : !_.isEmpty(values.city) &&
                                  values.state &&
                                  values.country &&
                                  !_.isEmpty(allAreaByCity) && [{ label: 'All', value: 'All' }]
                            }
                            placeholder={t('placeHolder.selectArea')}
                            name="area"
                            isMulti
                            value={values.area}
                            onMenuScrollDown={true}
                            getDataOnScrollDown={(data) => getAreaByCity({ ...data, city_name: values.city === 'All' ? '' : values.city })}
                            page={areaByCityPage}
                            totalPage={areaByCityTotalPage}
                            onChange={(val) => {
                              if (_.includes(val, 'All')) {
                                // getStations({ area_array: '' });
                                getStations({ city_array: cityData });
                                setChangeArea(true);
                                setFieldValue(`area`, 'All');
                              } else if (_.isEmpty(val)) {
                                setFieldValue(`area`, '');
                                getStations({ city_array: cityData });
                                setChangeArea(false);
                              } else {
                                getStations({ area_array: val });
                                setChangeArea(false);
                                setFieldValue('area', val);
                              }
                              setFieldValue(`charging_stations`, '');
                            }}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <Select
                            label={t('filters.station')}
                            options={
                              !_.isEmpty(allFilterStations) && [
                                { label: 'All', value: 'All' },
                                ..._.map(allFilterStations, (state) => {
                                  return { label: state.name, value: state.id };
                                }),
                              ]
                            }
                            placeholder={t('placeHolder.selectStation')}
                            name="charging_stations"
                            isMulti
                            value={values.charging_stations}
                            onMenuScrollDown={true}
                            onChange={(val) => {
                              if (_.includes(val, 'All')) {
                                setFieldValue(`charging_stations`, 'All');
                              } else if (_.isEmpty(val)) {
                                setFieldValue(`charging_stations`, '');
                              } else {
                                setFieldValue(`charging_stations`, val);
                              }
                            }}
                          />
                        </Col>
                        <Col lg={6} md={12}>
                          <Select
                            label={t('filters.addTagFilter')}
                            options={
                              !_.isEmpty(allTagManagement) && [
                                { label: 'All', value: 'All' },
                                ..._.map(allTagManagement, (state) => {
                                  return { label: state.name, value: state.id };
                                }),
                              ]
                            }
                            placeholder={t('placeHolder.selectTags')}
                            name="tags"
                            isMulti
                            value={values.tags}
                            onMenuScrollDown={true}
                            onChange={(val) => {
                              if (_.includes(val, 'All')) {
                                setFieldValue(`tags`, 'All');
                              } else if (_.isEmpty(val)) {
                                setFieldValue(`tags`, '');
                              } else {
                                setFieldValue(`tags`, val);
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="filter-btn__block">
                      <span>
                        <Button type="submit" className="filter_btn" disabled={isSubmitting || isLoading}>
                          {t('button.applyFilters')}
                        </Button>
                      </span>
                      {/* <span>
                          <div
                            className="reset--block"
                            onClick={() => {
                              resetForm();
                            }}
                          >
                            <Button className='reset_btn'>
                              <MdRefresh size={24} className="reset--icon" />
                              Reset
                            </Button>
                          </div>
                        </span> */}
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default StationWiseAccess;
