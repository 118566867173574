import { FETCH_SMS, GET_SMS, CREATE_SMS, UPDATE_SMS, DELETE_SMS } from 'actions/sms';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  sms: [],
  smsDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const smsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SMS.REQUEST:
    case GET_SMS.REQUEST:
    case CREATE_SMS.REQUEST:
    case DELETE_SMS.REQUEST: {
      return { ...state, isLoading: true, smsDetail: {} };
    }
    case UPDATE_SMS.REQUEST: {
      return { ...state, isLoading: true };
    }
    case FETCH_SMS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        sms: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_SMS.SUCCESS: {
      return {
        ...state,
        smsDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_SMS.SUCCESS: {
      toast.success('SMS added successfully');
      return {
        ...state,
        sms: _.concat(state.sms, payload),
        isLoading: false,
      };
    }

    case UPDATE_SMS.SUCCESS: {
      toast.success('SMS updated successfully');
      return {
        ...state,
        isLoading: false,
        sms: state.sms.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_SMS.SUCCESS: {
      toast.success('SMS deleted successfully');
      return {
        ...state,
        isLoading: false,
        sms: state.sms.filter((data) => data.id !== payload),
      };
    }

    case FETCH_SMS.FAIL:
    case GET_SMS.FAIL:
    case CREATE_SMS.FAIL:
    case UPDATE_SMS.FAIL:
    case DELETE_SMS.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default smsReducer;
