import { FETCH_CUSTOMER, FETCH_WIDGETS, GET_CUSTOMER, CREATE_CUSTOMER, UPDATE_CUSTOMER, DELETE_CUSTOMER, FETCH_ROLES } from 'actions/customer';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  search: '',
  customers: [],
  customerDetail: {},
  role: [],
  page: 1,
  limit: 5,
  totalPages: 1,
  total: 1,
};

const customerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CUSTOMER.REQUEST:
    case CREATE_CUSTOMER.REQUEST:
    case DELETE_CUSTOMER.REQUEST:
    case FETCH_ROLES.REQUEST:
    case FETCH_WIDGETS.REQUEST: {
      return { ...state, isLoading: true };
    }
    case FETCH_CUSTOMER: {
      return { ...state, isLoading: true, search: '' };
    }
    case FETCH_CUSTOMER.SUCCESS: {
      // if (payload.page === 1) {
      //   return {
      //     ...state,
      //     isLoading: false,
      //     customers: payload.results,
      //     page: payload.page,
      //     limit: payload.limit,
      //     totalPages: payload.totalPages,
      //     total: payload.totalResults,
      //   };
      // } else {
      //   return {
      //     ...state,
      //     isLoading: false,
      //     customers: _.concat(state.customers, payload.results),
      //     page: payload.page,
      //     limit: payload.limit,
      //     totalPages: payload.totalPages,
      //     total: payload.totalResults,
      //   };
      // }
      return {
        ...state,
        isLoading: false,
        search: payload.config?.params?.search,
        customers: payload.data?.results,
        page: payload.data?.page,
        limit: payload.data?.limit,
        totalPages: payload.data?.totalPages,
        total: payload.data?.totalResults,
      };
    }

    case FETCH_ROLES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        role: payload?.data,
      };
    }

    case FETCH_WIDGETS.SUCCESS: {
      return {
        ...state,
        totalUserCount: payload.data?.userCount,
        activeUser: payload.data?.activeUsers,
        inactiveUser: payload.data?.inactiveUsers,
        androidUser: payload.data?.androidUsers,
        iosUser: payload.data?.iosUsers,
      };
    }

    case GET_CUSTOMER.SUCCESS: {
      return {
        ...state,
        customerDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_CUSTOMER.SUCCESS: {
      toast.success('Customer added successfully');
      setTimeout(() => {
        window.location.href = '/customers';
      }, 500);
      return {
        ...state,
        customers: _.concat(state.customers, payload),
        isLoading: false,
      };
    }

    case UPDATE_CUSTOMER: {
      return { ...state, isLoading: true };
    }

    case UPDATE_CUSTOMER.SUCCESS: {
      // toast.success('Customer updated successfully');
      return {
        ...state,
        customers: state.customers.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case DELETE_CUSTOMER.SUCCESS: {
      toast.success('Customer deleted successfully');
      return {
        ...state,
        customers: state.customers.filter((data) => data.id !== payload),
      };
    }

    case FETCH_CUSTOMER.FAIL:
    case FETCH_WIDGETS.FAIL:
    case FETCH_ROLES.FAIL:
    case GET_CUSTOMER.FAIL:
    case CREATE_CUSTOMER.FAIL:
    case UPDATE_CUSTOMER.FAIL:
    case DELETE_CUSTOMER.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default customerReducer;
