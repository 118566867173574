import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
import { TiArrowUnsorted } from 'react-icons/ti';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { JSON_REQUEST_RESPONSE_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { startsWith } from 'components/common/utils';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';

// import DropDown from 'components/inputs/DropDown';
// import { AiFillCaretDown } from 'react-icons/ai';

const JsonRequestResponseReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');

  const [allJsonRequestResponseReportData, setAllJsonRequestResponseReportData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const jsonRequestResponseReportList = useSelector((state) => state.jsonRequestResponseReport.jsonRequestResponseReports);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const jsonRequestResponseReport = useCallback(
    (value) => {
      const data = {
        ...value,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD'),
      };
      dispatch({ type: JSON_REQUEST_RESPONSE_REPORT.REQUEST, payload: data });
    },
    [startDate, endDate]
  );

  const dateFilter = useCallback(() => {
    const filterDateData = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
    };
    jsonRequestResponseReport(filterDateData);
  }, [startDate, endDate]);

  useEffect(() => {
    setAllJsonRequestResponseReportData(
      _.filter(jsonRequestResponseReportList, (item) => startsWith(_.get(item, 'charger.charger_id', ''), searchText))
    );
  }, [searchText, jsonRequestResponseReportList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };
  const handleSearch = () => {
    jsonRequestResponseReport({});
  };

  useEffect(() => {
    jsonRequestResponseReport();
  }, []);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.jsonRequestResponseReport')} />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col xl={6} md={12}>
                      <SearchBox
                        preIcon={<BsSearch />}
                        value={searchText}
                        onChange={searchHandler}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSearch();
                          }
                        }}
                      />
                    </Col>
                    <Col xl={5} md={9}>
                      <div className="data-report-date_picker">
                        <DatePickerWithFloatingLabel
                          defaultValueOfStartDate={new Date()}
                          defaultValueOfEndDate={new Date()}
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                        />
                      </div>
                    </Col>
                    <Col xl={1} md={3}>
                      <div className="log-search-box" onClick={dateFilter}>
                        <Button className="log-search-button">{t('button.search')}</Button>
                      </div>
                    </Col>
                    {/* <Col xl={1} md={2}>
                      <div className="data-report__table-download-icon">
                        <AiOutlineDownload />
                      </div>
                    </Col> */}
                  </Row>
                </div>
                <div className="data-report__table">
                  <div className="table-responsive  data-report_inner">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('dataReport.srNo')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('dataReport.oppID')}</span>
                              <span className="ico">
                                <TiArrowUnsorted />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('dataReport.reqString')}</span>
                              <span className="ico">
                                <TiArrowUnsorted />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('dataReport.resString')}</span>
                              <span className="ico">
                                <TiArrowUnsorted />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('dataReport.reqDateIST')}</span>
                              <span className="ico">
                                <TiArrowUnsorted />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('dataReport.resDateIST')}</span>
                              <span className="ico">
                                <TiArrowUnsorted />
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allJsonRequestResponseReportData) ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="empty-data-block">{t('dataReport.noJsonRequestResponseData')}</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(allJsonRequestResponseReportData, (item, key) => {
                            return (
                              <>
                                <tr key={`json-request-response-report-${key}`}>
                                  <td>{key + 1}</td>
                                  <td>{_.get(item, 'charger.charger_id', '')}</td>
                                  <td className="json-data__block">
                                    <div className="json--data">{JSON.stringify(_.get(item, 'message', ''))}</div>
                                  </td>
                                  <td className="json-data__block">
                                    <div className="json--data">{JSON.stringify(_.get(item, 'response', ''))}</div>
                                  </td>
                                  {/* <td>{moment(_.get(item, 'date_time.from', '-')).format('DD/MM/YYYY HH:mm:ss')}</td> */}
                                  <td>{_.get(item, 'date_time.from') ? moment(_.get(item, 'date_time.from')).format('DD/MM/YYYY HH:mm:ss') : '-'}</td>
                                  <td>{item.response_time ? moment(item.response_time).format('DD/MM/YYYY HH:mm:ss') : ''}</td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </>
  );
};

export default JsonRequestResponseReport;
