import { get, post, patch, deleteRequest } from 'utils/api';

export const getChargerState = (data) => {
  return get(`/charger_status`, data);
};

export const getChargerStateById = (id) => {
  return get(`/charger_status/${id}`);
};

export const addChargerState = (data) => {
  return post(`/charger_status`, data);
};

export const updateChargerState = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/charger_status/${id}`, data);
};

export const deleteChargerState = (id) => {
  return deleteRequest(`/charger_status/${id}`);
};

export const chargerState = {
  getChargerState,
  getChargerStateById,
  addChargerState,
  updateChargerState,
  deleteChargerState,
};
