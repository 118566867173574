import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/inputs/Select';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { CREATE_STATE, UPDATE_STATE, FETCH_COUNTRY } from 'actions/address';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const StateForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const stateDetail = useSelector((state) => state.state.stateDetail);
  const allCountries = useSelector((state) => state.country.countries);
  const isLoading = useSelector((state) => state.state.isLoading);
  const countryPage = useSelector((state) => state.country.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);

  const addState = useCallback((data) => {
    dispatch({
      type: CREATE_STATE.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateState = useCallback((data) => {
    dispatch({
      type: UPDATE_STATE.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getAllCountry();
  }, []);

  const StateSchema = Yup.object().shape({
    country: Yup.string().required(t('stateForm.country')),
    name: Yup.string()
      .required(t('stateForm.name'))
      .strict(true)
      .trim(t('stateForm.space'))
      .matches(/^[A-Za-z\s]+$/, t('stateForm.matches'))
      .max(100, t('stateForm.maxlimit')),
    state_code: Yup.string()
      .required(t('stateForm.stateCode'))
      .strict(true)
      .trim(t('stateForm.space'))
      .matches(/^[A-Za-z]+$/, t('stateForm.matches'))
      .max(15, t('stateForm.stateCodelength')),
  });

  const initialValues = isEdit
    ? { ...stateDetail, country: _.get(stateDetail, 'country.id', '') }
    : {
        name: '',
        state_code: '',
        country: '',
      };
  return (
    <React.Fragment>
      <div className="state-form-page__main">
        <Card>
          <div className="state-form-header__block">
            <div className="state-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('stateForm.title')}
              </span>
            </div>
            <div className="close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="state-form-body__block">
            <div className="state-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={StateSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateState(values);
                  } else {
                    addState(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={12}>
                        <Select
                          isRequired
                          label={t('stateForm.countryName')}
                          options={_.map(allCountries, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          placeholder={t('placeHolder.selectCountryName')}
                          onMenuScrollDown={true}
                          getDataOnScrollDown={getAllCountry}
                          page={countryPage}
                          totalPage={countryTotalPage}
                          name="country"
                          value={_.get(values, 'country')}
                          onChange={(val) => setFieldValue(`country`, val)}
                          error={errors.country && touched.country ? errors.country : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <TextInput
                          isRequired
                          label={t('stateForm.stateName')}
                          placeholder={t('stateForm.stateName')}
                          name="name"
                          value={_.get(values, 'name')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <TextInput
                          isRequired
                          label={t('stateForm.stateCodeLabel')}
                          placeholder={t('stateForm.stateCodeLabel')}
                          name="state_code"
                          value={_.get(values, 'state_code')}
                          onChange={handleChange}
                          error={errors.state_code && touched.state_code ? errors.state_code : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn-block">
                      <Button className="state-form-btn cancel_btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="state-form-btn create-btn">
                        {isEdit ? 'Update' : 'Create'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
StateForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default StateForm;
