import React, { useEffect, useState, useCallback, useRef } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import SessionHeading from 'components/general/SessionHeading';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import RadioButton from 'components/inputs/RadioButton';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { HiUserGroup } from 'react-icons/hi';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import PartnerGroupSoltForm from './PartnerGroupSoltForm';
import Select from 'components/inputs/Select';
import { UPDATE_TARIFF_SLOT, GET_TARIFF } from 'actions/tariff';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { GET_TARRIFS, MANAGE_TARRIFS } from 'components/common/constant';
import SlotsForm from './SlotsForm';
import moment from 'moment';

export const WeekEndOptions = [
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
];
export const WeekDayOptions = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  // ...WeekEndOptions,
];

const Slots = () => {
  const validateTimeRange = (from, to) => {
    if (!from || !to) return true;
    const fromTime = moment(from, 'HH:mm');
    const toTime = moment(to, 'HH:mm');
    return toTime.isAfter(fromTime);
  };

  const ref = useRef(null);
  const { t } = useTranslation();

  const { slotId } = useParams();
  const history = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [partnerForm, setPartnerForm] = useState(false);

  const [isEdit, setIsEdit] = useState(true);
  const [userGroupIndex, setUserGroupIndex] = useState(0);
  const [partnerGroupIndex, setPartnerGroupIndex] = useState(0);
  const [initialValues, setInitialValues] = useState({
    default_price_per_unit: '',
    schedule: [{ price_per_unit: '', day: 'daily', from: '', to: '', user_group_condition: [{ price_per_user_group: '', user_group: '' }] }],
    timing_type: 'daily',
    is_active: true,
    id: slotId,
  });
  const dispatch = useDispatch();
  const tariffDetail = useSelector((state) => state.tariff.tariffDetail);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getFormValues = () => {
    return ref.current.values;
  };

  const getTariffDetail = useCallback((id) => {
    dispatch({ type: GET_TARIFF.REQUEST, payload: id });
  }, []);

  useEffect(() => {
    getTariffDetail(slotId);
  }, [slotId]);

  useEffect(() => {
    if (Object.keys(tariffDetail).length !== 0) {
      setInitialValues({
        ...tariffDetail,
      });
    }
  }, [tariffDetail]);

  const updateTariff = useCallback((data) => {
    dispatch({
      type: UPDATE_TARIFF_SLOT.REQUEST,
      payload: data,
    });
  }, []);

  const getTariffPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_TARRIFS),
      (data) => data.name
    );

  const manageTariffPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_TARRIFS),
      (data) => data.name
    );

  const AddTariffSchema = Yup.object().shape({
    schedule: Yup.array().of(
      Yup.object().shape({
        from: Yup.string().required(t('slots.fromTo')),
        to: Yup.string()
          .required(t('slots.fromTo'))
          .test('is-greater', t('slots.toMustBeAfterFrom'), function (to) {
            return validateTimeRange(this.parent.from, to);
          }),
        day: Yup.string(),
        price_per_unit: Yup.number().positive(t('slots.defaultPricePerUnit')).integer(t('slots.defaultPricePerUnit')).required(t('slots.rate')),
        user_group_condition: Yup.array().of(
          Yup.object().shape({
            price_per_user_group: Yup.number().positive(t('slots.defaultPricePerUnit')).integer(t('slots.defaultPricePerUnit')),
            user_group: Yup.string(),
          })
        ),
      })
    ),
  });

  const EditTariffSchema = Yup.object().shape({
    schedule: Yup.array().of(
      Yup.object().shape({
        price_per_unit: Yup.number().positive(t('slots.defaultPricePerUnit')).integer(t('slots.defaultPricePerUnit')).required(t('slots.rate')),
        from: Yup.string().required(t('slots.fromTo')),
        to: Yup.string()
          .required(t('slots.fromTo'))
          .test('is-greater', t('slots.toMustBeAfterFrom'), function (to) {
            return validateTimeRange(this.parent.from, to);
          }),
        day: Yup.string(),
        user_group_condition: Yup.array().of(
          Yup.object().shape({
            price_per_user_group: Yup.number().positive(t('slots.defaultPricePerUnit')).integer(t('slots.defaultPricePerUnit')),
            user_group: Yup.string(),
          })
        ),
      })
    ),
  });

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('slots.title')} backTo="/tariffs" />
        <div className="page-content-wrapper scrollable">
          <div className="add-charging-station-page__main">
            <Card className="station-form--card">
              <Formik
                innerRef={ref}
                enableReinitialize={isEdit}
                initialValues={initialValues}
                validationSchema={slotId ? EditTariffSchema : AddTariffSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const newTariff = {
                    ...values,
                    schedule: values.schedule.map((schedule) => {
                      delete schedule._id;
                      return {
                        ...schedule,
                        user_group_condition: schedule?.user_group_condition
                          ? schedule.user_group_condition.map((group) => {
                              delete group._id;
                              delete group.id;
                              return {
                                ...group,
                              };
                            })
                          : [{ price_per_user_group: '', user_group: '' }],
                        ocpi_partner_condition: schedule?.ocpi_partner_condition
                          ? schedule.ocpi_partner_condition.map((partner) => {
                              delete partner._id;
                              delete partner.id;
                              return {
                                ...partner,
                              };
                            })
                          : [{ price_per_partner: '', partner: '' }],
                      };
                    }),
                  };
                  const editTariffData = _.omit(newTariff, ['tenant', 'oem']);
                  if (isEdit) {
                    updateTariff(editTariffData);
                  }
                  setSubmitting(false);
                  history.replace('/tariffs');
                }}
              >
                {({ values, handleSubmit, touched, errors, setFieldValue, isSubmitting }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div>
                        <SessionHeading text={`Slots (${tariffDetail?.name})`} />
                        <div className="station-form--block">
                          <div className="checkbox-input--group">
                            <div id="timing" className="checkbox--input-items">
                              <Row>
                                <Col xxl={6} xl={8} md={12}>
                                  <Row className="m-0">
                                    <Col xl={2} lg={2} md={6} className="p-0">
                                      <RadioButton
                                        checked={values.timing_type === 'daily'}
                                        onChange={() => {
                                          setFieldValue('timing_type', 'daily');
                                        }}
                                      >
                                        {t('slots.daily')}
                                      </RadioButton>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} className="p-0">
                                      <RadioButton
                                        checked={values.timing_type === 'weekday'}
                                        onChange={() => setFieldValue('timing_type', 'weekday')}
                                      >
                                        {t('slots.weekdaysonly')}
                                      </RadioButton>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} className="p-0">
                                      <RadioButton
                                        checked={values.timing_type === 'weekends'}
                                        onChange={() => setFieldValue('timing_type', 'weekends')}
                                      >
                                        {t('slots.weekendsonly')}
                                      </RadioButton>
                                    </Col>
                                    <Col xl={2} lg={2} md={6} className="p-0">
                                      <RadioButton checked={values.timing_type === 'custom'} onChange={() => setFieldValue('timing_type', 'custom')}>
                                        {t('slots.custom')}
                                      </RadioButton>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          {values.timing_type && (
                            <div className="time-block__field-inputs">
                              <FieldArray
                                name="schedule"
                                render={(arrayHelpers) => (
                                  <>
                                    {_.map(values.schedule, (field, index) => (
                                      <Row key={`station-timimg-${index}`}>
                                        {values.timing_type !== 'daily' && (
                                          <Col lg={3} md={4}>
                                            <Select
                                              label={t('slots.day')}
                                              name={`schedule[${index}].day`}
                                              placeholder={t('slots.selectDay')}
                                              value={field.day || ''}
                                              options={
                                                values.timing_type === 'weekends'
                                                  ? WeekEndOptions
                                                  : values.timing_type === 'custom'
                                                    ? WeekDayOptions.concat(WeekEndOptions)
                                                    : WeekDayOptions
                                              }
                                              onChange={(val) => setFieldValue(`schedule[${index}].day`, val)}
                                            />
                                          </Col>
                                        )}
                                        <Col lg={8} md={7}>
                                          <Row>
                                            <Col>
                                              <TextInput
                                                type="time"
                                                label={t('slots.hourFrom')}
                                                name="from"
                                                value={field.from}
                                                onChange={(e) => setFieldValue(`schedule[${index}].from`, e.target.value)}
                                                error={
                                                  getIn(errors, `schedule[${index}].from`) && getIn(touched, `schedule[${index}].from`)
                                                    ? getIn(errors, `schedule[${index}].from`)
                                                    : null
                                                }
                                              />
                                            </Col>
                                            <Col>
                                              <TextInput
                                                type="time"
                                                label={t('slots.hourTo')}
                                                name="to"
                                                value={field.to}
                                                onChange={(e) => setFieldValue(`schedule[${index}].to`, e.target.value)}
                                                error={
                                                  getIn(errors, `schedule[${index}].to`) && getIn(touched, `schedule[${index}].to`)
                                                    ? getIn(errors, `schedule[${index}].to`)
                                                    : null
                                                }
                                              />
                                            </Col>
                                            <Col>
                                              <TextInput
                                                type="number"
                                                label={t('slots.defaultRate')}
                                                name="price_per_unit"
                                                value={field.price_per_unit}
                                                onChange={(e) => setFieldValue(`schedule[${index}].price_per_unit`, parseInt(e.target.value))}
                                                error={
                                                  getIn(errors, `schedule[${index}].price_per_unit`) &&
                                                  getIn(touched, `schedule[${index}].price_per_unit`)
                                                    ? getIn(errors, `schedule[${index}].price_per_unit`)
                                                    : null
                                                }
                                              />
                                            </Col>
                                            <Col>
                                              <p>{t('slots.userGroupPrices')}</p>
                                              <div
                                                className="edit-icon"
                                                onClick={() => {
                                                  setShowForm(true);
                                                  setIsEdit(true);
                                                  setUserGroupIndex(index);
                                                }}
                                              >
                                                <HiUserGroup />
                                              </div>
                                            </Col>
                                            <Col>
                                              <p>{t('slots.ocpiPartnerGroupPrices')}</p>
                                              <div
                                                className="edit-icon"
                                                onClick={() => {
                                                  setPartnerForm(true);
                                                  setIsEdit(true);
                                                  setPartnerGroupIndex(index);
                                                }}
                                              >
                                                <HiUserGroup />
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md={1} lg={1} sm={1} className="d-flex align-items-center">
                                          {index > 0 && (
                                            <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>
                                              <BiTrash size={26} />
                                            </Button>
                                          )}
                                        </Col>
                                      </Row>
                                    ))}
                                    <div>
                                      <Button
                                        className="field--btn"
                                        onClick={() =>
                                          arrayHelpers.insert(values.schedule.length, {
                                            day: values.timing_type,
                                            from: values.from || '',
                                            to: values.to || '',
                                            price_per_unit: values.price_per_unit || '',
                                            user_group_condition: [{ price_per_user_group: '', user_group: '' }],
                                          })
                                        }
                                      >
                                        <BiPlus size={22} className="plus--icon" /> {t('slots.add')}
                                      </Button>
                                    </div>
                                  </>
                                )}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="save-draft-btn--block mt-5">
                        {!_.isEmpty(manageTariffPermissions) || (!_.isEmpty(getTariffPermissions) && !_.isEmpty(manageTariffPermissions)) ? (
                          <Button type="submit" className="save-btn" disabled={isSubmitting}>
                            {t('button.save')}
                          </Button>
                        ) : (
                          <Button className="save-btn">{t('slots.save')}</Button>
                        )}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <SlotsForm userGroupIndex={userGroupIndex} getFormValues={getFormValues} onClose={() => setShowForm(false)} isEdit={isEdit} />
          </Modal>
        )}
        {partnerForm && (
          <Modal show={partnerForm} size="lg" centered>
            <PartnerGroupSoltForm
              partnerGroupIndex={partnerGroupIndex}
              getFormValues={getFormValues}
              onClose={() => setPartnerForm(false)}
              isEdit={isEdit}
            />
          </Modal>
        )}

        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};
export default Slots;
