import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_CUSTOMER,
  FETCH_WIDGETS,
  GET_CUSTOMER,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  WALLET_TRANSACTION,
  FETCH_CUSTOMER_TRANSACTION,
  FETCH_FAULTY_CUSTOMER_TRANSACTION,
  FETCH_CUSTOMER_BOOKING,
  FETCH_CUSTOMER_PROFILE,
  DOWNLOAD_CUSTOMER_BOOKING,
  FETCH_CHARGE_COIN_TRANSACTION,
  DOWNLOAD_CUSTOMER_TRANSACTION,
  DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION,
  SYNC_WALLET,
  CALCULATE_CUSTOMER_BOOKING,
  CREATE_CUSTOMER_BOOKING,
  RESCHEDULE_CUSTOMER_BOOKING,
  CANCEL_CUSTOMER_BOOKING,
  FETCH_ROLES,
  RESET_OTP_LIMIT,
  UPDATE_CUSTOMER_VEHICLE,
  DOWNLOAD_PAYMENT_RECEIPT,
} from 'actions/customer';

function* fetchCustomer(action) {
  try {
    const response = yield call(API.getCustomers, action.payload);
    yield put({ type: FETCH_CUSTOMER.SUCCESS, payload: response });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CUSTOMER.FAIL, payload: { error: e } });
  }
}

function* fetchWidgets(action) {
  try {
    const response = yield call(API.getWidgets, action.payload);
    yield put({ type: FETCH_WIDGETS.SUCCESS, payload: response });
    action.cs && action.cs(response);
  } catch (e) {
    yield put({ type: FETCH_WIDGETS.FAIL, payload: { error: e } });
  }
}

function* getCustomerById(action) {
  try {
    const response = yield call(API.getUserById, action.payload);
    yield put({ type: GET_CUSTOMER.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CUSTOMER.FAIL, payload: { error: e } });
  }
}

function* createCustomer(action) {
  try {
    const response = yield call(API.addUser, action.payload);
    yield put({ type: CREATE_CUSTOMER.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: CREATE_CUSTOMER.FAIL, payload: { error: e } });
  }
}

function* createWalletTransaction(action) {
  try {
    const response = yield call(API.walletTransaction, action.payload);
    yield put({ type: WALLET_TRANSACTION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: WALLET_TRANSACTION.FAIL, payload: { error: e } });
  }
}

function* fetchCustomerProfile(action) {
  try {
    const response = yield call(API.getCustomerProfile, action.payload);
    yield put({ type: FETCH_CUSTOMER_PROFILE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CUSTOMER_PROFILE.FAIL, payload: { error: e } });
  }
}

function* fetchCustomerBooking(action) {
  try {
    const response = yield call(API.getCustomerBooking, action.payload);
    yield put({ type: FETCH_CUSTOMER_BOOKING.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CUSTOMER_BOOKING.FAIL, payload: { error: e } });
  }
}

function* fetchCustomerTransaction(action) {
  try {
    const response = yield call(API.getCustomerTransaction, action.payload);
    yield put({ type: FETCH_CUSTOMER_TRANSACTION.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CUSTOMER_TRANSACTION.FAIL, payload: { error: e } });
  }
}

function* fetchFaultyCustomerTransaction(action) {
  try {
    const response = yield call(API.getFaultyCustomerTransaction, action.payload);
    yield put({ type: FETCH_FAULTY_CUSTOMER_TRANSACTION.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_FAULTY_CUSTOMER_TRANSACTION.FAIL, payload: { error: e } });
  }
}

function* updateCustomer(action) {
  try {
    const response = yield call(API.updateCustomer, action.payload);
    yield put({ type: UPDATE_CUSTOMER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CUSTOMER.FAIL, payload: { error: e } });
  }
}

function* deleteCustomer(action) {
  try {
    yield call(API.deleteUser, action.payload);
    yield put({ type: DELETE_CUSTOMER.SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_CUSTOMER.FAIL, payload: { error: e } });
  }
}

function* downloadCustomerBooking(action) {
  try {
    const response = yield call(API.downloadCustomerBooking, action.payload);
    yield put({ type: DOWNLOAD_CUSTOMER_BOOKING.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CUSTOMER_BOOKING.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* fetchChargeCoinTransaction(action) {
  try {
    const response = yield call(API.getAllChargeCoinTransaction, action.payload);
    yield put({ type: FETCH_CHARGE_COIN_TRANSACTION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CHARGE_COIN_TRANSACTION.FAIL, payload: { error: e } });
  }
}

function* downloadCustomerTransactions(action) {
  try {
    const response = yield call(API.downloadCustomerTransactions, action.payload);
    yield put({ type: DOWNLOAD_CUSTOMER_TRANSACTION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CUSTOMER_TRANSACTION.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadFaultyCustomerTransactions(action) {
  try {
    const response = yield call(API.downloadFaultyCustomerTransactions, action.payload);
    yield put({ type: DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* syncWallet(action) {
  try {
    const response = yield call(API.syncWallet, action.payload);
    yield put({ type: SYNC_WALLET.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SYNC_WALLET.FAIL, payload: { error: e } });
  }
}

function* calculateCustomerBooking(action) {
  try {
    const response = yield call(API.calculateCustomerBooking, action.payload);
    yield put({ type: CALCULATE_CUSTOMER_BOOKING.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CALCULATE_CUSTOMER_BOOKING.FAIL, payload: { error: e } });
  }
}

function* createCustomerBooging(action) {
  try {
    const response = yield call(API.createCustomerBooking, action.payload);
    yield put({ type: CREATE_CUSTOMER_BOOKING.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CUSTOMER_BOOKING.FAIL, payload: { error: e } });
  }
}

function* rescheduleCustomerBooking(action) {
  try {
    const response = yield call(API.rescheduleCustomerBooking, action.payload);
    yield put({ type: RESCHEDULE_CUSTOMER_BOOKING.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RESCHEDULE_CUSTOMER_BOOKING.FAIL, payload: { error: e } });
  }
}

function* cancelCustomerBooging(action) {
  try {
    const response = yield call(API.cancelCustomerBooking, action.payload);
    yield put({ type: CANCEL_CUSTOMER_BOOKING.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CANCEL_CUSTOMER_BOOKING.FAIL, payload: { error: e } });
  }
}

function* getRoles(action) {
  try {
    const response = yield call(API.getRoles, action.payload);
    yield put({ type: FETCH_ROLES.SUCCESS, payload: response });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_ROLES.FAIL, payload: { error: e } });
  }
}

function* resetOTPLimit(action) {
  try {
    const response = yield call(API.resetOTPLimit, action.payload);
    yield put({ type: RESET_OTP_LIMIT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RESET_OTP_LIMIT.FAIL, payload: { error: e } });
  }
}

function* updateCustomerVehicle(action) {
  try {
    const response = yield call(API.updateCustomerVehicle, action.payload);
    yield put({ type: UPDATE_CUSTOMER_VEHICLE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CUSTOMER_VEHICLE.FAIL, payload: { error: e } });
  }
}

function* downlaodPaymentReceipt(action) {
  try {
    const response = yield call(API.downloadPaymentReceipt, action.payload);
    yield put({ type: DOWNLOAD_PAYMENT_RECEIPT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_PAYMENT_RECEIPT.FAIL, payload: { error: e } });
  }
}

function* customer() {
  yield takeLatest(FETCH_CUSTOMER.REQUEST, fetchCustomer);
  yield takeLatest(FETCH_WIDGETS.REQUEST, fetchWidgets);
  yield takeLatest(GET_CUSTOMER.REQUEST, getCustomerById);
  yield takeLatest(CREATE_CUSTOMER.REQUEST, createCustomer);
  yield takeLatest(UPDATE_CUSTOMER.REQUEST, updateCustomer);
  yield takeLatest(DELETE_CUSTOMER.REQUEST, deleteCustomer);
  yield takeLatest(WALLET_TRANSACTION.REQUEST, createWalletTransaction);
  yield takeLatest(FETCH_CUSTOMER_TRANSACTION.REQUEST, fetchCustomerTransaction);
  yield takeLatest(FETCH_FAULTY_CUSTOMER_TRANSACTION.REQUEST, fetchFaultyCustomerTransaction);
  yield takeLatest(FETCH_CUSTOMER_BOOKING.REQUEST, fetchCustomerBooking);
  yield takeLatest(FETCH_CUSTOMER_PROFILE.REQUEST, fetchCustomerProfile);
  yield takeLatest(DOWNLOAD_CUSTOMER_BOOKING.REQUEST, downloadCustomerBooking);
  yield takeLatest(FETCH_CHARGE_COIN_TRANSACTION.REQUEST, fetchChargeCoinTransaction);
  yield takeLatest(DOWNLOAD_CUSTOMER_TRANSACTION.REQUEST, downloadCustomerTransactions);
  yield takeLatest(DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION.REQUEST, downloadFaultyCustomerTransactions);
  yield takeLatest(SYNC_WALLET.REQUEST, syncWallet);
  yield takeLatest(CALCULATE_CUSTOMER_BOOKING.REQUEST, calculateCustomerBooking);
  yield takeLatest(CREATE_CUSTOMER_BOOKING.REQUEST, createCustomerBooging);
  yield takeLatest(RESCHEDULE_CUSTOMER_BOOKING.REQUEST, rescheduleCustomerBooking);
  yield takeLatest(CANCEL_CUSTOMER_BOOKING.REQUEST, cancelCustomerBooging);
  yield takeLatest(FETCH_ROLES.REQUEST, getRoles);
  yield takeLatest(RESET_OTP_LIMIT.REQUEST, resetOTPLimit);
  yield takeLatest(UPDATE_CUSTOMER_VEHICLE.REQUEST, updateCustomerVehicle);
  yield takeLatest(DOWNLOAD_PAYMENT_RECEIPT.REQUEST, downlaodPaymentReceipt);
}

export default customer;
