import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SplitFlapDisplay = ({ text }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [text]);

  useEffect(() => {
    // Add the CSS for the split flap effect
    const style = document.createElement('style');
    style.textContent = `
      .split-flap-container {
        display: inline-block;
      }
      .split-flap-char {
        display: inline-block;
        position: relative;
        overflow: hidden;
        animation: splitFlap 0.5s ease;
      }
      @keyframes splitFlap {
        0% {
          transform: rotateX(0deg);
          opacity: 0;
        }
        100% {
          transform: rotateX(360deg);
          opacity: 1;
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Convert the input to a string and handle null/undefined
  const safeText = String(text || '');

  return (
    <div className="split-flap-container">
      {safeText.split('').map((char, index) => (
        <span key={`${key}-${index}`} className="split-flap-char">
          {char}
        </span>
      ))}
    </div>
  );
};

SplitFlapDisplay.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

SplitFlapDisplay.defaultProps = {
  text: '',
};

export default SplitFlapDisplay;
