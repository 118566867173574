import { toast } from 'react-toastify';
import { CREATE_CPO, GET_CPO, FETCH_CPO, UPDATE_CPO, DELETE_CPO } from 'actions/partnerManagement';

const initialState = {
  isLoading: false,
  partnerInfo: [],
  partnerDetails: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const partnerManagementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_CPO.REQUEST:
    case FETCH_CPO.REQUEST:
    case DELETE_CPO.REQUEST:
    case GET_CPO.REQUEST: {
      return { ...state, isLoading: true, partnerDetails: {} };
    }

    case UPDATE_CPO.REQUEST: {
      return { ...state, isLoading: true };
    }

    case CREATE_CPO.SUCCESS: {
      toast.success('creaded successfully');
      return {
        ...state,
        isLoading: false,
        partnerInfo: payload,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_CPO.SUCCESS: {
      return {
        ...state,
        partnerInfo: payload,
        isLoading: false,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case FETCH_CPO.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        partnerDetails: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case UPDATE_CPO.SUCCESS: {
      toast.success('updated successfully');
      return {
        ...state,
        isLoading: false,
        partnerInfo: payload,
      };
    }

    case DELETE_CPO.SUCCESS: {
      toast.success('delete successfully');
      return {
        ...state,
        isLoading: false,
        partnerInfo: payload,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case CREATE_CPO.FAIL:
    case UPDATE_CPO.FAIL:
    case FETCH_CPO.FAIL:
    case DELETE_CPO.FAIL:
    case GET_CPO.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default partnerManagementReducer;
