import { post, downloadFile } from 'utils/api';
import { CHARGING_SESSION } from './endpointURLs';

export const stationUnitConsumption = (data) => {
  const page = data.page ? data.page : 1;
  const limit = data.limit ? data.limit : 10;
  if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['limit'];
    delete data['sortBy'];
    return post(`/report/unitconsumption?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (page) {
    delete data['page'];
    delete data['limit'];
    return post(`/report/unitconsumption?page=${parseInt(page)}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/unitconsumption?sortBy=${sortBy}`, data);
  } else if (data.is_rfid_based_booking) {
    if (data.is_rfid_based_booking === 'OTP') {
      delete data['page'];
      delete data['is_rfid_based_booking'];
      return post(`/report/unitconsumption?page=${parseInt(page)}&is_rfid_based_booking=${false}`, data);
    }
    if (data.is_rfid_based_booking === 'RFID') {
      delete data['page'];
      delete data['limit'];
      delete data['is_rfid_based_booking'];
      return post(`/report/unitconsumption?page=${parseInt(page)}&is_rfid_based_booking=${true}`, data);
    }
  } else {
    delete data['page'];
    delete data['limit'];
    return post(`/report/unitconsumption?page=${parseInt(page)}&limit=${parseInt(limit)}`, data);
  }
};

export const b2bStationChargingHistory = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/b2bcharginghistory?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (page) {
    delete data['page'];
    return post(`/report/b2bcharginghistory?page=${parseInt(page)}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/b2bcharginghistory?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/b2bcharginghistory?page=${parseInt(page)}`, data);
  }
};

export const b2cStationChargingHistory = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/b2ccharginghistory?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (page) {
    delete data['page'];
    return post(`/report/b2ccharginghistory?page=${parseInt(page)}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/b2ccharginghistory?search=sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/b2ccharginghistory?page=${parseInt(page)}`, data);
  }
};

export const jsonRequestResponseReport = (data) => {
  return post('/charger_logs/report', data);
};

export const walletTransactionReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/wallettransaction?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/wallettransaction?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/wallettransaction?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/wallettransaction?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/wallettransaction?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/wallettransaction?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/wallettransaction?page=${parseInt(page)}`, data);
  }
};

export const alldatareport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/alldatareport?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/alldatareport?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/alldatareport?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/alldatareport?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/alldatareport?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/alldatareport?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/alldatareport?page=${parseInt(page)}`, data);
  }
};

export const HMILCustomChargingReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/hmilcustomcharginghistory?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/hmilcustomcharginghistory?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/hmilcustomcharginghistory?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/hmilcustomcharginghistory?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/hmilcustomcharginghistory?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/hmilcustomcharginghistory?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/hmilcustomcharginghistory?page=${parseInt(page)}`, data);
  }
};

export const ocpiPartnerChargingReport = (data) => {
  const page = data.page ? data.page : 1;
  console.log('-----------', data);
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/ocpipartnerchargingreport?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/ocpipartnerchargingreport?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/ocpipartnerchargingreport?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/ocpipartnerchargingreport?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/ocpipartnerchargingreport?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/ocpipartnerchargingreport?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/ocpipartnerchargingreport?page=${parseInt(page)}`, data);
  }
};

export const moneyTransactionPgReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/moneytransaction?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/moneytransaction?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/moneytransaction?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/moneytransaction?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/moneytransaction?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/moneytransaction?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/moneytransaction?page=${parseInt(page)}`, data);
  }
};

export const appUserDetailsList = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/appuserlist?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/appuserlist?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/appuserlist?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/appuserlist?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/appuserlist?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/appuserlist?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/appuserlist?page=${parseInt(page)}`, data);
  }
};

export const mostVehicleChargedList = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/vehiclescharged?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/vehiclescharged?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/vehiclescharged?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/vehiclescharged?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/vehiclescharged?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/vehiclescharged?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/vehiclescharged?page=${parseInt(page)}`, data);
  }
};

export const bookingHistory = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/bookinghistory?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (page) {
    delete data['page'];
    return post(`/report/bookinghistory?page=${parseInt(page)}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/bookinghistory?search=sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/bookinghistory?page=${parseInt(page)}`, data);
  }
};

export const userChargingHistory = (data) => {
  const page = data.page ? data.page : 1;
  if (page && data.sortBy) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/usercharginghistory?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (page) {
    delete data['page'];
    return post(`/report/usercharginghistory?page=${parseInt(page)}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/usercharginghistory?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/usercharginghistory?page=${parseInt(page)}`, data);
  }
};

export const stationChargingSummary = (data) => {
  const page = data.page ? data.page : 1;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/chargingsummary?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (page) {
    delete data['page'];
    return post(`/report/chargingsummary?page=${parseInt(page)}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/chargingsummary?search=sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/chargingsummary?page=${parseInt(page)}`, data);
  }
};

export const privateStationChargingSummary = (data) => {
  const page = data.page ? data.page : 1;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/chargingsummaryprivate?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/chargingsummaryprivate?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/chargingsummaryprivate?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/chargingsummaryprivate?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/chargingsummaryprivate?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/chargingsummaryprivate?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/chargingsummaryprivate?page=${parseInt(page)}`, data);
  }
};

export const publicStationChargingSummary = (data) => {
  const page = data.page ? data.page : 1;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/chargingsummarypublic?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/chargingsummarypublic?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/chargingsummarypublic?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/chargingsummarypublic?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/chargingsummarypublic?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/chargingsummarypublic?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/chargingsummarypublic?page=${parseInt(page)}`, data);
  }
};

export const bookingSummaryReport = (data) => {
  const page = data.page ? data.page : 1;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/summary?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/summary?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/summary?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/summary?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/summary?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/summary?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/summary?page=${parseInt(page)}`, data);
  }
};

export const stationDownTimeReport = (data) => {
  const page = data.page ? data.page : 1;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/charger_downtime/summary?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['search'];
    return post(`/charger_downtime/summary?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['search'];
    delete data['sortBy'];
    return post(`/charger_downtime/summary?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/charger_downtime/summary?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = encodeURIComponent(data.search);
    delete data['search'];
    return post(`/charger_downtime/summary?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/charger_downtime/summary?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/charger_downtime/summary?page=${parseInt(page)}`, data);
  }
};

export const plantLoadFactorReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/plantloadfactor?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (page) {
    delete data['page'];
    return post(`/report/plantloadfactor?page=${parseInt(page)}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/plantloadfactor?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/plantloadfactor?page=${parseInt(page)}`, data);
  }
};

export const downloadStationChargingSummary = (data) => {
  return downloadFile(`/report/chargingsummary`, data);
};

export const downloadPrivateStationChargingSummary = (data) => {
  return downloadFile(`/report/chargingsummaryprivate`, data);
};

export const downloadPublicStationChargingSummary = (data) => {
  return downloadFile(`/report/chargingsummarypublic`, data);
};

export const downloadMeterValues = (data) => {
  return downloadFile(`/charger_bookings/metervalues`, data);
};

export const downloadBookingHistory = (data) => {
  return downloadFile(`/report/bookinghistory`, data);
};

export const downloadBookingSummaryReport = (data) => {
  return downloadFile(`/report/summary`, data);
};

export const downloadUserChargingHistory = (data) => {
  return downloadFile(`/report/usercharginghistory`, data);
};

export const downloadMoneyTransactionPGReport = (data) => {
  return downloadFile('/report/moneytransaction', data);
};

export const downloadWalletTransactionReport = (data) => {
  return downloadFile('/report/wallettransaction', data);
};
export const downloadPlantLoadFactorReport = (data) => {
  return downloadFile('/report/plantloadfactor', data);
};
export const downloadalldatareport = (data) => {
  return downloadFile('/report/alldatareport', data);
};

export const downloadHMILCustomChargingReport = (data) => {
  return downloadFile('/report/hmilcustomcharginghistory', data);
};

export const downloadocpiPartnerChargingReport = (data) => {
  return downloadFile('/report/ocpipartnerchargingreport', data);
};

export const downloadB2CStationChargingHistory = (data) => {
  return downloadFile('/report/b2ccharginghistory', data);
};

export const downloadChargingHistory = (data) => {
  return downloadFile('/report/charginghistory', data);
};
export const downloadB2BStationChargingHistory = (data) => {
  return downloadFile('/report/b2bcharginghistory', data);
};

export const downloadCouponUsageReport = (data) => {
  return downloadFile('/report/couponUsageReport', data);
};

export const downloadCouponPerformanceReport = (data) => {
  return downloadFile('/report/couponPerformanceReport', data);
};

export const downloadStationUnitConsumption = (data) => {
  if (data.is_rfid_based_booking) {
    if (data.is_rfid_based_booking === 'OTP') {
      delete data['page'];
      delete data['is_rfid_based_booking'];
      return downloadFile(`/report/unitconsumption?is_rfid_based_booking=${false}`, data);
    }
    if (data.is_rfid_based_booking === 'RFID') {
      delete data['page'];
      delete data['is_rfid_based_booking'];
      return downloadFile(`/report/unitconsumption?is_rfid_based_booking=${true}`, data);
    }
  } else {
    return downloadFile(`/report/unitconsumption`, data);
  }
};

export const calendarView = (data) => {
  return post('/charger_bookings/calendarview', data);
};

export const downloadStationDownTimeReport = (data) => {
  if (data.search) {
    const search = encodeURIComponent(data.search);
    delete data['search'];
    return downloadFile(`/charger_downtime/report?search=${search}`, data);
  } else {
    return downloadFile(`/charger_downtime/report`, data);
  }
};

export const downloadappUserDetailList = (data) => {
  // console.log(data);
  return downloadFile('/report/appuserlist', data);
};

export const acMeterReadingReport = (data) => {
  const page = data.page ? data.page : 1;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/getmqttlogger?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/getmqttlogger?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/getmqttlogger?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/getmqttlogger?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/getmqttlogger?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/getmqttlogger?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/getmqttlogger?page=${parseInt(page)}`, data);
  }
};

export const downloadACMeterReadingReport = (data) => {
  const search = data.search;
  delete data['search'];
  if (search && search !== '') {
    return downloadFile(`/report/getmqttlogger?&search=${search}`, data);
  }
  return downloadFile(`/report/getmqttlogger`, data);
};

export const downloadJobVoucherInvoice = (data) => {
  return downloadFile(`/report/voucher`, data);
};

export const downloadGSTReport = (data) => {
  return downloadFile(`/report/gstreport`, data);
};

export const invoiceReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/invoicesreport?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/invoicesreport?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/invoicesreport?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/invoicesreport?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/invoicesreport?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/invoicesreport?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/invoicesreport?page=${parseInt(page)}`, data);
  }
};

export const paymentStatusReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/paymentstatusreport?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/paymentstatusreport?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/paymentstatusreport?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/paymentstatusreport?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/paymentstatusreport?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/paymentstatusreport?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/paymentstatusreport?page=${parseInt(page)}`, data);
  }
};

export const downloadInvoiceReport = (data) => {
  return downloadFile('/report/invoicesreport', data);
};

export const downloadPaymentStatusReport = (data) => {
  return downloadFile('/report/paymentstatusreport', data);
};

export const downloadMostVehicleChargedList = (data) => {
  return downloadFile(`/report/vehiclescharged`, data);
};
export const vehicleChargingSession = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/vehiclecharginghistory?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/report/vehiclecharginghistory?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/vehiclecharginghistory?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/vehiclecharginghistory?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/report/vehiclecharginghistory?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/vehiclecharginghistory?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/vehiclecharginghistory?page=${parseInt(page)}`, data);
  }
};

export const downloadVehicleChargingSession = (data) => {
  return downloadFile('/report/vehiclecharginghistory', data);
};

export const faultyBookingReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/charger_bookings/faultyBookings?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/charger_bookings/faultyBookings?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/charger_bookings/faultyBookings?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/charger_bookings/faultyBookings?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/charger_bookings/faultyBookings?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/charger_bookings/faultyBookings?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/charger_bookings/faultyBookings?page=${parseInt(page)}`, data);
  }
};

export const downloadFaultyBookingReport = (data) => {
  return downloadFile('/charger_bookings/faultyBookings', data);
};

export const chargingSessionReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`${CHARGING_SESSION}?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['search'];
    return post(`${CHARGING_SESSION}?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['search'];
    delete data['sortBy'];
    return post(`${CHARGING_SESSION}?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`${CHARGING_SESSION}?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = encodeURIComponent(data.search);
    delete data['search'];
    return post(`${CHARGING_SESSION}?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`${CHARGING_SESSION}?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`${CHARGING_SESSION}?page=${parseInt(page)}`, data);
  }
};

export const downloadChargingSessionReport = (data) => {
  if (data.search) {
    const search = encodeURIComponent(data.search);
    delete data['search'];
    return downloadFile(`${CHARGING_SESSION}?search=${search}`, data);
  } else {
    return downloadFile(`${CHARGING_SESSION}`, data);
  }
};

export const couponUsageReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/couponUsageReport?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (page) {
    delete data['page'];
    return post(`/report/couponUsageReport?page=${parseInt(page)}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/couponUsageReport?search=sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/couponUsageReport?page=${parseInt(page)}`, data);
  }
};

export const couponPerformanceReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/couponPerformanceReport?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['search'];
    return post(`/report/couponPerformanceReport?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/couponPerformanceReport?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/couponPerformanceReport?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = encodeURIComponent(data.search);
    delete data['search'];
    return post(`/report/couponPerformanceReport?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/couponPerformanceReport?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/couponPerformanceReport?page=${parseInt(page)}`, data);
  }
};

export const partnerChargingSummary = (data) => {
  const page = data.page ? data.page : 1;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/partnerChargingsummary?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (page) {
    delete data['page'];
    return post(`/report/partnerChargingsummary?page=${parseInt(page)}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/partnerChargingsummary?search=sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/partnerChargingsummary?page=${parseInt(page)}`, data);
  }
};

export const downloadPartnerChargingSummary = (data) => {
  return downloadFile(`/report/partnerChargingsummary`, data);
};

export const customerWalletTransactionReport = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page && data.search) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/report/customerWalletTransaction?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}`, data);
  } else if (page && data.search) {
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['search'];
    return post(`/report/customerWalletTransaction?page=${parseInt(page)}&search=${search}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['search'];
    delete data['sortBy'];
    return post(`/report/customerWalletTransaction?search=${search}&sortBy=${sortBy}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/report/customerWalletTransaction?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (data.search) {
    const search = encodeURIComponent(data.search);
    delete data['search'];
    return post(`/report/customerWalletTransaction?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/report/customerWalletTransaction?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/report/customerWalletTransaction?page=${parseInt(page)}`, data);
  }
};

export const downloadCustomerWalletTransactionReport = (data) => {
  return downloadFile('/report/customerWalletTransaction', data);
};

export const dataReport = {
  downloadocpiPartnerChargingReport,
  stationUnitConsumption,
  ocpiPartnerChargingReport,
  b2bStationChargingHistory,
  b2cStationChargingHistory,
  jsonRequestResponseReport,
  walletTransactionReport,
  moneyTransactionPgReport,
  appUserDetailsList,
  bookingHistory,
  mostVehicleChargedList,
  userChargingHistory,
  stationChargingSummary,
  privateStationChargingSummary,
  publicStationChargingSummary,
  bookingSummaryReport,
  downloadStationChargingSummary,
  downloadPrivateStationChargingSummary,
  downloadPublicStationChargingSummary,
  downloadMeterValues,
  downloadBookingHistory,
  downloadUserChargingHistory,
  downloadMoneyTransactionPGReport,
  downloadWalletTransactionReport,
  downloadB2CStationChargingHistory,
  downloadB2BStationChargingHistory,
  downloadStationUnitConsumption,
  calendarView,
  stationDownTimeReport,
  downloadStationDownTimeReport,
  acMeterReadingReport,
  downloadACMeterReadingReport,
  downloadChargingHistory,
  downloadappUserDetailList,
  downloadJobVoucherInvoice,
  invoiceReport,
  downloadInvoiceReport,
  downloadGSTReport,
  downloadBookingSummaryReport,
  downloadMostVehicleChargedList,
  paymentStatusReport,
  downloadPaymentStatusReport,
  vehicleChargingSession,
  downloadVehicleChargingSession,
  faultyBookingReport,
  downloadFaultyBookingReport,
  chargingSessionReport,
  downloadChargingSessionReport,
  alldatareport,
  downloadalldatareport,
  plantLoadFactorReport,
  downloadPlantLoadFactorReport,
  couponPerformanceReport,
  couponUsageReport,
  downloadCouponUsageReport,
  downloadCouponPerformanceReport,
  HMILCustomChargingReport,
  downloadHMILCustomChargingReport,
  customerWalletTransactionReport,
  downloadCustomerWalletTransactionReport,
  partnerChargingSummary,
  downloadPartnerChargingSummary,
};
