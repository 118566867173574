import React, { useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { Form, Formik, getIn } from 'formik';
import { Col, Row } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { FETCH_TENANT_SETTING, UPDATE_TENANT_SETTING } from 'actions/tenantSetting';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import CheckBox from 'components/inputs/CheckBox';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';

const OCPISetting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tenantSettingDetail = useSelector((state) => state.tenantSetting.tenantSettings);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const data = _.filter(tenantSettingDetail, (data) => data.key_name === 'ocpi_settings');

  // console.log(data, '>>>>>>>>');

  const OCPISettingSchema = Yup.object().shape({
    key_value: Yup.object().shape({
      // AUID: Yup.string().required(t('ocpiSetting.AUID')),
      // ChargeTransactionID: Yup.string().required(t('ocpiSetting.ChargeTransactionID')),
      // Contract_Id: Yup.string().required(t('ocpiSetting.Contract_Id')),
      // Country_Code: Yup.string().required(t('ocpiSetting.Country_Code')),
      // IGST: Yup.string().required(t('ocpiSetting.IGST')),
      // NetAmount: Yup.string().required(t('ocpiSetting.NetAmount')),
      // PartyCode: Yup.string().required(t('ocpiSetting.PartyCode')),
      // PartyId: Yup.string().required(t('ocpiSetting.PartyId')),
      // PartyToken: Yup.string().required(t('ocpiSetting.PartyToken')),
      // SGST: Yup.string().required(t('ocpiSetting.SGST')),
      // ShortName: Yup.string().required(t('ocpiSetting.ShortName')),
      // TaxAmount: Yup.string().required(t('ocpiSetting.TaxAmount')),
      // TaxRate: Yup.string().required(t('ocpiSetting.TaxRate')),
      // TotalAmount: Yup.string().required(t('ocpiSetting.TotalAmount')),
      // Total_Cost: Yup.string().required(t('ocpiSetting.Total_Cost')),
      // access_token: Yup.string().required(t('ocpiSetting.access_token')),
      // currency: Yup.string().required(t('ocpiSetting.currency')),
      // mvrid: Yup.string().required(t('ocpiSetting.mvrid')),
      // webhook_url: Yup.string().required(t('ocpiSetting.webhook_url')),
      // webhook_url_cdr: Yup.string().required(t('ocpiSetting.webhook_url_cdr')),
      // webhook_url_location: Yup.string().required(t('ocpiSetting.webhook_url_location')),
      is_ocpi_supported: Yup.boolean().oneOf([true], t('ocpiSetting.is_ocpi_supported')).required(t('ocpiSetting.is_ocpi_supported')),
    }),
  });

  const initialValues = !_.isEmpty(data)
    ? { key_value: _.get(data, '0.key_value') }
    : {
        key_value: {
          AUID: '',
          ChargeTransactionID: '',
          Contract_Id: '',
          Country_Code: '',
          IGST: '',
          NetAmount: '',
          PartyCode: '',
          PartyId: '',
          PartyToken: '',
          SGST: '',
          ShortName: '',
          TaxAmount: '',
          TaxRate: '',
          TotalAmount: '',
          Total_Cost: '',
          access_token: '',
          currency: '',
          is_ocpi_supported: false,
          modules: ['MeterValues', 'StopTransaction', 'StatusNotification'],
          mvrid: '',
          webhook_url: '',
          webhook_url_cdr: '',
          webhook_url_location: '',
        },
      };

  const getTenantSetting = useCallback(() => {
    dispatch({ type: FETCH_TENANT_SETTING.REQUEST });
  }, []);

  useEffect(() => {
    getTenantSetting();
  }, []);

  const updateTenantSetting = useCallback((data = {}) => {
    dispatch({
      type: UPDATE_TENANT_SETTING.REQUEST,
      payload: data,
    });
  }, []);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.ocpiSetting')} />
        <div className="page-content-wrapper scrollable">
          <div className="app-setting_main">
            <Card className="app-setting-form-card">
              <div className="app-setting-form__block">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={OCPISettingSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    updateTenantSetting({ ...values, id: _.get(data, '0.id'), key_name: 'ocpi_settings' });
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="app-setting-form__inner">
                        <Row>
                          <Col lg={6} md={12}>
                            <div className="force-upgrade-field-main--block">
                              <div className="force-upgrade-checkbox-input__block">
                                <label htmlFor="force-upgrade" className="force-upgrade--label">
                                  {t('ocpiSetting.isOCPISupport')}
                                </label>
                                <CheckBox
                                  name="key_value.is_ocpi_supported"
                                  checked={_.get(values, 'key_value.is_ocpi_supported')}
                                  onChange={(e) => setFieldValue('key_value.is_ocpi_supported', e.target.checked)}
                                />
                              </div>
                              {
                                <div style={{ color: 'red', fontSize: 10 }}>
                                  {getIn(errors, 'key_value.is_ocpi_supported') && getIn(touched, 'key_value.is_ocpi_supported')
                                    ? getIn(errors, 'key_value.is_ocpi_supported')
                                    : null}
                                </div>
                              }
                            </div>
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.countryCode')}
                              label={t('ocpiSetting.countryCode')}
                              name="key_value.Country_Code"
                              value={_.get(values, 'key_value.Country_Code')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.Country_Code') && getIn(touched, 'key_value.Country_Code')
                              //     ? getIn(errors, 'key_value.Country_Code')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.shortName')}
                              label={t('ocpiSetting.shortName')}
                              name="key_value.ShortName"
                              value={_.get(values, 'key_value.ShortName')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.ShortName') && getIn(touched, 'key_value.ShortName')
                              //     ? getIn(errors, 'key_value.ShortName')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.webhookURL')}
                              label={t('ocpiSetting.webhookURL')}
                              name="key_value.webhook_url"
                              value={_.get(values, 'key_value.webhook_url')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.webhook_url') && getIn(touched, 'key_value.webhook_url')
                              //     ? getIn(errors, 'key_value.webhook_url')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.webhookURLCDR')}
                              label={t('ocpiSetting.webhookURLCDR')}
                              name="key_value.webhook_url_cdr"
                              value={_.get(values, 'key_value.webhook_url_cdr')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.webhook_url_cdr') && getIn(touched, 'key_value.webhook_url_cdr')
                              //     ? getIn(errors, 'key_value.webhook_url_cdr')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.webhookURLLocation')}
                              label={t('ocpiSetting.webhookURLLocation')}
                              name="key_value.webhook_url_location"
                              value={_.get(values, 'key_value.webhook_url_location')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.webhook_url_location') && getIn(touched, 'key_value.webhook_url_location')
                              //     ? getIn(errors, 'key_value.webhook_url_location')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.mvrID')}
                              label={t('ocpiSetting.mvrID')}
                              name="key_value.mvrid"
                              value={_.get(values, 'key_value.mvrid')}
                              onChange={handleChange}
                              // error={getIn(errors, 'key_value.mvrid') && getIn(touched, 'key_value.mvrid') ? getIn(errors, 'key_value.mvrid') : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.partyToken')}
                              label={t('ocpiSetting.partyToken')}
                              name="key_value.PartyToken"
                              value={_.get(values, 'key_value.PartyToken')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.PartyToken') && getIn(touched, 'key_value.PartyToken')
                              //     ? getIn(errors, 'key_value.PartyToken')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.accessToken')}
                              label={t('ocpiSetting.accessToken')}
                              name="key_value.access_token"
                              value={_.get(values, 'key_value.access_token')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.access_token') && getIn(touched, 'key_value.access_token')
                              //     ? getIn(errors, 'key_value.access_token')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.partyCode')}
                              label={t('ocpiSetting.partyCode')}
                              name="key_value.PartyCode"
                              value={_.get(values, 'key_value.PartyCode')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.PartyCode') && getIn(touched, 'key_value.PartyCode')
                              //     ? getIn(errors, 'key_value.PartyCode')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.partyId')}
                              label={t('ocpiSetting.partyId')}
                              name="key_value.PartyId"
                              value={_.get(values, 'key_value.PartyId')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.PartyId') && getIn(touched, 'key_value.PartyId') ? getIn(errors, 'key_value.PartyId') : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.IGSTLabel')}
                              label={t('ocpiSetting.IGSTLabel')}
                              name="key_value.IGST"
                              value={_.get(values, 'key_value.IGST')}
                              onChange={handleChange}
                              // error={getIn(errors, 'key_value.IGST') && getIn(touched, 'key_value.IGST') ? getIn(errors, 'key_value.IGST') : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.SGSTLabel')}
                              label={t('ocpiSetting.SGSTLabel')}
                              name="key_value.SGST"
                              value={_.get(values, 'key_value.SGST')}
                              onChange={handleChange}
                              // error={getIn(errors, 'key_value.SGST') && getIn(touched, 'key_value.SGST') ? getIn(errors, 'key_value.SGST') : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.currencyLabel')}
                              label={t('ocpiSetting.currencyLabel')}
                              name="key_value.currency"
                              value={_.get(values, 'key_value.currency')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.currency') && getIn(touched, 'key_value.currency')
                              //     ? getIn(errors, 'key_value.currency')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.netAmount')}
                              label={t('ocpiSetting.netAmount')}
                              name="key_value.NetAmount"
                              value={_.get(values, 'key_value.NetAmount')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.NetAmount') && getIn(touched, 'key_value.NetAmount')
                              //     ? getIn(errors, 'key_value.NetAmount')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.taxAmount')}
                              label={t('ocpiSetting.taxAmount')}
                              name="key_value.TaxAmount"
                              value={_.get(values, 'key_value.TaxAmount')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.TaxAmount') && getIn(touched, 'key_value.TaxAmount')
                              //     ? getIn(errors, 'key_value.TaxAmount')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.taxRate')}
                              label={t('ocpiSetting.taxRate')}
                              name="key_value.TaxRate"
                              value={_.get(values, 'key_value.TaxRate')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.TaxRate') && getIn(touched, 'key_value.TaxRate') ? getIn(errors, 'key_value.TaxRate') : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.totalAmount')}
                              label={t('ocpiSetting.totalAmount')}
                              name="key_value.TotalAmount"
                              value={_.get(values, 'key_value.TotalAmount')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.TotalAmount') && getIn(touched, 'key_value.TotalAmount')
                              //     ? getIn(errors, 'key_value.TotalAmount')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.totalCost')}
                              label={t('ocpiSetting.totalCost')}
                              name="key_value.Total_Cost"
                              value={_.get(values, 'key_value.Total_Cost')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.Total_Cost') && getIn(touched, 'key_value.Total_Cost')
                              //     ? getIn(errors, 'key_value.Total_Cost')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <Row>
                              <Col lg={12}>
                                <div className="force-upgrade-field-main--block">
                                  <div className="force-upgrade-checkbox-input__block">
                                    <label htmlFor="force-upgrade" className="force-upgrade--label">
                                      {t('ocpiSetting.modules')}
                                    </label>
                                    <div id="notification_allow" className="notification-setting--inner">
                                      <div className="notification--item">
                                        <CheckBox
                                          name="MeterValues"
                                          checked={_.includes(_.get(values, 'key_value.modules'), 'MeterValues')}
                                          onChange={() => {
                                            if (_.includes(_.get(values, 'key_value.modules'), 'MeterValues')) {
                                              const newNotificationData = _.remove(
                                                _.get(values, 'key_value.modules'),
                                                (item) => item !== 'MeterValues'
                                              );
                                              setFieldValue('key_value.modules', newNotificationData);
                                            } else {
                                              setFieldValue('key_value.modules', _.concat(_.get(values, 'key_value.modules'), 'MeterValues'));
                                            }
                                          }}
                                        >
                                          {t('ocpiSetting.meterValue')}
                                        </CheckBox>
                                      </div>
                                      <div className="notification--item">
                                        <CheckBox
                                          name="StopTransaction"
                                          checked={_.includes(_.get(values, 'key_value.modules'), 'StopTransaction')}
                                          onChange={() => {
                                            if (_.includes(_.get(values, 'key_value.modules'), 'StopTransaction')) {
                                              const newNotificationData = _.remove(
                                                _.get(values, 'key_value.modules'),
                                                (item) => item !== 'StopTransaction'
                                              );
                                              setFieldValue('key_value.modules', newNotificationData);
                                            } else {
                                              setFieldValue('key_value.modules', _.concat(_.get(values, 'key_value.modules'), 'StopTransaction'));
                                            }
                                          }}
                                        >
                                          {t('ocpiSetting.stopTransaction')}
                                        </CheckBox>
                                      </div>
                                      <div className="notification--item">
                                        <CheckBox
                                          name="StatusNotification"
                                          checked={_.includes(_.get(values, 'key_value.modules'), 'StatusNotification')}
                                          onChange={() => {
                                            if (_.includes(_.get(values, 'key_value.modules'), 'StatusNotification')) {
                                              const newNotificationData = _.remove(
                                                _.get(values, 'key_value.modules'),
                                                (item) => item !== 'StatusNotification'
                                              );
                                              setFieldValue('key_value.modules', newNotificationData);
                                            } else {
                                              setFieldValue('key_value.modules', _.concat(_.get(values, 'key_value.modules'), 'StatusNotification'));
                                            }
                                          }}
                                        >
                                          {t('ocpiSetting.statusNotification')}
                                        </CheckBox>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.contractId')}
                              label={t('ocpiSetting.contractId')}
                              name="key_value.Contract_Id"
                              value={_.get(values, 'key_value.Contract_Id')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.Contract_Id') && getIn(touched, 'key_value.Contract_Id')
                              //     ? getIn(errors, 'key_value.Contract_Id')
                              //     : null
                              // }
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.AUIDLabel')}
                              label={t('ocpiSetting.AUIDLabel')}
                              name="key_value.AUID"
                              value={_.get(values, 'key_value.AUID')}
                              onChange={handleChange}
                              // error={getIn(errors, 'key_value.AUID') && getIn(touched, 'key_value.AUID') ? getIn(errors, 'key_value.AUID') : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <TextInput
                              // isRequired
                              placeholder={t('ocpiSetting.chargeTransactionId')}
                              label={t('ocpiSetting.chargeTransactionId')}
                              name="key_value.ChargeTransactionID"
                              value={_.get(values, 'key_value.ChargeTransactionID')}
                              onChange={handleChange}
                              // error={
                              //   getIn(errors, 'key_value.ChargeTransactionID') && getIn(touched, 'key_value.ChargeTransactionID')
                              //     ? getIn(errors, 'key_value.ChargeTransactionID')
                              //     : null
                              // }
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="app-setting-btn--block">
                        <Button type="submit">{t('button.update')}</Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </>
  );
};

export default OCPISetting;
