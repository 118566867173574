import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_ACCOUNT_DELETE_REQUEST, APPROVED_ACCOUNT_DELETE_REQUEST, REJECTED_ACCOUNT_DELETE_REQUEST } from 'actions/accountDeleteRequest';

function* fetchAccountDeleteRequest(action) {
  try {
    const response = yield call(API.getAccountDeleteRequest, action.payload);
    yield put({ type: FETCH_ACCOUNT_DELETE_REQUEST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_ACCOUNT_DELETE_REQUEST.FAIL, payload: { error: e } });
  }
}

function* approveAccountDeleteRequest(action) {
  try {
    const response = yield call(API.approveAccountDeleteRequest, action.payload);
    yield put({ type: APPROVED_ACCOUNT_DELETE_REQUEST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: APPROVED_ACCOUNT_DELETE_REQUEST.FAIL, payload: { error: e } });
  }
}

function* rejectAccountDeleteRequest(action) {
  try {
    const response = yield call(API.rejectAccountDeleteRequest, action.payload);
    yield put({ type: REJECTED_ACCOUNT_DELETE_REQUEST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: REJECTED_ACCOUNT_DELETE_REQUEST.FAIL, payload: { error: e } });
  }
}

function* accountDeleteRequest() {
  yield takeLatest(FETCH_ACCOUNT_DELETE_REQUEST.REQUEST, fetchAccountDeleteRequest);
  yield takeLatest(APPROVED_ACCOUNT_DELETE_REQUEST.REQUEST, approveAccountDeleteRequest);
  yield takeLatest(REJECTED_ACCOUNT_DELETE_REQUEST.REQUEST, rejectAccountDeleteRequest);
}

export default accountDeleteRequest;
