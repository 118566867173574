import { createActionTypes } from 'utils/action/actionTypes';

export const FETCH_COUNTRY = createActionTypes('FETCH_COUNTRY');
export const GET_COUNTRY = createActionTypes('GET_COUNTRY');
export const CREATE_COUNTRY = createActionTypes('CREATE_COUNTRY');
export const UPDATE_COUNTRY = createActionTypes('UPDATE_COUNTRY');
export const DELETE_COUNTRY = createActionTypes('DELETE_COUNTRY');
export const FETCH_STATE = createActionTypes('FETCH_STATE');
export const GET_STATE = createActionTypes('GET_STATE');
export const CREATE_STATE = createActionTypes('CREATE_STATE');
export const UPDATE_STATE = createActionTypes('UPDATE_STATE');
export const DELETE_STATE = createActionTypes('DELETE_STATE');
export const FETCH_CITY = createActionTypes('FETCH_CITY');
export const GET_CITY = createActionTypes('GET_CITY');
export const CREATE_CITY = createActionTypes('CREATE_CITY');
export const UPDATE_CITY = createActionTypes('UPDATE_CITY');
export const DELETE_CITY = createActionTypes('DELETE_CITY');
export const FETCH_AREA = createActionTypes('FETCH_AREA');
export const GET_AREA = createActionTypes('GET_AREA');
export const CREATE_AREA = createActionTypes('CREATE_AREA');
export const UPDATE_AREA = createActionTypes('UPDATE_AREA');
export const DELETE_AREA = createActionTypes('DELETE_AREA');
export const GET_CITY_BY_STATE = createActionTypes('GET_CITY_BY_STATE');
export const GET_AREA_BY_CITY = createActionTypes('GET_AREA_BY_CITY');
