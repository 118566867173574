import { createActionTypes } from 'utils/action/actionTypes';

export const FETCH_CHARGER = createActionTypes('FETCH_CHARGER');
export const GET_CHARGER = createActionTypes('GET_CHARGER');
export const CREATE_CHARGER = createActionTypes('CREATE_CHARGER');
export const DELETE_CHARGER = createActionTypes('DELETE_CHARGER');
export const FETCH_CHARGER_BY_STATION = createActionTypes('FETCH_CHARGER_BY_STATION');
export const FETCH_CHARGERS_LIST = createActionTypes('FETCH_CHARGERS_LIST');
export const UPDATE_CHARGER = createActionTypes('UPDATE_CHARGER');
export const CHANGE_CHARGER_STATUS = createActionTypes('CHANGE_CHARGER_STATUS');
export const CHANGE_CHARGER_IS_ACTIVE_STATUS = createActionTypes('CHANGE_CHARGER_IS_ACTIVE_STATUS');
export const FETCH_CHARGERS_BY_STATIONS = createActionTypes('FETCH_CHARGERS_BY_STATIONS');
