import { get, post, deleteRequest, put, downloadFile } from 'utils/api';

export const getVouchers = (data) => {
  return get(`/voucher`, data);
};

export const addVoucher = (data) => {
  return post(`/voucher`, data);
};

export const updateVoucher = (data) => {
  const id = data.id;
  delete data['id'];
  return put(`/voucher/${id}`, data);
};

export const deleteVoucher = (id) => {
  return deleteRequest(`/voucher/${id}`);
};

export const getVoucherById = (id) => {
  return get(`/voucher/${id}`);
};

export const downloadVoucherLogs = (data) => {
  return downloadFile('/voucher/voucherLogs', data);
};

export const getVoucherLogs = (data) => {
  const page = data.page ? data.page : 1;
  if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/voucher/voucherLogs?page=${parseInt(page)}&sortBy=${sortBy}`, data);
  } else if (page) {
    delete data['page'];
    return post(`/voucher/voucherLogs?page=${parseInt(page)}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/voucher/voucherLogs?search=sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    return post(`/voucher/voucherLogs?page=${parseInt(page)}`, data);
  }
};

export const voucher = {
  getVouchers,
  addVoucher,
  updateVoucher,
  deleteVoucher,
  getVoucherById,
  getVoucherLogs,
  downloadVoucherLogs,
};
