/* eslint-disable jsx-a11y/label-has-associated-control */

import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import AdminHeader from 'components/header';
import { Col, Row } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsSearch, BsUpload } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import ReactPagination from 'components/general/ReactPagination';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { UPDATE_USER } from 'actions/user';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { toast } from 'react-toastify';
import DeleteModal from 'components/general/DeleteModal';
import { startsWith } from 'components/common/utils';
import { FETCH_CUSTOMER } from 'actions/customer';
import { BiSort } from 'react-icons/bi';
import { CREATE_BULK_USERS_TO_USER_GROUP } from 'actions/userGroup';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { GiSwipeCard } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const UserList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userGroupId } = useParams();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const [searchText, setSearchText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userId, setUserId] = useState('');
  const [allUserInUserGroupListData, setAllUserInUserGroupListData] = useState();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const allCustomer = useSelector((state) => state.customer.customers);
  const isLoader = useSelector((state) => state.customer.isLoading);
  const page = useSelector((state) => state.allUserInUserGroup.page);
  const totalData = useSelector((state) => state.allUserInUserGroup.total);
  const limit = useSelector((state) => state.allUserInUserGroup.limit);
  const totalPages = useSelector((state) => state.allUserInUserGroup.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const sampleUserList = [
    {
      phone: '',
      country_code: '',
    },
  ];

  const deleteUser = useCallback(() => {
    const userData = {
      id: userId,
      user_group: null,
    };
    dispatch({
      type: UPDATE_USER.REQUEST,
      payload: userData,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          toast.success('User deleted successfully');
          setShowDeleteModal(false);
          getAllCustomer({ page: 1, user_group: userGroupId });
        }
      },
    });
  }, [userId, userGroupId]);

  const getAllCustomer = useCallback((data = {}) => {
    dispatch({ type: FETCH_CUSTOMER.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getAllCustomer({ user_group: userGroupId });
  }, []);

  const handleSearch = () => {
    getAllCustomer({ user_group: userGroupId });
  };

  // const handlePageClick = useCallback((page) => {
  //   const data = {
  //     page: page.selected + 1,
  //     user_group: userGroupId,
  //   };
  //   getAllCustomer(data);
  // }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getAllCustomer({ ...data, user_group: userGroupId });
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getAllCustomer({ ...data, user_group: userGroupId });
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getAllCustomer({ ...data, user_group: userGroupId });
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    setAllUserInUserGroupListData(
      _.filter(
        allCustomer,
        (item) =>
          startsWith(_.get(item, 'name'), searchText) ||
          startsWith(_.get(item, 'email'), searchText) ||
          startsWith(_.get(item, 'phone').toString(), searchText)
      )
    );
  }, [searchText, allCustomer]);

  const upload = () => {
    //upload a excel file and get data of uploaded excel
    const fileUpload = document.getElementById('file');
    const regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.xls|.xlsx)$/;
    if (regex.test(fileUpload.value.toLowerCase())) {
      // let fileName = fileUpload.files[0].name;
      if (typeof FileReader !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = () => {
            processExcel(reader.result);
          };
          reader.readAsBinaryString(fileUpload.files[0]);
        }
      } else {
        toast.error('This browser does not support HTML5.');
      }
    } else {
      toast.error('Please upload a valid Excel file.');
    }
  };

  const addBulkUsersToUserGroup = useCallback((data) => {
    const bulkUserData = {
      userData: data,
      id: userGroupId,
    };
    dispatch({
      type: CREATE_BULK_USERS_TO_USER_GROUP.REQUEST,
      payload: bulkUserData,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          toast.success('User added successfully');
          setTimeout(() => {
            getAllCustomer({ user_group: userGroupId });
          }, 1000);
        }
      },
    });
  }, []);

  const processExcel = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
    addBulkUsersToUserGroup(excelRows);
  };

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const downloadSampleUserList = (file, fileName) => {
    // download static Excel file
    const ws = XLSX.utils.json_to_sheet(file);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.users')} backTo="/userGroupManagement" />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="user-group-page_main">
              <div className="user-group-main-block">
                <Card>
                  <div className="user-group-block">
                    <div className="user-group-search-box">
                      <Row>
                        <Col xl={6} md={12}>
                          <SearchBox
                            preIcon={<BsSearch />}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                handleSearch();
                              }
                            }}
                          />
                        </Col>
                        <Col xl={2} md={3}>
                          <Button className="user-group-btn" onClick={() => navigateTo(`/addUser/${userGroupId}`)}>
                            {t('button.addUser')}
                          </Button>
                        </Col>
                        <Col xl={4} md={9}>
                          <div className="import-user--block">
                            <div className="upload-file-block">
                              <input onChange={upload} type="file" id="file" className="upload-file--input" />
                              <label className="upload-file-inner" htmlFor="file">
                                <div className="import-btn__block">
                                  <div className="import_btn">
                                    <span className="user-upload-file-box-icon">
                                      <BsUpload />
                                    </span>
                                    <span className="user-upload-file-box-text"> {t('button.importData')}</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                            <div className="download-sample--block">
                              <div className="download-sample-file" onClick={() => downloadSampleUserList(sampleUserList, 'userList')}>
                                {isLoader ? (
                                  <Skeleton width={100} height={15} />
                                ) : (
                                  <>
                                    <GiSwipeCard color="#58d37d" size={20} />
                                    {t('addUserGroupList.download')}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="user-group-table">
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addUserGroupList.name')}</span>
                                  <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addUserGroupList.emailID')}</span>
                                  <span className="ico" onClick={() => handleSorting('email', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addUserGroupList.phoneNo')}</span>
                                  <span className="ico" onClick={() => handleSorting('phone', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div>
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addUserGroupList.action')}</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoader ? (
                              <tr>
                                <td colSpan={10} className="border-0">
                                  <Skeleton height={15} />
                                </td>
                              </tr>
                            ) : _.isEmpty(allUserInUserGroupListData) ? (
                              <tr>
                                <td colSpan={8} className="border-0">
                                  <div className="empty-data-block">{t('addUserGroupList.noUsers')}</div>
                                </td>
                              </tr>
                            ) : (
                              !(isLoader || _.isUndefined(isLoader)) &&
                              _.map(allUserInUserGroupListData, (item, index) => {
                                return (
                                  <tr key={`user-group-${index}`}>
                                    <td>{isLoader ? <Skeleton width={100} height={15} /> : _.get(item, 'name', '')}</td>
                                    <td>{isLoader ? <Skeleton width={100} height={15} /> : _.get(item, 'email', '')}</td>
                                    <td>
                                      +{isLoader ? <Skeleton width={100} height={15} /> : _.get(item, 'country_code', '')}
                                      {isLoader ? <Skeleton width={100} height={15} /> : _.get(item, 'phone', 'NA')}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton width={100} height={15} />
                                      ) : (
                                        <Button
                                          className="user-group-delete-Btn"
                                          onClick={() => {
                                            setShowDeleteModal(true);
                                            setUserId(_.get(item, 'id', ''));
                                          }}
                                        >
                                          {t('button.removeUser')}
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allUserInUserGroupListData) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </SkeletonTheme>
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="User" onClose={handleCloseDeleteModel} onRemove={deleteUser} />
          </Modal>
        )}
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default UserList;
