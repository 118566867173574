import { CALENDAR_VIEW } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  calendarView: [],
};

const calendarViewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CALENDAR_VIEW.REQUEST: {
      return { ...state, isLoading: true };
    }
    case CALENDAR_VIEW.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        calendarView: payload,
      };
    }

    case CALENDAR_VIEW.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default calendarViewReducer;
