import { FETCH_REVIEWS } from 'actions/chargingStation';

const initialState = {
  isLoading: false,
  reviews: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const reviewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_REVIEWS.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_REVIEWS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        results: payload?.results,
        page: payload?.page,
        limit: payload?.limit,
        totalPages: payload?.totalPages,
        total: payload?.totalResults,
      };
    }

    case FETCH_REVIEWS.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default reviewReducer;
