import React from 'react';
import Card from 'components/inputs/Card';
import { ProgressBar } from 'react-bootstrap';
import { VscSettingsGear } from 'react-icons/vsc';
import Button from 'components/inputs/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
const UpdateFirmwareModal = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="update-notification-main_page">
        <Card>
          <div className="update-notification-inner_page">
            <div className="update-notification--content">
              <div className="update-notification-setting_icon">
                <VscSettingsGear />
              </div>
              <div className="update-notification-progressbar">
                <ProgressBar className="update--progressbar" variant="success" animated now={50} />
              </div>
              <div className="update-notification--name">{t('updateFirmware.updating')}</div>
            </div>
            <div className="update-notification-btn">
              <Button className="update--btn" onClick={onClose}>
                {t('button.cancel')}
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
UpdateFirmwareModal.propTypes = {
  onClose: PropTypes.func,
};
export default UpdateFirmwareModal;
