import { FETCH_EMAIL, GET_EMAIL, UPDATE_EMAIL, CREATE_EMAIL, DELETE_EMAIL } from 'actions/email';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchEmail(action) {
  try {
    const response = yield call(API.getEmail, action.payload);
    yield put({ type: FETCH_EMAIL.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_EMAIL.FAIL, payload: { error: e } });
  }
}

function* getEmailById(action) {
  try {
    const response = yield call(API.getEmailById, action.payload);
    yield put({ type: GET_EMAIL.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_EMAIL.FAIL, payload: { error: e } });
  }
}

function* createEmail(action) {
  try {
    const response = yield call(API.addEmail, action.payload);
    yield put({ type: CREATE_EMAIL.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_EMAIL.FAIL, payload: { error: e } });
  }
}

function* updateEmail(action) {
  try {
    const response = yield call(API.updateEmail, action.payload);
    yield put({ type: UPDATE_EMAIL.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_EMAIL.FAIL, payload: { error: e } });
  }
}

function* deleteEmail(action) {
  try {
    const response = yield call(API.deleteEmail, action.payload);
    yield put({ type: DELETE_EMAIL.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_EMAIL.FAIL, payload: { error: e } });
  }
}

function* email() {
  yield takeLatest(FETCH_EMAIL.REQUEST, fetchEmail);
  yield takeLatest(GET_EMAIL.REQUEST, getEmailById);
  yield takeLatest(CREATE_EMAIL.REQUEST, createEmail);
  yield takeLatest(UPDATE_EMAIL.REQUEST, updateEmail);
  yield takeLatest(DELETE_EMAIL.REQUEST, deleteEmail);
}
export default email;
