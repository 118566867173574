import { get, patch } from 'utils/api';

export const getAllTenantSetting = (data) => {
  return get(`/tenantSetting`, data);
};

export const getTenantSettingById = (id) => {
  return get(`/tenantSetting/${id}`);
};

export const updateTenantSetting = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/tenantSetting/${id}`, data);
};

export const tenantSetting = {
  getAllTenantSetting,
  getTenantSettingById,
  updateTenantSetting,
};