import { CUSTOMER_WALLET_TRANSACTION_REPORT } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  customerWalletTransactionReport: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};
const customerWalletTransactionReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CUSTOMER_WALLET_TRANSACTION_REPORT.REQUEST: {
      return { ...state, isLoading: true };
    }

    case CUSTOMER_WALLET_TRANSACTION_REPORT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        topup: payload.total_wallet_topups,
        amountAdded: payload.total_amount_added,
        advanceBalance: payload.advance_balance,
        amountByCMS: payload.total_amount_by_cms,
        customerWalletTransactionReport: payload.wallets.results,
        page: payload.wallets.page,
        limit: payload.wallets.limit,
        totalPages: payload.wallets.totalPages,
        total: payload.wallets.totalResults,
      };
    }

    case CUSTOMER_WALLET_TRANSACTION_REPORT.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default customerWalletTransactionReportReducer;
