import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';
import * as _ from 'lodash';
import { DOWNLOAD_PLANT_LOAD_FACTOR_REPORT, PLANT_LOAD_FACTOR_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import DatePicker from 'components/inputs/DatePicker';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import ReactPagination from 'components/general/ReactPagination';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';

const PlantLoadFactorReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const plantLoadFactorReportList = useSelector((state) => state.plantLoadFactorReport.plantLoadFactorReports);
  const isLoader = useSelector((state) => state.plantLoadFactorReport.isLoading);
  const page = useSelector((state) => state.plantLoadFactorReport.page);
  const totalData = useSelector((state) => state.plantLoadFactorReport.total);
  const limit = useSelector((state) => state.plantLoadFactorReport.limit);
  const totalPages = useSelector((state) => state.plantLoadFactorReport.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const plantLoadFactorReport = useCallback(
    (value) => {
      const data = {
        ...value,
        from: moment(startDate).utc().toDate(),
        to: moment(endDate).utc().toDate(),
        is_external_charger: false,
      };
      dispatch({ type: PLANT_LOAD_FACTOR_REPORT.REQUEST, payload: data });
    },
    [startDate, endDate]
  );

  useEffect(() => {
    plantLoadFactorReport();
  }, [startDate, endDate]);

  const getSortByItem = useCallback(
    (name, order, page) => {
      const sortedOrder = order || 'asc';
      if (startDate && endDate && page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          from: moment(startDate).utc().toDate(),
          to: moment(endDate).utc().toDate(),
        };
        endDate && plantLoadFactorReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        plantLoadFactorReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        plantLoadFactorReport(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        plantLoadFactorReport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        plantLoadFactorReport(data);
      }
    },
    [sortByItem, startDate, endDate]
  );
  // Download Excel Functionality

  const downloadPlantLoadFactorReport = useCallback(() => {
    const data = {
      excel: true,
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
      is_external_charger: false,
    };
    dispatch({
      type: DOWNLOAD_PLANT_LOAD_FACTOR_REPORT.REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Plant Load Factor Report'}.xlsx`);
          }
        }
      },
    });
  }, [startDate, endDate]);

  const downloadFile = () => {
    downloadPlantLoadFactorReport();
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.plantLoadFactorReport')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col xl={'auto'} md={'auto'}>
                        <div className="data-report-date_picker">
                          <DatePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            defaultValueOfStartDate={moment().startOf('month')}
                            defaultValueOfEndDate={moment(new Date()).endOf('day')}
                            maxDateOfStartDate={moment(new Date()).startOf('day')}
                            maxDateOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>

                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('plantLoadFactorReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('plantLoadFactorReport.stationName')}</span>
                                <span className="ico" onClick={() => handleSorting('station_name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('plantLoadFactorReport.chargerName')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('plantLoadFactorReport.unitUsed')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('plantLoadFactorReport.capacityCharger')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('plantLoadFactorReport.commissioningDate')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('plantLoadFactorReport.plf')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('plantLoadFactorReport.totalAvailableCapacity')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={11} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(plantLoadFactorReportList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('plantLoadFactorReport.noHistoryFound')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(plantLoadFactorReportList, (item, key) => {
                              const serial_num = limit * (page - 1) + key;
                              return (
                                <>
                                  <tr key={`plant-load-factor-report-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'station_name', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger_id', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'total_consumption', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charge_capacity', '-')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : _.get(item, 'installation_date', '') === '' ? (
                                        '-'
                                      ) : (
                                        _.get(item, 'installation_date', '-')
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'plf', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'total_available_capacity', '-')}</td>
                                  </tr>
                                </>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(plantLoadFactorReportList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};

export default PlantLoadFactorReport;
