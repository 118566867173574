import { CREATE_AMENITY, DELETE_AMENITY, FETCH_AMENITY, GET_AMENITY, UPDATE_AMENITY } from 'actions/amenity';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

const initialState = {
  isLoading: false,
  amenities: [],
  amenityDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const amenityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_AMENITY.REQUEST:
    case GET_AMENITY.REQUEST:
    case CREATE_AMENITY.REQUEST:
    case DELETE_AMENITY.REQUEST: {
      return { ...state, isLoading: true, amenityDetail: {} };
    }

    case UPDATE_AMENITY.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_AMENITY.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        amenities: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_AMENITY.SUCCESS: {
      return {
        ...state,
        amenityDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_AMENITY.SUCCESS: {
      toast.success('Amenity added successfully');
      return {
        ...state,
        amenities: _.concat(state.amenities, payload),
        isLoading: false,
      };
    }

    case UPDATE_AMENITY.SUCCESS: {
      toast.success('Amenity updated successfully');
      return {
        ...state,
        amenities: state.amenities.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_AMENITY.SUCCESS: {
      toast.success('Amenity deleted successfully');
      return {
        ...state,
        isLoading: false,
        amenities: state.amenities.filter((data) => data.id !== payload),
      };
    }

    case FETCH_AMENITY.FAIL:
    case GET_AMENITY.FAIL:
    case UPDATE_AMENITY.FAIL:
    case CREATE_AMENITY.FAIL:
    case DELETE_AMENITY.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default amenityReducer;
