import React, { useCallback, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import * as _ from 'lodash';
import Button from 'components/inputs/Button';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import ResetPassword from './ResetPassword';
import { Link } from 'react-router-dom';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { FORGOT_PASSWORD } from 'actions/authAction';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [phoneError, setPhoneError] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phoneData, setPhoneData] = useState({});
  const [showResetPasswordScreen, setShowResetPasswordScreen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const getCountryCode = _.get(parsePhoneNumber(countryCode), 'countryCallingCode', '');
  const getPhoneNumber = _.get(parsePhoneNumber(countryCode), 'nationalNumber', '');

  const forgotPassword = useCallback((data) => {
    dispatch({
      type: FORGOT_PASSWORD.REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 201) {
            setShowResetPasswordScreen(true);
          } else {
            setErrorMsg(res.data.message);
            setShowResetPasswordScreen(false);
          }
        }
      },
    });
  }, []);

  const ForgotSchema = Yup.object({
    phone: Yup.string().required(t('forgotPassword.phone')),
    country_code: Yup.string().required(t('forgotPassword.countryCode')),
  });

  return (
    <main className="forgot-page__main">
      <div className="forgot-bg-block">
        <div className="forgot-content-block">
          {!showResetPasswordScreen ? (
            <div className="forgot-content--inner">
              <div className="title">{t('forgotPassword.title')}</div>
              <div className="sub-title">{t('forgotPassword.subTitle')}</div>
              <div className="forgotPassword-form__block">
                <Formik
                  initialValues={{ phone: '', country_code: '91' }}
                  validationSchema={ForgotSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (!isValidPhoneNumber(values.phone)) {
                      setPhoneError('Phone number is not valid!');
                    } else {
                      // send forgotPassword api calling...
                      const phoneDetails = {
                        country_code: getCountryCode,
                        phone: getPhoneNumber,
                      };
                      forgotPassword(phoneDetails);
                      setPhoneData(phoneDetails);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="form-content-block">
                        <PhoneNumberInput
                          label={t('forgotPassword.phoneNo')}
                          name="phone"
                          value={values.phone}
                          onChange={(val) => {
                            setFieldValue(`phone`, val);
                            setCountryCode(val || '');
                            if (!_.isEmpty(phoneError)) {
                              setPhoneError('');
                            }
                          }}
                          error={errors.phone && touched.phone ? errors.phone : phoneError ? phoneError : errorMsg ? errorMsg : null}
                        />
                        <div className="form-button--block">
                          <Button type="submit" className="send--btn" disabled={isSubmitting}>
                            {t('button.requestOTP')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="back-to-login--block">
                <Link to={'/login'}>
                  <RiArrowGoBackLine className="back--icon" /> {t('button.back')}
                </Link>
              </div>
            </div>
          ) : (
            <ResetPassword phoneData={phoneData} />
          )}
        </div>
      </div>
    </main>
  );
};
export default ForgotPassword;
