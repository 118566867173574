import { patch, get } from 'utils/api';

export const updateUserProfile = (data) => {
  return patch(`/users/updateUserProfile`, data);
};

export const getProfile = (data) => {
  return get(`/users/myprofile`, data);
};

export const profile = {
  updateUserProfile,
  getProfile,
};
