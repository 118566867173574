import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { UPLOAD_NO_LOAD_REPORT, FETCH_NO_LOAD_REPORT, DOWNLOAD_NO_LOAD_REPORT } from 'actions/noLoadReport';

function* uploadNoLoadReport(action) {
  try {
    const response = yield call(API.uploadNoLoadReport, action.payload);
    yield put({ type: UPLOAD_NO_LOAD_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPLOAD_NO_LOAD_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* fetchNoLoadReport(action) {
  try {
    const response = yield call(API.fetchNoLoadReport, action.payload);
    yield put({ type: FETCH_NO_LOAD_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_NO_LOAD_REPORT.FAIL, payload: { error: e } });
  }
}

function* downloadNoLoadReport(action) {
  try {
    const response = yield call(API.downloadNoLoadReport, action.payload);
    yield put({ type: DOWNLOAD_NO_LOAD_REPORT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_NO_LOAD_REPORT.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* noLoadReport() {
  yield takeLatest(UPLOAD_NO_LOAD_REPORT.REQUEST, uploadNoLoadReport);
  yield takeLatest(FETCH_NO_LOAD_REPORT.REQUEST, fetchNoLoadReport);
  yield takeLatest(DOWNLOAD_NO_LOAD_REPORT.REQUEST, downloadNoLoadReport);
}

export default noLoadReport;
