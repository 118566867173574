import { FETCH_OEM, GET_OEM, CREATE_OEM, DELETE_OEM, UPDATE_OEM, OEM_UPDATE_FIRMWARE } from 'actions/oem';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  oems: [],
  oemDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const oemReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_OEM.REQUEST:
    case GET_OEM.REQUEST:
    case CREATE_OEM.REQUEST:
    case DELETE_OEM.REQUEST: {
      return { ...state, isLoading: true, oemDetail: {} };
    }

    case FETCH_OEM.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          oems: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          oems: _.concat(state.oems, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_OEM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        oemDetail: payload,
      };
    }

    case CREATE_OEM.SUCCESS: {
      toast.success('ChargeSpot added successfully');
      setTimeout(() => {
        window.location.href = '/ChargeSpot';
      }, 500);
      return {
        ...state,
        isLoading: false,
        oems: _.concat(state.oems, payload),
      };
    }

    case UPDATE_OEM.SUCCESS: {
      toast.success('ChargeSpot updated successfully');
      setTimeout(() => {
        window.location.href = '/ChargeSpot';
      }, 500);
      return {
        ...state,
        oems: state.oems.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case OEM_UPDATE_FIRMWARE.SUCCESS: {
      toast.success('Oem firmware updated successfully');
      return {
        ...state,
        oems: state.oems.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_OEM.SUCCESS: {
      toast.success('ChargeSpot deleted successfully');
      return {
        ...state,
        oems: state.oems.filter((data) => data.id !== payload),
      };
    }

    case FETCH_OEM.FAIL:
    case GET_OEM.FAIL:
    case CREATE_OEM.FAIL:
    case UPDATE_OEM.FAIL:
    case DELETE_OEM.FAIL:
    case OEM_UPDATE_FIRMWARE.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default oemReducer;
