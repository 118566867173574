import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_COUPONS,
  FETCH_COUPONS_SUCCESS,
  FETCH_COUPONS_FAIL,
  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
  UPDATE_COUPON,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  CREATE_COUPON,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  GET_COUPON,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAIL,
  BOOKING_COUPON,
  BOOKING_COUPON_SUCCESS,
  BOOKING_COUPON_FAIL,
} from 'actions/coupon';

function* fetchCoupons(action) {
  try {
    const response = yield call(API.getCoupons, action.payload);
    yield put({ type: FETCH_COUPONS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_COUPONS_FAIL, payload: { error: e } });
  }
}

function* getCoupon(action) {
  try {
    const response = yield call(API.getCouponById, action.payload);
    yield put({ type: GET_COUPON_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_COUPON_FAIL, payload: { error: e } });
  }
}

function* createCoupon(action) {
  try {
    const response = yield call(API.addCoupon, action.payload);
    yield put({ type: CREATE_COUPON_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_COUPON_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateCoupon(action) {
  try {
    const response = yield call(API.updateCoupon, action.payload);
    yield put({ type: UPDATE_COUPON_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_COUPON_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* deleteCoupon(action) {
  try {
    const response = yield call(API.deleteCoupon, action.payload);
    yield put({ type: DELETE_COUPON_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_COUPON_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}
function* validateCoupon(action) {
  try {
    const response = yield call(API.validateCoupon, action.payload);
    yield put({ type: BOOKING_COUPON_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: BOOKING_COUPON_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* coupon() {
  yield takeLatest(FETCH_COUPONS, fetchCoupons);
  yield takeLatest(GET_COUPON, getCoupon);
  yield takeLatest(CREATE_COUPON, createCoupon);
  yield takeLatest(UPDATE_COUPON, updateCoupon);
  yield takeLatest(DELETE_COUPON, deleteCoupon);
  yield takeLatest(BOOKING_COUPON, validateCoupon);
}

export default coupon;
