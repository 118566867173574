import { get, post, deleteRequest, patch } from 'utils/api';

export const getEmail = (data) => {
  return get(`/email_template`, data);
};

export const getEmailById = (id) => {
  return get(`/email_template/${id}`);
};

export const addEmail = (data) => {
  return post(`/email_template`, data);
};

export const updateEmail = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/email_template/${id}`, data);
};

export const deleteEmail = (id) => {
  return deleteRequest(`/email_template/${id}`);
};

export const email = {
  getEmail,
  getEmailById,
  addEmail,
  updateEmail,
  deleteEmail,
};
