import { createActionTypes } from 'utils/action/actionTypes';

export const CANCEL_RESERVATION = createActionTypes('CANCEL_RESERVATION');
export const CHANGE_AVAILABILITY = createActionTypes('CHANGE_AVAILABILITY');
export const GET_CONFIGURATION = createActionTypes('GET_CONFIGURATION');
export const GET_DIAGNOSTICS = createActionTypes('GET_DIAGNOSTICS');
export const CLEAR_CACHE = createActionTypes('CLEAR_CACHE');
export const REMOTE_START_TRANSACTION = createActionTypes('REMOTE_START_TRANSACTION');
export const REMOTE_STOP_TRANSACTION = createActionTypes('REMOTE_STOP_TRANSACTION');
export const RESERVE_NOW = createActionTypes('RESERVE_NOW');
export const RESET = createActionTypes('RESET');
export const SEND_LOCAL_LIST = createActionTypes('SEND_LOCAL_LIST');
export const TRIGGER_MESSAGE = createActionTypes('TRIGGER_MESSAGE');
export const UNLOCK_CONNECTOR = createActionTypes('UNLOCK_CONNECTOR');
export const UPDATE_FIRMWARE = createActionTypes('UPDATE_FIRMWARE');
export const DATA_TRANSFER_SERVER = createActionTypes('DATA_TRANSFER_SERVER');
export const GET_LOCAL_LIST = createActionTypes('GET_LOCAL_LIST');
export const CHANGE_CONFIGURATION = createActionTypes('CHANGE_CONFIGURATION');
export const SET_CHARGING_PROFILE = createActionTypes('SET_CHARGING_PROFILE');
export const CLEAR_CHARGING_PROFILE = createActionTypes('CLEAR_CHARGING_PROFILE');
