import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_OCPI_PARTNER_GROUP,
  FETCH_OCPI_PARTNER_GROUP,
  GET_OCPI_PARTNER_GROUP,
  DELETE_OCPI_PARTNER_GROUP,
  UPDATE_OCPI_PARTNER_GROUP,
  ALL_USERS_IN_OCPI_PARTNER_GROUP,
  CREATE_BULK_USERS_TO_OCPI_PARTNER_GROUP,
  ADD_PARTY_IN_OCPI_PARTNER_GROUP,
  DELETE_OCPI_PARTNER,
} from 'actions/ocpiPartnerGroup';

function* getPartnerGroup(action) {
  try {
    const response = yield call(API.getPartnerGroup, action.payload);
    yield put({ type: FETCH_OCPI_PARTNER_GROUP.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_OCPI_PARTNER_GROUP.FAIL, payload: { error: e } });
  }
}

function* getPartnerGroupById(action) {
  try {
    const response = yield call(API.getPartnerGroupById, action.payload);
    yield put({ type: GET_OCPI_PARTNER_GROUP.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_OCPI_PARTNER_GROUP.FAIL, payload: { error: e } });
  }
}

function* createPartnerGroup(action) {
  try {
    const response = yield call(API.addPartnerGroup, action.payload);
    yield put({ type: CREATE_OCPI_PARTNER_GROUP.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_OCPI_PARTNER_GROUP.FAIL, payload: { error: e } });
  }
}

function* updatePartnerGroup(action) {
  try {
    const response = yield call(API.updatePartnerGroup, action.payload);
    yield put({ type: UPDATE_OCPI_PARTNER_GROUP.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_OCPI_PARTNER_GROUP.FAIL, payload: { error: e } });
  }
}

function* deletePartnerGroup(action) {
  try {
    const response = yield call(API.deletePartnerGroup, action.payload);
    yield put({ type: DELETE_OCPI_PARTNER_GROUP.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_OCPI_PARTNER_GROUP.FAIL, payload: { error: e } });
  }
}

function* deletePartner(action) {
  try {
    const response = yield call(API.deletePartner, action.payload);
    yield put({ type: DELETE_OCPI_PARTNER.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_OCPI_PARTNER.FAIL, payload: { error: e } });
  }
}

function* allUsersInPartnerGroup(action) {
  try {
    const response = yield call(API.allUsersInPartnerGroup, action.payload);
    yield put({ type: ALL_USERS_IN_OCPI_PARTNER_GROUP.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: ALL_USERS_IN_OCPI_PARTNER_GROUP.FAIL, payload: { error: e } });
  }
}

function* addPartyInPartnerGroup(action) {
  try {
    const response = yield call(API.addPartyInPartnerGroup, action.payload);
    yield put({ type: ADD_PARTY_IN_OCPI_PARTNER_GROUP.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: ADD_PARTY_IN_OCPI_PARTNER_GROUP.FAIL, payload: { error: e } });
  }
}

function* createBulkUsersToPartnerGroup(action) {
  try {
    const response = yield call(API.createBulkUsersToPartnerGroup, action.payload);
    yield put({ type: CREATE_BULK_USERS_TO_OCPI_PARTNER_GROUP.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_BULK_USERS_TO_OCPI_PARTNER_GROUP.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* partnerGroup() {
  yield takeLatest(FETCH_OCPI_PARTNER_GROUP.REQUEST, getPartnerGroup);
  yield takeLatest(CREATE_OCPI_PARTNER_GROUP.REQUEST, createPartnerGroup);
  yield takeLatest(GET_OCPI_PARTNER_GROUP.REQUEST, getPartnerGroupById);
  yield takeLatest(DELETE_OCPI_PARTNER_GROUP.REQUEST, deletePartnerGroup);
  yield takeLatest(UPDATE_OCPI_PARTNER_GROUP.REQUEST, updatePartnerGroup);
  yield takeLatest(ALL_USERS_IN_OCPI_PARTNER_GROUP.REQUEST, allUsersInPartnerGroup);
  yield takeLatest(CREATE_BULK_USERS_TO_OCPI_PARTNER_GROUP.REQUEST, createBulkUsersToPartnerGroup);
  yield takeLatest(ADD_PARTY_IN_OCPI_PARTNER_GROUP.REQUEST, addPartyInPartnerGroup);
  yield takeLatest(DELETE_OCPI_PARTNER.REQUEST, deletePartner);
}

export default partnerGroup;
