import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useCallback, useEffect, useState } from 'react';
import AdminHeader from 'components/header/index';
import SessionHeading from 'components/general/SessionHeading';
import Card from 'components/inputs/Card';
import DropDown from 'components/inputs/DropDown';
import { AiFillCaretDown } from 'react-icons/ai';
import { BsFilter } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_CHARGER_LOG, FETCH_CHARGER_LOG, FETCH_USER_ACTIVITY } from 'actions/activityLog';
import ReactPagination from 'components/general/ReactPagination';
import _ from 'lodash';
import { Modal, Spinner } from 'react-bootstrap';
import DeleteModal from 'components/general/DeleteModal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';

const ChargerActivityDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allUserActivity = useSelector((state) => state.userActivity.userActivities);
  const isLoader = useSelector((state) => state.userActivity.isLoading);
  const page = useSelector((state) => state.userActivity.page);
  const limit = useSelector((state) => state.userActivity.limit);
  const totalData = useSelector((state) => state.userActivity.total);
  const totalPages = useSelector((state) => state.userActivity.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const [activityID, setActivityId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fieldValue, setFieldValue] = useState('');
  const [fieldLabel, setFiledLabel] = useState('All');

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setActivityId('');
  };

  const getUserActivity = useCallback((data) => {
    dispatch({ type: FETCH_USER_ACTIVITY.REQUEST, payload: data ? data : { sortBy: -1 } });
  });

  const getActivity = useCallback((data) => {
    dispatch({ type: FETCH_CHARGER_LOG.REQUEST, payload: data ? data : {} });
  });

  const deleteActivity = useCallback(
    () =>
      dispatch({
        type: DELETE_CHARGER_LOG.REQUEST,
        payload: activityID,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_CHARGER_LOG) {
            handleCloseDeleteModel();
            getActivity({ page });
          }
        },
      }),
    [activityID]
  );

  useEffect(() => {
    if (fieldValue) {
      const filterData = {
        type: fieldValue,
      };
      fieldValue === 'All' ? getUserActivity({ limit: 20, sortBy: -1 }) : getUserActivity({ ...filterData, limit: 20, sortBy: -1 });
    } else {
      getUserActivity({ sortBy: -1, limit: 20 });
    }
  }, [fieldValue]);

  const handlePageClick = (page) => {
    const data = {
      page: page.selected + 1,
      limit: limit || 20,
      sortBy: -1,
    };
    getUserActivity(data);
  };

  const filterData = [
    { label: 'RFID', value: 'rfid' },
    {
      label: 'All',
      value: 'All',
    },
  ];

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.activityLog')} />
        <div className="page-content-wrapper scrollable">
          <div className="charger-activity-log-page__main">
            {isLoader ? (
              <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
                <Card>
                  <div className="activity-content__block-main">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>
                                  <Skeleton height={15} width={100} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>
                                  <Skeleton height={15} width={100} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>
                                  <Skeleton height={15} width={100} />
                                </span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div>
                                <span>
                                  <Skeleton height={15} width={100} />
                                </span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div>
                                <span>
                                  <Skeleton height={15} width={100} />
                                  <br />
                                  <Skeleton height={10} width={80} />
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Skeleton height={15} width={100} />
                            </td>
                            <td>
                              <Skeleton height={15} width={100} />
                            </td>
                            <td>
                              <Skeleton height={15} width={100} />
                            </td>
                            <td>
                              <Skeleton height={15} width={100} />
                            </td>
                            <td>
                              <Skeleton height={15} width={100} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Card>
              </SkeletonTheme>
            ) : (
              <Card>
                <SessionHeading text="All Activity" className="activity-header--block">
                  <div className="session-heading-right-content">
                    <DropDown
                      popupClass="main-menu bottom scrollable"
                      trigger={
                        <>
                          <div className="filter-block">
                            <div className="filter-icon">
                              <BsFilter />
                              <span className="filter-label">{fieldLabel}</span>
                            </div>
                            <div className="arrow__icon">
                              <AiFillCaretDown />
                            </div>
                          </div>
                        </>
                      }
                      triggerClass="filter-dropdown"
                    >
                      {_.map(filterData, (item, index) => (
                        <ul key={`${index}`}>
                          <li
                            className="item"
                            onClick={() => {
                              setFiledLabel(item.label);
                              setFieldValue(item.value);
                            }}
                          >
                            {item.label}
                          </li>
                        </ul>
                      ))}
                    </DropDown>
                  </div>
                </SessionHeading>
                <div className="activity-content__block-main">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('chargerActivityDetail.srNo')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('chargerActivityDetail.userName')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('chargerActivityDetail.phoneNumber')}</span>
                            </div>
                          </th>
                          <th className="action-col">
                            <div>
                              <span>{t('chargerActivityDetail.message')}</span>
                            </div>
                          </th>
                          <th className="action-col">
                            <div>
                              <span>
                                {t('chargerActivityDetail.dateTime')}
                                <br />
                                {t('chargerActivityDetail.dateTimeFormat')}
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={5} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(allUserActivity) ? (
                          <tr>
                            <td colSpan={5} className="border-0">
                              <div className="empty-data-block">{t('chargerActivityDetail.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(allUserActivity, (activityLog, index) => {
                            const userName = _.get(activityLog, 'user.name') ? `${_.get(activityLog, 'user.name', '')}` : '';
                            const phoneNumber = _.get(activityLog, 'user.phone')
                              ? `+${_.get(activityLog, 'user.country_code', ' ')} ${_.get(activityLog, 'user.phone', ' ')}`
                              : '';
                            const message = _.get(activityLog, 'message') ? ` ${_.get(activityLog, 'message', '-')}` : '-';
                            const dateAndtiem = _.get(activityLog, 'createdAt', '-');
                            const s_no = limit * (page - 1) + index;
                            return (
                              <tr key={`user-activity-log-${_.get(activityLog, 'id')}`}>
                                <td>{s_no + 1}</td>
                                <td>{userName}</td>
                                <td>{phoneNumber}</td>
                                <td>{message}</td>
                                <td>{moment(dateAndtiem).utc(true).format('YYYY-MM-DD HH:mm:ss')}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allUserActivity) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                    )}
                  </div>

                  {showDeleteModal && (
                    <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                      <DeleteModal title="Activity" onClose={handleCloseDeleteModel} onRemove={deleteActivity} />
                    </Modal>
                  )}
                </div>
              </Card>
            )}
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default ChargerActivityDetail;
