import { FETCH_ROLE, GET_ROLE, CREATE_ROLE, UPDATE_ROLE, DELETE_ROLE, MANAGE_ROLE, GET_ROLE_BY_NAME } from 'actions/role';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchRole(action) {
  try {
    const response = yield call(API.getRole, action.payload);
    yield put({ type: FETCH_ROLE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_ROLE.FAIL, payload: { error: e } });
  }
}

function* getRoleById(action) {
  try {
    const response = yield call(API.getRoleById, action.payload);
    yield put({ type: GET_ROLE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_ROLE.FAIL, payload: { error: e } });
  }
}

function* createRole(action) {
  try {
    const response = yield call(API.addRole, action.payload);
    yield put({ type: CREATE_ROLE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_ROLE.FAIL, payload: { error: e } });
  }
}

function* updateRole(action) {
  try {
    const response = yield call(API.updateRole, action.payload);
    yield put({ type: UPDATE_ROLE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_ROLE.FAIL, payload: { error: e } });
  }
}

function* deleteRole(action) {
  try {
    const response = yield call(API.deleteRole, action.payload);
    yield put({ type: DELETE_ROLE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_ROLE.FAIL, payload: { error: e } });
  }
}

function* manageRole(action) {
  try {
    const response = yield call(API.manageRole, action.payload);
    yield put({ type: MANAGE_ROLE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: MANAGE_ROLE.FAIL, payload: { error: e } });
  }
}

function* getRoleByName(action) {
  try {
    const response = yield call(API.manageRole, action.payload);
    yield put({ type: GET_ROLE_BY_NAME.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_ROLE_BY_NAME.FAIL, payload: { error: e } });
  }
}

function* role() {
  yield takeLatest(FETCH_ROLE.REQUEST, fetchRole);
  yield takeLatest(GET_ROLE.REQUEST, getRoleById);
  yield takeLatest(CREATE_ROLE.REQUEST, createRole);
  yield takeLatest(UPDATE_ROLE.REQUEST, updateRole);
  yield takeLatest(DELETE_ROLE.REQUEST, deleteRole);
  yield takeLatest(MANAGE_ROLE.REQUEST, manageRole);
  yield takeLatest(GET_ROLE_BY_NAME.REQUEST, getRoleByName);
}
export default role;
