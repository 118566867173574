import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import Select from 'components/inputs/Select';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_ELECTRICITY_SUPPLIER, UPDATE_ELECTRICITY_SUPPLIER } from 'actions/electricitySupplier';
import { FETCH_CITY } from 'actions/address';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const ElectricitySupplierForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const electricitySupplierDetail = useSelector((state) => state.electricitySupplier.supplierDetail);
  const allCities = useSelector((state) => state.city.city);
  const page = useSelector((state) => state.city.page);
  const totalPage = useSelector((state) => state.city.totalPages);
  const isLoading = useSelector((state) => state.electricitySupplier.isLoading);

  const addElectricitySupplier = useCallback((data) => {
    dispatch({
      type: CREATE_ELECTRICITY_SUPPLIER.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateElectricitySupplier = useCallback((data) => {
    dispatch({
      type: UPDATE_ELECTRICITY_SUPPLIER.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const getAllCity = useCallback((data = {}) => {
    dispatch({ type: FETCH_CITY.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getAllCity();
  }, []);

  const ElectricitySupplierSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('electricitySupplierForm.name'))
      .strict(true)
      .trim(t('electricitySupplierForm.space'))
      .max(100, t('electricitySupplierForm.maxlimit'))
      .matches(/^[A-Za-z\s]+$/, t('electricitySupplierForm.matches')),
    rate: Yup.number().required(t('electricitySupplierForm.rate')).min(1, t('electricitySupplierForm.minrate')),
    city: Yup.string().required(t('electricitySupplierForm.city')),
  });

  const initialValues = isEdit
    ? { ...electricitySupplierDetail, city: _.get(electricitySupplierDetail, 'city.id', '') }
    : {
        name: '',
        rate: '',
        city: '',
      };
  return (
    <React.Fragment>
      <div className="electricitySupplier-form-page__main">
        <Card>
          <div className="electricitySupplier-form-header__block">
            <div className="electricitySupplier-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('electricitySupplierForm.title')}
              </span>
            </div>
            <div className="close--btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="electricitySupplier-form-body__block">
            <div className="electricitySupplier-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={ElectricitySupplierSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateElectricitySupplier(values);
                  } else {
                    addElectricitySupplier(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={12} md={12}>
                        <TextInput
                          isRequired
                          label={t('electricitySupplierForm.electricitySupplierName')}
                          placeholder={t('electricitySupplierForm.electricitySupplierName')}
                          name="name"
                          value={_.get(values, 'name', '')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                      <Col lg={6} md={12}>
                        <TextInput
                          isRequired
                          type="number"
                          label={t('electricitySupplierForm.rate')}
                          placeholder={t('electricitySupplierForm.rate')}
                          name="rate"
                          value={values.rate}
                          onChange={handleChange}
                          error={errors.rate && touched.rate ? errors.rate : null}
                        />
                      </Col>
                      <Col lg={6} md={12}>
                        <Select
                          isRequired
                          label={t('filters.city')}
                          options={_.map(allCities, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          placeholder={t('placeHolder.selectCity')}
                          name="city"
                          value={_.get(values, 'city', '')}
                          onChange={(val) => setFieldValue(`city`, val)}
                          onMenuScrollDown={true}
                          getDataOnScrollDown={getAllCity}
                          page={page}
                          totalPage={totalPage}
                          error={errors.city && touched.city ? errors.city : null}
                        />
                      </Col>
                    </Row>
                    <div className="form_btn---block">
                      <Button className="form---btn cancel__btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form---btn">
                        {isEdit ? 'Edit' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
ElectricitySupplierForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default ElectricitySupplierForm;
