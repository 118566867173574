import { FETCH_OEM_VENDOR, GET_OEM_VENDOR, CREATE_OEM_VENDOR, UPDATE_OEM_VENDOR, DELETE_OEM_VENDOR } from 'actions/oemVendor';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchOemVendor(action) {
  try {
    const response = yield call(API.getOemVendor, action.payload);
    yield put({ type: FETCH_OEM_VENDOR.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_OEM_VENDOR.FAIL, payload: { error: e } });
  }
}

function* getOemVendorById(action) {
  try {
    const response = yield call(API.getOemVendorById, action.payload);
    yield put({ type: GET_OEM_VENDOR.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_OEM_VENDOR.FAIL, payload: { error: e } });
  }
}

function* createOemVendor(action) {
  try {
    const response = yield call(API.addOemVendor, action.payload);
    yield put({ type: CREATE_OEM_VENDOR.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_OEM_VENDOR.FAIL, payload: { error: e } });
  }
}

function* updateOemVendor(action) {
  try {
    const response = yield call(API.updateOemVendor, action.payload);
    yield put({ type: UPDATE_OEM_VENDOR.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_OEM_VENDOR.FAIL, payload: { error: e } });
  }
}

function* deleteOemVendor(action) {
  try {
    const response = yield call(API.deleteOemVendor, action.payload);
    yield put({ type: DELETE_OEM_VENDOR.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_OEM_VENDOR.FAIL, payload: { error: e } });
  }
}

function* oemVendor() {
  yield takeLatest(FETCH_OEM_VENDOR.REQUEST, fetchOemVendor);
  yield takeLatest(GET_OEM_VENDOR.REQUEST, getOemVendorById);
  yield takeLatest(CREATE_OEM_VENDOR.REQUEST, createOemVendor);
  yield takeLatest(UPDATE_OEM_VENDOR.REQUEST, updateOemVendor);
  yield takeLatest(DELETE_OEM_VENDOR.REQUEST, deleteOemVendor);
}

export default oemVendor;
