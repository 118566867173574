import { FETCH_FAQ, GET_FAQ, CREATE_FAQ, UPDATE_FAQ, DELETE_FAQ } from 'actions/faq';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  faqs: [],
  faqDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const faqReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FAQ.REQUEST:
    case GET_FAQ.REQUEST:
    case CREATE_FAQ.REQUEST:
    case DELETE_FAQ.REQUEST: {
      return { ...state, isLoading: true, faqDetail: {} };
    }

    case UPDATE_FAQ: {
      return { ...state, isLoading: true };
    }

    case FETCH_FAQ.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        faqs: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_FAQ.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        faqDetail: payload,
      };
    }

    case CREATE_FAQ.SUCCESS: {
      toast.success(' FAQ added successfully');
      return {
        ...state,
        isLoading: false,
        faqs: _.concat(state.faqs, payload),
      };
    }

    case UPDATE_FAQ.SUCCESS: {
      toast.success(' FAQ updated successfully');
      return {
        ...state,
        faqs: state.faqs.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_FAQ.SUCCESS: {
      toast.success(' FAQ deleted successfully');
      return {
        ...state,
        isLoading: false,
        faqs: state.faqs.filter((data) => data.id !== payload),
      };
    }

    case FETCH_FAQ.FAIL:
    case GET_FAQ.FAIL:
    case CREATE_FAQ.FAIL:
    case UPDATE_FAQ.FAIL:
    case DELETE_FAQ.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default faqReducer;
