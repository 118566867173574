import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
const BookingModal = (props) => {
  const { onClose = _.noop(), bookingModel = _.noop(), title, subTitle } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="booking-modal-main_page">
        <div className="booking-modal--inner">
          <div className="booking-modal---block_title">
            <div className="inner--block_title">
              <div className="title">{subTitle} this booking</div>
              <p className="sub-title">Are you sure want to {title} booking?</p>
            </div>
          </div>
          <div className="booking-modal-input-box__block">
            <Row>
              <Col lg={6}>
                <Button className="booking-model--btn cancel--btn" onClick={onClose}>
                  {t('button.no')}
                </Button>
              </Col>
              <Col lg={6}>
                <Button className="booking-model--btn  remove--btn" onClick={bookingModel}>
                  {t('button.yes')}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
BookingModal.propTypes = {
  onClose: PropTypes.func,
  bookingModel: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
export default BookingModal;
