import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_NOTIFICATION, UPDATE_FIRMWARE_NOTIFICATION, DELETE_NOTIFICATION, PUSH_NOTIFICATION } from 'actions/notification';

function* fetchNotification(action) {
  try {
    const response = yield call(API.getNotification, action.payload);
    yield put({ type: FETCH_NOTIFICATION.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_NOTIFICATION.FAIL, payload: { error: e } });
  }
}

function* deleteNotification(action) {
  try {
    yield call(API.deleteNotification, action.payload);
    yield put({ type: DELETE_NOTIFICATION.SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_NOTIFICATION.FAIL, payload: { error: e } });
  }
}

function* updateFirmwareNotification(action) {
  try {
    const response = yield call(API.updateFirmwareNotification, action.payload);
    yield put({ type: UPDATE_FIRMWARE_NOTIFICATION.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_FIRMWARE_NOTIFICATION.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* pushNotification(action) {
  try {
    const response = yield call(API.pushNotification, action.payload);
    yield put({ type: PUSH_NOTIFICATION.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: PUSH_NOTIFICATION.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* notification() {
  yield takeLatest(FETCH_NOTIFICATION.REQUEST, fetchNotification);
  yield takeLatest(DELETE_NOTIFICATION.REQUEST, deleteNotification);
  yield takeLatest(PUSH_NOTIFICATION.REQUEST, pushNotification);
  yield takeLatest(UPDATE_FIRMWARE_NOTIFICATION.REQUEST, updateFirmwareNotification);
}
export default notification;
