import { post, downloadFile } from 'utils/api';
import { RATING_REVIEW, RATING_REVIEW_SUMMARY } from './endpointURLs';

export const getRating = (data) => {
  const page = data.page ? data.page : 1;
  const limit = data.limit ? data.limit : 10;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['limit'];
    delete data['sortBy'];
    delete data['search'];
    return post(`${RATING_REVIEW}?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}&limit=${parseInt(limit)}`, data);
  } else if (page && data.search) {
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['limit'];
    delete data['search'];
    return post(`${RATING_REVIEW}?page=${parseInt(page)}&search=${search}&limit=${parseInt(limit)}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['search'];
    delete data['sortBy'];
    return post(`${RATING_REVIEW}?search=${search}&sortBy=${sortBy}&limit=${parseInt(limit)}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    delete data['limit'];
    return post(`${RATING_REVIEW}?page=${parseInt(page)}&sortBy=${sortBy}&limit=${parseInt(limit)}`, data);
  } else if (data.search) {
    const search = encodeURIComponent(data.search);
    delete data['search'];
    return post(`${RATING_REVIEW}?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`${RATING_REVIEW}?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    delete data['limit'];
    return post(`${RATING_REVIEW}?page=${parseInt(page)}&limit=${parseInt(limit)}`, data);
  }
};

export const downloadRating = (data) => {
  if (data.search) {
    const search = encodeURIComponent(data.search);
    delete data['search'];
    return downloadFile(`${RATING_REVIEW}?search=${search}`, data);
  } else {
    return downloadFile(`${RATING_REVIEW}`, data);
  }
};
export const getRatingSummary = (data) => {
  const page = data.page ? data.page : 1;
  const limit = data.limit ? data.limit : 10;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['limit'];
    delete data['sortBy'];
    delete data['search'];
    return post(`${RATING_REVIEW_SUMMARY}?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}&limit=${parseInt(limit)}`, data);
  } else if (page && data.search) {
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['limit'];
    delete data['search'];
    return post(`${RATING_REVIEW_SUMMARY}?page=${parseInt(page)}&search=${search}&limit=${parseInt(limit)}`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['search'];
    delete data['sortBy'];
    return post(`${RATING_REVIEW_SUMMARY}?search=${search}&sortBy=${sortBy}&limit=${parseInt(limit)}`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    delete data['limit'];
    return post(`${RATING_REVIEW_SUMMARY}?page=${parseInt(page)}&sortBy=${sortBy}&limit=${parseInt(limit)}`, data);
  } else if (data.search) {
    const search = encodeURIComponent(data.search);
    delete data['search'];
    return post(`${RATING_REVIEW_SUMMARY}?search=${search}`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`${RATING_REVIEW_SUMMARY}?sortBy=${sortBy}`, data);
  } else {
    delete data['page'];
    delete data['limit'];
    return post(`${RATING_REVIEW_SUMMARY}?page=${parseInt(page)}&limit=${parseInt(limit)}`, data);
  }
};

export const downloadRatingSummary = (data) => {
  return downloadFile(`${RATING_REVIEW_SUMMARY}`, data);
};
export const ratingReview = {
  getRating,
  downloadRating,
  getRatingSummary,
  downloadRatingSummary,
};
