import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_CHARGER_LOG,
  FETCH_USER_ACTIVITY,
  DELETE_CHARGER_LOG,
  DOWNLOAD_CHARGER_LOG_DATA,
  DOWNLOAD_TABULAR_METERVALUE,
} from 'actions/activityLog';

function* fetchActivityLog(action) {
  try {
    const response = yield call(API.getActivityLog, action.payload);
    yield put({ type: FETCH_CHARGER_LOG.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CHARGER_LOG.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* fetchUserActivity(action) {
  try {
    const response = yield call(API.getUserActivity, action.payload);
    yield put({ type: FETCH_USER_ACTIVITY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_USER_ACTIVITY.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* deleteActivityLog(action) {
  try {
    yield call(API.deleteActivityLog, action.payload);
    yield put({ type: DELETE_CHARGER_LOG.SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_CHARGER_LOG.FAIL, payload: { error: e } });
  }
}

function* downloadChargerLog(action) {
  try {
    const response = yield call(API.downloadChargerLogData, action.payload);
    yield put({ type: DOWNLOAD_CHARGER_LOG_DATA.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CHARGER_LOG_DATA.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* downloadTabMeterValue(action) {
  try {
    const response = yield call(API.downloadTabularMeterValue, action.payload);
    yield put({ type: DOWNLOAD_TABULAR_METERVALUE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_TABULAR_METERVALUE.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* activityLog() {
  yield takeLatest(FETCH_CHARGER_LOG.REQUEST, fetchActivityLog);
  yield takeLatest(FETCH_USER_ACTIVITY.REQUEST, fetchUserActivity);
  yield takeLatest(DELETE_CHARGER_LOG.REQUEST, deleteActivityLog);
  yield takeLatest(DOWNLOAD_CHARGER_LOG_DATA.REQUEST, downloadChargerLog);
  yield takeLatest(DOWNLOAD_TABULAR_METERVALUE.REQUEST, downloadTabMeterValue);
}
export default activityLog;
