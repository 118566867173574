import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_CHARGER_BOOKING, DELETE_CHARGER_BOOKING, START_REMOTE, STOP_TRANSACTION, GET_CHARGER_BOOKING } from 'actions/chargerBooking';

function* getChargerBooking(action) {
  try {
    const response = yield call(API.getChargerBooking, action.payload);
    yield put({ type: FETCH_CHARGER_BOOKING.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CHARGER_BOOKING.FAIL, payload: { error: e } });
  }
}

function* getChargerBookingById(action) {
  try {
    const response = yield call(API.getChargerBookingById, action.payload);
    yield put({ type: GET_CHARGER_BOOKING.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGER_BOOKING.FAIL, payload: { error: e } });
  }
}

function* createStartRemote(action) {
  try {
    yield call(API.addStartRemote, action.payload);
    yield put({ type: START_REMOTE.SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: START_REMOTE.FAIL, payload: { error: e } });
  }
}
function* createStopTransaction(action) {
  try {
    const response = yield call(API.stopTransaction, action.payload);
    yield put({ type: STOP_TRANSACTION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: STOP_TRANSACTION.FAIL, payload: { error: e } });
  }
}

function* deleteChargerBooking(action) {
  try {
    yield call(API.deleteChargerBooking, action.payload);
    yield put({ type: DELETE_CHARGER_BOOKING.SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_CHARGER_BOOKING.FAIL, payload: { error: e } });
  }
}

function* chargerBooking() {
  yield takeLatest(FETCH_CHARGER_BOOKING.REQUEST, getChargerBooking);
  yield takeLatest(GET_CHARGER_BOOKING.REQUEST, getChargerBookingById);
  yield takeLatest(DELETE_CHARGER_BOOKING.REQUEST, deleteChargerBooking);
  yield takeLatest(START_REMOTE.REQUEST, createStartRemote);
  yield takeLatest(STOP_TRANSACTION.REQUEST, createStopTransaction);
}
export default chargerBooking;
