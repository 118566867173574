import { FETCH_CONNECTOR_TYPE, GET_CONNECTOR_TYPE, CREATE_CONNECTOR_TYPE, UPDATE_CONNECTOR_TYPE, DELETE_CONNECTOR_TYPE } from 'actions/connectorType';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchConnectorType(action) {
  try {
    const response = yield call(API.getConnectorType, action.payload);
    yield put({ type: FETCH_CONNECTOR_TYPE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CONNECTOR_TYPE.FAIL, payload: { error: e } });
  }
}

function* getConnectorTypeId(action) {
  try {
    const response = yield call(API.getConnectorTypeById, action.payload);
    yield put({ type: GET_CONNECTOR_TYPE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CONNECTOR_TYPE.FAIL, payload: { error: e } });
  }
}

function* createConnectorType(action) {
  try {
    const response = yield call(API.addConnectorType, action.payload);
    yield put({ type: CREATE_CONNECTOR_TYPE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CONNECTOR_TYPE.FAIL, payload: { error: e } });
  }
}

function* updateConnectorType(action) {
  try {
    const response = yield call(API.updateConnectorType, action.payload);
    yield put({ type: UPDATE_CONNECTOR_TYPE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CONNECTOR_TYPE.FAIL, payload: { error: e } });
  }
}

function* deleteConnectorType(action) {
  try {
    const response = yield call(API.deleteConnectorType, action.payload);
    yield put({ type: DELETE_CONNECTOR_TYPE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CONNECTOR_TYPE.FAIL, payload: { error: e } });
  }
}

function* connectorType() {
  yield takeLatest(FETCH_CONNECTOR_TYPE.REQUEST, fetchConnectorType);
  yield takeLatest(GET_CONNECTOR_TYPE.REQUEST, getConnectorTypeId);
  yield takeLatest(CREATE_CONNECTOR_TYPE.REQUEST, createConnectorType);
  yield takeLatest(UPDATE_CONNECTOR_TYPE.REQUEST, updateConnectorType);
  yield takeLatest(DELETE_CONNECTOR_TYPE.REQUEST, deleteConnectorType);
}

export default connectorType;
