import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_MODULE, UPDATE_MODULE } from 'actions/module';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const ModuleForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const modulesList = useSelector((state) => state.module.modules);
  const moduleDetail = useSelector((state) => state.module.moduleDetail);
  const isLoading = useSelector((state) => state.module.isLoading);

  const addModule = useCallback((data) => {
    dispatch({
      type: CREATE_MODULE.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateModule = useCallback((data) => {
    dispatch({
      type: UPDATE_MODULE.REQUEST,
      payload: data,
      cb: (res) => {
        try {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            onClose();
          }
        } catch (error) {
          console.log(error, 'errorrr');
        }
      },
    });
  }, []);

  const ModuleSchema = Yup.object().shape({
    name: Yup.string().required(t('moduleForm.name')).strict(true).trim(t('moduleForm.space')).max(100, t('moduleForm.maxlimit')),
  });

  const initialValues = isEdit
    ? { ...moduleDetail }
    : {
        name: '',
      };

  return (
    <React.Fragment>
      <div className="role-form-page__main">
        <Card>
          <div className="role-form-header__block">
            <div className="role-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('moduleForm.title')}
              </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </div>
          </div>
          <div className="role-form-body__block">
            <div className="role-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={ModuleSchema}
                onSubmit={(values, { setSubmitting }) => {
                  delete values.permissions;
                  delete values.excluded_tenants;
                  if (isEdit) {
                    updateModule(values);
                  } else {
                    addModule(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('moduleForm.moduleName')}
                          placeholder={t('moduleForm.moduleName')}
                          name="name"
                          value={_.get(values, 'name', '')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('moduleForm.key')}
                          placeholder={t('moduleForm.key')}
                          name="key"
                          value={_.get(values, 'key', '')}
                          onChange={handleChange}
                          error={errors.key && touched.key ? errors.key : null}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          label={t('moduleForm.position')}
                          placeholder={t('moduleForm.position')}
                          name="position"
                          value={_.get(values, 'position', '')}
                          onChange={handleChange}
                          error={errors.position && touched.position ? errors.position : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <Select
                          label={t('moduleForm.parent_module')}
                          options={_.map(modulesList, (module) => {
                            return { label: module.name, value: module.id };
                          })}
                          placeholder={t('placeHolder.selectModule')}
                          name="parentId"
                          value={values.parentId}
                          onChange={(val) => setFieldValue(`parentId`, val)}
                          error={errors.parentId && touched.parentId ? errors.parentId : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      <Button className="form_btn cancel____btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form_btn">
                        {isEdit ? 'Edit' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
ModuleForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default ModuleForm;
