import React from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
// import './downloadreports.css';
import GCMData from './GCMData';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import Footer from 'components/general/Footer';

const DownloadReports = () => {
  const { t } = useTranslation();

  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.gcmOldData')} />
        <div className="page-content-wrapper scrollable">
          <div className="maindiv">
            <h5>{t('downloadReports.title')}</h5>
            <div className="tablearea">
              <table>
                <thead>
                  <tr>
                    <th>{t('downloadReports.year')}</th>
                    <th>{t('downloadReports.month')}</th>
                    <th>{t('downloadReports.fileName')}</th>
                    <th>{t('downloadReports.action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {GCMData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.year}</td>
                      <td>{item.month}</td>
                      <td>{item.fileName}</td>
                      <td>
                        <a href={item.downloadLink}>{t('button.download')}</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </>
  );
};

export default DownloadReports;
