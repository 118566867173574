import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import InvoiceInfo from './InvoiceInfo';
import { BsDownload } from 'react-icons/bs';
import moment from 'moment';
import { useDispatch } from 'react-redux';
// import { FETCH_INVOICE, DOWNLOAD_INVOICE, FETCH_INVOICE_WIDGETS, DOWNLOAD_BULK_INVOICE } from 'actions/invoice';
import { FETCH_INVOICE, DOWNLOAD_INVOICE, FETCH_INVOICE_WIDGETS } from 'actions/invoice';
import fileDownload from 'js-file-download';
import * as _ from 'lodash';
import DateTimePicker from 'components/inputs/DateTimePicker';
import Button from 'components/inputs/Button';
// import './billing.css';
// import './billingwidgets.css';
import { FiMinimize2 } from 'react-icons/fi';
import { BiRupee } from 'react-icons/bi';
import { FiFileText } from 'react-icons/fi';
import SearchBox from 'components/general/SearchBox';
import { BsSearch } from 'react-icons/bs';
import { Spinner } from 'react-bootstrap';
import ReactPagination from 'components/general/ReactPagination';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
// import axios from 'axios';
// import JSZip from 'jszip';
// import { toast } from 'react-toastify';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const BillingInvoice = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAllowed } = useContext(NavContext);
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('month'));
  const isLoader = useSelector((state) => state.invoice.isLoading);
  const page = useSelector((state) => state.invoice.page);
  const totalData = useSelector((state) => state.invoice.total);
  const limit = useSelector((state) => state.invoice.limit);
  const totalPages = useSelector((state) => state.invoice.totalPages);
  const totaltransactions = useSelector((state) => state.invoice.totalTranstions);
  const totalamount = useSelector((state) => state.invoice.totalAmount);
  const totalgst = useSelector((state) => state.invoice.totalGst);
  const totalrevenue = useSelector((state) => state.invoice.totalRevenue);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');
  // const [downloading, setDownloading] = useState(false);

  const getInvoiceSearch = useCallback(
    (data) => {
      if (searchText) {
        const InvoiceData = {
          ...data,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: searchText,
        };
        dispatch({
          type: FETCH_INVOICE.REQUEST,
          payload: InvoiceData,
        });
      } else {
        const InvoiceData = {
          ...data,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: FETCH_INVOICE.REQUEST,
          payload: InvoiceData,
        });
      }
    },
    [searchText, startDate, endDate, dispatch]
  );

  useEffect(() => {
    getInvoiceSearch();
  }, []);

  const getInvoiceWidgets = useCallback(() => {
    const idata = {
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
    };
    dispatch({
      type: FETCH_INVOICE_WIDGETS.REQUEST,
      payload: idata,
    });
  }, [startDate, endDate, dispatch]);

  const getInvoice = useCallback((data = {}) => {
    dispatch({ type: FETCH_INVOICE.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getInvoiceWidgets();
  }, []);

  // useEffect(() => {
  //   const filterDateData = {
  //     from: moment(startDate).utc(),
  //     to: moment(endDate).utc(),
  //   };
  //   endDate && getInvoiceSearch(filterDateData);
  // }, [startDate, endDate]);

  const dateFilter = useCallback(() => {
    if (searchText) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        search: searchText,
      };
      getInvoiceSearch(filterDateData);
      getInvoiceWidgets(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      getInvoiceSearch(filterDateData);
      getInvoiceWidgets(filterDateData);
    }
  }, [startDate, endDate, getInvoiceSearch, searchText, getInvoiceWidgets]);

  const downloadinvoiceReport = useCallback(
    (data) => {
      if (searchText) {
        const InvoiceData = {
          ...data,
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: searchText,
        };
        dispatch({
          type: DOWNLOAD_INVOICE.REQUEST,
          payload: InvoiceData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Billing And Invoice'}.xlsx`);
              }
            }
          },
        });
      } else {
        const InvoiceData = {
          excel: true,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        dispatch({
          type: DOWNLOAD_INVOICE.REQUEST,
          payload: InvoiceData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Billing And Invoice'}.xlsx`);
              }
            }
          },
        });
      }
    },
    [searchText, startDate, endDate, dispatch]
  );

  const downloadFile = () => {
    downloadinvoiceReport();
  };

  const handleSearch = () => {
    getInvoiceSearch({}); // Call chargerStatus function with empty object to trigger search
  };

  const handlePageClick = useCallback(
    (page) => {
      if (searchText) {
        const data = {
          search: searchText,
          page: page.selected + 1,
        };
        getInvoiceSearch(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getInvoiceSearch(data);
      }
    },
    [searchText, getInvoiceSearch]
  );

  // const downloadBulkInvoice = useCallback(
  //   (data) => {
  //     setDownloading(true);
  //     const dateDiff = moment(endDate).diff(moment(startDate), 'days');
  //     if (dateDiff <= 6) {
  //       const InvoiceData = {
  //         ...data,
  //         from: moment(startDate).utc(),
  //         to: moment(endDate).utc(),
  //       };

  //       dispatch({
  //         type: DOWNLOAD_BULK_INVOICE,
  //         payload: InvoiceData,
  //         cb: downloadAndZipPDFs,
  //       });
  //     } else {
  //       toast.error('Date range exceeds 1 week. Please select a shorter date range.');
  //       setDownloading(false);
  //     }
  //   },
  //   [startDate, endDate]
  // );

  // const downloadAndZipPDFs = async (urls) => {
  //   const zip = new JSZip();

  //   try {
  //     let isEmptyResponse = true;
  //     for (const url of urls) {
  //       const response = await axios.get(url.pdfURL, { responseType: 'arraybuffer' });
  //       if (response.data.byteLength > 0) {
  //         isEmptyResponse = false;
  //       }
  //       const pdfname = url.invoice_no;
  //       zip.file(`${pdfname}.pdf`, response.data);
  //     }
  //     if (isEmptyResponse) {
  //       toast.error('No data found');
  //       setDownloading(false);
  //       return;
  //     }

  //     const blob = await zip.generateAsync({ type: 'blob' });

  //     const zipFileName = 'invoices.zip';

  //     const anchorElement = document.createElement('a');
  //     anchorElement.href = URL.createObjectURL(blob);
  //     anchorElement.download = zipFileName;
  //     anchorElement.click();
  //     toast.success('Invoices downloaded successfully!');
  //     setDownloading(false);
  //   } catch (error) {
  //     if (error.response && error.response.status === 500) {
  //       // Handle 500 status response
  //       toast.error('Internal Server Error: Failed to download or zip PDFs');
  //       setDownloading(false);
  //     } else {
  //       toast.error('Error downloading or zipping PDFs');
  //     }

  //     setDownloading(false);
  //   }
  // };

  // const downloadBulkFile = () => {
  //   downloadBulkInvoice();
  // };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.billingInvoicing')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable" style={{ padding: '1.2rem 0.5rem' }}>
            {isAllowed('viewWidgetsInvoicingReport') && (
              <div className="status-blocks">
                <div className="status-block">
                  <div className="breating-area">
                    {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <FiMinimize2 className="icon-inner-color" size={35} />}
                  </div>
                  <div className="status-info">
                    <h1>{isLoader ? <Skeleton height={15} width={100} /> : totaltransactions ? totaltransactions : '0'}</h1>
                    <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.totalTransactions')}</p>
                  </div>
                </div>

                <div className="status-block">
                  <div className="breating-area">
                    {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <BiRupee className="icon-inner-color" size={35} />}
                  </div>
                  <div className="status-info">
                    <h1>{isLoader ? <Skeleton height={15} width={100} /> : totalamount ? totalamount : '0'}</h1>
                    <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.totalAmount')}</p>
                  </div>
                </div>

                <div className="status-block">
                  <div className="breating-area">
                    {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <FiFileText className="icon-inner-color" size={35} />}
                  </div>
                  <div className="status-info">
                    <h1>{isLoader ? <Skeleton height={15} width={100} /> : totalgst ? totalgst : '0'}</h1>
                    <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.totalGST')}</p>
                  </div>
                </div>

                <div className="status-block">
                  <div className="breating-area">
                    {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <BiRupee className="icon-inner-color" size={35} />}
                  </div>
                  <div className="status-info">
                    <h1>{isLoader ? <Skeleton height={15} width={100} /> : totalrevenue ? totalrevenue : '0'}</h1>
                    <p>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.totalRevenue')}</p>
                  </div>
                </div>
              </div>
            )}
            <div className="billing-invoicing__main">
              <Card style={{ backgroundColor: 'red' }}>
                <div className="tab-block">
                  <Row>
                    <Col xl={3}>
                      <SearchBox
                        preIcon={<BsSearch />}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSearch();
                          }
                        }}
                      />
                    </Col>
                    <Col xl={5} lg={5} md={5}>
                      <DateTimePicker
                        onChangeOfStartDate={(item) => setStartDate(item)}
                        onChangeOfEndDate={(item) => setEndDate(item)}
                        initialValueOfStartDate={startDate}
                        initialValueOfEndDate={endDate}
                      />
                    </Col>
                    <Col xl={1} lg={1} md={2}>
                      <div className="invoice-search-excel-block">
                        <div className="invoice-search-box" onClick={dateFilter}>
                          <Button className="invoice-search-button">{t('button.search')}</Button>
                        </div>
                      </div>
                    </Col>
                    <Col xl={1} lg={1} md={1} className="excel-icon--block">
                      {isLoading ? (
                        <div className="loader--block">
                          <Spinner as="span" animation="border" size="sm" role="status" />
                        </div>
                      ) : (
                        isAllowed('downloadInvoicingReport') && (
                          <div className="report-excel-icon icon-pos">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} onClick={downloadFile} id="dwnbtn" />
                          </div>
                        )
                      )}
                    </Col>
                    {/* {isAllowed('downloadBulkInvoice') && (
                      <Col xl={2} lg={2} md={3}>
                        <div className="invoice-search-excel-block bulk_downlaod">
                          <div className="invoice-search-box" onClick={dateFilter}>
                            <Button id={'downloadButton'} className="invoice-search-button" onClick={downloadBulkFile}>
                              {downloading ? (
                                <>
                                  <span className="loader--block">
                                    {t('placeHolder.downloading')} &nbsp;
                                    <Spinner as="span" animation="border" size="sm" role="status" />
                                  </span>
                                </>
                              ) : (
                                t('placeHolder.bulkDownload')
                              )}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    )} */}
                  </Row>
                </div>
                <div className="tab-content-block">
                  <InvoiceInfo startingDate={startDate} endingDate={endDate} />
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && _.isEmpty(getInvoice) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};

const BillingInvoiceInfo = (props) => {
  const { icon, title, subtitle } = props;
  return (
    <div className="billing-info--box">
      <div className="billing-info-icon">{icon}</div>
      <div className="billing-info--wrapper">
        <span className="billing-title">{title}</span>
        <span className="billing-subtitle">{subtitle}</span>
      </div>
    </div>
  );
};

BillingInvoiceInfo.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
export default BillingInvoice;
