import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = ({ containerClassName, labelClassName, className, id, name, checked, children, ...rest }) => {
  return (
    <div className={`d-inline-block ${containerClassName ? containerClassName : ''}`}>
      <input type="checkbox" id={id} name={name} checked={checked} className={`form-check-input ${className ? className : ''}`} {...rest} />
      <label className={`form-check-label ${labelClassName ? labelClassName : ''}`} htmlFor={id}>
        {children}
      </label>
    </div>
  );
};

CheckBox.propTypes = {
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  children: PropTypes.node,
  containerClassName: PropTypes.string,
};

export default CheckBox;
