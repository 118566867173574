import React, { useState, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { BsDot, BsLightningCharge, BsPlug } from 'react-icons/bs';
import { MdOutlineWatchLater } from 'react-icons/md';
import { RiNumbersLine } from 'react-icons/ri';
//import { FiPhone } from 'react-icons/fi';
import { AiOutlineCar } from 'react-icons/ai';
import InformationBlock from 'components/general/InformationBlock';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
// import Button from 'components/inputs/Button';
import ContactPersonForm from './ContactPersonForm';
import moment from 'moment';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import { stationBlueMarker, stationGreenMarker, stationGreyMarker, stationRedMarker, privateStationMarker, powerlossStationMarker } from 'utils/Constants/images';
import { FiUser } from 'react-icons/fi';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { t } from 'i18next';

const General = () => {
  const [showForm, setShowForm] = useState(false);
  const [activeMarker, setActiveMarker] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const [center, setCenter] = useState({
    lat: 30.722475,
    lng: 76.6682639,
  });
  const chargingStationData = useSelector((state) => state.chargingStation.chargingStationDetail);
  // const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const isLoader = useSelector((state) => state.chargingStation.isLoading);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY, // Add your API key
  });

  const loadHandler = (map) => {
    setMapRef(map);
  };

  useEffect(() => {
    loadHandler();
  }, [mapRef, center, chargingStationData]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleCloseFormModel = () => {
    setShowForm(false);
  };

  // const getStationGeneral =
  //   !_.isEmpty(rolePermissionData) &&
  //   _.map(
  //     _.filter(rolePermissionData[0].permissions, (item) => item.name === 'getStationGeneral'),
  //     (data) => data.name
  //   );

  // const manageStationGeneral =
  //   !_.isEmpty(rolePermissionData) &&
  //   _.map(
  //     _.filter(rolePermissionData[0].permissions, (item) => item.name === 'manageStationGeneral'),
  //     (data) => data.name
  //   );

  return (
    <div className="general-details__main">
      {isLoader ? (
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="general-details--inner">
            <div className="general-details__block">
              <Row>
                <Col xl={3} md={6} className="general-details--wrapper">
                  <InformationBlock
                    infoText={<Skeleton height={15} width={100} />}
                    icon={<Skeleton circle="true" height={16} width={16} />}
                    infoTitle={<Skeleton height={15} width={100} />}
                  />
                </Col>
                <Col xl={3} md={6} className="general-details--wrapper">
                  <InformationBlock
                    infoText={<Skeleton height={15} width={100} />}
                    icon={<Skeleton circle="true" height={16} width={16} />}
                    infoTitle={<Skeleton height={15} width={100} />}
                  />
                </Col>
                <Col xl={3} md={6} className="general-details--wrapper">
                  <InformationBlock
                    infoText={<Skeleton height={15} width={100} />}
                    icon={<Skeleton circle="true" height={16} width={16} />}
                    infoTitle={<Skeleton height={15} width={100} />}
                  />
                </Col>
                <Col xl={3} md={6} className="general-details--wrapper">
                  <InformationBlock
                    infoText={<Skeleton height={15} width={100} />}
                    icon={<Skeleton circle="true" height={16} width={16} />}
                    infoTitle={<Skeleton height={15} width={100} />}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="general-info__main">
            <Row>
              <Col xl={3} xs={12} className="general-info__main-wrapper">
                <div className="operational-timing--block">
                  <div className="operational-timing-inner">
                    <div className="operation-timing-text">
                      <span className="timing-text-inner">
                        <Skeleton height={15} width={100} />
                      </span>
                    </div>

                    <div className="operation-timing__content">
                      <div className="operation-timing--icon">
                        <Skeleton circle="true" height={16} width={16} />
                      </div>
                      <div className="operation--time">
                        <span className="operation-time-text" style={{ display: 'flex', gap: '20' }}>
                          <Skeleton height={15} width={100} /> <Skeleton height={15} width={100} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xl={3} xs={12} className="general-info__main-wrapper">
                <div className="operational-timing--block">
                  <div className="operational-timing-inner">
                    <div className="operation-timing-text">
                      <span className="timing-text-inner">
                        <Skeleton height={15} width={100} />
                      </span>
                    </div>

                    <div className="operation-timing__content">
                      <div className="operation-timing--icon">
                        <Skeleton circle="true" height={16} width={16} />
                      </div>
                      <div className="operation--time">
                        <span className="operation-time-text">
                          <Skeleton height={15} width={100} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xl={6} xs={12} className="address__box">
                <div className="address__box--inner">
                  <div className="address-heading">
                    <Skeleton height={15} width={100} />
                  </div>
                  <div className="station-address">
                    <Skeleton height={15} />
                    <Skeleton height={15} />
                  </div>
                  <div className="station-map-img">
                    <Skeleton height={400} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </SkeletonTheme>
      ) : (
        <>
          <div className="general-details--inner">
            <div className="general-details__block">
              <Row>
                <Col xl={3} md={6} className="general-details--wrapper">
                  <InformationBlock
                    infoText={t('generalDetails.totalEnergy')}
                    icon={<BsLightningCharge />}
                    infoTitle={`${_.get(chargingStationData, 'energy_consumed', 0) / 1000} kWh`}
                  />
                </Col>
                <Col xl={3} md={6} className="general-details--wrapper">
                  <InformationBlock
                    infoText={t('generalDetails.totalCharger')}
                    icon={<BsPlug />}
                    infoTitle={`${_.get(chargingStationData, 'total_chargers', 0)}`}
                  />
                </Col>
                <Col xl={3} md={6} className="general-details--wrapper">
                  <InformationBlock
                    infoText={t('generalDetails.noofsessions')}
                    icon={<RiNumbersLine />}
                    infoTitle={_.get(chargingStationData, 'number_of_sessions', 0)}
                  />
                </Col>
                <Col xl={3} md={6} className="general-details--wrapper">
                  <InformationBlock
                    infoText={t('generalDetails.noOfEVCharged')}
                    icon={<AiOutlineCar />}
                    infoTitle={_.get(chargingStationData, 'total_ev_charged', 'NA')}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="general-info__main">
            <Row>
              <Col xl={3} xs={12} className="general-info__main-wrapper">
                <div className="operational-timing--block">
                  <div className="operational-timing-inner">
                    <div className="operation-timing-text">
                      <span className="timing-text-inner">{t('generalDetails.operationalTimings')}</span>
                    </div>
                    {_.map(chargingStationData.working_hours, (data, index) => {
                      return (
                        <div className="operation-timing__content" key={`station-timing-${index}`}>
                          <div className="operation-timing--icon">
                            <MdOutlineWatchLater />
                          </div>
                          <div className="operation--time">
                            <span className="operation-time-text">{_.startCase(_.get(data, 'day', ''))}</span>
                            <span className="operation-time-icon">
                              <BsDot />
                            </span>
                            <span className="operation-time-text">
                              {moment(_.get(data, 'from', ''), 'hh:mm A').format('LT')} - {moment(_.get(data, 'to', ''), 'hh:mm A').format('LT')}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>

              <Col xl={3} xs={12} className="general-info__main-wrapper">
                <div className="operational-timing--block">
                  <div className="operational-timing-inner">
                    <div className="operation-timing-text">
                      <span className="timing-text-inner">{t('generalDetails.pointOfContact')}</span>
                    </div>
                    {_.map(chargingStationData.working_hours, (data, index) => {
                      return (
                        <div className="operation-timing__content" key={`station-timing-${index}`}>
                          <div className="operation-timing--icon">
                            <FiUser />
                          </div>
                          <div className="operation--time">
                            <span className="operation-time-text">
                              {_.get(chargingStationData, 'contact_name', 'NA')} : {t('generalDetails.countryCode')}
                              {_.get(chargingStationData, 'contact_phone', 'NA')}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>

              <Col xl={6} xs={12} className="address__box">
                <div className="address__box--inner">
                  <div className="address-heading">Address</div>
                  <div className="station-address">
                    {_.get(chargingStationData, 'address', '')}, {_.get(chargingStationData, 'area', '')}, {_.get(chargingStationData, 'city', '')},{' '}
                    {_.get(chargingStationData, 'pincode', '')}, {_.get(chargingStationData, 'state', '')},{' '}
                    {_.get(chargingStationData, 'country', '')}
                  </div>
                  <div className="station-map-img">
                    {isLoaded && (
                      <GoogleMap
                        onLoad={loadHandler}
                        center={_.get(chargingStationData, 'location') ? _.get(chargingStationData, 'location') : center}
                        zoom={15}
                        onCenterChanged={() => mapRef && setCenter(_.get(mapRef, 'getCenter()').toJSON())}
                        mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '0.625rem' }}
                      >
                        <Marker
                          options={{
                            icon: {
                              url:
                                chargingStationData.access_type === 'Private'
                                  ? privateStationMarker
                                  : chargingStationData.status === 'Available'
                                  ? stationGreenMarker
                                  : chargingStationData.status === 'Unavailable' || chargingStationData.status === 'Under Maintenance'
                                  ? stationRedMarker
                                  : chargingStationData.status === 'Coming Soon'
                                  ? stationGreyMarker
                                  :chargingStationData.status === 'PowerLoss'
                                  ? powerlossStationMarker
                                  : chargingStationData.status === 'In Use' && stationBlueMarker,
                              scaledSize: { width: 27, height: 38 },
                            },
                          }}
                          key={_.get(chargingStationData, 'id', '')}
                          position={_.get(chargingStationData, 'location')}
                          onClick={handleActiveMarker}
                        >
                          {activeMarker ? (
                            <InfoWindow onCloseClick={() => setActiveMarker(false)}>
                              <div className="station-marker-text">{_.get(chargingStationData, 'name', '')}</div>
                            </InfoWindow>
                          ) : null}
                        </Marker>
                      </GoogleMap>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
      {showForm && (
        <Modal show={showForm} size="lg" centered>
          <ContactPersonForm onClose={handleCloseFormModel} />
        </Modal>
      )}
    </div>
  );
};

export default General;
