import React, { useState, useEffect, useCallback, useContext } from 'react';
import { BiPlug } from 'react-icons/bi';
//import { BsDot } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { GET_CHARGER } from 'actions/charger';
import { CHANGE_CHARGER_IS_ACTIVE_STATUS, FETCH_CHARGERS_LIST } from 'actions/charger';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_CHARGER_STATUS } from 'actions/charger';
import * as _ from 'lodash';
import { BsPlugin } from 'react-icons/bs';
import ReactPagination from 'components/general/ReactPagination';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaChargingStation } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { GENERATE_QR_CODE } from 'actions/generateQrCode';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
// import { saveAs } from 'file-saver';
import { FiCopy } from 'react-icons/fi';
import { Modal } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsDownload } from 'react-icons/bs';
import { AiOutlineScan } from 'react-icons/ai';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { useTranslation } from 'react-i18next';
// import { propTypes } from 'react-bootstrap/esm/Image';
import { useLocation } from 'react-router-dom';
const Chargers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { stationId } = useParams();
  const [switchTab, setSwitchTab] = useState('publish');
  // const getStationChargers = useSelector((state) => state.charger.stationChargers);
  const page = useSelector((state) => state.charger.page);
  const totalData = useSelector((state) => state.charger.total);
  const limit = useSelector((state) => state.charger.limit);
  const totalPages = useSelector((state) => state.charger.totalPages);
  const isLoader = useSelector((state) => state.chargingStation.isLoading);
  const getChargersList = useSelector((state) => state.charger.chargersList);
  const publishedChargers = _.filter(getChargersList, { is_active: true });
  const draftChargers = _.filter(getChargersList, { is_active: false });

  const getChargers = useCallback((data = {}) => {
    dispatch({ type: FETCH_CHARGERS_LIST.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getChargers({ id: stationId, sortBy: 'charger_id' });
  }, [stationId]);

  // const getChargerByStation = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_CHARGER_BY_STATION, payload: data });
  // }, []);

  // useEffect(() => {
  //   getChargerByStation({ id: stationId, sortBy: 'charger_id' });
  // }, [stationId]);

  const handlePageClick = useCallback(
    (page) => {
      const data = {
        id: stationId,
        sortBy: 'charger_id',
        page: page.selected + 1,
      };
      getChargers(data);
    },
    [stationId]
  );

  return (
    <div className="chargers-details__main">
      <div className="charger-details--tabs">
        {isLoader ? (
          <>
            <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
              <div style={{ display: 'flex' }} className={`{charger-status-tab }`} onClick={() => setSwitchTab('publish')}>
                <Skeleton height={15} width={100} />{' '}
                <span className="charger-count--badge">
                  {' '}
                  <Skeleton height={15} width={15} />
                </span>
              </div>
              <div style={{ display: 'flex' }} className={`{charger-status-tab}`} onClick={() => setSwitchTab('draft')}>
                <Skeleton height={15} width={100} />
                <span className="charger-count--badge draft-badge">
                  {' '}
                  <Skeleton height={15} width={15} />
                </span>
              </div>
            </SkeletonTheme>
          </>
        ) : (
          <>
            <div className={`charger-status-tab ${switchTab === 'publish' ? 'active' : ''}`} onClick={() => setSwitchTab('publish')}>
              {t('chargerDetail.published')} <span className="charger-count--badge">{_.size(publishedChargers)}</span>
            </div>
            <div className={`charger-status-tab ${switchTab === 'draft' ? 'active' : ''}`} onClick={() => setSwitchTab('draft')}>
              {t('chargerDetail.inDraft')}
              <span className="charger-count--badge draft-badge">{_.size(draftChargers)}</span>
            </div>
          </>
        )}
      </div>
      <div className="charger-list--block">
        {switchTab === 'publish' &&
          (_.isEmpty(publishedChargers) ? (
            <div className="empty-data-block">{t('chargerDetail.noData')}</div>
          ) : (
            _.map(publishedChargers, (charger, index) => {
              // Find the matching charger details in getChargersList
              const matchingChargerDetails = _.find(getChargersList, (item) => item.charger_id === charger.charger_id);
              return (
                <ChargerDataItem
                  key={`charger-${index}`}
                  isPublished
                  icon={<BiPlug />}
                  chargerId={charger._id}
                  chargerName={charger.charger_id}
                  is_enabled={_.get(charger, 'is_enabled')}
                  chargerStatus={_.get(charger, 'charger_status', '')}
                  getChargers={getChargers}
                  stationId={stationId}
                  chargerPlugs={_.map(_.get(charger, 'plugs', []), (item) => item)}
                  chargerCopy={charger.ocpp_url}
                  make={matchingChargerDetails ? _.get(matchingChargerDetails, 'make', '') : ''}
                  model={matchingChargerDetails ? _.get(matchingChargerDetails, 'model', '') : ''}
                  plugs={_.get(matchingChargerDetails, 'plugs', '')}
                  matchingChargerDetails={matchingChargerDetails}
                  is_external_charger={charger.is_external_charger}
                />
              );
            })
          ))}

        {switchTab === 'draft' &&
          (_.isEmpty(draftChargers) ? (
            <div className="empty-data-block">{t('chargerDetail.noData')}</div>
          ) : (
            _.map(draftChargers, (charger, index) => (
              <ChargerDataItem
                key={`charger-${index}`}
                icon={<BiPlug />}
                chargerId={charger._id}
                chargerName={charger.charger_id}
                is_enabled={_.get(charger, 'is_enabled')}
                chargerStatus={_.get(charger, 'charger_status', '')}
                getChargers={getChargers}
                stationId={stationId}
                chargerCopy={charger.ocpp_url}
                is_external_charger={charger.is_external_charger}
              />
            ))
          ))}
      </div>

      {!_.isEmpty(getChargersList) && (
        <ReactPagination
          currentPage={page}
          limit={limit}
          total={totalData}
          handlePageClick={(pageVal) => handlePageClick(pageVal)}
          totalPages={totalPages}
          marginPagesDisplayed={1}
        />
        // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
      )}
    </div>
  );
};

const ChargerDataItem = (props) => {
  const {
    isPublished,
    chargerId,
    is_enabled,
    chargerName,
    chargerStatus,
    getChargers,
    stationId,
    chargerPlugs,
    chargerCopy,
    make,
    model,
    plugs,
    is_external_charger,
  } = props;
  // const history = useHistory();
  const location = useLocation();
  const { navigateTo } = useContext(NavContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [qrCodeImage, setQrCodeImage] = useState('');
  const handleCloseQrCodeModal = () => setShowQrCodeModal(false);
  const isLoader = useSelector((state) => state.chargingStation.isLoading);
  let statusClassName = chargerStatus === 'Unavailable' ? 'disabled--text' : 'available--text',
    btnLabel,
    btnClassName;

  if (isPublished) {
    if (chargerStatus === 'In Use') {
      statusClassName = 'in-use--text';
      btnLabel = 'Stop';
      btnClassName = 'btn--red';
    } else if (is_enabled === false) {
      // statusClassName = 'disabled--text';
      btnLabel = 'Re-enable';
      btnClassName = 'btn--blue';
    }
  } else {
    btnLabel = 'Publish Now';
    btnClassName = 'btn--red';
  }

  const goToChargerDetail = () =>
    !is_external_charger ? navigateTo(`/charger/${chargerId}`, location.state?.backTo ? { backTo: 'chargerStatus' } : '') : '';
  const publishNow = useCallback(() => {
    const chargerData = {
      chargerId: chargerId,
      is_active: true,
    };
    dispatch({
      type: CHANGE_CHARGER_IS_ACTIVE_STATUS.REQUEST,
      payload: chargerData,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            getChargers({ id: stationId });
          }
        }
      },
    });
  }, [chargerId, stationId]);

  const changeStatus = useCallback(() => {
    const chargerData = {
      chargerId: chargerId,
      is_enabled: is_enabled !== true,
    };
    dispatch({
      type: CHANGE_CHARGER_STATUS.REQUEST,
      payload: chargerData,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            getChargers({ id: stationId });
          }
        }
      },
    });
  }, [is_enabled]);

  const handleClick = () => {
    if (btnLabel === 'Publish Now') {
      publishNow();
    }
    if (btnLabel === 'Re-enable') {
      changeStatus();
    }
  };

  const getQrCode = useCallback(() => {
    dispatch({
      type: GENERATE_QR_CODE.REQUEST,
      payload: chargerId,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            setQrCodeImage(res.data.image);
          }
        }
      },
    });
  }, []);

  useEffect(() => {
    if (copied) {
      toast.success('Copied!');
    }
  }, [copied]);

  const handleClickCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const downloadImage = (chargerName) => {
    const qrBlock = document.getElementById('qr_block');

    if (qrBlock) {
      // Set scale factor for high resolution
      const scaleFactor = 5; // Increase the value to improve quality (e.g., 2, 3, 4)

      // Get the dimensions of the qrBlock
      const qrBlockWidth = qrBlock.offsetWidth;
      const qrBlockHeight = qrBlock.offsetHeight;

      // Create a canvas element and set its dimensions with scale factor for high quality
      const canvas = document.createElement('canvas');
      canvas.width = qrBlockWidth * scaleFactor;
      canvas.height = qrBlockHeight * scaleFactor;

      // Get the context and scale it for high-quality rendering
      const context = canvas.getContext('2d');
      context.scale(scaleFactor, scaleFactor);

      // Set background to white for the canvas (to match original block)
      context.fillStyle = 'White';
      context.fillRect(0, 0, qrBlockWidth, qrBlockHeight);

      // Calculate the center of the canvas
      const centerX = qrBlockWidth / 2;

      // Get the text and image elements in the correct order
      const textElements = qrBlock.querySelectorAll('p');
      const img = qrBlock.querySelector('img');

      // Helper function to handle text wrapping
      const drawWrappedText = (context, text, x, y, maxWidth, lineHeight) => {
        const words = text.split(' ');
        let line = '';
        let yPosition = y;

        words.forEach((word) => {
          const testLine = line + word + ' ';
          const testWidth = context.measureText(testLine).width;
          if (testWidth > maxWidth) {
            context.fillText(line, x, yPosition);
            line = word + ' ';
            yPosition += lineHeight;
          } else {
            line = testLine;
          }
        });

        context.fillText(line, x, yPosition); // Draw the remaining text
      };

      // Ensure there are exactly two text elements
      if (textElements.length === 2 && img) {
        const maxTextWidth = qrBlockWidth; // Set some padding around the text
        // const lineHeight = 0; // Adjust the line height for wrapped text

        // Draw the first text element (Charger Id), centered and wrapped
        context.font = '1.2vw Arial'; // Adjust font size
        context.fillStyle = 'black'; // Set text color
        context.textAlign = 'center';
        context.fontWeight = '900';
        const firstTextY = 60; // Y position for the first text
        drawWrappedText(context, textElements[0].textContent, centerX, firstTextY, maxTextWidth);

        // Draw the image, centered, below the first text
        const imgX = centerX - img.width / 2;
        const imgY = firstTextY + 10; // Adjust this Y position based on the text's height
        context.drawImage(img, imgX, imgY, img.width, img.height);

        // Draw the second text element (Scan, Pay & Charge), centered and wrapped, below the image
        const secondTextY = imgY + img.height + 25; // Adjust Y position based on image height
        drawWrappedText(context, textElements[1].textContent, centerX, secondTextY, maxTextWidth);
      }

      // Convert the canvas to a high-quality image and download it
      canvas.toBlob(
        (blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `${chargerName || 'qr_code'}.jpg`;
          link.click();
        },
        'image/jpeg',
        1.0 // Set image quality (1.0 is the highest quality)
      );
    }
  };

  return (
    <div className="charger--list charger-status--info" onClick={goToChargerDetail}>
      <div className="list-info">
        {isLoader ? <Skeleton height={10} width={100} /> : <FaChargingStation color="#3c7cdd" />}
        <span className="list-name" title="OCPPID">
          {isLoader ? <Skeleton height={10} width={100} /> : chargerName}
        </span>
      </div>
      <div className="make-info">
        <span className="make-name" title="Charger Make Name">
          {isLoader ? <Skeleton height={10} width={100} /> : make}
        </span>
        <span className="model-name" title="Charger Model Name">
          {isLoader ? <Skeleton height={10} width={100} /> : model}
        </span>
      </div>
      <div className="charger-details--block">
        {/* {isPublished && (
          <div className={`charger-status--block ${statusClassName}`}>
            <div className={'charger-details--status'}>
              {is_enabled === false && (
                <>
                  <div className="list-dot-icon">
                    <BsDot color={'#a5b6c6'} />
                    <span className="disabled-list-text">{isLoader ? <Skeleton height={10} width={100} /> : 'Disabled'}</span>
                  </div>
                </>
              )}
              <div className="list-dot-icon">
                <BsDot />
                <span className="list-text">{isLoader ? <Skeleton height={10} width={100} /> : chargerStatus}</span>
              </div>
            </div>
          </div>
        )} */}

        <div className="plug-info--block">
          <div className="plug-info-wrapper">
            <div className="plug-info--inner">
              {_.map(chargerPlugs, (data, index) => {
                return (
                  <div key={index} className={`plug-info-content ${statusClassName}`}>
                    <div className={`charger-round_icon ${data.status}`}>
                      <BsPlugin />
                    </div>
                    <div>
                      <span>{isLoader ? <Skeleton height={10} width={100} /> : data.connector_id}</span>
                      <span className="ps-2">{isLoader ? <Skeleton height={10} width={100} /> : data.status}</span>
                      <div className="more-info" key={`rating-${index}`}>
                        <span className="more-info-charger" title="Connector Rating">
                          {isLoader ? (
                            <Skeleton height={10} width={100} />
                          ) : plugs[index] && plugs[index].rating ? (
                            plugs[index].rating.endsWith('kW') ? (
                              plugs[index].rating
                            ) : (
                              plugs[index].rating + ' kW'
                            )
                          ) : (
                            ''
                          )}
                        </span>
                        <span className="more-info-charger" title="Connector Type">
                          {isLoader ? (
                            <Skeleton height={10} width={100} />
                          ) : plugs[index] && plugs[index].connector_type ? (
                            plugs[index].connector_type
                          ) : (
                            ''
                          )}
                        </span>
                        <span className="more-info-charger" title="Plug Power Output Type">
                          {isLoader ? <Skeleton height={10} width={100} /> : plugs[index] && plugs[index].type ? plugs[index].type : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="charger-meta-info">
          <div className="copy-clipboard__block" onClick={(e) => e.stopPropagation()} title="Copy WebSocket URL">
            <CopyToClipboard onCopy={handleClickCopy} text={chargerCopy}>
              <button className="copy-btn">
                <FiCopy className="copy--icon" />
              </button>
            </CopyToClipboard>
          </div>
          <div
            title="Open Charger QR Code"
            className="qr-code--btn"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation(); // Prevent the click event from propagating to the parent div
              getQrCode();
              setShowQrCodeModal(true);
            }}
          >
            <AiOutlineScan size={22} />
          </div>
        </div>
      </div>
      <div className="charger-list--button">
        {btnLabel && (
          <Button onClick={handleClick} className={`charger-action--btn ${btnClassName}`}>
            {btnLabel}
          </Button>
        )}
      </div>
      {showQrCodeModal && (
        <Modal show={showQrCodeModal} centered onHide={handleCloseQrCodeModal}>
          <React.Fragment>
            <div className="qr-code-modal--main">
              <Card>
                <div className="qr-code__block">
                  <div className="qr-code-name">
                    <span> {t('chargerDetail.qrCode')} </span>
                  </div>
                  <div className="qr-code-close-btn">
                    <MdClose
                      size={30}
                      color="#be210b"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleCloseQrCodeModal();
                      }}
                    />
                  </div>
                </div>
                <div className="qrCode--img">
                  {qrCodeImage ? (
                    <div
                      style={{
                        background: 'white',
                        borderRadius: '15px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '1rem',
                      }}
                      id="qr_block"
                    >
                      <p style={{ color: 'Black', fontSize: '30px', textAlign: 'center', fontWeight: 700 }}>Charger ID : {chargerName}</p>
                      <img src={qrCodeImage} alt="" style={{ width: '350px', height: '350px', borderRadius: '15px' }} />
                      <p style={{ color: 'Black', fontSize: '30px', textAlign: 'center', fontWeight: 700 }}>Scan, Pay & Charge</p>
                    </div>
                  ) : (
                    <div className={'empty-qrCode'}>{t('chargerDetail.noQR')}</div>
                  )}
                </div>

                <div className="qrCode--button">
                  {qrCodeImage && (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        downloadImage(chargerName);
                      }}
                    >
                      <BsDownload title="Download" size={30} />
                    </Button>
                  )}
                </div>
              </Card>
            </div>
          </React.Fragment>
        </Modal>
      )}
    </div>
  );
};

ChargerDataItem.propTypes = {
  isPublished: PropTypes.bool,
  chargerName: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  chargerStatus: PropTypes.string,
  is_enabled: PropTypes.bool,
  chargerId: PropTypes.string,
  icon: PropTypes.node,
  getChargers: PropTypes.func,
  stationId: PropTypes.string,
  chargerPlugs: PropTypes.array,
  chargerCopy: PropTypes.string,
  plugs: PropTypes.object,
  is_external_charger: PropTypes.bool,
};

export default Chargers;
