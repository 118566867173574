import { GET_ALL_MODULES, UPDATE_MODULE } from 'actions/tenantAdminAccess';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* getAllModules(action) {
  try {
    const response = yield call(API.getAllModules, action.payload);
    yield put({ type: GET_ALL_MODULES.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_ALL_MODULES.FAIL, payload: { error: e } });
  }
}

function* updateModule(action) {
  try {
    const response = yield call(API.updateModule, action.payload);
    yield put({ type: UPDATE_MODULE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_MODULE.FAIL, payload: { error: e } });
  }
}

function* tenantAdminAccess() {
  yield takeLatest(GET_ALL_MODULES.REQUEST, getAllModules);
  yield takeLatest(UPDATE_MODULE.REQUEST, updateModule);
}
export default tenantAdminAccess;
