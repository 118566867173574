import { RATING_REVIEW, DOWNLOAD_RATING_REVIEW, RATING_REVIEW_SUMMARY, DOWNLOAD_RATING_REVIEW_SUMMARY } from '../actions/rating';

import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchRating(action) {
  try {
    const response = yield call(API.getRating, action.payload);
    yield put({ type: RATING_REVIEW.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RATING_REVIEW.FAIL, payload: { error: e } });
  }
}

function* downloadRatingReview(action) {
  try {
    const response = yield call(API.downloadRating, action.payload);
    yield put({ type: DOWNLOAD_RATING_REVIEW.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_RATING_REVIEW.FAIL, payload: { error: e } });
  }
}

function* fetchRatingSummary(action) {
  try {
    const response = yield call(API.getRatingSummary, action.payload);
    yield put({ type: RATING_REVIEW_SUMMARY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RATING_REVIEW_SUMMARY.FAIL, payload: { error: e } });
  }
}

function* downloadRatingReviewSummary(action) {
  try {
    const response = yield call(API.downloadRatingSummary, action.payload);
    yield put({ type: DOWNLOAD_RATING_REVIEW_SUMMARY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_RATING_REVIEW_SUMMARY.FAIL, payload: { error: e } });
  }
}

function* rating() {
  yield takeLatest(RATING_REVIEW.REQUEST, fetchRating);
  yield takeLatest(DOWNLOAD_RATING_REVIEW.REQUEST, downloadRatingReview);
  yield takeLatest(RATING_REVIEW_SUMMARY.REQUEST, fetchRatingSummary);
  yield takeLatest(DOWNLOAD_RATING_REVIEW_SUMMARY.REQUEST, downloadRatingReviewSummary);
}
export default rating;
