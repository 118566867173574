import { FETCH_CHARGE_SPEED, GET_CHARGE_SPEED, CREATE_CHARGE_SPEED, UPDATE_CHARGE_SPEED, DELETE_CHARGE_SPEED } from 'actions/chargeSpeed';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchChargeSpeed(action) {
  try {
    const response = yield call(API.getChargeSpeed, action.payload);
    yield put({ type: FETCH_CHARGE_SPEED.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CHARGE_SPEED.FAIL, payload: { error: e } });
  }
}

function* getChargeSpeedById(action) {
  try {
    const response = yield call(API.getChargeSpeedById, action.payload);
    yield put({ type: GET_CHARGE_SPEED.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGE_SPEED.FAIL, payload: { error: e } });
  }
}

function* createChargeSpeed(action) {
  try {
    const response = yield call(API.addChargeSpeed, action.payload);
    yield put({ type: CREATE_CHARGE_SPEED.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CHARGE_SPEED.FAIL, payload: { error: e } });
  }
}

function* updateChargeSpeed(action) {
  try {
    const response = yield call(API.updateChargeSpeed, action.payload);
    yield put({ type: UPDATE_CHARGE_SPEED.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CHARGE_SPEED.FAIL, payload: { error: e } });
  }
}

function* deleteChargeSpeed(action) {
  try {
    const response = yield call(API.deleteChargeSpeed, action.payload);
    yield put({ type: DELETE_CHARGE_SPEED.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CHARGE_SPEED.FAIL, payload: { error: e } });
  }
}

function* chargeSpeed() {
  yield takeLatest(FETCH_CHARGE_SPEED.REQUEST, fetchChargeSpeed);
  yield takeLatest(GET_CHARGE_SPEED.REQUEST, getChargeSpeedById);
  yield takeLatest(CREATE_CHARGE_SPEED.REQUEST, createChargeSpeed);
  yield takeLatest(UPDATE_CHARGE_SPEED.REQUEST, updateChargeSpeed);
  yield takeLatest(DELETE_CHARGE_SPEED.REQUEST, deleteChargeSpeed);
}
export default chargeSpeed;
