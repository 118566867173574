import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useLocation } from 'react-router';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { SHOW_SIDEBAR } from 'actions/sidebar';
import CZLogo from 'assets/images/icons/czLogoWhite.svg';
// import CZIconLogo from 'assets/images/icons/czIconWhite.svg';
import { cookie } from 'utils/cookies/cookies';
import { Link } from 'react-router-dom';
import { RealTimeData } from '../privateRoute/PrivateRoute';
import { NOTIFICATION, REMOVE_NOTIFICATION } from 'actions/notification';
import { FETCH_MASTER_SETTING } from 'actions/masterSetting';
// import { MANAGE_ROLE } from 'actions/role';
import { useTranslation } from 'react-i18next';
import { HiOutlineMenuAlt1 } from 'react-icons/hi';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { fetchSideBarMenu } from 'utils/sidebarConfig/sidebar-config';
// import { useParams } from 'react-router-dom';

const Sidebar = () => {
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const tenantSessionId = location.search.split('=')?.[1];
  const tenantSessionId = new URLSearchParams(location.search).get('tenant');
  // const tenantSessionId = params.get('tenant');
  // const searchParams = useParams();
  // const tenantSessionId = searchParams.get('tenant');
  const tenantSessionData = JSON.parse(localStorage.getItem(`${tenantSessionId}_tenant_data`));
  const currentUserRole = tenantSessionId !== undefined && tenantSessionData?.role !== undefined ? tenantSessionData?.role : cookie.get('user-role');

  const isShowSidebar = useSelector((state) => state.sidebar.showSidebar);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sideMenuData, setSideMenuData] = useState([]);
  const realTimeDataType = useContext(RealTimeData);
  const notification = useSelector((state) => state.notification.notificationCount);
  const allMasterSetting = useSelector((state) => state.masterSetting.masterSettings);
  const profileData = useSelector((state) => state.profile.userProfile);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const excludedModules = useSelector((state) => state.manageRole?.excludedModules);

  useEffect(() => {
    let sidebarData = fetchSideBarMenu(t, allMasterSetting);
    setSideMenuData(sidebarData);
  }, [JSON.stringify(rolePermissionData), currentUserRole]);

  const handleToggleSidebar = () => {
    dispatch({ type: SHOW_SIDEBAR.REQUEST });
  };

  React.useEffect(() => {
    dispatch({ type: FETCH_MASTER_SETTING.REQUEST });
  }, []);

  // React.useEffect(() => {
  //   const data = {
  //     name: currentUserRole,
  //   };
  //   dispatch({ type: MANAGE_ROLE, payload: data });
  // }, [currentUserRole]);

  React.useEffect(() => {
    // real-time Notification
    if (
      realTimeDataType &&
      realTimeDataType !== 'update_dashboard' &&
      realTimeDataType !== false &&
      !getActivePage(location.pathname, '/notification') === true
    ) {
      // setNotificationCount(notificationCount + 1);
      dispatch({ type: NOTIFICATION.REQUEST, payload: notification + 1 });
    }
  }, [realTimeDataType]);

  const gotoPage = (item) => {
    if (_.get(item, 'path') !== '') {
      navigateTo(item.path);
    }
  };

  const removeNotification = () => {
    dispatch({ type: REMOVE_NOTIFICATION.REQUEST });
  };

  React.useEffect(() => {
    if (getActivePage(location.pathname, '/notification') === true) {
      dispatch({ type: REMOVE_NOTIFICATION.REQUEST });
    }
  }, [location]);

  const getActivePage = (currentPage, pageItem) => {
    const currPageName = _.split(currentPage, '/')[1];
    const itemPageName = _.split(pageItem, '/')[1];
    return !!(currPageName && itemPageName && currPageName === itemPageName);
  };

  const getSideMenuByRole = useCallback(
    (arrData, role) => {
      const excludedModulesids = excludedModules.map((exMod) => exMod.id);
      const allowModulesKeys =
        rolePermissionData?.[0]?.modules?.filter((module) => !excludedModulesids.includes(module.id))?.map((module) => module.key) || [];

      return _.filter(arrData, (dataItem) => {
        if (dataItem?.showSuperAdmin && dataItem.accessUser.includes(role)) {
          return true;
        }
        return allowModulesKeys.includes(dataItem?.key);
      });
    },
    [JSON.stringify(rolePermissionData), JSON.stringify(excludedModules)]
  );

  return (
    <React.Fragment>
      <aside className="sidebar-main">
        <ProSidebar collapsed={isCollapsed} className="react-sidebar__main" breakPoint="md" toggled={isShowSidebar} onToggle={handleToggleSidebar}>
          <SidebarHeader className="sidebar-header--block">
            <Link to={tenantSessionId ? `/dashboard?tenant=${tenantSessionId}` : '/dashboard'}>
              {isCollapsed ? (
                <div className="small-sidebar-logo__block">
                  <div className="small-logo--img">
                    <HiOutlineMenuAlt1 size={50} color={'#ffffff'} />
                  </div>
                </div>
              ) : (
                <div className="sidebar__logo">
                  <div className="logo--img">
                    {profileData && profileData.tenant === null ? (
                      <img src={profileData?.tenant?.logo} alt="logo" />
                    ) : profileData?.tenant?.logo ? (
                      <img src={profileData?.tenant?.logo} alt="logo" />
                    ) : (
                      <img src={CZLogo} alt="logo" />
                    )}
                  </div>
                </div>
              )}
            </Link>
          </SidebarHeader>
          <SidebarContent className="sidebar-content--block scrollable">
            <Menu className="side-menu-bar">
              {_.map(getSideMenuByRole(sideMenuData, currentUserRole), (item, index) => {
                const activeMenu = getActivePage(location.pathname, _.get(item, 'path'));
                let subMenuPage;
                if (item.children) {
                  subMenuPage = _.find(item.children, { path: location.pathname });
                }
                if (item.children) {
                  return (
                    <SubMenu
                      key={`sub-menu-${item.key}-${index}`}
                      className="sidebar-menu-item sidebar-sub-menu-item"
                      title={item.label}
                      icon={item.icon}
                      defaultOpen={isShowSidebar && subMenuPage}
                    >
                      {_.map(getSideMenuByRole(item.children, currentUserRole), (subItem, subIndex) => {
                        const activeSubMenu = getActivePage(location.pathname, _.get(subItem, 'path'));
                        return subItem.children ? (
                          <SubMenu
                            key={`sub-menu-${subItem.key}-${subIndex}`}
                            className="sub-menu-inner-menu-item"
                            title={subItem.label}
                            icon={subItem.icon}
                            // defaultOpen={isShowSidebar && subMenuPage}
                          >
                            {_.map(getSideMenuByRole(subItem.children, currentUserRole), (subItem, subIndex) => {
                              const activeSubMenu = getActivePage(location.pathname, _.get(subItem, 'path'));
                              return (
                                <MenuItem
                                  key={`sub-menu-${subItem.key}-${subIndex}`}
                                  className={`sidebar-menu-item ${activeSubMenu ? 'active' : ''}`}
                                  onClick={() => gotoPage(subItem)}
                                >
                                  <Link to={tenantSessionId ? `${subItem.path}?tenant=${tenantSessionId}` : subItem.path} target="_self">
                                    {subItem.label}
                                  </Link>
                                </MenuItem>
                              );
                            })}
                          </SubMenu>
                        ) : (
                          <MenuItem
                            key={`sub-menu-${subItem.key}-${subIndex}`}
                            className={`sidebar-menu-item ${activeSubMenu ? 'active' : ''}`}
                            onClick={() => gotoPage(subItem)}
                          >
                            <Link to={tenantSessionId ? `${subItem.path}?tenant=${tenantSessionId}` : subItem.path} target="_self">
                              {subItem.label}
                            </Link>
                          </MenuItem>
                        );
                      })}
                    </SubMenu>
                  );
                } else {
                  return item.label === 'Notification' ? (
                    <MenuItem
                      key={`menu-${item.key}-${index}`}
                      className={`sidebar-menu-item ${activeMenu ? 'active' : ''}`}
                      icon={item.icon}
                      onClick={() => {
                        gotoPage(item);
                        removeNotification();
                      }}
                    >
                      <Link to={tenantSessionId ? `${item.path}?tenant=${tenantSessionId}` : item.path} target="_self">
                        {item.label}
                      </Link>
                      {notification > 0 && !activeMenu && <span className="notification--number"> {notification}</span>}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={`menu-${item.key}-${index}`}
                      className={`sidebar-menu-item ${activeMenu ? 'active' : ''}`}
                      icon={item.icon}
                      onClick={() => gotoPage(item)}
                    >
                      <Link to={tenantSessionId ? `${item.path}?tenant=${tenantSessionId}` : item.path} target="_self">
                        {item.label}
                      </Link>
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </SidebarContent>
          <SidebarFooter className="sidebar-footer--block">
            <div className="sidebar-footer--block__inner">
              <span className="custom-span" onClick={() => setIsCollapsed(!isCollapsed)}>
                <span className="chevron-icon" style={{ fontSize: 'large' }}>
                  {isCollapsed ? <BsChevronRight /> : <BsChevronLeft />}
                </span>
              </span>
            </div>
          </SidebarFooter>
        </ProSidebar>
      </aside>
    </React.Fragment>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
