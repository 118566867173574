import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import { Col, Row, Spinner } from 'react-bootstrap';
// import './table.css';
import { BiSort } from 'react-icons/bi';
import moment from 'moment';
import Card from 'components/inputs/Card';
import { BsDownload } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import ReactPagination from 'components/general/ReactPagination';
import { CAPACITY_UTILISATION, DOWNLOAD_CAPACITY_DATA } from 'actions/dataReport';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
// import DateTimePicker from 'components/inputs/DateTimePicker';
import DatePicker from 'components/inputs/DatePicker';
import fileDownload from 'js-file-download';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Footer from 'components/general/Footer';

const Capacityutilization = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [sortByItem, setSortByItem] = useState({ item: '', order: 'asc' });
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const capacityUtilisationsList = useSelector((state) => state.capacityUtilisation.CapacityUtilisation);
  const isLoader = useSelector((state) => state.capacityUtilisation.isLoading);
  const page = useSelector((state) => state.capacityUtilisation.page);
  const totalData = useSelector((state) => state.capacityUtilisation.total);
  const limit = useSelector((state) => state.capacityUtilisation.limit);
  const totalPages = useSelector((state) => state.capacityUtilisation.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getCapacityUtilisations = useCallback(
    (value) => {
      const data = {
        ...value,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        is_external_charger: false,
      };

      dispatch({
        type: CAPACITY_UTILISATION.REQUEST,
        payload: data,
      });
    },
    [startDate, endDate]
  );

  useEffect(() => {
    const data = {
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
    };
    getCapacityUtilisations(data);
  }, [startDate, endDate, sortByItem]);

  const downloadCapacityUtilisation = useCallback(() => {
    const data = {
      excel: true,
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
      is_external_charger: false,
    };
    dispatch({
      type: DOWNLOAD_CAPACITY_DATA.REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Time Utilization'}.xlsx`);
          }
        }
      },
    });
  }, [startDate, endDate, capacityUtilisationsList]);

  const downloadFile = () => {
    downloadCapacityUtilisation();
  };

  const getSortByItem = useCallback(
    (name, order, page) => {
      const sortedOrder = order || 'asc';
      if (startDate && endDate && page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          from: moment(startDate).utc().toDate(),
          to: moment(endDate).utc().toDate(),
        };
        endDate && getCapacityUtilisations(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        getCapacityUtilisations(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        getCapacityUtilisations(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        getCapacityUtilisations(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getCapacityUtilisations(data);
      }
    },
    [sortByItem, startDate, endDate]
  );

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.capacityUtilization')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col xl={'auto'} md={'auto'}>
                        <div className="data-report-date_picker">
                          <DatePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            defaultValueOfStartDate={moment().startOf('month')}
                            defaultValueOfEndDate={moment(new Date()).endOf('day')}
                            maxDateOfStartDate={moment(new Date()).startOf('day')}
                            maxDateOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>

                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>{isLoader ? <Skeleton height={15} width={100} /> : t('capacityUtilization.srNo')}</th>
                            <th onClick={() => handleSorting('station_name', sortByItem)}>
                              {isLoader ? <Skeleton height={15} width={100} /> : t('capacityUtilization.stationName')}
                              <BiSort size={15} className={'sicon'} />
                            </th>
                            <th onClick={() => handleSorting('charger_id', sortByItem)}>
                              {isLoader ? <Skeleton height={15} width={100} /> : t('capacityUtilization.chargerName')}
                              <BiSort size={15} className={'sicon'} />
                            </th>
                            <th onClick={() => handleSorting('total_usage', sortByItem)}>
                              {isLoader ? <Skeleton height={15} width={100} /> : t('capacityUtilization.utilized')}
                              <BiSort size={15} className={'sicon'} />
                            </th>
                            <th>{isLoader ? <Skeleton height={15} width={100} /> : t('capacityUtilization.charger_availability')}</th>
                            <th>{isLoader ? <Skeleton height={15} width={100} /> : t('capacityUtilization.installation_date')}</th>
                            <th>{isLoader ? <Skeleton height={15} width={100} /> : t('capacityUtilization.time_utilization')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={11} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(capacityUtilisationsList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('capacityUtilization.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(capacityUtilisationsList, (item, key) => {
                              const serial_num = limit * (page - 1) + key + 1; // Start serial_num from 1

                              return (
                                <tr key={`time-utilization-report-${item.charger_id}-${serial_num}`}>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'station_name', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger_id', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'total_usage', '-')}</td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger_availabilty', '-')}</td>
                                  <td>
                                    {isLoader ? (
                                      <Skeleton height={15} width={100} />
                                    ) : _.get(item, 'installation_date', '') === '' ? (
                                      '-'
                                    ) : (
                                      _.get(item, 'installation_date', '-')
                                    )}
                                  </td>
                                  <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'time_utilization', '-')}</td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(capacityUtilisationsList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};

export default Capacityutilization;
