import { get, post, patch, deleteRequest } from 'utils/api';

export const getOemVendor = (data) => {
  return get(`/oem_vendor`, data);
};

export const getOemVendorById = (id) => {
  return get(`/oem_vendor/${id}`);
};
export const addOemVendor = (data) => {
  return post(`/oem_vendor`, data);
};

export const updateOemVendor = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/oem_vendor/${id}`, data);
};

export const deleteOemVendor = (id) => {
  return deleteRequest(`/oem_vendor/${id}`);
};

export const oemVendor = {
  getOemVendor,
  getOemVendorById,
  addOemVendor,
  updateOemVendor,
  deleteOemVendor,
};
