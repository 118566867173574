import React, { useState, useEffect } from 'react';
import CZLogo from 'assets/images/icons/czLogoWhite.svg';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextInput from 'components/inputs/Input';
// import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
// import * as _ from 'lodash';
import Button from 'components/inputs/Button';
// import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LOGIN } from 'actions/authAction';
import { Token } from '../../firebase';
import PropTypes from 'prop-types';
import 'yup-phone-lite';
import { useTranslation } from 'react-i18next';
import { FiEye } from 'react-icons/fi';
import { FiEyeOff } from 'react-icons/fi';

const Login = () => {
  const [firebaseToken, setFirebaseToken] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await Token(setFirebaseToken);
      return data;
    }
    tokenFunc();
  }, [setFirebaseToken]);

  return (
    <main className="login-page__main">
      <div className="login-bg-block">
        <div className="login-content-block">
          <div className="logo-block">
            <div className="logo--img">
              <img src={CZLogo} alt="chargeZone" />
            </div>
          </div>
          <div className="page-block__main">
            <div className="title">{t('loginForm.title')}</div>
            <p className="sub-title">Welcome to {t('brand-name')}</p>
            <div className="login-tab">
              <div className="tab-block">
                <div className="tab-content-block">
                  <div className="tab-content__item active" id="login-tab">
                    {<LoginPage firebaseToken={firebaseToken} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

const LoginPage = (props) => {
  const { firebaseToken } = props;
  // const [phoneError, setPhoneError] = useState('');
  // const [countryCode, setCountryCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const getCountryCode = _.get(parsePhoneNumber(countryCode), 'countryCallingCode', '');
  // const getPhoneNumber = _.get(parsePhoneNumber(countryCode), 'nationalNumber', '');

  const loginSchema = Yup.object({
    // phone: Yup.string().required(t('loginForm.phone')).phone('IN', t('loginForm.phoneChk')),
    password: Yup.string().required(t('loginForm.password')).trim(t('loginForm.space')).strict(true),
    // country_code: Yup.string().required(t('loginForm.countryCode')),
    username: Yup.string().required(t('loginForm.username')),
  });

  return (
    <div className="login-form__block">
      <Formik
        initialValues={{ password: '', username: '' }}
        validationSchema={loginSchema}
        onSubmit={(values, { setSubmitting }) => {
          // if (!isValidPhoneNumber(values.phone)) {
          //   setPhoneError('Phone number is not valid!');
          // } else {
          if (firebaseToken) {
            const data = { ...values, loginwith: 'password', web_token: firebaseToken };
            dispatch({ type: LOGIN.REQUEST, payload: data });
          } else {
            const data = { ...values, loginwith: 'password' }; //, country_code: getCountryCode, phone: getPhoneNumber
            dispatch({ type: LOGIN.REQUEST, payload: data });
          }
          // }

          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form-block--wrapper">
              <div className="form--content">
                {/* <PhoneNumberInput
                  label={t('loginForm.phoneNo')}
                  name="phone"
                  value={values.phone}
                  onChange={(val) => {
                    setFieldValue(`phone`, val);
                    setCountryCode(val || '');
                    if (!_.isEmpty(phoneError)) {
                      setPhoneError('');
                    }
                  }}
                  error={errors.phone && touched.phone ? errors.phone : phoneError ? phoneError : null}
                /> */}
                <TextInput
                  label={t('loginForm.userName')}
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  error={errors.username && touched.username ? errors.username : null}
                />
                <div className="login-password-eye">
                  <TextInput
                    label={t('loginForm.passwordLabel')}
                    name="password"
                    type={showPassword ? 'text' : 'password'} // Toggle type based on showPassword state
                    value={values.password}
                    onChange={handleChange}
                    error={errors.password && touched.password ? errors.password : null}
                  />

                  <div
                    type="button"
                    className="password-toggle-button"
                    onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                  >
                    {showPassword ? <FiEye /> : <FiEyeOff />}
                  </div>
                </div>
              </div>

              <div className="login-button--block">
                <Button type="submit" className="login--btn" disabled={isSubmitting}>
                  {t('button.login')}
                </Button>
              </div>
              <div className="forgot-password-link--block">
                <Link to={'/forgotPassword'}>{t('button.forgotPassword')}</Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

LoginPage.propTypes = {
  firebaseToken: PropTypes.string,
};

export default Login;
