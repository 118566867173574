import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { CREATE_SCHEDULE, FETCH_SCHEDULE, DELETE_SCHEDULE, UPDATE_SCHEDULE } from 'actions/schedule';

function* fetchSchedule(action) {
  try {
    const response = yield call(API.fetchSchedule, action.payload);
    yield put({ type: FETCH_SCHEDULE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_SCHEDULE.FAIL, payload: { error: e } });
  }
}

function* createSchedule(action) {
  try {
    const response = yield call(API.createSchedule, action.payload);
    yield put({ type: CREATE_SCHEDULE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_SCHEDULE.FAIL, payload: { error: e } });
  }
}

function* updateSchedule(action) {
  try {
    const response = yield call(API.updateSchedule, action.payload);
    yield put({ type: UPDATE_SCHEDULE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_SCHEDULE.FAIL, payload: { error: e } });
  }
}

function* deleteYourSchedule(action) {
  try {
    const response = yield call(API.deleteYourSchedule, action.payload);
    yield put({ type: DELETE_SCHEDULE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_SCHEDULE.FAIL, payload: { error: e } });
  }
}

function* schedule() {
  yield takeLatest(FETCH_SCHEDULE.REQUEST, fetchSchedule);
  yield takeLatest(CREATE_SCHEDULE.REQUEST, createSchedule);
  yield takeLatest(DELETE_SCHEDULE.REQUEST, deleteYourSchedule);
  yield takeLatest(UPDATE_SCHEDULE.REQUEST, updateSchedule);
}

export default schedule;
