import {
  GET_ELECTRICITY_BILL,
  DELETE_ELECTRICITY_BILL,
  UPDATE_ELECTRICITY_BILL,
  UPLOAD_ELECTRICITY_BILL,
  DOWNLOAD_ELECTRICITY_BILL,
} from 'actions/electricityBillReport';

const initialState = {
  isLoading: false,
  electricityBillReports: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const electricityBillReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ELECTRICITY_BILL.REQUEST:
    case DELETE_ELECTRICITY_BILL.REQUEST:
    case UPDATE_ELECTRICITY_BILL.REQUEST:
    case UPLOAD_ELECTRICITY_BILL.REQUEST:
    case DOWNLOAD_ELECTRICITY_BILL.REQUEST: {
      return { ...state, isLoading: true };
    }

    case GET_ELECTRICITY_BILL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        electricityBillReports: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case DELETE_ELECTRICITY_BILL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        partnerGroups: state.electricityBillReports.filter((data) => data.id !== payload),
      };
    }

    case UPDATE_ELECTRICITY_BILL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        partnerGroups: state.electricityBillReports.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case UPLOAD_ELECTRICITY_BILL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        partnerGroups: state.electricityBillReports.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DOWNLOAD_ELECTRICITY_BILL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        partnerGroups: state.electricityBillReports.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case GET_ELECTRICITY_BILL.FAIL:
    case DELETE_ELECTRICITY_BILL.FAIL:
    case UPDATE_ELECTRICITY_BILL.FAIL:
    case UPLOAD_ELECTRICITY_BILL.FAIL:
    case DOWNLOAD_ELECTRICITY_BILL.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default electricityBillReportReducer;
