import React from 'react';
import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { promiseMiddleware } from '@adobe/redux-saga-promise';
import { thunk } from 'redux-thunk';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'reducers/index';
import rootSaga from 'sagas/index';
import PropTypes from 'prop-types';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(promiseMiddleware, thunk, sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

const Store = ({ children }) => <Provider store={store}>{children}</Provider>;
Store.propTypes = {
  children: PropTypes.node,
};

export const browserHistory = createBrowserHistory();

export default Store;
