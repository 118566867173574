import { FETCH_SMS, GET_SMS, CREATE_SMS, UPDATE_SMS, DELETE_SMS } from 'actions/sms';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchSms(action) {
  try {
    const response = yield call(API.getSms, action.payload);
    yield put({ type: FETCH_SMS.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_SMS.FAIL, payload: { error: e } });
  }
}

function* getSmsById(action) {
  try {
    const response = yield call(API.getSmsById, action.payload);
    yield put({ type: GET_SMS.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_SMS.FAIL, payload: { error: e } });
  }
}

function* createSms(action) {
  try {
    const response = yield call(API.addSms, action.payload);
    yield put({ type: CREATE_SMS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_SMS.FAIL, payload: { error: e } });
  }
}

function* updateSms(action) {
  try {
    const response = yield call(API.updateSms, action.payload);
    yield put({ type: UPDATE_SMS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_SMS.FAIL, payload: { error: e } });
  }
}

function* deleteSms(action) {
  try {
    const response = yield call(API.deleteSms, action.payload);
    yield put({ type: DELETE_SMS.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_SMS.FAIL, payload: { error: e } });
  }
}

function* sms() {
  yield takeLatest(FETCH_SMS.REQUEST, fetchSms);
  yield takeLatest(GET_SMS.REQUEST, getSmsById);
  yield takeLatest(CREATE_SMS.REQUEST, createSms);
  yield takeLatest(UPDATE_SMS.REQUEST, updateSms);
  yield takeLatest(DELETE_SMS.REQUEST, deleteSms);
}
export default sms;
