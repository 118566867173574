import { get, post, patch, deleteRequest } from 'utils/api';

export const getOwnershipType = (data) => {
  return get('/ownership_type', data);
};

export const getOwnershipTypeById = (id) => {
  return get(`/ownership_type/${id}`);
};
export const addOwnershipType = (data) => {
  return post(`/ownership_type`, data);
};

export const updateOwnershipType = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/ownership_type/${id}`, data);
};

export const deleteOwnershipType = (id) => {
  return deleteRequest(`/ownership_type/${id}`);
};

export const ownershipType = {
  getOwnershipType,
  getOwnershipTypeById,
  addOwnershipType,
  updateOwnershipType,
  deleteOwnershipType,
};
