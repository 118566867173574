import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import SessionHeading from 'components/general/SessionHeading';
import ActivityItem from 'components/general/ActivityItem';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import { DELETE_FAQ, FETCH_FAQ, GET_FAQ } from 'actions/faq';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import FaqForm from '../faq/FaqForm';
import { Modal, Spinner } from 'react-bootstrap';
import DeleteModal from 'components/general/DeleteModal';
import Button from 'components/inputs/Button';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { t } from 'i18next';

const Faq = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [faqId, setFaqId] = useState();

  const page = useSelector((state) => state.faq.page);
  const totalData = useSelector((state) => state.faq.total);
  const limit = useSelector((state) => state.faq.limit);
  const totalPages = useSelector((state) => state.faq.totalPages);
  const faqsList = useSelector((state) => state.faq.faqs);
  const isLoader = useSelector((state) => state.faq.isLoading);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setFaqId('');
  };

  const deleteFaq = useCallback(
    () =>
      dispatch({
        type: DELETE_FAQ.REQUEST,
        payload: faqId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getFaq({ page });
          }
        },
      }),
    [faqId]
  );

  const getFaq = useCallback((data = {}) => {
    dispatch({ type: FETCH_FAQ.REQUEST, payload: data });
  }, []);

  const getFaqDetail = useCallback((id) => {
    dispatch({ type: GET_FAQ.REQUEST, payload: id });
  }, []);

  useEffect(() => {
    getFaq();
  }, []);

  const handlePageClick = (page) => {
    const data = {
      page: page.selected + 1,
    };
    getFaq(data);
  };

  const handleCloseFormFaq = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.faq')} />
        <div className="page-content-wrapper scrollable">
          <div className="faq-page__main">
            <Card>
              <SessionHeading text="FAQs" className="activity-header--block">
                <div className="page-top--block">
                  <Button
                    onClick={() => {
                      setShowForm(true);
                      setIsEdit(false);
                    }}
                  >
                    {t('button.addFAQ')}
                  </Button>
                </div>
              </SessionHeading>
              <div className="page-list__block-main">
                {isLoader ? (
                  <div className="loader--block">
                    <Spinner as="span" animation="border" size="sm" role="status" />
                    <span className="ps-2">{t('placeHolder.loading')}</span>
                  </div>
                ) : _.isEmpty(faqsList) ? (
                  <div className="empty-data-block">{t('faqForm.noFAQS')}</div>
                ) : (
                  !(isLoader || _.isUndefined(isLoader)) && (
                    <div>
                      <div className="list-item--block">
                        {_.map(faqsList, (item, index) => (
                          <ActivityItem
                            key={`FAQs-${index}`}
                            text={
                              <>
                                <div className="mb-2">
                                  <span className="meta-info">{_.get(item, 'title', '')}</span>
                                </div>
                                <div className="meta-info">
                                  <span>{_.get(item, 'message', '')}</span>
                                </div>
                              </>
                            }
                            dropdownList={
                              <>
                                <li
                                  className="item"
                                  onClick={() => {
                                    setShowForm(true);
                                    getFaqDetail(_.get(item, 'id', ''));
                                    setIsEdit(true);
                                  }}
                                >
                                  {t('faqForm.editFAQ')}
                                </li>
                                <li
                                  className="item"
                                  onClick={() => {
                                    setShowDeleteModal(true);
                                    setFaqId(_.get(item, 'id', ''));
                                  }}
                                >
                                  {t('faqForm.deleteFAQ')}
                                </li>
                              </>
                            }
                          />
                        ))}
                      </div>
                      {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(faqsList) && (
                        <ReactPagination
                          currentPage={page}
                          limit={limit}
                          total={totalData}
                          handlePageClick={(pageVal) => handlePageClick(pageVal)}
                          totalPages={totalPages}
                          marginPagesDisplayed={1}
                        />
                      )}
                      {/* <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} /> */}
                    </div>
                  )
                )}
              </div>
              {showForm && (
                <Modal show={showForm} size="lg" centered>
                  <FaqForm onClose={handleCloseFormFaq} isEdit={isEdit} onSuccess={getFaq} />
                </Modal>
              )}
              {showDeleteModal && (
                <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                  <DeleteModal title="FAQs" onClose={handleCloseDeleteModel} onRemove={() => deleteFaq(faqId)} />
                </Modal>
              )}
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default Faq;
