import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { LOGIN, FORGOT_PASSWORD, LOGIN_WITH_OTP, CREATE_NEW_PASSWORD, TENANT_ADMIN_LOGIN, TENANT_ADMIN_LOGIN_AS } from 'actions/authAction';
import { CHANGE_PASSWORD } from 'actions/authAction';

function* login(action) {
  try {
    const response = yield call(API.login, action.payload);
    yield put({ type: LOGIN.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: LOGIN.FAIL, payload: { error: e } });
  }
}

function* forgotPassword(action) {
  try {
    const response = yield call(API.forgotPassword, action.payload);
    yield put({ type: FORGOT_PASSWORD.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FORGOT_PASSWORD.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* changePassword(action) {
  try {
    const response = yield call(API.changePassword, action.payload);
    yield put({ type: CHANGE_PASSWORD.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHANGE_PASSWORD.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* loginWithOtp(action) {
  try {
    const response = yield call(API.login, action.payload);
    yield put({ type: LOGIN_WITH_OTP.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: LOGIN_WITH_OTP.FAIL, payload: { error: e } });
  }
}

function* createNewPassword(action) {
  try {
    const response = yield call(API.resetPassword, action.payload);
    yield put({ type: CREATE_NEW_PASSWORD.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_NEW_PASSWORD.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* tenantAdminLogin(action) {
  try {
    const response = yield call(API.tenantAdminLogin, action.payload);
    yield put({ type: TENANT_ADMIN_LOGIN.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: TENANT_ADMIN_LOGIN.FAIL, payload: { error: e } });
  }
}

function* tenantAdminLoginAsSupport(action) {
  try {
    const response = yield call(API.tenantAdminLoginAsSupport, action.payload);
    yield put({ type: TENANT_ADMIN_LOGIN_AS.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: TENANT_ADMIN_LOGIN_AS.FAIL, payload: { error: e } });
  }
}

function* auth() {
  yield takeLatest(LOGIN.REQUEST, login);
  yield takeLatest(FORGOT_PASSWORD.REQUEST, forgotPassword);
  yield takeLatest(CHANGE_PASSWORD.REQUEST, changePassword);
  yield takeLatest(LOGIN_WITH_OTP.REQUEST, loginWithOtp);
  yield takeLatest(CREATE_NEW_PASSWORD.REQUEST, createNewPassword);
  yield takeLatest(TENANT_ADMIN_LOGIN.REQUEST, tenantAdminLogin);
  yield takeLatest(TENANT_ADMIN_LOGIN_AS.REQUEST, tenantAdminLoginAsSupport);
}

export default auth;
