import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
// import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
// import { AiOutlineDownload } from 'react-icons/ai';
// import { TiArrowUnsorted } from 'react-icons/ti';
import * as _ from 'lodash';
import { roundOfDigit } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { BOOKING_SUMMARY_REPORT, DOWNLOAD_BOOKING_SUMMARY_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
// import DropDown from 'components/inputs/DropDown';
// import { AiFillCaretDown } from 'react-icons/ai';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import ReactPagination from 'components/general/ReactPagination';
import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import { FETCH_CITY, FETCH_STATE } from 'actions/address';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
// import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';

const BookingSummaryReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  // const [allBookingSummaryReportData, setAllBookingSummaryReportData] = useState([]);
  // const [fieldValue, setFieldValue] = useState('All');
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  // const [selectedCityOptions, setSelectedCityOptions] = useState([]);
  // const [selectedStationOptions, setSelectedStationOptions] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));

  const bookingSummaryReportList = useSelector((state) => state.bookingSummaryReport.bookingSummaryReports);
  const isLoader = useSelector((state) => state.bookingSummaryReport.isLoading);
  const page = useSelector((state) => state.bookingSummaryReport.page);
  const totalData = useSelector((state) => state.bookingSummaryReport.total);
  const limit = useSelector((state) => state.bookingSummaryReport.limit);
  const totalPages = useSelector((state) => state.bookingSummaryReport.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserName = _.get(profileData, 'name', '');
  // const allChargingStations = useSelector((state) => state.chargingStation.chargingStations);
  // const allCity = useSelector((state) => state.city.city);
  // const allState = useSelector((state) => state.state.states);

  // const stationFilter = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allChargingStations, (station) => {
  //     return { label: station.name, value: station.id };
  //   }),
  // ];

  // const stateFilter = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allState, (state) => {
  //     return { label: state.name, value: state.name };
  //   }),
  // ];

  // const cityFilter = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allCity, (city) => {
  //     return { label: city.name, value: city.name };
  //   }),
  // ];

  const bookingSummaryReport = useCallback(
    (value) => {
      if (searchText) {
        const bookingSummaryReportData = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          search: searchText,
        };
        dispatch({ type: BOOKING_SUMMARY_REPORT.REQUEST, payload: bookingSummaryReportData });
      } else {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({ type: BOOKING_SUMMARY_REPORT.REQUEST, payload: data });
      }
    },
    [startDate, endDate, searchText]
  );

  const downloadBookingSummaryReport = useCallback(() => {
    const data = {
      excel: true,
      from: moment(startDate).utc(true),
      to: moment(endDate).utc(true),
      charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
    };
    dispatch({
      type: DOWNLOAD_BOOKING_SUMMARY_REPORT.REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Booking Summary Report'}.xlsx`);
          }
        }
      },
    });
  }, [startDate, endDate]);

  const downloadFile = () => {
    downloadBookingSummaryReport();
  };

  useEffect(() => {
    bookingSummaryReport();
  }, []);

  // useEffect(()=>{
  //   const data = {
  //     state: _.map(selectedStateOptions, (item) => item.value),
  //     city: _.map(selectedCityOptions, (item) => item.value),
  //     station: _.map(selectedStationOptions, (item) => item.value),
  //   }
  //     bookingSummaryReport(data);
  // },[selectedStateOptions,selectedCityOptions,selectedStationOptions])

  // useEffect(() => {
  //   dispatch({ type: FETCH_STATE, payload: {} });
  //   dispatch({ type: FETCH_CHARGING_STATION, payload: {} });
  // }, []);

  // const getCityByState = useCallback((stateName = '') => {
  //   dispatch({ type: FETCH_CITY, payload: { state_name: stateName } });
  // }, []);

  // function getDropdownButtonLabel({ placeholderButtonLabel }) {
  //     return `${placeholderButtonLabel}`;
  // }

  // function stateOnChange(value, event) {
  //   if (event.action === 'select-option' && event.option.value === 'All') {
  //     this.setState(this.options);
  //     getCityByState(_.get(event, 'option.value'));
  //   } else if (event.action === 'deselect-option' && event.option.value === 'All') {
  //     this.setState([]);
  //   } else if (event.action === 'deselect-option') {
  //     this.setState(value.filter((o) => o.value !== 'All'));
  //   }
  //   // else if (value.length === this.options.length - 1) {
  //   //   this.setState(this.options);
  //   //   getCityByState(_.get(event, 'option.value'));
  //   // }
  //   else {
  //     this.setState(value);
  //     getCityByState(_.get(event, 'option.value'));
  //   }
  // }

  // function onChange(value, event) {
  //   if (event.action === 'select-option' && event.option.value === 'All') {
  //     this.setState(this.options);
  //   } else if (event.action === 'deselect-option' && event.option.value === 'All') {
  //     this.setState([]);
  //   } else if (event.action === 'deselect-option') {
  //     this.setState(value.filter((o) => o.value !== 'All'));
  //   }
  //   // else if (value.length === this.options.length - 1) {
  //   //   this.setState(this.options);
  //   // }
  //   else {
  //     this.setState(value);
  //   }
  // }

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      if (searchText) {
        const filterSearchData = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: searchText,
        };
        bookingSummaryReport(filterSearchData);
      } else {
        const filterDateData = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        bookingSummaryReport(filterDateData);
      }
    }
  }, [startDate, endDate, sortByItem, searchText]);

  // const filterData = [
  //   { label: 'Newest', value: 'Newest' },
  //   { label: 'Oldest', value: 'Oldest' },
  //   { label: 'All', value: 'All' },
  // ];

  // useEffect(() => {
  //   const newestData = {
  //     sortBy: '1',
  //   };
  //   fieldValue === 'Newest' && bookingSummaryReport(newestData);
  //   const oldestData = {
  //     sortBy: '-1',
  //   };
  //   fieldValue === 'Oldest' && bookingSummaryReport(oldestData);
  //   fieldValue === 'All' && bookingSummaryReport();
  // }, [fieldValue]);

  // useEffect(() => {
  //   setAllBookingSummaryReportData(
  //     _.filter(
  //       bookingSummaryReportList,
  //       (item) =>
  //         startsWith(_.get(item, 'name'), searchText) ||
  //         startsWith(_.get(item, 'state'), searchText) ||
  //         startsWith(_.get(item, 'city'), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'charging_scheduled_amount'), 2).toString(), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'charging_cancelled_amount'), 2).toString(), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'charging_completed_amount', ''), 2).toString(), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'charging_returned_amount', ''), 2).toString(), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'charging_tax_amount', ''), 2).toString(), searchText)
  //     )
  //   );
  // }, [searchText, bookingSummaryReportList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleSearch = () => {
    bookingSummaryReport({});
  };

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        endDate && bookingSummaryReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        bookingSummaryReport(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        endDate && bookingSummaryReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        bookingSummaryReport(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: searchText,
          page: page.selected + 1,
        };
        bookingSummaryReport(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        bookingSummaryReport(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        bookingSummaryReport(data);
      }
    },
    [sortByItem, startDate, endDate, searchText]
  );

  const search = (
    <SearchBox
      preIcon={<BsSearch />}
      value={searchText}
      onChange={searchHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.bookingSummaryReport')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      {/* <Col xl={2} md={4}>
                      <DropDown
                        popupClass="main-menu bottom scrollable"
                        trigger={
                          <>
                            <div className="log-filter-block">
                              <div className="log-filter-icon">
                                <BsFilter />
                                <span className="log-filter-label">{fieldValue}</span>
                              </div>
                              <div className="log-arrow__icon">
                                <AiFillCaretDown />
                              </div>
                            </div>
                          </>
                        }
                        triggerClass="log-filter-dropdown"
                      >
                        {_.map(filterData, (item, index) => (
                          <ul key={`${index}`}>
                            <li
                              onClick={() => {
                                setFieldValue(item.value);
                              }}
                            >
                              {item.label}
                            </li>
                          </ul>
                        ))}
                      </DropDown>
                    </Col> */}
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          {/* <DatePickerWithFloatingLabel
                          defaultValueOfStartDate={new Date()}
                          defaultValueOfEndDate={new Date()}
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                        /> */}
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={startDate}
                            initialValueOfEndDate={endDate}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn">
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                      {/* <Col xl={1} md={2}>
                      <div className="data-report__table-download-icon">
                        <AiOutlineDownload />
                      </div>
                    </Col> */}
                    </Row>
                    {/* <Row>
                    <Col xl={2} md={4}>
                      <ReactMultiSelectCheckboxes
                        options={stateFilter}
                        placeholderButtonLabel="Select state"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedStateOptions}
                        onChange={stateOnChange}
                        setState={setSelectedStateOptions}
                        hideSearch={true}
                      />
                    </Col>
                    <Col xl={2} md={4}>
                      <ReactMultiSelectCheckboxes
                        options={cityFilter}
                        placeholderButtonLabel="Select city"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedCityOptions}
                        onChange={onChange}
                        setState={setSelectedCityOptions}
                        hideSearch={true}
                      />
                    </Col>
                    <Col xl={2} md={4}>
                      <ReactMultiSelectCheckboxes
                        options={stationFilter}
                        placeholderButtonLabel="Select Station"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedStationOptions}
                        onChange={onChange}
                        setState={setSelectedStationOptions}
                        hideSearch={true}
                      />
                    </Col>
                  </Row> */}
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.stationName')}</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.stateName')}</span>
                                <span className="ico" onClick={() => handleSorting('state', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.cityName')}</span>
                                <span className="ico" onClick={() => handleSorting('city', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.booked')}</span>
                                <span className="ico" onClick={() => handleSorting('charging_scheduled', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.bookingAmount')}</span>
                                <span className="ico" onClick={() => handleSorting('charging_scheduled_amount', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.cancelledBooking')}</span>
                                <span className="ico" onClick={() => handleSorting('charging_cancelled', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.cancelledBookingAmount')}</span>
                                <span className="ico" onClick={() => handleSorting('charging_cancelled_amount', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.chargingCompleted')}</span>
                                <span className="ico" onClick={() => handleSorting('charging_completed', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.actualAmount')}</span>
                                <span className="ico" onClick={() => handleSorting('charging_completed_amount', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.returnAmount')}</span>
                                <span className="ico" onClick={() => handleSorting('charging_returned_amount', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.taxAmount')}</span>
                                <span className="ico" onClick={() => handleSorting('charging_tax_amount', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={9} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(bookingSummaryReportList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('dataReport.noBookingSummaryData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(bookingSummaryReportList, (item, key) => {
                              const serial_num = limit * (page - 1) + key;
                              return (
                                <>
                                  <tr key={`booking-summary-report-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'name', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'state', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'city', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charging_scheduled', '')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        roundOfDigit(_.get(item, 'charging_scheduled_amount', ''), 2)
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charging_cancelled', '')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        roundOfDigit(_.get(item, 'charging_cancelled_amount', ''), 2)
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charging_completed', '')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        roundOfDigit(_.get(item, 'charging_completed_amount', ''), 2)
                                      )}
                                    </td>
                                    <td>
                                      {isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'charging_returned_amount', ''), 2)}
                                    </td>
                                    <td>
                                      {isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'charging_tax_amount', ''), 2)}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(bookingSummaryReportList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};

export default BookingSummaryReport;
