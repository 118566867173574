import { FETCH_TENANT_SETTING, GET_TENANT_SETTING, UPDATE_TENANT_SETTING } from 'actions/tenantSetting';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchTenantSetting(action) {
  try {
    const response = yield call(API.getAllTenantSetting, action.payload);
    yield put({ type: FETCH_TENANT_SETTING.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_TENANT_SETTING.FAIL, payload: { error: e } });
  }
}

function* getTenantSettingById(action) {
  try {
    const response = yield call(API.getTenantSettingById, action.payload);
    yield put({ type: GET_TENANT_SETTING.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_TENANT_SETTING.FAIL, payload: { error: e } });
  }
}

function* updateTenantSetting(action) {
  try {
    const response = yield call(API.updateTenantSetting, action.payload);
    yield put({ type: UPDATE_TENANT_SETTING.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_TENANT_SETTING.FAIL, payload: { error: e } });
  }
}

function* tenantSetting() {
  yield takeLatest(FETCH_TENANT_SETTING.REQUEST, fetchTenantSetting);
  yield takeLatest(GET_TENANT_SETTING.REQUEST, getTenantSettingById);
  yield takeLatest(UPDATE_TENANT_SETTING.REQUEST, updateTenantSetting);
}
export default tenantSetting;
