import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_PROFILE, GET_PROFILE, UPDATE_PROFILE } from '../actions/profile';
import { createPromiseAction, implementPromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

function* updateUserProfile(action) {
  try {
    const response = yield call(API.updateUserProfile, action.payload);
    yield put({ type: UPDATE_PROFILE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: UPDATE_PROFILE.FAIL, payload: { error: e } });
  }
}

function* getProfile(action) {
  try {
    const response = yield call(API.getProfile, action.payload);
    yield put({ type: GET_PROFILE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_PROFILE.FAIL, payload: { error: e } });
  }
}

function* fetchProfile(action) {
  try {
    yield call(implementPromiseAction, action, function* () {
      return yield call(API.getProfile, action.payload);
    });
  } catch (e) {
    yield call(rejectPromiseAction, action, new Error(e.data.message()));
  }
}

function* profile() {
  yield takeLatest(UPDATE_PROFILE.REQUEST, updateUserProfile);
  yield takeLatest(GET_PROFILE.REQUEST, getProfile);
  yield takeLatest(createPromiseAction(FETCH_PROFILE.REQUEST), fetchProfile);
}

export default profile;
