import { post, get, downloadFile } from 'utils/api';
import { SMC_NO_LOAD_REPORT } from './endpointURLs';

export const uploadNoLoadReport = (data) => {
  return post(`${SMC_NO_LOAD_REPORT}`, data);
};

export const fetchNoLoadReport = (data) => {
  if (data.page) {
    return get(`${SMC_NO_LOAD_REPORT}?page=${data.page}`);
  } else {
    return get(`${SMC_NO_LOAD_REPORT}`, data);
  }
};

export const downloadNoLoadReport = (data) => {
  const id = data.id;
  delete data['id'];
  return downloadFile(`${SMC_NO_LOAD_REPORT}/${id}`, data);
};

export const noLoadReport = {
  uploadNoLoadReport,
  fetchNoLoadReport,
  downloadNoLoadReport,
};
