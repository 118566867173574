import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
const RequestModal = (props) => {
  const { onClose = _.noop(), requestModal = _.noop(), title, subTitle } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="request-modal-main_page">
        <div className="request-modal--inner">
          <div className="request-modal---block_title">
            <div className="inner--block_title">
              <div className="title">{subTitle} this user to delete it softly.</div>
              <p className="sub-title">Are you sure want to {title} this user?</p>
            </div>
          </div>
          <div className="request-modal-input-box__block">
            <Row>
              <Col lg={6}>
                <Button className="request-model--btn cancel--btn" onClick={onClose}>
                  {t('button.no')}
                </Button>
              </Col>
              <Col lg={6}>
                <Button className="request-model--btn  remove--btn" onClick={requestModal}>
                  {t('button.yes')}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
RequestModal.propTypes = {
  onClose: PropTypes.func,
  requestModal: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
export default RequestModal;
