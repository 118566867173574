import { FETCH_CHARGE_COIN, UPDATE_CHARGE_COIN, UPDATE_CHARGE_COIN_LEVEL, DELETE_CHARGE_COIN_LEVEL } from 'actions/chargeCoin';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  results: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const chargeCoinReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHARGE_COIN.REQUEST:
    case UPDATE_CHARGE_COIN.REQUEST:
    case DELETE_CHARGE_COIN_LEVEL.REQUEST:
    case UPDATE_CHARGE_COIN_LEVEL.REQUEST: {
      return { ...state, isLoading: true };
    }
    case FETCH_CHARGE_COIN.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        results: payload.results.filter((data) => data.key_name === 'chargecoin_settings'),
      };
    }
    case DELETE_CHARGE_COIN_LEVEL.SUCCESS: {
      toast.success('Level deleted successfully');
      return {
        ...state,
        isLoading: false,
        results: payload,
      };
    }
    case UPDATE_CHARGE_COIN.SUCCESS: {
      toast.success('ChargeCoin updated successfully');
      return {
        ...state,
        isLoading: false,
        results: payload,
      };
    }
    case UPDATE_CHARGE_COIN_LEVEL.SUCCESS: {
      toast.success('ChargeCoin level updated successfully');
      return {
        ...state,
        isLoading: false,
        results: payload,
      };
    }
    case FETCH_CHARGE_COIN.FAIL:
    case UPDATE_CHARGE_COIN.FAIL:
    case DELETE_CHARGE_COIN_LEVEL.FAIL:
    case UPDATE_CHARGE_COIN_LEVEL.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};

export default chargeCoinReducer;
