import {
  FETCH_RFID,
  GET_RFID,
  CREATE_RFID,
  UPDATE_RFID,
  DELETE_RFID,
  CREATE_BULK_RFID,
  CREATE_BULK_RFID_FOR_FLEET,
  FETCH_RFID_FOR_FLEET,
  UPDATE_VEHICLE_RFID,
  DELETE_RFID_BY_FLEET,
} from 'actions/rfid';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchRfid(action) {
  try {
    const response = yield call(API.getRfid, action.payload);
    yield put({ type: FETCH_RFID.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_RFID.FAIL, payload: { error: e } });
  }
}

function* getRfidById(action) {
  try {
    const response = yield call(API.getRfidById, action.payload);
    yield put({ type: GET_RFID.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_RFID.FAIL, payload: { error: e } });
  }
}

function* createRfid(action) {
  try {
    const response = yield call(API.addRfid, action.payload);
    yield put({ type: CREATE_RFID.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_RFID.FAIL, payload: { error: e } });
  }
}

function* updateRfid(action) {
  try {
    const response = yield call(API.updateRfid, action.payload);
    yield put({ type: UPDATE_RFID.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_RFID.FAIL, payload: { error: e } });
  }
}

function* deleteRfid(action) {
  try {
    const response = yield call(API.deleteRfid, action.payload);
    yield put({ type: DELETE_RFID.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_RFID.FAIL, payload: { error: e } });
  }
}

function* createBulkRfid(action) {
  try {
    const response = yield call(API.addBulkRfid, action.payload);
    yield put({ type: CREATE_BULK_RFID.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_BULK_RFID.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* fetchRfidForFleet(action) {
  try {
    const response = yield call(API.getRfidForFleet, action.payload);
    yield put({ type: FETCH_RFID_FOR_FLEET.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_RFID_FOR_FLEET.FAIL, payload: { error: e } });
  }
}

function* createBulkRfidForFleet(action) {
  try {
    const response = yield call(API.addBulkRfidForFleet, action.payload);
    yield put({ type: CREATE_BULK_RFID_FOR_FLEET.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_BULK_RFID_FOR_FLEET.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateVehicleRfid(action) {
  try {
    const response = yield call(API.updateVehicleRfid, action.payload);
    yield put({ type: UPDATE_VEHICLE_RFID.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VEHICLE_RFID.FAIL, payload: { error: e } });
  }
}

function* deleteRfidByFleet(action) {
  try {
    const response = yield call(API.deleteRfidByFleet, action.payload);
    yield put({ type: DELETE_RFID_BY_FLEET.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_RFID_BY_FLEET.FAIL, payload: { error: e } });
  }
}

function* rfid() {
  yield takeLatest(FETCH_RFID.REQUEST, fetchRfid);
  yield takeLatest(GET_RFID.REQUEST, getRfidById);
  yield takeLatest(CREATE_RFID.REQUEST, createRfid);
  yield takeLatest(UPDATE_RFID.REQUEST, updateRfid);
  yield takeLatest(DELETE_RFID.REQUEST, deleteRfid);
  yield takeLatest(CREATE_BULK_RFID.REQUEST, createBulkRfid);
  yield takeLatest(FETCH_RFID_FOR_FLEET.REQUEST, fetchRfidForFleet);
  yield takeLatest(CREATE_BULK_RFID_FOR_FLEET.REQUEST, createBulkRfidForFleet);
  yield takeLatest(UPDATE_VEHICLE_RFID.REQUEST, updateVehicleRfid);
  yield takeLatest(DELETE_RFID_BY_FLEET.REQUEST, deleteRfidByFleet);
}
export default rfid;
