import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useCallback, useEffect, useState } from 'react';
import Card from 'components/inputs/Card';
import { FETCH_CHARGE_COIN_TRANSACTION } from 'actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { useParams } from 'react-router-dom';
import ReactPagination from 'components/general/ReactPagination';
import { BiSort } from 'react-icons/bi';
// import { roundOfDigit } from 'components/common/utils';
import moment from 'moment';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
const ChargeCoinTransaction = () => {
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const { t } = useTranslation();
  const chargeCoinTransactionList = useSelector((state) => state.chargeCoinTransaction.chargeCoinTransaction);
  const page = useSelector((state) => state.chargeCoinTransaction.page);
  const totalData = useSelector((state) => state.chargeCoinTransaction.total);
  const limit = useSelector((state) => state.chargeCoinTransaction.limit);
  const totalPages = useSelector((state) => state.chargeCoinTransaction.totalPages);
  const isLoader = useSelector((state) => state.chargeCoinTransaction.isLoading);

  const getchargeCoinTransaction = useCallback(
    (data) => {
      const transactionData = {
        ...data,
        id: customerId,
      };
      dispatch({ type: FETCH_CHARGE_COIN_TRANSACTION.REQUEST, payload: transactionData });
    },
    [customerId]
  );

  useEffect(() => {
    getchargeCoinTransaction();
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getchargeCoinTransaction(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getchargeCoinTransaction(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getchargeCoinTransaction(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <Card className="customerDetail-table">
            <div className="customerDetail-table-heading">
              {isLoader ? <Skeleton height={15} width={100} /> : t('header.chargeCoinTransactionDetails')}
            </div>
            <div className="customerDetail-list-table">
              <div className="table-responsive">
                <table className="record-list-table">
                  <thead>
                    <tr>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('chargeCoinTransactionDetails.srNo')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('chargeCoinTransactionDetails.message')}</span>
                          <span className="ico" onClick={() => handleSorting('wallet_type', sortByItem)}>
                            <BiSort size={15} />
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('chargeCoinTransactionDetails.chargeCoin')}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{isLoader ? <Skeleton height={15} width={100} /> : t('chargeCoinTransactionDetails.dateTime')}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isEmpty(chargeCoinTransactionList) ? (
                      <tr>
                        <td colSpan={8} className="border-0">
                          <div className="empty-data-block">{t('chargeCoinTransactionDetails.noData')}</div>
                        </td>
                      </tr>
                    ) : (
                      _.map(chargeCoinTransactionList, (item, key) => {
                        const serial_num = limit * (page - 1) + key;
                        return (
                          <tr key={`charge-coin-transaction-data-item-${serial_num}`}>
                            <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'message', '-')}</td>
                            <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'amount', '-')}</td>
                            <td>
                              {isLoader ? (
                                <Skeleton height={15} width={100} />
                              ) : item.createdAt ? (
                                moment(item.createdAt).format('DD/MM/YYYY  H:mm:ss')
                              ) : (
                                '-'
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {!_.isEmpty(chargeCoinTransactionList) && (
              <ReactPagination
                currentPage={page}
                limit={limit}
                total={totalData}
                handlePageClick={(pageVal) => handlePageClick(pageVal)}
                totalPages={totalPages}
                marginPagesDisplayed={1}
              />
            )}
          </Card>
        </SkeletonTheme>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default ChargeCoinTransaction;
