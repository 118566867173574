import { createActionTypes } from 'utils/action/actionTypes';

export const CREATE_VEHICLE = createActionTypes('CREATE_VEHICLE');
export const CREATE_MULTIPLE_VEHICLE = createActionTypes('CREATE_MULTIPLE_VEHICLE');
export const FETCH_VEHICLE = createActionTypes('FETCH_VEHICLE');
export const GET_VEHICLE = createActionTypes('GET_VEHICLE');
export const DELETE_VEHICLE = createActionTypes('DELETE_VEHICLE');
export const UPDATE_VEHICLE = createActionTypes('UPDATE_VEHICLE');
export const DOWNLOAD_EXCEL_VEHICLE = createActionTypes('DOWNLOAD_EXCEL_VEHICLE');
export const UPDATE_VRN = createActionTypes('UPDATE_VRN');
