import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import { useParams } from 'react-router-dom';
import Card from 'components/inputs/Card';
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import * as _ from 'lodash';
import Button from 'components/inputs/Button';
import { FETCH_CUSTOMER } from 'actions/customer';
import { UPDATE_USER } from 'actions/user';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const AddUserForm = () => {
  const { userGroupId } = useParams();
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigateTo } = useContext(NavContext);

  const allCustomer = useSelector((state) => state.customer.customers);
  const customerPage = useSelector((state) => state.customer.page);
  const customerTotalPage = useSelector((state) => state.customer.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const allCustomerDetails = _.filter(allCustomer, (item) => item['user_group'] === undefined || item['user_group'] === null);

  const updateUser = useCallback(
    (userId) => {
      const userData = {
        id: userId,
        user_group: userGroupId,
      };
      dispatch({
        type: UPDATE_USER.REQUEST,
        payload: userData,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            toast.success('User added successfully');
            setTimeout(() => {
              navigateTo(`/user/${userGroupId}`);
            }, 1000);
          }
        },
      });
    },
    [userGroupId]
  );

  const getAllCustomer = useCallback(
    (data = {}) => {
      if (searchText) {
        const customerData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_CUSTOMER.REQUEST, payload: customerData });
      } else {
        dispatch({ type: FETCH_CUSTOMER.REQUEST, payload: data });
      }
    },
    [searchText]
  );

  useEffect(() => {
    getAllCustomer();
  }, [searchText]);

  const userSchema = Yup.object().shape({
    user_group: Yup.string().required(t('addUserForm.userGroup')),
  });

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('addUserForm.addUser')} backTo={`/user/${userGroupId}`} />
        <div className="page-content-wrapper scrollable">
          <Card>
            <div className="user-form-body__block">
              <div className="user-form--block">
                <Formik
                  initialValues={{
                    user_group: '',
                  }}
                  validationSchema={userSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    updateUser(values.user_group);
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Select
                            isRequired
                            label={t('addUserForm.user')}
                            options={_.map(allCustomerDetails, (item) => {
                              return {
                                label: _.get(item, 'name', 'Guest User') + ' ' + `+${_.get(item, 'country_code')} ${_.get(item, 'phone')}`,
                                value: item.id,
                              };
                            })}
                            placeholder={t('addUserForm.selectUser')}
                            name="user_group"
                            onMenuScrollDown={true}
                            getDataOnScrollDown={getAllCustomer}
                            page={customerPage}
                            totalPage={customerTotalPage}
                            value={_.get(values, 'user_group')}
                            onChange={(val) => setFieldValue(`user_group`, val)}
                            onInputChange={(text) => setSearchText(text)}
                            error={errors.user_group && touched.user_group ? errors.user_group : null}
                          />
                        </Col>
                      </Row>
                      <div className="user-form-btn__block">
                        <Button type="submit" disabled={isSubmitting} className="user-form-btn">
                          {t('button.addUser')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Card>
        </div>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default AddUserForm;
