import React, { useCallback } from 'react';
import Button from 'components/inputs/Button';
import Card from 'components/inputs/Card';
import { GET_LOCAL_LIST } from 'actions/chargerOcpp';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { GET_STATION_CHARGER_OPERATIONS, MANAGE_STATION_CHARGER_OPERATIONS } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const GetLocalListVersionForm = () => {
  const { chargerId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const getGetLocalListVersion = useCallback((chargerId) => {
    const data = {
      id: chargerId,
    };
    dispatch({
      type: GET_LOCAL_LIST.REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            toast.success('Get local list version successfully');
          }
        }
      },
    });
  }, []);

  const getStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  const manageStationChargerOperations =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_STATION_CHARGER_OPERATIONS),
      (data) => data.name
    );

  return (
    <React.Fragment>
      <div className="serverOperation-title-name">{t('localListConfig.formTitle')}</div>
      <Card>
        <div className="p-2">
          {!_.isEmpty(manageStationChargerOperations) || (!_.isEmpty(getStationChargerOperations) && !_.isEmpty(manageStationChargerOperations)) ? (
            <Button onClick={() => getGetLocalListVersion(chargerId)}>{t('localListConfig.button')}</Button>
          ) : (
            <Button>{t('localListConfig.button')}</Button>
          )}
        </div>
      </Card>
    </React.Fragment>
  );
};

export default GetLocalListVersionForm;
