import { get, post, patch, deleteRequest } from 'utils/api';

export const getStationAccessType = (data) => {
  return get('/cs_access_type', data);
};

export const getStationAccessTypeById = (id) => {
  return get(`/cs_access_type/${id}`);
};
export const addStationAccessType = (data) => {
  return post(`/cs_access_type`, data);
};

export const updateStationAccessType = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/cs_access_type/${id}`, data);
};

export const deleteStationAccessType = (id) => {
  return deleteRequest(`/cs_access_type/${id}`);
};

export const stationAccessType = {
  getStationAccessType,
  getStationAccessTypeById,
  addStationAccessType,
  updateStationAccessType,
  deleteStationAccessType,
};
