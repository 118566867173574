import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_COUNTRY,
  GET_COUNTRY,
  CREATE_COUNTRY,
  UPDATE_COUNTRY,
  DELETE_COUNTRY,
  FETCH_STATE,
  GET_STATE,
  CREATE_STATE,
  UPDATE_STATE,
  DELETE_STATE,
  FETCH_AREA,
  GET_AREA,
  CREATE_AREA,
  UPDATE_AREA,
  DELETE_AREA,
  FETCH_CITY,
  GET_CITY,
  CREATE_CITY,
  UPDATE_CITY,
  DELETE_CITY,
  GET_CITY_BY_STATE,
  GET_AREA_BY_CITY,
} from 'actions/address';

function* fetchCountry(action) {
  try {
    const response = yield call(API.getCountry, action.payload);
    yield put({ type: FETCH_COUNTRY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_COUNTRY.FAIL, payload: { error: e } });
  }
}

function* getCountryById(action) {
  try {
    const response = yield call(API.getCountryById, action.payload);
    yield put({ type: GET_COUNTRY.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_COUNTRY.FAIL, payload: { error: e } });
  }
}

function* getCityByState(action) {
  try {
    const response = yield call(API.getCityByState, action.payload);
    yield put({ type: GET_CITY_BY_STATE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CITY_BY_STATE.FAIL, payload: { error: e } });
  }
}

function* getAreaByCity(action) {
  try {
    const response = yield call(API.getAreaByCity, action.payload);
    yield put({ type: GET_AREA_BY_CITY.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_AREA_BY_CITY.FAIL, payload: { error: e } });
  }
}

function* createCountry(action) {
  try {
    const response = yield call(API.addCountry, action.payload);
    yield put({ type: CREATE_COUNTRY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_COUNTRY.FAIL, payload: { error: e } });
  }
}

function* updateCountry(action) {
  try {
    const response = yield call(API.updateCountry, action.payload);
    yield put({ type: UPDATE_COUNTRY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_COUNTRY.FAIL, payload: { error: e } });
  }
}

function* deleteCountry(action) {
  try {
    const response = yield call(API.deleteCountry, action.payload);
    yield put({ type: DELETE_COUNTRY.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_COUNTRY.FAIL, payload: { error: e } });
  }
}

function* fetchState(action) {
  try {
    const response = yield call(API.getState, action.payload);
    yield put({ type: FETCH_STATE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_STATE.FAIL, payload: { error: e } });
  }
}

function* getStateById(action) {
  try {
    const response = yield call(API.getStateById, action.payload);
    yield put({ type: GET_STATE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_STATE.FAIL, payload: { error: e } });
  }
}

function* createState(action) {
  try {
    const response = yield call(API.addState, action.payload);
    yield put({ type: CREATE_STATE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_STATE.FAIL, payload: { error: e } });
  }
}

function* updateState(action) {
  try {
    const response = yield call(API.updateState, action.payload);
    yield put({ type: UPDATE_STATE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_STATE.FAIL, payload: { error: e } });
  }
}

function* deleteState(action) {
  try {
    const response = yield call(API.deleteState, action.payload);
    yield put({ type: DELETE_STATE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_STATE.FAIL, payload: { error: e } });
  }
}

function* fetchCity(action) {
  try {
    const response = yield call(API.getCity, action.payload);
    yield put({ type: FETCH_CITY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CITY.FAIL, payload: { error: e } });
  }
}

function* getCityById(action) {
  try {
    const response = yield call(API.getCityById, action.payload);
    yield put({ type: GET_CITY.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CITY.FAIL, payload: { error: e } });
  }
}

function* createCity(action) {
  try {
    const response = yield call(API.addCity, action.payload);
    yield put({ type: CREATE_CITY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CITY.FAIL, payload: { error: e } });
  }
}

function* updateCity(action) {
  try {
    const response = yield call(API.updateCity, action.payload);
    yield put({ type: UPDATE_CITY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CITY.FAIL, payload: { error: e } });
  }
}

function* deleteCity(action) {
  try {
    const response = yield call(API.deleteCity, action.payload);
    yield put({ type: DELETE_CITY.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_CITY.FAIL, payload: { error: e } });
  }
}

function* fetchArea(action) {
  try {
    const response = yield call(API.getArea, action.payload);
    yield put({ type: FETCH_AREA.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_AREA.FAIL, payload: { error: e } });
  }
}

function* getAreaById(action) {
  try {
    const response = yield call(API.getAreaById, action.payload);
    yield put({ type: GET_AREA.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_AREA.FAIL, payload: { error: e } });
  }
}

function* createArea(action) {
  try {
    const response = yield call(API.addArea, action.payload);
    yield put({ type: CREATE_AREA.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_AREA.FAIL, payload: { error: e } });
  }
}

function* updateArea(action) {
  try {
    const response = yield call(API.updateArea, action.payload);
    yield put({ type: UPDATE_AREA.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_AREA.FAIL, payload: { error: e } });
  }
}

function* deleteArea(action) {
  try {
    const response = yield call(API.deleteArea, action.payload);
    yield put({ type: DELETE_AREA.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_AREA.FAIL, payload: { error: e } });
  }
}

function* address() {
  yield takeLatest(FETCH_COUNTRY.REQUEST, fetchCountry);
  yield takeLatest(GET_COUNTRY.REQUEST, getCountryById);
  yield takeLatest(CREATE_COUNTRY.REQUEST, createCountry);
  yield takeLatest(UPDATE_COUNTRY.REQUEST, updateCountry);
  yield takeLatest(DELETE_COUNTRY.REQUEST, deleteCountry);
  yield takeLatest(FETCH_STATE.REQUEST, fetchState);
  yield takeLatest(GET_STATE.REQUEST, getStateById);
  yield takeLatest(CREATE_STATE.REQUEST, createState);
  yield takeLatest(UPDATE_STATE.REQUEST, updateState);
  yield takeLatest(DELETE_STATE.REQUEST, deleteState);
  yield takeLatest(FETCH_CITY.REQUEST, fetchCity);
  yield takeLatest(GET_CITY.REQUEST, getCityById);
  yield takeLatest(CREATE_CITY.REQUEST, createCity);
  yield takeLatest(UPDATE_CITY.REQUEST, updateCity);
  yield takeLatest(DELETE_CITY.REQUEST, deleteCity);
  yield takeLatest(FETCH_AREA.REQUEST, fetchArea);
  yield takeLatest(GET_AREA.REQUEST, getAreaById);
  yield takeLatest(CREATE_AREA.REQUEST, createArea);
  yield takeLatest(UPDATE_AREA.REQUEST, updateArea);
  yield takeLatest(DELETE_AREA.REQUEST, deleteArea);
  yield takeLatest(GET_CITY_BY_STATE.REQUEST, getCityByState);
  yield takeLatest(GET_AREA_BY_CITY.REQUEST, getAreaByCity);
}

export default address;
