import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { BiSort } from 'react-icons/bi';
import { roundOfDigit } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { RATING_REVIEW, DOWNLOAD_RATING_REVIEW } from 'actions/rating';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
// import fileDownload from 'js-file-download';
import { MdClear /*, MdOutlineReviews*/ } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';
import ReactStars from 'react-rating-stars-component';
import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';
import { GET_STATION_BY_CITY } from 'actions/chargingStation';
import fileDownload from 'js-file-download';
import InformationBlock from 'components/general/InformationBlock';

const RatingReviewReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
  const [changeStation, setChangeStation] = useState(false);
  const [changeRating, setChangeRating] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});

  const ratingReviewList = useSelector((state) => state.rating.rating);
  const isLoader = useSelector((state) => state.rating.isLoading);
  const page = useSelector((state) => state.rating.page);
  const totalData = useSelector((state) => state.rating.total);
  const limit = useSelector((state) => state.rating.limit);
  const totalPages = useSelector((state) => state.rating.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const averageRating = useSelector((state) => state.rating.avg_rating);
  const currentUserName = _.get(profileData, 'name', '');
  const userProfileStations = profileData.charging_stations;
  const allStationByCity = useSelector((state) => state.stationByCity.stationByCities);
  const stationList =
    userProfileStations && userProfileStations.length > 0
      ? allStationByCity.filter((x) => userProfileStations.indexOf(x.id) !== -1)
      : allStationByCity;

  const ratingReview = useCallback(
    (value) => {
      if (searchText) {
        const ratingReviewData = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          search: searchText,
          is_emsp_based_booking: false,
        };
        dispatch({
          type: RATING_REVIEW.REQUEST,
          payload: ratingReviewData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          is_emsp_based_booking: false,
        };
        dispatch({
          type: RATING_REVIEW.REQUEST,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText]
  );

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && ratingReview(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        ratingReview(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && ratingReview(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        ratingReview(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        ratingReview(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
        };
        ratingReview(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: userProfileStations,
      };
      ratingReview(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      ratingReview(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData, searchText]);

  // Download Excel Functionality

  const downloadRatingReview = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          excel: true,
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_RATING_REVIEW.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Rating & Review'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          excel: true,
          is_emsp_based_booking: false,
        };
        dispatch({
          type: DOWNLOAD_RATING_REVIEW.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Rating & Review'}.xlsx`);
              }
            }
          },
        });
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        charging_station: userProfileStations,
        excel: true,
        is_emsp_based_booking: false,
      };
      dispatch({
        type: DOWNLOAD_RATING_REVIEW.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Rating & Review'}.xlsx`);
            }
          }
        },
      });
    } else if (searchText) {
      const data = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        excel: true,
        search: searchText,
        is_emsp_based_booking: false,
      };
      dispatch({
        type: DOWNLOAD_RATING_REVIEW.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Rating & Review'}.xlsx`);
            }
          }
        },
      });
    } else {
      const data = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
        excel: true,
        is_emsp_based_booking: false,
      };
      dispatch({
        type: DOWNLOAD_RATING_REVIEW.REQUEST,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Rating & Review'}.xlsx`);
            }
          }
        },
      });
    }
  }, [searchText, startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadRatingReview();
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        if (advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          ratingReview(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
          };
          ratingReview(data);
        }
      } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const filterDateData = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        ratingReview(filterDateData);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        ratingReview(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        ratingReview(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        ratingReview(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData, searchText]
  );

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      // access_type: 'Public',
    };
    dispatch({ type: GET_STATION_BY_CITY.REQUEST, payload: stationData });
  }, []);

  useEffect(() => {
    ratingReview();
    getAllStation();
  }, []);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };
  const handleSearch = () => {
    ratingReview({});
  };

  const ratingReviews = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
  ];
  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = (
    <SearchBox
      preIcon={<BsSearch />}
      value={searchText}
      onChange={searchHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { charging_station: '', ratings: '' };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.ratingReviewReport')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="rating-review--main">
              <Card>
                <div className="rating-review__inner">
                  <div className="rating-review__box">
                    <Row className="rating-review__row">
                      <Col xl={'auto'} md={'auto'} className="rating-details--wrapper">
                        <InformationBlock
                          icon={isLoader ? <Skeleton circle="true" height={35} width={35} /> : roundOfDigit(averageRating, 3)}
                          infoTitle={
                            isLoader ? (
                              <Skeleton circle="true" height={35} width={100} />
                            ) : (
                              <ReactStars
                                edit={false}
                                a11y={true}
                                count={5}
                                value={averageRating}
                                size={15}
                                isHalf={true}
                                emptyIcon={<BsStar />}
                                halfIcon={<BsStarHalf />}
                                filledIcon={<BsStarFill />}
                                activeColor="#BE210B"
                                color="#BE210B"
                              />
                            )
                          }
                          infoText={isLoader ? <Skeleton circle="true" height={35} width={100} /> : t('ratingReview.averageRating')}
                        />
                      </Col>
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'rating-review-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="rating-review-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment().startOf('month')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <FaFilter className="hide-lap" />
                              <span>{t('button.advancedFilter')}</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            <BsDownload size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="rating-review__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ratingReview.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ratingReview.stationName')}</span>
                                <span className="ico" onClick={() => handleSorting('charging_station.name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ratingReview.userName')}</span>
                                <span className="ico" onClick={() => handleSorting('customer.name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ratingReview.mobileNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ratingReview.feedback')}</span>
                                <span className="ico" onClick={() => handleSorting('feedback', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ratingReview.rating')}</span>
                                <span className="ico" onClick={() => handleSorting('ratings', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ratingReview.reviewDate')}</span>
                                <span className="ico" onClick={() => handleSorting('createdAt', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={12} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(ratingReviewList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('ratingReview.noHistoryFound')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(
                              _.filter(ratingReviewList, (item) => item !== null),
                              (item, key) => {
                                const serial_num = limit * (page - 1) + key;
                                return (
                                  <>
                                    <tr key={`rating-review-${serial_num}`}>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charging_station.name', '-')}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer.name', '-')}</td>
                                      <td>
                                        {isLoader ? (
                                          <Skeleton height={15} width={100} />
                                        ) : _.get(item, 'customer.country_code') && _.get(item, 'customer.phone') ? (
                                          `+${_.get(item, 'customer.country_code', '')} ${_.get(item, 'customer.phone', '')}`
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td style={{ whiteSpace: 'pre-line', width: '200px' }}>
                                        {isLoader ? (
                                          <Skeleton height={15} width={100} />
                                        ) : !_.isEmpty(_.get(item, 'feedback')) ? (
                                          _.get(item, 'feedback', '-')
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td>
                                        {isLoader ? (
                                          <Skeleton height={15} width={100} />
                                        ) : (
                                          <ReactStars
                                            edit={false}
                                            a11y={true}
                                            count={5}
                                            value={_.get(item, 'ratings', '')}
                                            size={12}
                                            isHalf={true}
                                            emptyIcon={<BsStar />}
                                            halfIcon={<BsStarHalf />}
                                            filledIcon={<BsStarFill />}
                                            activeColor="#BE210B"
                                            color="#BE210B"
                                          />
                                        )}
                                      </td>
                                      <td>
                                        {isLoader ? (
                                          <Skeleton height={15} width={100} />
                                        ) : item.createdAt ? (
                                          moment(item.createdAt).format('DD/MM/YYYY H:mm:ss')
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(ratingReviewList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.charging_station && delete values['charging_station'];
              !values.ratings && delete values['ratings'];
              if (values.charging_station === 'All') {
                const ratingReviewData = { ...values, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                ratingReview(ratingReviewData);
              } else {
                ratingReview(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <Select
                      label="Rating & Review"
                      options={
                        !changeRating
                          ? !_.isEmpty(ratingReviews) && [
                              // { label: 'All', value: 'All' },
                              ..._.map(ratingReviews, (item) => {
                                return { label: item.label, value: item.value };
                              }),
                            ]
                          : !_.isEmpty(ratingReviews) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Rating & Review"
                      name="ratings"
                      isMulti
                      value={values.ratings}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeRating(true);
                          setFieldValue(`ratings`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`ratings`, '');
                          setChangeRating(false);
                        } else {
                          setChangeRating(false);
                          setFieldValue('ratings', val);
                        }
                      }}
                    />
                    <Select
                      label="Station"
                      options={
                        !changeStation
                          ? !_.isEmpty(stationList) && [
                              { label: 'All', value: 'All' },
                              ..._.map(stationList, (station) => {
                                return { label: station.name, value: station.id };
                              }),
                            ]
                          : !_.isEmpty(stationList) && [
                              { label: 'All', value: 'All' },
                              ..._.map(stationList, (station) => {
                                return { label: station.name, value: station.id };
                              }),
                            ]
                      }
                      placeholder="Select Station"
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charging_station`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue('charging_station', val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {t('button.applyFilters')}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      ratingReview();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {t('button.clear')}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default RatingReviewReport;
