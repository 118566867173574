import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import AdminHeader from 'components/header';
import { Col, Row, Modal /*, Spinner*/ } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { FETCH_VEHICLE /*, DOWNLOAD_EXCEL_VEHICLE*/ } from 'actions/vehicle';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import { DELETE_VEHICLE } from 'actions/vehicle';
import PropTypes from 'prop-types';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
// import { BsDownload } from 'react-icons/bs';
// import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { AiOutlineCar } from 'react-icons/ai';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { CiCreditCardOff } from 'react-icons/ci';
import DropDown from 'components/inputs/DropDown';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { UPDATE_VEHICLE_RFID } from 'actions/rfid';

const ManageVehicle = () => {
  const { navigateTo } = useContext(NavContext);
  const [searchText, setSearchText] = useState('');
  const [currentTab, setCurrentTab] = useState('totalVehicles');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [vehicleId, setVehicleId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allVehicles = useSelector((state) => state.vehicle.vehicles);
  const isLoader = useSelector((state) => state.vehicle.isLoading);
  const page = useSelector((state) => state.vehicle.page);
  const page_no = useSelector((state) => state.vehicle.page_no);
  const totalData = useSelector((state) => state.vehicle.total);
  const limit = useSelector((state) => state.vehicle.limit);
  const totalPages = useSelector((state) => state.vehicle.totalPages);
  // const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');
  const currentUserId = _.get(profileData, 'id', '');

  const getVehicle = useCallback(
    (data) => {
      if (searchText) {
        const vehicleData = {
          ...data,
          search: searchText,
          fleet_owner: currentUserId,
        };
        dispatch({ type: FETCH_VEHICLE.REQUEST, payload: vehicleData });
      } else {
        const body = {
          ...data,
          fleet_owner: currentUserId,
        };
        dispatch({ type: FETCH_VEHICLE.REQUEST, payload: body });
      }
    },
    [currentUserId]
  );

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setVehicleId('');
  };

  const deleteVehicle = useCallback(
    () =>
      dispatch({
        type: DELETE_VEHICLE.REQUEST,
        payload: vehicleId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getVehicle({ page: 1 });
          }
        },
      }),
    [vehicleId]
  );

  // const downloadExcelVehicle = useCallback(() => {
  //   const data = {
  //     excel: true,
  //   };
  //   dispatch({
  //     type: DOWNLOAD_EXCEL_VEHICLE,
  //     payload: data,
  //     cb: (res) => {
  //       if (res) {
  //         if (_.get(res, 'status') === 200) {
  //           fileDownload(res.data, `${'Vehicle Data'}.xlsx`);
  //         }
  //       }
  //     },
  //   });
  // }, [currentUserId]);

  // const downloadFile = () => {
  //   downloadExcelVehicle();
  // };

  const updateVehicleRfid = useCallback(
    (id) => {
      dispatch({
        type: UPDATE_VEHICLE_RFID.REQUEST,
        payload: id,
        cb: (res) => {
          if (_.get(res, 'status', '') === 200) {
            getVehicle({ page: 1 });
          }
        },
      });
    },
    [allVehicles, dispatch]
  );

  useEffect(() => {
    if (page_no) {
      getVehicle({ page });
    } else {
      currentUserId && getVehicle();
    }
  }, [currentUserId]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (searchText) {
        const data = {
          search: searchText,
          page: page.selected + 1,
        };
        getVehicle(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getVehicle(data);
      }
    },
    [sortByItem, searchText]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getVehicle(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getVehicle(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === '') {
      // If search text is empty, load all data by passing an empty object
      getVehicle({});
    } else {
      // If search text is not empty, call the API with the current searchText
      getVehicle({ search: searchText, page: 1 });
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.vehicleManagement')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="vehicle-management-page_main">
              <div className="vehicle-management-card-block"></div>
              <div className="vehicle-list-main-block">
                <Card>
                  {currentTab === 'totalVehicles' && (
                    <div className="vehicle-list-block">
                      <div className="vehicle-search-box">
                        <Row>
                          <Col xl={2}>
                            <div className={`vehicle-card-block `} onClick={() => setCurrentTab('totalVehicles')}>
                              {
                                <div className="Vehicle-model-icon">
                                  {isLoader ? (
                                    <Skeleton circle="true" height={24} width={24} />
                                  ) : (
                                    <AiOutlineCar className="Vehicle-model-icon-photo" />
                                  )}
                                  {isLoader ? <Skeleton height={15} width={100} /> : totalData}
                                </div>
                              }
                            </div>
                          </Col>

                          <Col xxl={7} xl={5}>
                            <SearchBox
                              preIcon={<BsSearch />}
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  handleSearch();
                                }
                              }}
                            />
                          </Col>

                          <Col xl={'auto'} md={2}>
                            <div className="report-search-box" onClick={handleSearch}>
                              <Button className="add-vehicle-btn">{t('button.search')}</Button>
                            </div>
                          </Col>

                          <Col xl={'auto'} md={'auto'}>
                            {
                              <Button className="add-vehicle-btn" onClick={() => navigateTo('/Manage-Vehicle/addVehicle')}>
                                {t('button.addVehicle')}
                              </Button>
                            }
                          </Col>
                          {/* <Col xl={'auto'} md={'auto'}>
                            {isLoading ? (
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                              </div>
                            ) : (
                              <div className="report-excel-icon icon-pos">
                                <BsDownload size={28} color={'#3c7cdd'} onClick={downloadFile} id="dwnbtn" />
                              </div>
                            )}
                          </Col> */}
                        </Row>
                      </div>
                      <div className="vehicle-list-table">
                        <div className="table-responsive">
                          <table className="record-list-table">
                            <thead>
                              <tr>
                                <th>
                                  <div className="sorting">
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.srNo')}</span>
                                  </div>
                                </th>
                                <th>
                                  <div className="sorting">
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.evType')}</span>
                                    <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                                      <BiSort size={15} />
                                    </span>
                                  </div>
                                </th>
                                <th>
                                  <div className="sorting">
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.make')}</span>
                                    <span className="ico" onClick={() => handleSorting('make', sortByItem)}>
                                      <BiSort size={15} />
                                    </span>
                                  </div>
                                </th>
                                <th>
                                  <div className="sorting">
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.vehicleRegistrationNo')}</span>
                                    <span className="ico" onClick={() => handleSorting('rto_no', sortByItem)}>
                                      <BiSort size={15} />
                                    </span>
                                  </div>
                                </th>
                                <th>
                                  <div className="sorting">
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.assignedRfid')}</span>
                                    <span className="ico" onClick={() => handleSorting('RFID', sortByItem)}>
                                      <BiSort size={15} />
                                    </span>
                                  </div>
                                </th>
                                <th>
                                  <div className="sorting">
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.vehicleOwner')}</span>
                                  </div>
                                </th>
                                <th>
                                  <div>
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addVehicleList.action')}</span>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {isLoader ? (
                                <tr>
                                  <td colSpan={10} className="border-0">
                                    <Skeleton height={15} />
                                  </td>
                                </tr>
                              ) : _.isEmpty(allVehicles) ? (
                                <tr>
                                  <td colSpan={8} className="border-0">
                                    <div className="empty-data-block">{t('addVehicleList.noData')}</div>
                                  </td>
                                </tr>
                              ) : (
                                !(isLoader || _.isUndefined(isLoader)) &&
                                _.map(_.filter(allVehicles), (vehicle, index) => {
                                  const serial_num = limit * (page - 1) + index;
                                  return (
                                    <tr key={`vehicle-data-item-${serial_num}`}>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(vehicle, 'type', '')}</td>
                                      <td>
                                        {isLoader ? (
                                          <Skeleton height={15} width={100} />
                                        ) : (
                                          <>
                                            {_.get(vehicle, 'make', '')} ({_.get(vehicle, 'model', '')})
                                          </>
                                        )}
                                      </td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(vehicle, 'rto_no', '')}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(vehicle, 'rfid_object[0].code', '-')}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(vehicle, 'owner.name', '-')}</td>
                                      {
                                        <td>
                                          {/* <div key={`action-${index}`} className="vehicle-list">
                                            <span className="list-icon">
                                              <div className={'more-info'}>
                                                <span className="chargerStateList-table-editIcon">
                                                  {isLoader ? (
                                                    <Skeleton circle="true" height={24} width={24} />
                                                  ) : (
                                                    <FiEdit2 onClick={() => navigateTo(`/Manage-Vehicle/${vehicle.id}`)} />
                                                  )}
                                                </span>
                                                <span className="chargerStateList-table-deleteIcon">
                                                  {isLoader ? (
                                                    <Skeleton circle="true" height={24} width={24} />
                                                  ) : (
                                                    <RiDeleteBinLine
                                                      onClick={() => {
                                                        setShowDeleteModal(true);
                                                        setVehicleId(_.get(vehicle, 'id', ''));
                                                      }}
                                                    />
                                                  )}
                                                </span>
                                              </div>
                                            </span>
                                          </div> */}
                                          <DropDown
                                            trigger={
                                              <div className="dropdown-icon">
                                                <BsThreeDotsVertical size={23} />
                                              </div>
                                            }
                                            popupClass="bottom-left"
                                          >
                                            <ul className="vehicle-list-icon-list">
                                              <li className="item" onClick={() => navigateTo(`/Manage-Vehicle/${vehicle.id}`)}>
                                                <span className="vehicle-list-table-editIcon">
                                                  <FiEdit2 size={23} />
                                                </span>
                                                <span className="vehicle-list-text">{t('addVehicleList.editVehicle')}</span>
                                              </li>
                                              {!_.isEmpty(vehicle?.rfid_object) && (
                                                <li
                                                  className="item"
                                                  onClick={() => {
                                                    updateVehicleRfid(_.get(vehicle, 'rfid_object[0].id'));
                                                  }}
                                                >
                                                  <span className="vehicle-list-lock-unlock-icon">
                                                    <CiCreditCardOff size={23} />
                                                  </span>
                                                  <span className="vehicle-list-text">{t('addVehicleList.unassignRfid')}</span>
                                                </li>
                                              )}
                                              <li
                                                className="item"
                                                onClick={() => {
                                                  setShowDeleteModal(true);
                                                  setVehicleId(_.get(vehicle, 'id', ''));
                                                }}
                                              >
                                                <span className="vehicle-list-table-deleteIcon">
                                                  <RiDeleteBinLine size={23} />
                                                </span>
                                                <span className="vehicle-list-text">{t('addVehicleList.deleteVehicle')}</span>
                                              </li>
                                            </ul>
                                          </DropDown>
                                        </td>
                                      }
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allVehicles) && (
                        <ReactPagination
                          currentPage={page}
                          limit={limit}
                          total={totalData}
                          handlePageClick={(pageVal) => handlePageClick(pageVal)}
                          totalPages={totalPages}
                          marginPagesDisplayed={1}
                        />
                      )}
                      {showDeleteModal && (
                        <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                          <DeleteModal title="vehicle" onClose={handleCloseDeleteModel} onRemove={deleteVehicle} />
                        </Modal>
                      )}
                    </div>
                  )}
                  {currentTab === 'chargingVehicles' && (
                    <div className="d-flex align-items-center justify-content-center">{t('placeHolder.pending')}</div>
                  )}
                  {currentTab === 'finishedCharging' && (
                    <div className="d-flex align-items-center justify-content-center">{t('placeHolder.pending')}</div>
                  )}
                  {currentTab === 'vehiclesProblems' && (
                    <div className="d-flex align-items-center justify-content-center">{t('placeHolder.pending')}</div>
                  )}
                </Card>
              </div>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default ManageVehicle;

export const VehicleCard = (props) => {
  const { icon, title, subTitle, metaIcon } = props;

  return (
    <Card className="vehicle-card">
      <div className="vehicle-detail-box">
        <div className="vehicle-icon">
          <div className="car-icon">{icon}</div>
          <div className="vehicle-status-icon">{metaIcon}</div>
        </div>
        <div className="vehicle__count-info">
          <div className="total-vehicle">{title}</div>
          <div className="info-text">{subTitle}</div>
        </div>
      </div>
    </Card>
  );
};

VehicleCard.propTypes = {
  /**
   * Card icon
   */
  icon: PropTypes.node,
  /**meta icon */
  metaIcon: PropTypes.node,
  /**
   * Vehicle title
   */
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Vehical sub-title
   */
  subTitle: PropTypes.string,
};
