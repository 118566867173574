import React, { useState, useEffect, useCallback, useContext } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import * as _ from 'lodash';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/inputs/Card';
import { Modal } from 'react-bootstrap';
import PricingForm from './PricingForm';
import { FETCH_TARIFF, GET_TARIFF } from 'actions/tariff';
import ReactPagination from 'components/general/ReactPagination';
import { DELETE_TARIFF } from 'actions/tariff';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import Button from 'components/inputs/Button';
import { BiSort } from 'react-icons/bi';
// import { roundOfDigit } from 'components/common/utils';
import { AiOutlineCalendar } from 'react-icons/ai';
// import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const TariffList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tariffId, setTariffId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseTariffData, setPageWiseTariffData] = useState([]);

  const allTariffList = useSelector((state) => state.tariff.tariff);
  const isLoader = useSelector((state) => state.tariff.isLoading);
  const page = useSelector((state) => state.tariff.page);
  const totalData = useSelector((state) => state.tariff.total);
  const limit = useSelector((state) => state.tariff.limit);
  const totalPages = useSelector((state) => state.tariff.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const tariffData = allTariffList.filter(({ id: id1 }) => pageWiseTariffData.some(({ id: id2 }) => id2 === id1));
  // const history = useHistory();
  const { navigateTo, isAllowed } = useContext(NavContext);
  const deleteTariff = useCallback(
    () =>
      dispatch({
        type: DELETE_TARIFF.REQUEST,
        payload: tariffId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getTariff({ page: 1 });
          }
        },
      }),
    [tariffId]
  );

  const getTariff = useCallback(
    (data = {}) => {
      dispatch({
        type: FETCH_TARIFF.REQUEST,
        payload: data ? data : {},
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              setPageWiseTariffData(res.data.results);
            }
          }
        },
      });
    },
    [pageWiseTariffData, allTariffList]
  );

  const getTariffDetail = useCallback((id) => {
    dispatch({ type: GET_TARIFF.REQUEST, payload: id });
  }, []);

  useEffect(() => {
    getTariff();
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getTariff(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getTariff(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getTariff(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setTariffId('');
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.tariffs')} />
        <div className="page-content-wrapper scrollable">
          <div className="existing-pricing__main">
            <div className="existing-pricing--block">
              <Card>
                <div className="pricing-block-content">
                  <div className="pricing-block-text">{t('addTariffList.tariffs')}</div>
                  {isAllowed('modifyTariffs') && (
                    <div
                      className="add-new-price"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      <Button>{t('button.addTarrif')}</Button>
                    </div>
                  )}
                </div>
                <div className="pricing-list-table">
                  <div className="table-responsive">
                    <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
                      <table className="record-list-table">
                        <thead>
                          <tr key={`tariff-header-row`}>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addTariffList.name')}</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addTariffList.DefaultPriceUnit')}</span>
                                <span className="ico" onClick={() => handleSorting('default_price_per_unit', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addTariffList.oem')}</span>
                              </div>
                            </th>
                            {/* <th>
                            <div className="sorting">
                              <span>Load/Type (kWh)</span>
                              <span className="ico" onClick={() => handleSorting('load', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th> */}
                            {isAllowed('modifyTariffs') && (
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton width={100} height={15} /> : t('addTariffList.action')}</span>
                                </div>
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td>
                                <Skeleton height={15} width={100} />
                              </td>
                              <td>
                                <Skeleton height={15} width={100} />
                              </td>
                              <td>
                                <Skeleton height={15} width={100} />
                              </td>
                              <td>
                                <Skeleton height={15} width={100} />
                              </td>
                            </tr>
                          ) : _.isEmpty(tariffData) ? (
                            <tr key={`tariff-empty-row`}>
                              <td colSpan={5}>
                                <div className="empty-data-block">{t('addTariffList.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(tariffData, (tariff, index) => {
                              // const time = moment(new Date()).format('HH:mm');
                              // const defaultPrice = _.get(tariff, 'default_price_per_unit', '');
                              // let price;
                              // if (!_.isEmpty(tariff?.schedule)) {
                              //   for (let i = 0; i < tariff.schedule.length; i++) {
                              //     if (time >= tariff?.schedule[i].from && time <= tariff?.schedule[i].to) {
                              //       for (let i = 0; i < tariff.schedule.length; i++) {
                              //         price = tariff?.schedule[i].price_per_unit;
                              //       }
                              //     } else {
                              //       price = defaultPrice;
                              //     }
                              //   }
                              // } else if (_.isEmpty(tariff?.schedule)) {
                              //   price = defaultPrice;
                              // } else {
                              //   price = defaultPrice;
                              // }
                              return (
                                <tr key={`tariff-${index}`}>
                                  <td>{isLoader ? <Skeleton width={100} height={15} /> : _.get(tariff, 'name')}</td>
                                  <td>{isLoader ? <Skeleton width={100} height={15} /> : _.get(tariff, 'default_price_per_unit', '')}</td>
                                  <td>{isLoader ? <Skeleton width={100} height={15} /> : _.get(tariff, 'oem.name', 'All')}</td>
                                  {/* <td>{roundOfDigit(_.get(tariff, 'load', ''), 3)}</td> */}
                                  {isAllowed('modifyTariffs') && (
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div
                                          className="edit-icon slot-icon"
                                          title="Add slot to tariff"
                                          onClick={() => navigateTo(`/editaddSlot/${_.get(tariff, 'id')}`)}
                                        >
                                          {isLoader ? <Skeleton circle="true" width={24} height={24} /> : <AiOutlineCalendar />}
                                        </div>
                                        <div
                                          title="Edit Tariff"
                                          className="edit-icon"
                                          onClick={() => {
                                            setShowForm(true);
                                            getTariffDetail(tariff.id);
                                            setIsEdit(true);
                                          }}
                                        >
                                          {isLoader ? <Skeleton circle="true" width={24} height={24} /> : <FiEdit2 title="Edit" />}
                                        </div>
                                        <div
                                          title="Delete Tariff"
                                          className="delete-icon"
                                          onClick={() => {
                                            setShowDeleteModal(true);
                                            setTariffId(_.get(tariff, 'id', ''));
                                          }}
                                        >
                                          {isLoader ? <Skeleton circle="true" width={24} height={24} /> : <RiDeleteBinLine title="Delete" />}
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </SkeletonTheme>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(tariffData) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </Card>
            </div>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>

        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <PricingForm onClose={() => setShowForm(false)} isEdit={isEdit} onSuccess={getTariff} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="tariff" onClose={handleCloseDeleteModel} onRemove={deleteTariff} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};

export default TariffList;
