import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IoCallOutline, IoMaleFemaleSharp } from 'react-icons/io5';
import { FaBirthdayCake } from 'react-icons/fa';
import { AiOutlineHome, AiOutlineMail } from 'react-icons/ai';
import InformationBlock from 'components/general/InformationBlock';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
const Profile = () => {
  const profileData = useSelector((state) => state.profile.userProfile);
  const phoneNumber = `${_.get(profileData, 'country_code', ' ')}${_.get(profileData, 'phone', ' ')}`;
  const { t } = useTranslation();
  return (
    <div className="profile-details__main">
      <div className="profile-details-inner">
        <div className="profile--content">
          <Row>
            {profileData.gender ? (
              <Col xl={3} md={6} className="profile-details--wrapper">
                <InformationBlock
                  icon={<IoMaleFemaleSharp />}
                  infoTitle={_.capitalize(_.get(profileData, 'gender', '#NA'))}
                  infoText={t('profile.gender')}
                />
              </Col>
            ) : (
              ''
            )}

            {profileData.dob ? (
              <Col xl={3} md={6} className="profile-details--wrapper">
                <InformationBlock
                  icon={<FaBirthdayCake />}
                  infoTitle={profileData.dob ? moment(profileData.dob).format('LL') : ''}
                  infoText={t('profile.birthday')}
                />
              </Col>
            ) : (
              ''
            )}

            <Col xl={3} md={6} className="profile-details--wrapper">
              <InformationBlock icon={<AiOutlineMail />} infoTitle={_.get(profileData, 'email', '')} infoText={t('profile.email')} />
            </Col>
            <Col xl={3} md={6} className="profile-details--wrapper">
              <InformationBlock
                icon={<IoCallOutline />}
                infoTitle={!_.isEmpty(phoneNumber) ? `+${phoneNumber}` : ''}
                infoText={t('profile.phoneNo')}
              />
            </Col>
            {profileData.address ? (
              <Col xl={12} md={12} className="profile-details--wrapper">
                <InformationBlock icon={<AiOutlineHome />} infoTitle={_.get(profileData, 'address', '')} infoText={t('profile.address')} />
              </Col>
            ) : (
              ''
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};
export default Profile;
