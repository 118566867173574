import { GET_STATION_BY_CITY } from '../actions/chargingStation';

const initialState = {
  isLoading: false,
  stationByCities: [],
};

const stationByCityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_STATION_BY_CITY.REQUEST: {
      return { ...state, isLoading: true };
    }

    case GET_STATION_BY_CITY.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stationByCities: payload,
      };
    }

    case GET_STATION_BY_CITY.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default stationByCityReducer;
