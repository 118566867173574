import React, { useState, useEffect, useRef, useCallback } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import TextInput from 'components/inputs/Input';
import { BsFillFileEarmarkZipFill, BsUpload } from 'react-icons/bs';
import * as _ from 'lodash';
// import Avatar from 'components/inputs/Avatar';
// import DummyImage from 'assets/images/icons/dummyImage.png';
import Dropzone from 'react-dropzone';
// import { startsWith } from 'components/common/utils';
import Button from 'components/inputs/Button';
import { AiOutlineClose, AiOutlineFile, AiOutlineFileJpg, AiOutlineFilePdf } from 'react-icons/ai';
import { FcVideoFile } from 'react-icons/fc';
import { FaRegFileAudio } from 'react-icons/fa';
// import SearchBox from 'components/general/SearchBox';
import { Col, Row } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import { FETCH_OEM_VENDOR } from '../../actions/oemVendor';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { FETCH_OEM, OEM_UPDATE_FIRMWARE } from 'actions/oem';
import axios from 'axios';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { cookie } from 'utils/cookies/cookies';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const FirmWareUpdate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = cookie.get('token');
  const allOemVendor = useSelector((state) => state.oemVendor.oemVendors);
  const allOems = useSelector((state) => state.oem.oems);
  // const [searchText, setSearchText] = useState('');
  const [files, setFiles] = useState({});
  const [showFileError, setShowFileError] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(files)) {
      setShowFileError(false);
    }
  }, [files, showFileError]);

  useEffect(() => {
    setFiles([]);
  }, []);

  useEffect(() => {
    getOemVendor();
  }, []);

  const getOemVendor = useCallback((data = {}) => {
    dispatch({ type: FETCH_OEM_VENDOR.REQUEST, payload: data });
  }, []);

  const getChargeSpotByOem = useCallback((id = '') => {
    dispatch({ type: FETCH_OEM.REQUEST, payload: { vendor: id } });
  }, []);

  const dropZoneRef = useRef(null);

  // const tenantAdminDetails = [
  //   {
  //     image: '',
  //     name: 'imogene james',
  //     version: 'V. 2.1',
  //   },
  //   {
  //     image: '',
  //     name: 'Larry james',
  //     version: 'V. 2.0',
  //   },
  //   {
  //     image: '',
  //     name: 'Gaye Garland',
  //     version: 'V. 1.6',
  //   },
  //   {
  //     image: '',
  //     name: 'Frank Day',
  //     version: 'V. 1.8',
  //   },
  //   {
  //     image: '',
  //     name: 'Gaye Garland',
  //     version: 'V. 1.6',
  //   },
  //   {
  //     image: '',
  //     name: 'Larry james',
  //     version: 'V. 2.1',
  //   },
  // ];

  // const searchHandler = (event) => {
  //   const value = event.target.value;
  //   setSearchText(value);
  // };

  // const tenantAdminList = _.orderBy(_.filter(tenantAdminDetails, (tenantAdminUser) => startsWith(tenantAdminUser.name, searchText)));

  const onRemoveFile = (removedFile) => {
    const newFiles = files.filter((file) => file.name !== removedFile.name);
    setFiles(newFiles);
  };

  const checkIfFileIs = (filetype, matches) => {
    const uploadedFileType = filetype.substr(0, filetype.indexOf('/'));
    return uploadedFileType.indexOf(matches) >= 0 || filetype === matches;
  };

  const handleFileUpload = (acceptedFiles, path, setFieldValue) => {
    setFiles(acceptedFiles);
    const uploadedFile = acceptedFiles[0];
    const fileName = _.get(uploadedFile, 'name', '');
    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, uploadedFile, path, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const handleUploadFile = (url, uploadFile, path, setFieldValue) => {
    const fileType = _.get(uploadFile, 'type', '');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: uploadFile,
    })
      .then((res) => {
        if (res.status === 200) {
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(path, imgUrlData.url);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const OemSchema = Yup.object().shape({
    vendor: Yup.string().required(t('firmWareUpdate.vendor')),
    oemId: Yup.string().required(t('firmWareUpdate.oemId')),
    version: Yup.string().required(t('firmWareUpdate.version')),
  });

  const initialValues = {
    vendor: '',
    oemId: '',
    version: '',
    file: '',
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.firmwareUpdate')} />
        <div className="page-content-wrapper scrollable">
          <div className="firmWareUpdate-page__main">
            <div className="firmWareUpdate--version">
              <Card>
                <div className="firmWareUpdate-version-wrapper">
                  <span className="firmWareUpdate-version-text">{t('firmWareUpdate.currentVersion')}</span>
                  <span className="firmWareUpdate-version-text">{t('firmWareUpdate.versionNo')}</span>
                </div>
              </Card>
            </div>
            <div className="firmWareUpdate-upload--file">
              <Card>
                <div className="firmWareUpdate-upload-file-text">
                  <span>{t('firmWareUpdate.uploadFile')}</span>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={OemSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (_.isEmpty(files)) {
                      setShowFileError(true);
                    } else {
                      dispatch({ type: OEM_UPDATE_FIRMWARE.REQUEST, payload: _.omit(values, 'vendor') });
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, handleSubmit, handleChange, setFieldValue, isSubmitting, errors, touched }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={6}>
                          <Select
                            isRequired
                            label={t('filters.oem')}
                            options={_.map(allOemVendor, (item) => {
                              return { label: item.name, value: item.id };
                            })}
                            placeholder={t('placeHolder.selectOEM')}
                            name="vendor"
                            value={_.get(values, 'vendor', '')}
                            onChange={(val) => {
                              setFieldValue(`vendor`, val);
                              getChargeSpotByOem(val);
                            }}
                            error={errors.vendor && touched.vendor ? errors.vendor : null}
                          />
                        </Col>
                        <Col lg={6}>
                          <Select
                            isRequired
                            label={t('filters.chargeSpot')}
                            options={_.map(allOems, (item) => {
                              return { label: item.name, value: item.id };
                            })}
                            placeholder={t('placeHolder.selectChargeSport')}
                            name="oemId"
                            value={_.get(values, 'oemId', '')}
                            onChange={(val) => {
                              setFieldValue(`oemId`, val);
                            }}
                            error={errors.oemId && touched.oemId ? errors.oemId : null}
                          />
                        </Col>
                      </Row>
                      <TextInput
                        isRequired
                        label={t('filters.version')}
                        name="version"
                        placeholder={t('placeHolder.versionNo')}
                        value={values.version}
                        onChange={handleChange}
                        error={errors.version && touched.version ? errors.version : null}
                      />
                      {files && files.length > 0 ? (
                        _.map(files, (file) => {
                          return (
                            <>
                              <div className="firmWareUpdate-uploaded-file-list">
                                <div className="firmWareUpdate-uploaded-file-list-wrapper">
                                  <div className="firmWareUpdate-uploaded--fileData">
                                    {checkIfFileIs(file['type'], 'image') ? (
                                      <AiOutlineFileJpg />
                                    ) : checkIfFileIs(file['type'], 'video') ? (
                                      <FcVideoFile />
                                    ) : checkIfFileIs(file['type'], 'application/zip') ? (
                                      <BsFillFileEarmarkZipFill />
                                    ) : checkIfFileIs(file['type'], 'audio') ? (
                                      <FaRegFileAudio />
                                    ) : checkIfFileIs(file['type'], 'application/pdf') ? (
                                      <AiOutlineFilePdf />
                                    ) : (
                                      <AiOutlineFile />
                                    )}
                                    <span className="firmWareUpdate-uploaded-file-list-text">{file.name}</span>
                                  </div>
                                  <div className="firmWareUpdate-uploaded-file-list-closeBtn" onClick={() => onRemoveFile(file)}>
                                    <AiOutlineClose />
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <Dropzone ref={dropZoneRef} noKeyboard>
                          {({ getRootProps, getInputProps, acceptedFiles }) => {
                            return (
                              <div className="field input-field file-upload-field">
                                <div className="firmWareUpdate-upload-file-box">
                                  <div {...getRootProps()} className="firmWareUpdate-upload-file-box-wrapper">
                                    <input {...getInputProps()} />
                                    <div className="firmWareUpdate-upload-file-box-content">
                                      <span className="firmWareUpdate-upload-file-box-icon">
                                        <BsUpload />
                                      </span>
                                      <span className="firmWareUpdate-upload-file-box-text">{t('button.uploadFiles')}</span>
                                    </div>
                                  </div>
                                </div>
                                <aside>
                                  {acceptedFiles && acceptedFiles.length > 0 && handleFileUpload([...files, ...acceptedFiles], 'file', setFieldValue)}
                                </aside>
                              </div>
                            );
                          }}
                        </Dropzone>
                      )}
                      {showFileError && <div className="firmWareUpdate--text">{t('firmWareUpdate.file')}</div>}
                      <div className="firmWareUpdate-upload-file-btn">
                        <Button type="submit" disabled={isSubmitting} className="firmWareUpdate-upload-file-btn-wrapper">
                          Save
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card>
            </div>
            {/*<div className="firmWareUpdate--notification">*/}
            {/*  <Card>*/}
            {/*    <div className="firmWareUpdate-notification-wrapper">*/}
            {/*      <div className="firmWareUpdate-notification-text">Send update notification</div>*/}
            {/*      <div className="firmWareUpdate-notification-search-box">*/}
            {/*        <SearchBox value={searchText} preIcon={<BsSearch />} onChange={searchHandler} />*/}
            {/*      </div>*/}
            {/*      <div className="firmWareUpdate-notification-table scrollable">*/}
            {/*        <div className="table-responsive">*/}
            {/*          <table className="record-list-table">*/}
            {/*            <thead>*/}
            {/*              <tr>*/}
            {/*                <th>*/}
            {/*                  <div className="sorting">*/}
            {/*                    <span>Tenants Admins</span>*/}
            {/*                  </div>*/}
            {/*                </th>*/}
            {/*              </tr>*/}
            {/*            </thead>*/}
            {/*            <tbody>*/}
            {/*              {_.map(tenantAdminList, (item, key) => {*/}
            {/*                return (*/}
            {/*                  <tr key={`tenant-${key}`}>*/}
            {/*                    <td>*/}
            {/*                      <div className="firmWareUpdate-notification-nameList">*/}
            {/*                        <Avatar className="firmWareUpdate-notification-avatarList" imgSrc={DummyImage} name={''} />*/}
            {/*                        <span>{item.name}</span>*/}
            {/*                      </div>*/}
            {/*                    </td>*/}
            {/*                    <td className="text-center">{item.version}</td>*/}
            {/*                    <td className="text-center">*/}
            {/*                      <span className="firmWareUpdate-notification-table-revertIcon">Revert</span>*/}
            {/*                      <span className="firmWareUpdate-notification-table-sendUpdateIcon">Send Update</span>*/}
            {/*                    </td>*/}
            {/*                  </tr>*/}
            {/*                );*/}
            {/*              })}*/}
            {/*            </tbody>*/}
            {/*          </table>*/}
            {/*        </div>*/}
            {/*        {searchText && _.size(tenantAdminList) === 0 && (*/}
            {/*          <p className=" text-center my-5">*/}
            {/*            <span>No member found</span>*/}
            {/*          </p>*/}
            {/*        )}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</div>*/}
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default FirmWareUpdate;
