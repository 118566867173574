import React, { useState } from 'react';
import ChangeAvailabilityForm from './serverOperations/ChangeAvailabilityForm';
import CancelReservationForm from './serverOperations/CancelReservationForm';
import ConfigurationList from './serverOperations/ConfigurationList';
import GetDiagnosticsForm from './serverOperations/GetDiagnosticsForm';
import RemoteStartTransactionForm from './serverOperations/RemoteStartTransaction';
import RemoteStopTransactionForm from './serverOperations/RemoteStopTransaction';
import ReserveNowForm from './serverOperations/ReserveNowForm';
import ResetForm from './serverOperations/ResetForm';
import SendLocalListForm from './serverOperations/SendLocalListForm';
import TriggerMessageForm from './serverOperations/TriggerMessageForm';
import UnlockConnectorForm from './serverOperations/UnlockConnectorForm';
import UpdateFirmwareForm from './serverOperations/UpdateFirmwareForm';
import ClearCacheForm from './serverOperations/ClearCacheForm';
import GetLocalListVersionForm from './serverOperations/GetLocalListVersionForm';
import DataTransferServerForm from './serverOperations/DataTransferServerForm';
import SetChargingProfileForm from './serverOperations/SetChargingProgile';
import ClearChargingProfileForm from './serverOperations/ClearCharging';

const ServerOperationInfo = () => {
  const [currentTab, setCurrentTab] = useState('cancelReservation');

  const setTab = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };
  return (
    <div className="serverOperation_main_page">
      <div className="tab--block">
        <div className="tab-nav--block">
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'cancelReservation' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'cancelReservation')}
            data-content="tab-cancelReservation"
          >
            Cancel Reservation
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'changeAvailability' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'changeAvailability')}
            data-content="tab-changeAvailability"
          >
            Change Availability
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'setConfiguration' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'setConfiguration')}
            data-content="tab-setConfiguration"
          >
            Get / Set Configuration
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'clearCache' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'clearCache')}
            data-content="tab-clearCache"
          >
            Clear Cache
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'getDiagnostics' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'getDiagnostics')}
            data-content="tab-getDiagnostics"
          >
            Get Diagnostics
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'getLocalListVersion' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'getLocalListVersion')}
            data-content="tab-getLocalListVersion"
          >
            Get Local List Version
          </a>{' '}
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'remoteStartTransaction' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'remoteStartTransaction')}
            data-content="tab-remoteStartTransaction"
          >
            Remote Start Transaction
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'remoteStopTransaction' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'remoteStopTransaction')}
            data-content="tab-remoteStopTransaction"
          >
            Remote Stop Transaction
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'reserveNow' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'reserveNow')}
            data-content="tab-reserveNow"
          >
            Reserve Now
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'reset' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'reset')}
            data-content="tab-reset"
          >
            Reset
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'sendLocalList' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'sendLocalList')}
            data-content="tab-sendLocalList"
          >
            Send Local List
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'triggerMessage' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'triggerMessage')}
            data-content="tab-triggerMessage"
          >
            Trigger Message
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'unlockConnector' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'unlockConnector')}
            data-content="tab-unlockConnector"
          >
            Unlock Connector
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'updateFirmware' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'updateFirmware')}
            data-content="tab-updateFirmware"
          >
            Update Firmware
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'dataTransferServer' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'dataTransferServer')}
            data-content="tab-dataTransferServer"
          >
            Data Transfer Server
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'setchargingprofile' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'setchargingprofile')}
            data-content="tab-setchargingprofiler"
          >
            Set Charging Profile
          </a>
          <a
            href="#"
            className={`tab-nav__item ${currentTab === 'clearchargingprofile' ? 'active' : ''}`}
            onClick={(e) => setTab(e, 'clearchargingprofile')}
            data-content="tab-clearchargingprofile"
          >
            Clear Charging Profile
          </a>
        </div>
        <div className="tab-content-block">
          <div className="tab-content___item active" id="station-details-tab">
            {currentTab === 'cancelReservation' && <CancelReservationForm />}
            {currentTab === 'changeAvailability' && <ChangeAvailabilityForm />}
            {currentTab === 'setConfiguration' && <ConfigurationList />}
            {currentTab === 'clearCache' && <ClearCacheForm />}
            {currentTab === 'getDiagnostics' && <GetDiagnosticsForm />}
            {currentTab === 'getLocalListVersion' && <GetLocalListVersionForm />}
            {currentTab === 'remoteStartTransaction' && <RemoteStartTransactionForm />}
            {currentTab === 'remoteStopTransaction' && <RemoteStopTransactionForm />}
            {currentTab === 'reserveNow' && <ReserveNowForm />}
            {currentTab === 'reset' && <ResetForm />}
            {currentTab === 'sendLocalList' && <SendLocalListForm />}
            {currentTab === 'triggerMessage' && <TriggerMessageForm />}
            {currentTab === 'unlockConnector' && <UnlockConnectorForm />}
            {currentTab === 'updateFirmware' && <UpdateFirmwareForm />}
            {currentTab === 'dataTransferServer' && <DataTransferServerForm />}
            {currentTab === 'setchargingprofile' && <SetChargingProfileForm />}
            {currentTab === 'clearchargingprofile' && <ClearChargingProfileForm />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServerOperationInfo;
