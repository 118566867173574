import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_OEM, GET_OEM, CREATE_OEM, UPDATE_OEM, DELETE_OEM, OEM_UPDATE_FIRMWARE } from 'actions/oem';

function* fetchOem(action) {
  try {
    const response = yield call(API.getOems, action.payload);
    yield put({ type: FETCH_OEM.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_OEM.FAIL, payload: { error: e } });
  }
}

function* getOemById(action) {
  try {
    const response = yield call(API.getOemById, action.payload);
    yield put({ type: GET_OEM.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_OEM.FAIL, payload: { error: e } });
  }
}

function* createOem(action) {
  try {
    const response = yield call(API.addOem, action.payload);
    yield put({ type: CREATE_OEM.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: CREATE_OEM.FAIL, payload: { error: e } });
  }
}

function* updateOem(action) {
  try {
    const response = yield call(API.updateOem, action.payload);
    yield put({ type: UPDATE_OEM.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: UPDATE_OEM.FAIL, payload: { error: e } });
  }
}

function* updateOemFirmware(action) {
  try {
    const response = yield call(API.updateOemFirmware, action.payload);
    yield put({ type: OEM_UPDATE_FIRMWARE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: OEM_UPDATE_FIRMWARE.FAIL, payload: { error: e } });
  }
}

function* deleteOem(action) {
  try {
    const response = yield call(API.deleteOem, action.payload);
    yield put({ type: DELETE_OEM.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_OEM.FAIL, payload: { error: e } });
  }
}

function* oem() {
  yield takeLatest(FETCH_OEM.REQUEST, fetchOem);
  yield takeLatest(GET_OEM.REQUEST, getOemById);
  yield takeLatest(CREATE_OEM.REQUEST, createOem);
  yield takeLatest(UPDATE_OEM.REQUEST, updateOem);
  yield takeLatest(DELETE_OEM.REQUEST, deleteOem);
  yield takeLatest(OEM_UPDATE_FIRMWARE.REQUEST, updateOemFirmware);
}

export default oem;
