import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CREATE_VEHICLE,
  CREATE_MULTIPLE_VEHICLE,
  FETCH_VEHICLE,
  GET_VEHICLE,
  DELETE_VEHICLE,
  UPDATE_VEHICLE,
  DOWNLOAD_EXCEL_VEHICLE,
  UPDATE_VRN,
} from 'actions/vehicle';

function* getVehicle(action) {
  try {
    const response = yield call(API.getVehicle, action.payload);
    yield put({ type: FETCH_VEHICLE.SUCCESS, payload: response });
  } catch (e) {
    yield put({ type: FETCH_VEHICLE.FAIL, payload: { error: e } });
  }
}

function* getVehicleById(action) {
  try {
    const response = yield call(API.getVehicleById, action.payload);
    yield put({ type: GET_VEHICLE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_VEHICLE.FAIL, payload: { error: e } });
  }
}

function* createVehicle(action) {
  try {
    const response = yield call(API.addVehicle, action.payload);
    yield put({ type: CREATE_VEHICLE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_VEHICLE.FAIL, payload: { error: e } });
  }
}

function* createMultipleVehicle(action) {
  try {
    const response = yield call(API.addMultipleVehicle, action.payload);
    yield put({ type: CREATE_MULTIPLE_VEHICLE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_MULTIPLE_VEHICLE.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateVehicle(action) {
  try {
    const response = yield call(API.updateVehicle, action.payload);
    yield put({ type: UPDATE_VEHICLE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VEHICLE.FAIL, payload: { error: e } });
  }
}
function* deleteVehicle(action) {
  try {
    const response = yield call(API.deleteVehicle, action.payload);
    yield put({ type: DELETE_VEHICLE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_VEHICLE.FAIL, payload: { error: e } });
  }
}

function* downloadExcelVehicle(action) {
  try {
    const response = yield call(API.downloadExcelVehicle, action.payload);
    yield put({ type: DOWNLOAD_EXCEL_VEHICLE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_EXCEL_VEHICLE.FAIL, payload: { error: e } });
  }
}

function* updateVRN(action) {
  try {
    const response = yield call(API.updateVRN, action.payload);
    yield put({ type: UPDATE_VRN.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VRN.FAIL, payload: { error: e } });
  }
}

function* vehicle() {
  yield takeLatest(FETCH_VEHICLE.REQUEST, getVehicle);
  yield takeLatest(CREATE_VEHICLE.REQUEST, createVehicle);
  yield takeLatest(CREATE_MULTIPLE_VEHICLE.REQUEST, createMultipleVehicle);
  yield takeLatest(GET_VEHICLE.REQUEST, getVehicleById);
  yield takeLatest(DELETE_VEHICLE.REQUEST, deleteVehicle);
  yield takeLatest(UPDATE_VEHICLE.REQUEST, updateVehicle);
  yield takeLatest(DOWNLOAD_EXCEL_VEHICLE.REQUEST, downloadExcelVehicle);
  yield takeLatest(UPDATE_VRN.REQUEST, updateVRN);
}

export default vehicle;
