import { CREATE_RESERVE_SLOTS, FETCH_RESERVE_SLOTS, GET_RESERVE_SLOTS, DELETE_RESERVE_SLOTS, UPDATE_RESERVE_SLOTS } from 'actions/reserveSlots';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  reserveSlots: [],
  reserveSlotDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const reserveSlotsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RESERVE_SLOTS.REQUEST:
    case CREATE_RESERVE_SLOTS.REQUEST:
    case GET_RESERVE_SLOTS.REQUEST:
    case DELETE_RESERVE_SLOTS.REQUEST: {
      return { ...state, isLoading: true, reserveSlotDetail: {} };
    }

    case UPDATE_RESERVE_SLOTS.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_RESERVE_SLOTS.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          reserveSlots: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          // reserveSlots: _.concat(state.reserveSlots, payload.results),
          reserveSlots: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_RESERVE_SLOTS.SUCCESS: {
      return {
        ...state,
        reserveSlotDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_RESERVE_SLOTS.SUCCESS: {
      toast.success(' Reserve slot creaded successfully');
      return {
        ...state,
        reserveSlots: _.concat(state.reserveSlots, payload),
        isLoading: false,
      };
    }

    case UPDATE_RESERVE_SLOTS.SUCCESS: {
      toast.success('Reserve slot updated successfully');
      return {
        ...state,
        isLoading: false,
        // reserveSlots: state.reserveSlots.filter((data) => data.id === payload.reserveSlotId ? payload : data),
        reserveSlots: state.reserveSlots.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_RESERVE_SLOTS.SUCCESS: {
      toast.success('Reserve slot deleted successfully');
      return {
        ...state,
        isLoading: false,
        reserveSlots: state.reserveSlots.filter((data) => data.id !== payload),
      };
    }

    case FETCH_RESERVE_SLOTS.FAIL:
    case CREATE_RESERVE_SLOTS.FAIL:
    case GET_RESERVE_SLOTS.FAIL:
    case DELETE_RESERVE_SLOTS.FAIL:
    case UPDATE_RESERVE_SLOTS.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default reserveSlotsReducer;
