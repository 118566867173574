import {
  FETCH_VOUCHERS,
  FETCH_VOUCHERS_SUCCESS,
  FETCH_VOUCHERS_FAIL,
  DELETE_VOUCHER,
  DELETE_VOUCHER_SUCCESS,
  DELETE_VOUCHER_FAIL,
  UPDATE_VOUCHER,
  UPDATE_VOUCHER_SUCCESS,
  UPDATE_VOUCHER_FAIL,
  CREATE_VOUCHER,
  CREATE_VOUCHER_SUCCESS,
  CREATE_VOUCHER_FAIL,
  GET_VOUCHER,
  GET_VOUCHER_SUCCESS,
  GET_VOUCHER_FAIL,
  VOUCHER_LOGS,
  VOUCHER_LOGS_SUCCESS,
  VOUCHER_LOGS_FAIL,
} from 'actions/voucher';
import { toast } from 'react-toastify';
import _ from 'lodash';

const initialState = {
  isLoading: false,
  vouchers: [],
  voucherDetail: {},
  voucherLogs: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const voucherReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_VOUCHER:
    case DELETE_VOUCHER:
    case FETCH_VOUCHERS:
    case CREATE_VOUCHER:
    case GET_VOUCHER:
    case VOUCHER_LOGS: {
      return { ...state, isLoading: true };
    }

    case FETCH_VOUCHERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        vouchers: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_VOUCHER_SUCCESS: {
      return {
        ...state,
        voucherDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_VOUCHER_SUCCESS: {
      toast.success('Voucher added successfully');
      return {
        ...state,
        vouchers: _.concat(state.vouchers, payload),
        isLoading: false,
      };
    }

    case VOUCHER_LOGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        voucherLogs: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case UPDATE_VOUCHER_SUCCESS: {
      toast.success('Voucher updated successfully');
      return {
        ...state,
        isLoading: false,
        vouchers: state.vouchers.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_VOUCHER_SUCCESS: {
      toast.success('Voucher deleted successfully');
      return {
        ...state,
        vouchers: state.vouchers.filter((data) => data.id !== payload),
      };
    }

    case DELETE_VOUCHER_FAIL:
    case UPDATE_VOUCHER_FAIL:
    case FETCH_VOUCHERS_FAIL:
    case CREATE_VOUCHER_FAIL:
    case GET_VOUCHER_FAIL:
    case VOUCHER_LOGS_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};

export default voucherReducer;
