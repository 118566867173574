import React, { useState, useCallback, useContext } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import { Formik, Form } from 'formik';
import { RiDeleteBinLine } from 'react-icons/ri';
import Button from 'components/inputs/Button';
import { useDispatch, useSelector } from 'react-redux';
//import { Spinner } from 'react-bootstrap';
import DeleteModal from 'components/general/DeleteModal';
import { UPDATE_CHARGE_COIN_LEVEL, DELETE_CHARGE_COIN_LEVEL } from 'actions/chargeCoin';
import * as _ from 'lodash';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';
const Levels = () => {
  const dispatch = useDispatch();
  const chargeCoinDetails = useSelector((state) => (state.chargeCoin.results[0] ? state.chargeCoin.results[0] : state.chargeCoin.results));
  const isLoader = useSelector((state) => state.chargeCoin.isLoading);
  const { t } = useTranslation();
  const { isAllowed } = useContext(NavContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [row, setRow] = useState();

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
  };

  const updateChargeCoin = useCallback((data) => {
    dispatch({
      type: UPDATE_CHARGE_COIN_LEVEL.REQUEST,
      payload: data,
    });
  });

  const deleteChargeCoinLevel = useCallback((data) => {
    dispatch({
      type: DELETE_CHARGE_COIN_LEVEL.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status', '') === UPDATE_STATUS_CODE) {
          handleCloseDeleteModel();
        }
      },
    });
  });

  const initialValues = !_.isEmpty(chargeCoinDetails)
    ? {
        results: {
          ...chargeCoinDetails,
        },
      }
    : {
        results: {
          key_value: {
            levels: [{ name: '', min_units_consumed: '', reward_per_unit: '', redemption_percent_of_booking: '' }],
          },
        },
      };

  return (
    <div className="profile-details__main">
      <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
        <div className="profile-details-inner">
          {isLoader ? (
            <div className="setting-page__main">
              <div className="loader--block">
                <Skeleton height={15} width={100} />
              </div>
            </div>
          ) : (
            <div className="profile--content">
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  if (isAllowed('modifyChargeCoinManagement')) {
                    updateChargeCoin(values);
                  }
                }}
              >
                {({ values, handleSubmit, setFieldValue, setValues }) => (
                  <Form onSubmit={handleSubmit}>
                    {values?.results?.key_value?.levels?.map((data, index) => {
                      return (
                        <Row key={index}>
                          <Col xl={3} md={6}>
                            {isLoader ? (
                              <Skeleton height={15} width={100} />
                            ) : (
                              <TextInput
                                isRequired // * Symbol
                                required
                                label={t('chargeCoinManagement.name')}
                                name={`name`}
                                value={data.name}
                                placeholder={t('chargeCoinManagement.name')}
                                onChange={(val) => setFieldValue(`results.key_value.levels.${index}.name`, val.target.value)}
                                // error={errors.name && touched.name ? errors.name : null}
                              />
                            )}
                          </Col>
                          <Col xl={3} md={6}>
                            {isLoader ? (
                              <Skeleton height={15} width={100} />
                            ) : (
                              <TextInput
                                isRequired // * Symbol
                                required
                                type="number"
                                label={t('chargeCoinManagement.minUnitConsumed')}
                                name={`min_units_consumed`}
                                value={data.min_units_consumed}
                                placeholder={t('chargeCoinManagement.minUnitConsumed')}
                                onChange={(val) => setFieldValue(`results.key_value.levels.${index}.min_units_consumed`, val.target.value)}
                                // error={errors.feild && touched.feild ? errors.feild : null}
                              />
                            )}
                          </Col>
                          <Col xl={2} md={6}>
                            {isLoader ? (
                              <Skeleton height={15} width={100} />
                            ) : (
                              <TextInput
                                isRequired // * Symbol
                                required
                                type="number"
                                label={t('chargeCoinManagement.rewardPerUnit')}
                                name={`reward_per_unit`}
                                value={data.reward_per_unit}
                                placeholder={t('chargeCoinManagement.rewardPerUnit')}
                                onChange={(val) => setFieldValue(`results.key_value.levels.${index}.reward_per_unit`, val.target.value)}
                                // error={errors.feild && touched.feild ? errors.feild : null}
                              />
                            )}
                          </Col>
                          <Col xl={3} md={6}>
                            {isLoader ? (
                              <Skeleton height={15} width={100} />
                            ) : (
                              <TextInput
                                isRequired // * Symbol
                                required
                                type="number"
                                label={t('chargeCoinManagement.redemptionPercent')}
                                name={`redemption_percent_of_booking`}
                                value={data.redemption_percent_of_booking}
                                placeholder={t('chargeCoinManagement.redemptionPercent')}
                                onChange={(val) => setFieldValue(`results.key_value.levels.${index}.redemption_percent_of_booking`, val.target.value)}
                                // error={errors.feild && touched.feild ? errors.feild : null}
                              />
                            )}
                          </Col>
                          <Col xl={1}>
                            {isAllowed('modifyChargeCoinManagement') && (
                              <div className="charge_coin_btn_sec_delete">
                                <span
                                  className="user-group-table-deleteIcon danger"
                                  onClick={() => {
                                    if (values.results.key_value.levels.length !== chargeCoinDetails?.key_value?.levels.length) {
                                      if (Object.values(values.results.key_value.levels[index]).includes('')) {
                                        const newResults = values.results.key_value.levels.filter((item, i) => i !== index);
                                        const newValues = {
                                          ...values,
                                          results: {
                                            ...values.results,
                                            key_value: {
                                              ...values.results.key_value,
                                              levels: newResults,
                                            },
                                          },
                                        };
                                        setValues(newValues);
                                      } else {
                                        const newResults = values.results.key_value.levels.filter((item, i) => i !== index);
                                        const newValues = {
                                          ...values,
                                          results: {
                                            ...values.results,
                                            key_value: {
                                              ...values.results.key_value,
                                              levels: newResults,
                                            },
                                          },
                                        };
                                        setValues(newValues);
                                      }
                                    } else {
                                      setRow(index);
                                      // deleteChargeCoinLevel(newValues);
                                      setShowDeleteModal(true);
                                    }
                                  }}
                                >
                                  {isLoader ? <Skeleton height={15} width={15} circle="true" /> : <RiDeleteBinLine title="Delete" size={26} />}
                                </span>
                              </div>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                    <div className="charge_coin_btn_sec_mr">
                      {isLoader ? (
                        <Skeleton height={15} width={100} />
                      ) : (
                        <Button
                          className="form_btn cancel____btn"
                          onClick={() => {
                            setValues({
                              ...values,
                              results: {
                                ...values.results,
                                key_value: {
                                  ...values.results.key_value,
                                  levels: [
                                    ...values.results.key_value.levels,
                                    { name: '', min_units_consumed: '', reward_per_unit: '', redemption_percent_of_booking: '' },
                                  ],
                                },
                              },
                            });
                          }}
                          disabled={!isAllowed('modifyChargeCoinManagement')}
                        >
                          {t('button.addMore')}
                        </Button>
                      )}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {isLoader ? (
                        <Skeleton height={15} width={100} />
                      ) : (
                        <Button type="submit" className="form_btn" disabled={!isAllowed('modifyChargeCoinManagement')}>
                          {t('button.update')}
                        </Button>
                      )}
                    </div>

                    {showDeleteModal && (
                      <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                        <DeleteModal
                          title="level "
                          onClose={handleCloseDeleteModel}
                          onRemove={() => {
                            const newResults = values.results.key_value.levels.filter((item, i) => i !== row);
                            const newValues = {
                              ...values,
                              results: {
                                ...values.results,
                                key_value: {
                                  ...values.results.key_value,
                                  levels: newResults,
                                },
                              },
                            };
                            setValues(newValues);
                            deleteChargeCoinLevel(newValues);
                          }}
                        />
                      </Modal>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default Levels;
