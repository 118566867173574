import { FETCH_CHARGER_BOOKING, DELETE_CHARGER_BOOKING, START_REMOTE, GET_CHARGER_BOOKING } from 'actions/chargerBooking';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  chargerBookings: [],
  chargerBookingDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const chargerBookingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHARGER_BOOKING.REQUEST:
    case DELETE_CHARGER_BOOKING.REQUEST:
    case GET_CHARGER_BOOKING.REQUEST:
    case START_REMOTE: {
      return { ...state, isLoading: true };
    }
    case FETCH_CHARGER_BOOKING.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        chargerBookings: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_CHARGER_BOOKING.SUCCESS: {
      return {
        ...state,
        chargerBookingDetail: payload,
        isLoading: false,
      };
    }

    case START_REMOTE.SUCCESS: {
      toast.success('Remote added successfully');
      return {
        ...state,
        isLoading: false,
      };
    }
    case DELETE_CHARGER_BOOKING.SUCCESS: {
      return {
        ...state,
        chargerBookings: state.chargerBookings.filter((data) => data.id !== payload),
      };
    }

    case DELETE_CHARGER_BOOKING.FAIL:
    case FETCH_CHARGER_BOOKING.FAIL:
    case GET_CHARGER_BOOKING.FAIL:
    case START_REMOTE.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default chargerBookingReducer;
