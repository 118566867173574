import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/inputs/Select';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { CREATE_CITY, UPDATE_CITY, FETCH_STATE, FETCH_COUNTRY } from 'actions/address';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const CityForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allCountries = useSelector((state) => state.country.countries);
  const cityDetail = useSelector((state) => state.city.cityDetail);
  const allStates = useSelector((state) => state.state.states);
  const isLoading = useSelector((state) => state.city.isLoading);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);

  const addCity = useCallback((data) => {
    dispatch({
      type: CREATE_CITY.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateCity = useCallback((data) => {
    dispatch({
      type: UPDATE_CITY.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY.REQUEST, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    getAllCountry();
  }, []);

  useEffect(() => {
    if (isEdit && !_.isEmpty(cityDetail)) {
      getStateByCountry({ country: cityDetail.state.country.id, limit: 999 });
    }
  }, [isEdit, cityDetail]);

  const CitySchema = Yup.object().shape({
    country: Yup.string().required(t('cityForm.country')),
    state: Yup.string().required(t('cityForm.state')),
    name: Yup.string()
      .required(t('cityForm.city'))
      .strict(true)
      .trim(t('cityForm.space'))
      .matches(/^[A-Za-z\s]+$/, t('cityForm.matches'))
      .max(100, t('cityForm.maxlimit')),
    // city_code: Yup.string()
    //   .required('City code is required')
    //   .strict(true)
    //   .trim('Space is not allowed')
    //   .matches(/^[a-zA-Z0-9 ]+$/, 'Only numbers and alphabets are allowed')
    //   .max(100, 'Text limit should be less than 100 digits.'),
    // lat: Yup.number().required(`latitude is required`).max(90, 'Max latitude is 90').typeError('Only number is allow!'),
    // lng: Yup.number().required(`longitude is required`).max(90, 'Max longitude is 90').typeError('Only number is allow!'),
  });

  const initialValues = isEdit
    ? { ...cityDetail, country: _.get(cityDetail, 'state.country.id', ''), state: _.get(cityDetail, 'state.id', '') }
    : {
        name: '',
        city_code: '',
        state: '',
        country: '',
        // lat: '',
        // lng: '',
      };
  return (
    <React.Fragment>
      <div className="city-form-page__main">
        <Card>
          <div className="city-form-header__block">
            <div className="city-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('cityForm.title')}
              </span>
            </div>
            <div className="close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="city-form-body__block">
            <div className="city-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={CitySchema}
                onSubmit={(values, { setSubmitting }) => {
                  const cityData = _.omit(values, ['country']);
                  if (isEdit) {
                    updateCity(cityData);
                  } else {
                    addCity(cityData);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={12}>
                        <Select
                          isRequired
                          label={t('areaForm.countryName')}
                          options={_.map(allCountries, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          placeholder={t('placeHolder.selectCountryName')}
                          name="country"
                          value={_.get(values, 'country')}
                          onMenuScrollDown={true}
                          getDataOnScrollDown={getAllCountry}
                          page={countryPage}
                          totalPage={countryTotalPage}
                          onChange={(val) => {
                            getStateByCountry({ country: val });
                            setFieldValue(`country`, val);
                            setFieldValue(`state`, '');
                          }}
                          error={errors.country && touched.country ? errors.country : null}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <Select
                          isRequired
                          label={t('dataReport.stateName')}
                          options={_.map(allStates, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          placeholder={t('placeHolder.selectStateName')}
                          name="state"
                          value={_.get(values, 'state')}
                          onMenuScrollDown={true}
                          getDataOnScrollDown={(data) => getStateByCountry({ ...data, country: values.country })}
                          page={statePage}
                          totalPage={stateTotalPage}
                          onChange={(val) => setFieldValue(`state`, val)}
                          error={errors.state && touched.state ? errors.state : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <TextInput
                          isRequired
                          label={t('cityForm.cityName')}
                          placeholder={t('cityForm.cityName')}
                          name="name"
                          value={_.get(values, 'name')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <TextInput
                          // isRequired
                          label={t('cityForm.cityCode')}
                          placeholder={t('cityForm.cityCode')}
                          name="city_code"
                          value={_.get(values, 'city_code')}
                          onChange={handleChange}
                          // error={errors.city_code && touched.city_code ? errors.city_code : null}
                        />
                      </Col>
                      {/* <Col lg={6}>
                        <TextInput
                          isRequired
                          label="Latitude"
                          placeholder="Latitude"
                          name="lat"
                          value={values.lat}
                          onChange={handleChange}
                          error={errors.lat && touched.lat ? errors.lat : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <TextInput
                          isRequired
                          label="Longitude"
                          placeholder="Longitude"
                          name="lng"
                          value={values.lng}
                          onChange={handleChange}
                          error={errors.lng && touched.lng ? errors.lng : null}
                        />
                      </Col> */}
                    </Row>
                    <div className="form-btn-block">
                      <Button className="city-form-btn cancel_btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="city-form-btn create-btn">
                        {isEdit ? 'Update' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
CityForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default CityForm;
