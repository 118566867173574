import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { Modal } from 'react-bootstrap';
import { DELETE_CHARGER_STATE, FETCH_CHARGER_STATE, GET_CHARGER_STATE } from 'actions/chargerState';
import ChargerStateForm from './ChargerStateForm';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

const ChargerStateList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  // const [allChargerStateData, setAllChargerStateData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [chargerStateId, setChargerStateId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const page = useSelector((state) => state.chargerState.page);
  const totalData = useSelector((state) => state.chargerState.total);
  const limit = useSelector((state) => state.chargerState.limit);
  const totalPages = useSelector((state) => state.chargerState.totalPages);
  const chargerStateList = useSelector((state) => state.chargerState.chargerStates);
  const isLoader = useSelector((state) => state.chargerState.isLoading);

  const getChargerState = useCallback(
    (data = {}) => {
      if (searchText) {
        const chargerStateData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_CHARGER_STATE.REQUEST, payload: chargerStateData });
      } else {
        dispatch({ type: FETCH_CHARGER_STATE.REQUEST, payload: data });
      }
    },
    [searchText]
  );

  const getChargerStateDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGER_STATE.REQUEST, payload: id });
  }, []);

  const deleteChargerState = useCallback(
    () =>
      dispatch({
        type: DELETE_CHARGER_STATE.REQUEST,
        payload: chargerStateId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getChargerState({ page: 1 });
          }
        },
      }),
    [chargerStateId]
  );

  useEffect(() => {
    getChargerState();
  }, []);

  const handleSearch = () => {
    getChargerState({});
  };

  // useEffect(() => {
  //   setAllChargerStateData(_.filter(chargerStateList, (item) => startsWith(_.get(item, 'name', ''), searchText)));
  // }, [searchText, chargerStateList]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getChargerState(data);
      }
    },
    [sortByItem]
  );

  const handleCloseFormChargerState = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getChargerState(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getChargerState(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setChargerStateId('');
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.chargerState')} />
        <div className="page-content-wrapper scrollable">
          <div className="chargerStateList-page">
            <Card>
              <div className="chargerStateList-page-wrapper">
                <Row className="chargerStateList-search-box">
                  <Col xl={10} lg={9} md={8} className="chargerStateList-search-inputBox">
                    <SearchBox
                      value={searchText}
                      preIcon={<BsSearch />}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                  </Col>
                  <Col xl={2} lg={3} md={4} className="chargerStateList-search-boxButton">
                    <Button
                      className="chargerStateList-search-box-Btn"
                      onClick={() => {
                        setShowForm(true);
                        setIsEdit(false);
                      }}
                    >
                      {t('button.addChargerState')}
                    </Button>
                  </Col>
                </Row>
                <div className="chargerStateList-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('chargerStateForm.cpoCode')}</span>
                              <span className="ico" onClick={() => handleSorting('cpo_code', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('chargerStateForm.nameLabel')}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th className="action-col">
                            <span>{t('chargerStateForm.action')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(chargerStateList) ? (
                          <tr>
                            <td colSpan={3} className="border-0">
                              <div className="empty-data-block">{t('chargerStateForm.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(chargerStateList, (item, key) => {
                            return (
                              <>
                                <tr key={`chargerState-${key}`}>
                                  <td>{_.get(item, 'cpo_code', '')}</td>
                                  <td>{_.get(item, 'name', '')}</td>
                                  <td>
                                    <span
                                      className="chargerStateList-table-editIcon"
                                      onClick={() => {
                                        setShowForm(true);
                                        getChargerStateDetail(_.get(item, 'id', ''));
                                        setIsEdit(true);
                                      }}
                                    >
                                      <FiEdit2 title="Edit" />
                                    </span>
                                    <span
                                      className="chargerStateList-table-deleteIcon"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setChargerStateId(_.get(item, 'id', ''));
                                      }}
                                    >
                                      <RiDeleteBinLine title="Delete" />
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(chargerStateList) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                  // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                )}
              </div>
            </Card>
          </div>
        </div>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <ChargerStateForm onClose={handleCloseFormChargerState} onSuccess={getChargerState} isEdit={isEdit} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="ChargerState" onClose={handleCloseDeleteModel} onRemove={deleteChargerState} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};
export default ChargerStateList;
