import { GET_CHARGER_LOG_GRAPH } from 'actions/chargerLogGraph';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* getChargerLogGraphById(action) {
  try {
    const response = yield call(API.getChargerLogGraphById, action.payload);
    yield put({ type: GET_CHARGER_LOG_GRAPH.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_CHARGER_LOG_GRAPH.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* chargerLogGraph() {
  yield takeLatest(GET_CHARGER_LOG_GRAPH.REQUEST, getChargerLogGraphById);
}

export default chargerLogGraph;
