import React, { useCallback, useEffect, useState, useContext } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
import Avatar from 'components/inputs/Avatar';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_USER } from 'actions/user';
import ReactPagination from 'components/general/ReactPagination';
import { Modal, Spinner } from 'react-bootstrap';
import DeleteModal from 'components/general/DeleteModal';
import { BiSort } from 'react-icons/bi';
import { FaRegUser } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import SearchBox from 'components/general/SearchBox';
import Button from 'components/inputs/Button';
import { MdClear } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { FETCH_CUSTOMER } from 'actions/customer';
import { useTranslation } from 'react-i18next';
const DriverManagement = () => {
  const dispatch = useDispatch();
  const { navigateTo } = useContext(NavContext);
  const [userId, setUserId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseCustomerData, setPageWiseCustomerData] = useState([]);
  const { t } = useTranslation();

  const isLoader = useSelector((state) => state.customer.isLoading);
  const customers = useSelector((state) => state.customer.customers);
  const page = useSelector((state) => state.customer.page);
  const totalData = useSelector((state) => state.customer.total);
  const limit = useSelector((state) => state.customer.limit);
  const totalPages = useSelector((state) => state.customer.totalPages);

  const customerData = customers.filter(({ id: id1 }) => pageWiseCustomerData.some(({ id: id2 }) => id2 === id1));

  const handleCloseDeleteModel = () => setShowDeleteModal(false);

  const getUsers = useCallback(
    (data = {}) => {
      if (searchText) {
        const customerData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_CUSTOMER.REQUEST,
          payload: customerData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseCustomerData(res.data.results);
              }
            }
          },
        });
      } else {
        dispatch({
          type: FETCH_CUSTOMER.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseCustomerData(res.data.results);
              }
            }
          },
        });
      }
    },
    [searchText, pageWiseCustomerData]
  );

  const dataFilter = useCallback(() => {
    if (searchText) {
      const filterDateData = {
        search: searchText,
      };
      getUsers(filterDateData);
    } else {
      getUsers();
    }
  }, [searchText]);

  const deleteUser = useCallback((id) => {
    dispatch({ type: DELETE_USER.REQUEST, payload: id });
    handleCloseDeleteModel();
    setTimeout(() => {
      getUsers();
    }, 500);
  }, []);

  useEffect(() => {
    getUsers();
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (searchText) {
        const data = {
          search: searchText,
          page: page.selected + 1,
        };
        getUsers(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getUsers(data);
      }
    },
    [sortByItem, searchText]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getUsers(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getUsers(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleReset = useCallback(() => {
    getUsers();
  }, []);

  const handleSearch = () => {
    getUsers();
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('sidebar.manageDriver')} />
        <div className="page-content-wrapper scrollable">
          <div className="customer-management-page__main">
            <div className="customer-details--block">
              {/* {( */}
              <Card>
                <div className="customer-details-search--block" style={{ border: 'none', borderRadius: 'none' }}>
                  <Row>
                    <Col xl={9} md={9}>
                      <SearchBox
                        preIcon={<BsSearch />}
                        postIcon={
                          <MdClear
                            onClick={() => {
                              setSearchText('');
                              handleReset();
                            }}
                          />
                        }
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSearch();
                          }
                        }}
                      />
                    </Col>
                    <Col xl={'auto'} md={'auto'}>
                      <div className="customerList-add-boxButton" onClick={dataFilter}>
                        <Button className="customerList-add-box-Btn">{t('button.search')}</Button>
                      </div>
                    </Col>
                    <Col xl={'auto'} md={'auto'}>
                      <Button
                        className="user-group-btn"
                        onClick={() => {
                          navigateTo('/driver/addDriver');
                        }}
                      >
                        <span className="me-2">
                          <AiOutlinePlus color={'#FFFFFF'} />
                        </span>
                        {t('header.addDriver')}
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="role-access-page__main">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{t('fleetList.name')}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('fleetList.email')}</span>
                              <span className="ico" onClick={() => handleSorting('email', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('fleetList.phoneNo')}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{t('fleetList.action')}</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader-block___">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">{t('placeHolder.loading')}</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(customerData) ? (
                          <tr>
                            <td colSpan={8} className="border-0">
                              <div className="empty-data-block">{t('fleetList.noData')}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(customerData, (user, key) => {
                            return (
                              <tr key={`user-index-${key}`}>
                                <td>
                                  <div className="role-access-name-list">
                                    <Avatar imgSrc={_.get(user, 'picture', '')} className="user-avatar" />
                                    <span>{_.get(user, 'name', '')}</span>
                                  </div>
                                </td>
                                <td>{_.get(user, 'email', '')}</td>
                                <td>
                                  +{_.get(user, 'country_code', '')}
                                  {_.get(user, 'phone', 'NA')}
                                </td>
                                <td>
                                  <div>
                                    <span className="role-access-charging-station-icon">
                                      <FaRegUser
                                        size={20}
                                        onClick={() => {
                                          navigateTo(`/driverProfile/${_.get(user, 'id')}`);
                                        }}
                                      />
                                    </span>
                                    <span className="role-access-delete-icon">
                                      <RiDeleteBinLine
                                        onClick={() => {
                                          setUserId(_.get(user, 'id'));
                                          setShowDeleteModal(true);
                                        }}
                                      />
                                    </span>
                                  </div>
                                  {/* )} */}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(customerData) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
                {showDeleteModal && (
                  <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                    <DeleteModal title="Fleet" onClose={handleCloseDeleteModel} onRemove={() => deleteUser(userId)} />
                  </Modal>
                )}
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default DriverManagement;
