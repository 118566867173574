export const CREATE_COUPON = 'CREATE_COUPON';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAIL = 'CREATE_COUPON_FAIL';

export const FETCH_COUPONS = 'FETCH_COUPONS';
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS';
export const FETCH_COUPONS_FAIL = 'FETCH_COUPONS_FAIL';

export const GET_COUPON = 'GET_COUPON';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_FAIL = 'GET_COUPON_FAIL';

export const DELETE_COUPON = 'DELETE_COUPON';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAIL = 'DELETE_COUPON_FAIL';

export const UPDATE_COUPON = 'UPDATE_COUPON';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAIL = 'UPDATE_COUPON_FAIL';

export const BOOKING_COUPON = 'BOOKING_COUPON';
export const BOOKING_COUPON_SUCCESS = 'BOOKING_COUPON_SUCCESS';
export const BOOKING_COUPON_FAIL = 'BOOKING_COUPON_FAIL';
