import { RATING_REVIEW, RATING_REVIEW_SUMMARY } from 'actions/rating';

const initialState = {
  isLoading: false,
  rating: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const ratingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RATING_REVIEW.REQUEST:
    case RATING_REVIEW_SUMMARY.REQUEST: {
      return { ...state, isLoading: true };
    }
    case RATING_REVIEW.SUCCESS:
    case RATING_REVIEW_SUMMARY.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        rating: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
        avg_rating: payload.avg_rating,
      };
    }

    case RATING_REVIEW.FAIL:
    case RATING_REVIEW_SUMMARY.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default ratingReducer;
