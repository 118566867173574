import React, { useCallback, useEffect, useState, useContext } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
// import Select from 'components/inputs/Select';
import Avatar from 'components/inputs/Avatar';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_USER, /* CHANGE_ROLE,*/ DELETE_USER } from 'actions/user';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { /*TENANT_ADMIN,*/ SUPER_ADMIN } from 'components/common/constant';
import { Modal, Spinner } from 'react-bootstrap';
import DeleteModal from 'components/general/DeleteModal';
// import { FETCH_ROLE } from 'actions/role';
import { BiSort } from 'react-icons/bi';
import { FaRegUser } from 'react-icons/fa';
// import { useHistory } from 'react-router';
// import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import SearchBox from 'components/general/SearchBox';
import Button from 'components/inputs/Button';
import { MdClear } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { FETCH_CUSTOMER } from 'actions/customer';
import { useTranslation } from 'react-i18next';
const FleetManagement = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const [userId, setUserId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseCustomerData, setPageWiseCustomerData] = useState([]);
  const { t } = useTranslation();
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserRole = _.get(profileData, 'role', '');
  const isLoader =
    currentUserRole === 'fleet_owner' ? useSelector((state) => state.customer.isLoading) : useSelector((state) => state.user.isLoading);
  const allUsers = useSelector((state) => state.user.users);
  const customers = useSelector((state) => state.customer.customers);
  //   const allRoles = useSelector((state) => state.role.roles);
  const page = currentUserRole === 'fleet_owner' ? useSelector((state) => state.customer.page) : useSelector((state) => state.user.page);
  const totalData = currentUserRole === 'fleet_owner' ? useSelector((state) => state.customer.total) : useSelector((state) => state.user.total);
  const limit = currentUserRole === 'fleet_owner' ? useSelector((state) => state.customer.limit) : useSelector((state) => state.user.limit);
  const totalPages =
    currentUserRole === 'fleet_owner' ? useSelector((state) => state.customer.totalPages) : useSelector((state) => state.user.totalPages);
  // const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const customerData = customers.filter(({ id: id1 }) => pageWiseCustomerData.some(({ id: id2 }) => id2 === id1));

  const handleCloseDeleteModel = () => setShowDeleteModal(false);

  const getAllUsers = useCallback(
    (data = {}) => {
      if (searchText) {
        const userData = {
          ...data,
          search: searchText,
          role: 'fleet_owner',
        };
        dispatch({
          type: FETCH_USER.REQUEST,
          payload: userData,
        });
      } else {
        dispatch({
          type: FETCH_USER.REQUEST,
          payload: { ...data, role: 'fleet_owner' },
        });
      }
    },
    [searchText]
  );

  const getUsers = useCallback(
    (data = {}) => {
      if (searchText) {
        const customerData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_CUSTOMER.REQUEST,
          payload: customerData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseCustomerData(res.data.results);
              }
            }
          },
        });
      } else {
        dispatch({
          type: FETCH_CUSTOMER.REQUEST,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseCustomerData(res.data.results);
              }
            }
          },
        });
      }
    },
    [searchText, pageWiseCustomerData]
  );

  //   const getAllRoles = useCallback((data) => {
  //     const roleData = {
  //       ...data,
  //       limit: 50,
  //       is_hidden: false,
  //     };
  //     dispatch({ type: FETCH_ROLE, payload: roleData });
  //   }, []);

  const dataFilter = useCallback(() => {
    if (currentUserRole === 'fleet_owner') {
      if (searchText) {
        const filterDateData = {
          search: searchText,
        };
        getUsers(filterDateData);
      } else {
        getUsers();
      }
    } else {
      if (searchText) {
        const filterDateData = {
          search: searchText,
          role: 'fleet_owner',
        };
        getAllUsers(filterDateData);
      } else {
        getAllUsers({ role: 'fleet_owner' });
      }
    }
  }, [searchText, currentUserRole]);

  const deleteUser = useCallback(
    (id) => {
      dispatch({ type: DELETE_USER.REQUEST, payload: id });
      handleCloseDeleteModel();
      if (currentUserRole === 'fleet_owner') {
        getUsers();
      } else {
        getAllUsers({ role: 'fleet_owner' });
      }
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    },
    [currentUserRole]
  );

  //   const editUserRole = useCallback(
  //     (role, userId) => {
  //       const userRole = {
  //         id: userId,
  //         role: role,
  //       };
  //       dispatch({
  //         type: CHANGE_ROLE,
  //         payload: userRole,
  //         cb: (res) => {
  //           if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
  //             getAllUsers({ page });
  //           }
  //         },
  //       });
  //     },
  //     [allUsers]
  //   );

  useEffect(() => {
    if (currentUserRole === 'fleet_owner') {
      getUsers();
    } else {
      getAllUsers();
    }
    // getAllRoles();
  }, [currentUserRole]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (currentUserRole === 'fleet_owner') {
        if (searchText) {
          const data = {
            search: searchText,
            page: page.selected + 1,
          };
          getUsers(data);
        } else {
          const data = {
            page: page.selected + 1,
          };
          getUsers(data);
        }
      } else {
        if (searchText) {
          const data = {
            search: searchText,
            page: page.selected + 1,
            role: 'fleet_owner',
          };
          getAllUsers(data);
        } else {
          const data = {
            page: page.selected + 1,
            role: 'fleet_owner',
          };
          getAllUsers(data);
        }
      }
    },
    [sortByItem, searchText, currentUserRole]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (currentUserRole === 'fleet_owner') {
      if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        getUsers(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        getUsers(data);
      }
    } else {
      if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          role: 'fleet_owner',
        };
        getAllUsers(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          role: 'fleet_owner',
        };
        getAllUsers(data);
      }
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleReset = useCallback(() => {
    if (currentUserRole === 'fleet_owner') {
      getUsers();
    } else {
      getAllUsers({ role: 'fleet_owner' });
    }
  }, [currentUserRole]);

  const handleSearch = () => {
    if (currentUserRole === 'fleet_owner') {
      getUsers();
    } else {
      getAllUsers({ role: 'fleet_owner' });
    }
  };

  // const getRolePermissions =
  //   !_.isEmpty(rolePermissionData) &&
  //   _.map(
  //     _.filter(rolePermissionData[0].permissions, (item) => item.name === 'getRoles'),
  //     (data) => data.name
  //   );

  // const manageRolePermissions =
  //   !_.isEmpty(rolePermissionData) &&
  //   _.map(
  //     _.filter(rolePermissionData[0].permissions, (item) => item.name === 'manageRoles'),
  //     (data) => data.name
  //   );

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('sidebar.fleetManagement')} />
        <div className="page-content-wrapper scrollable">
          <div className="customer-management-page__main">
            <div className="customer-details--block">
              {currentUserRole === 'fleet_owner' ? (
                <Card>
                  <div className="customer-details-search--block" style={{ border: 'none', borderRadius: 'none' }}>
                    <Row>
                      <Col xl={9} md={9}>
                        <SearchBox
                          preIcon={<BsSearch />}
                          postIcon={
                            <MdClear
                              onClick={() => {
                                setSearchText('');
                                handleReset();
                              }}
                            />
                          }
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleSearch();
                            }
                          }}
                        />
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="customerList-add-boxButton" onClick={dataFilter}>
                          <Button className="customerList-add-box-Btn">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <Button
                          className="user-group-btn"
                          onClick={() => {
                            navigateTo('/fleet/addFleet');
                          }}
                        >
                          <span className="me-2">
                            <AiOutlinePlus color={'#FFFFFF'} />
                          </span>
                          {t('header.addFleet2')}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="role-access-page__main">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{t('fleetList.name')}</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{t('fleetList.email')}</span>
                                <span className="ico" onClick={() => handleSorting('email', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{t('fleetList.phoneNo')}</span>
                              </div>
                            </th>
                            {/* <th>
                            <div className="sorting">
                              <span>Role</span>
                            </div>
                          </th> */}
                            <th>
                              <div className="sorting">
                                <span>{t('fleetList.action')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="loader-block___">
                                  <Spinner as="span" animation="border" size="sm" role="status" />
                                  <span className="ps-2">{t('placeHolder.loading')}</span>
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(customerData) ? (
                            <tr>
                              <td colSpan={8} className="border-0">
                                <div className="empty-data-block">{t('fleetList.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(customerData, (user, key) => {
                              //   const isCurrentUser = _.get(profileData, 'id', '') === _.get(user, 'id', '');
                              return (
                                <tr key={`user-index-${key}`}>
                                  <td>
                                    <div className="role-access-name-list">
                                      <Avatar imgSrc={_.get(user, 'picture', '')} className="user-avatar" />
                                      <span>{_.get(user, 'name', '')}</span>
                                    </div>
                                  </td>
                                  <td>{_.get(user, 'email', '')}</td>
                                  <td>
                                    +{_.get(user, 'country_code', '')}
                                    {_.get(user, 'phone', 'NA')}
                                  </td>
                                  {/* <td>
                                    <div className="role-access-role">
                                      {!_.isEmpty(manageRolePermissions) || (!_.isEmpty(getRolePermissions) && !_.isEmpty(manageRolePermissions)) ? (
                                        <Select
                                          isDisabled={_.get(profileData, 'role', '') !== TENANT_ADMIN || isCurrentUser}
                                          className="roles-select__box"
                                          value={_.get(user, 'role', '')}
                                          options={_.map(allRoles, (item) => {
                                            return { label: _.startCase(item.name), value: item.name };
                                          })}
                                          onChange={(val) => {
                                            editUserRole(val, user.id);
                                          }}
                                        />
                                      ) : (
                                        <Select
                                          isDisabled
                                          className="roles-select__box"
                                          value={_.get(user, 'role', '')}
                                          options={_.map(allRoles, (item) => {
                                            return { label: _.startCase(item.name), value: item.name };
                                          })}
                                          onChange={(val) => {
                                            editUserRole(val, user.id);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td> */}
                                  <td>
                                    {/* {(!_.isEmpty(manageRolePermissions) || (!_.isEmpty(getRolePermissions) && !_.isEmpty(manageRolePermissions))) && ( */}
                                    <div>
                                      <span className="role-access-charging-station-icon">
                                        <FaRegUser
                                          size={20}
                                          onClick={() => {
                                            navigateTo(`/fleetProfile/${_.get(user, 'id')}`);
                                          }}
                                        />
                                      </span>
                                      <span className="role-access-delete-icon">
                                        <RiDeleteBinLine
                                          title="Delete"
                                          onClick={() => {
                                            setUserId(_.get(user, 'id'));
                                            setShowDeleteModal(true);
                                          }}
                                        />
                                      </span>
                                    </div>
                                    {/* )} */}
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(customerData) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                      // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                    )}
                  </div>
                  {showDeleteModal && (
                    <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                      <DeleteModal title="Fleet" onClose={handleCloseDeleteModel} onRemove={() => deleteUser(userId)} />
                    </Modal>
                  )}
                </Card>
              ) : (
                <Card>
                  <div className="customer-details-search--block" style={{ border: 'none', borderRadius: 'none' }}>
                    <Row>
                      <Col xl={9} md={9}>
                        <SearchBox
                          preIcon={<BsSearch />}
                          postIcon={
                            <MdClear
                              onClick={() => {
                                setSearchText('');
                                handleReset();
                              }}
                            />
                          }
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleSearch();
                            }
                          }}
                        />
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="customerList-add-boxButton" onClick={dataFilter}>
                          <Button className="customerList-add-box-Btn">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <Button
                          className="user-group-btn"
                          onClick={() => {
                            navigateTo('/fleet/addFleet');
                          }}
                        >
                          <span className="me-2">
                            <AiOutlinePlus color={'#FFFFFF'} />
                          </span>
                          {t('fleetList.addFleet')}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="role-access-page__main">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{t('fleetList.name')}</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{t('fleetList.email')}</span>
                                <span className="ico" onClick={() => handleSorting('email', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{t('fleetList.phoneNo')}</span>
                              </div>
                            </th>
                            {/* <th>
                          <div className="sorting">
                            <span>Role</span>
                          </div>
                        </th> */}
                            <th>
                              <div className="sorting">
                                <span>{t('fleetList.action')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="loader-block___">
                                  <Spinner as="span" animation="border" size="sm" role="status" />
                                  <span className="ps-2">{t('placeHolder.loading')}</span>
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(allUsers) ? (
                            <tr>
                              <td colSpan={8} className="border-0">
                                <div className="empty-data-block">{t('fleetList.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(
                              _.filter(allUsers, (userData) => userData.role !== SUPER_ADMIN),
                              (user, key) => {
                                //   const isCurrentUser = _.get(profileData, 'id', '') === _.get(user, 'id', '');
                                return (
                                  <tr key={`user-index-${key}`}>
                                    <td>
                                      <div className="role-access-name-list">
                                        <Avatar imgSrc={_.get(user, 'picture', '')} className="user-avatar" />
                                        <span>{_.get(user, 'name', '')}</span>
                                      </div>
                                    </td>
                                    <td>{_.get(user, 'email', '')}</td>
                                    <td>
                                      +{_.get(user, 'country_code', '')}
                                      {_.get(user, 'phone', 'NA')}
                                    </td>
                                    {/* <td>
                                  <div className="role-access-role">
                                    {!_.isEmpty(manageRolePermissions) || (!_.isEmpty(getRolePermissions) && !_.isEmpty(manageRolePermissions)) ? (
                                      <Select
                                        isDisabled={_.get(profileData, 'role', '') !== TENANT_ADMIN || isCurrentUser}
                                        className="roles-select__box"
                                        value={_.get(user, 'role', '')}
                                        options={_.map(allRoles, (item) => {
                                          return { label: _.startCase(item.name), value: item.name };
                                        })}
                                        onChange={(val) => {
                                          editUserRole(val, user.id);
                                        }}
                                      />
                                    ) : (
                                      <Select
                                        isDisabled
                                        className="roles-select__box"
                                        value={_.get(user, 'role', '')}
                                        options={_.map(allRoles, (item) => {
                                          return { label: _.startCase(item.name), value: item.name };
                                        })}
                                        onChange={(val) => {
                                          editUserRole(val, user.id);
                                        }}
                                      />
                                    )}
                                  </div>
                                </td> */}
                                    <td>
                                      {/* {(!_.isEmpty(manageRolePermissions) || (!_.isEmpty(getRolePermissions) && !_.isEmpty(manageRolePermissions))) && ( */}
                                      <div>
                                        <span className="role-access-charging-station-icon">
                                          <FaRegUser
                                            size={20}
                                            onClick={() => {
                                              navigateTo(`/fleetProfile/${_.get(user, 'id')}`);
                                            }}
                                          />
                                        </span>
                                        <span className="role-access-delete-icon">
                                          <RiDeleteBinLine
                                            title="Delete"
                                            onClick={() => {
                                              setUserId(_.get(user, 'id'));
                                              setShowDeleteModal(true);
                                            }}
                                          />
                                        </span>
                                      </div>
                                      {/* )} */}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allUsers) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                      // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                    )}
                  </div>
                  {showDeleteModal && (
                    <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                      <DeleteModal title="Fleet" onClose={handleCloseDeleteModel} onRemove={() => deleteUser(userId)} />
                    </Modal>
                  )}
                </Card>
              )}
            </div>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default FleetManagement;
