import { post, downloadFile, downloadBulkFile } from 'utils/api';
import { INVOICES, CHARGER_BOOKINGS, WIDGETS } from './endpointURLs';

export const getAllInvoice = (data) => {
  let page = data.page ? data.page : 1;
  delete ['limit'];
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`${INVOICES}?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}&limit=20`, data);
  } else if (page && data.search) {
    const search = encodeURIComponent(data.search);
    delete data['page'];
    delete data['search'];
    return post(`${INVOICES}?page=${parseInt(page)}&search=${search}&limit=20`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = encodeURIComponent(data.search);
    delete data['search'];
    delete data['sortBy'];
    return post(`${INVOICES}?search=${search}&sortBy=${sortBy}&limit=20`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`${INVOICES}?page=${parseInt(page)}&sortBy=${sortBy}&limit=20`, data);
  } else if (data.search) {
    const search = encodeURIComponent(data.search);
    delete data['search'];
    return post(`${INVOICES}?search=${search}&limit=20`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`${INVOICES}?sortBy=${sortBy}&limit=20`, data);
  } else {
    delete data['page'];
    return post(`${INVOICES}?page=${parseInt(page)}&limit=20`, data);
  }
};

export const downloadInvoice = (data) => {
  if (data.search) {
    const search = encodeURIComponent(data.search);
    delete data['search'];
    return downloadFile(`${INVOICES}?search=${search}`, data);
  } else return downloadFile(`${INVOICES}`, data);
};

export const downloadChargeRct = (id, data) => {
  return downloadFile(`${CHARGER_BOOKINGS}/downloadmnmrct/${id}`, data);
};

export const downloadSingleInvoice = (id, data) => {
  return downloadFile(`${CHARGER_BOOKINGS}/downloadinvoice/${id}`, data);
};

export const downloadBulkInvoice = (data) => {
  return downloadBulkFile(`/invoices/logitaxBulkInvoiceDownload`, data);
};

export const getInvoiceWidgets = (data) => {
  return post(`${CHARGER_BOOKINGS}${WIDGETS}`, data);
};
export const invoice = {
  getAllInvoice,
  downloadInvoice,
  downloadSingleInvoice,
  downloadBulkInvoice,
  downloadChargeRct,
  getInvoiceWidgets,
};
