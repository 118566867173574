import { FETCH_CHARGE_SPEED, GET_CHARGE_SPEED, CREATE_CHARGE_SPEED, UPDATE_CHARGE_SPEED, DELETE_CHARGE_SPEED } from 'actions/chargeSpeed';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  chargeSpeed: [],
  chargeSpeedDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const chargeSpeedReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHARGE_SPEED.REQUEST:
    case GET_CHARGE_SPEED.REQUEST:
    case CREATE_CHARGE_SPEED.REQUEST:
    case DELETE_CHARGE_SPEED.REQUEST: {
      return { ...state, isLoading: true, chargeSpeedDetail: {} };
    }

    case UPDATE_CHARGE_SPEED: {
      return { ...state, isLoading: true };
    }

    case FETCH_CHARGE_SPEED.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          chargeSpeed: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          chargeSpeed: _.concat(state.chargeSpeed, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_CHARGE_SPEED.SUCCESS: {
      return {
        ...state,
        chargeSpeedDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_CHARGE_SPEED.SUCCESS: {
      toast.success('Charge speed added successfully');
      return {
        ...state,
        chargeSpeed: _.concat(state.chargeSpeed, payload),
        isLoading: false,
      };
    }

    case UPDATE_CHARGE_SPEED.SUCCESS: {
      toast.success('Charge speed updated successfully');
      return {
        ...state,
        isLoading: false,
        chargeSpeed: state.chargeSpeed.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case DELETE_CHARGE_SPEED.SUCCESS: {
      toast.success('Charge speed deleted successfully');
      return {
        ...state,
        isLoading: false,
        chargeSpeed: state.chargeSpeed.filter((data) => data.id !== payload),
      };
    }

    case FETCH_CHARGE_SPEED.FAIL:
    case GET_CHARGE_SPEED.FAIL:
    case CREATE_CHARGE_SPEED.FAIL:
    case UPDATE_CHARGE_SPEED.FAIL:
    case DELETE_CHARGE_SPEED.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default chargeSpeedReducer;
