import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import queryString from 'query-string';
import { cookie } from 'utils/cookies/cookies';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_AMENITY, UPDATE_AMENITY } from 'actions/amenity';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const AmenityForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const amenityDetail = useSelector((state) => state.amenity.amenityDetail);
  const isLoading = useSelector((state) => state.amenity.isLoading);
  const token = cookie.get('token');

  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name', '');

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type', '');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, imgUrlData.url);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const addAmenity = useCallback((data) => {
    dispatch({
      type: CREATE_AMENITY.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onClose();
          onSuccess();
        }
      },
    });
  }, []);

  const updateAmenity = useCallback((data) => {
    dispatch({
      type: UPDATE_AMENITY.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const AmenitySchema = Yup.object().shape({
    name: Yup.string()
      .required(t('amenityForm.name'))
      .strict(true)
      .trim(t('amenityForm.space'))
      .matches(/^[A-Za-z\s]+$/, t('amenityForm.matches'))
      .max(100, t('amenityForm.maxlimit')),
  });

  const initialValues = isEdit
    ? amenityDetail
    : {
        name: '',
        icon: '',
      };

  return (
    <React.Fragment>
      <div className="amenity-form__main">
        <Card>
          <div className="amenity-form-header__block">
            <div className="amenity-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('amenityForm.title')}
              </span>
            </div>
            <div className="close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="amenity-form-body__block">
            <div className="amenity-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={AmenitySchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateAmenity(values);
                  } else {
                    addAmenity(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={12} md={12}>
                        <TextInput
                          isRequired
                          label={t('amenityForm.nameLabel')}
                          placeholder={t('amenityForm.nameLabel')}
                          name="name"
                          value={_.get(values, 'name')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                      <Row>
                        <Col>
                          {isEdit ? (
                            <TextInput
                              type="file"
                              label={t('amenityForm.addIcon')}
                              name="icon"
                              accept="image/*"
                              onChange={(e) => handleImage(e.currentTarget.files[0], 'icon', setFieldValue)}
                              error={errors.variant && touched.variant ? errors.variant : null}
                              image={values.icon}
                            />
                          ) : (
                            <TextInput
                              type="file"
                              label={t('amenityForm.addIcon')}
                              name="icon"
                              accept="image/*"
                              onChange={(e) => handleImage(e.currentTarget.files[0], 'icon', setFieldValue)}
                              error={errors.variant && touched.variant ? errors.variant : null}
                            />
                          )}
                        </Col>
                      </Row>
                    </Row>
                    <div className="form_btn--block">
                      <Button className="form--btn cancel_btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form--btn">
                        {isEdit ? 'Update' : 'Create'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

AmenityForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default AmenityForm;
