export const CREATE_VOUCHER = 'CREATE_VOUCHER';
export const CREATE_VOUCHER_SUCCESS = 'CREATE_VOUCHER_SUCCESS';
export const CREATE_VOUCHER_FAIL = 'CREATE_VOUCHER_FAIL';

export const FETCH_VOUCHERS = 'FETCH_VOUCHERS';
export const FETCH_VOUCHERS_SUCCESS = 'FETCH_VOUCHERS_SUCCESS';
export const FETCH_VOUCHERS_FAIL = 'FETCH_VOUCHERS_FAIL';

export const GET_VOUCHER = 'GET_VOUCHER';
export const GET_VOUCHER_SUCCESS = 'GET_VOUCHER_SUCCESS';
export const GET_VOUCHER_FAIL = 'GET_VOUCHER_FAIL';

export const DELETE_VOUCHER = 'DELETE_VOUCHER';
export const DELETE_VOUCHER_SUCCESS = 'DELETE_VOUCHER_SUCCESS';
export const DELETE_VOUCHER_FAIL = 'DELETE_VOUCHER_FAIL';

export const UPDATE_VOUCHER = 'UPDATE_VOUCHER';
export const UPDATE_VOUCHER_SUCCESS = 'UPDATE_VOUCHER_SUCCESS';
export const UPDATE_VOUCHER_FAIL = 'UPDATE_VOUCHER_FAIL';

export const VOUCHER_LOGS = 'VOUCHER_LOGS';
export const VOUCHER_LOGS_SUCCESS = 'VOUCHER_LOGS_SUCCESS';
export const VOUCHER_LOGS_FAIL = 'VOUCHER_LOGS_FAIL';

export const DOWNLOAD_VOUCHER_LOGS = 'DOWNLOAD_VOUCHER_LOGS';
export const DOWNLOAD_VOUCHER_LOGS_SUCCESS = 'DOWNLOAD_VOUCHER_LOGS_SUCCESS';
export const DOWNLOAD_VOUCHER_LOGS_FAIL = 'DOWNLOAD_VOUCHER_LOGS_FAIL';
