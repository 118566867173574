import { get, post, patch, deleteRequest } from 'utils/api';

export const getConnectorType = (data) => {
  return get(`/connector_type`, data);
};

export const getConnectorTypeById = (id) => {
  return get(`/connector_type/${id}`);
};
export const addConnectorType = (data) => {
  return post(`/connector_type`, data);
};

export const updateConnectorType = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/connector_type/${id}`, data);
};

export const deleteConnectorType = (id) => {
  return deleteRequest(`/connector_type/${id}`);
};

export const connectorType = {
  getConnectorType,
  getConnectorTypeById,
  addConnectorType,
  updateConnectorType,
  deleteConnectorType,
};
