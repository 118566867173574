import { get, post, deleteRequest, patch} from 'utils/api';

export const getTags = (data) =>{
    return get(`/tags`, data);
} 

export const addTags = (data) =>{
    return post(`/tags`,data)
}

export const deleteTags = (id) =>{
   return deleteRequest(`/tags/${id}`)
}

export const updateTags = (data) => {
    const id = data.id;
    delete data['id'];
    delete data['tenant']
    return patch(`/tags/${id}`,data)
}

export const getTagsbyId = (id) => {
    return get(`/tags/${id}`)
}

export const tagmanagement = {
    getTags,
    addTags,
    deleteTags,
    updateTags,
    getTagsbyId
}