import React from 'react';
import TextInput from 'components/inputs/Input';
import PropTypes from 'prop-types';
import { BsSearch } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const SearchBox = (props) => {
  const { t } = useTranslation();

  const {
    onKeyPress,
    onChange,
    preIcon,
    placeholder,
    postIcon,
    value,
    label,
    name,
    disabled,
    inputClass,
    wrapperClass,
    className,
    preText,
    postText,
    searchBoxWidth,
  } = props;
  return (
    <div className={`search-box__main${className ? className : ''}`}>
      <TextInput
        {...props}
        placeholder={placeholder || t('placeHolder.search')}
        pre_icon={preIcon || <BsSearch />}
        post_icon={postIcon}
        pre_text={preText}
        post_text={postText}
        inputClass={`search-box--input ${inputClass ? inputClass : ''}`}
        wrapperclass={`search-box--wrapper ${wrapperClass ? wrapperClass : ''}`}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
        label={label}
        name={name}
        disabled={disabled}
        style={{ width: searchBoxWidth }}
      />
    </div>
  );
};

SearchBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  preIcon: PropTypes.object,
  postIcon: PropTypes.object,
  placeholder: PropTypes.string,
  wrapperClass: PropTypes.string,
  preText: PropTypes.string,
  postText: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  disabled: PropTypes.bool,
  inputClass: PropTypes.string,
  className: PropTypes.string,
  searchBoxWidth: PropTypes.number,
};
export default SearchBox;
