export const ACTIVITY_LOGS = '/charger_logs';
export const COUNTRY = '/country';

export const STATE = '/state';
export const CITY = '/city';
export const AREA = `/area`;
export const AMENITY = '/amenity';
export const AUTH = '/auth';
export const CHARGER_STATE = '/charger_state';
export const TENANT_SETTING = '/tenantSetting';
export const CHARGERS = '/chargers';
export const CHARGER_BOOKINGS = '/charger_bookings';
export const CHARGER_STATUS = '/charger_status';
export const CUSTOMERS = '/customers';
export const USERS = '/users';
export const CHARGING_STATIONS = '/chargingStations';
export const CUSTOMER_REVIEWS = '/customer_reviews';
export const WIDGETS = '/widgets';
export const CS_WIDGETS = '/csWidgets';
export const CS_CHARGER_STATUS = '/chargingStations/chargerStatus';
export const VRN_UPDATE = '/vehicles/updatevrn';
export const INVOICES = '/charger_bookings/invoices';
export const RATING_REVIEW = '/customer_reviews/customerratingandreviews';
export const SMC_NO_LOAD_REPORT = '/smc_no_load_report';
export const CHARGING_SESSION = '/report/chargingsession';
export const RATING_REVIEW_SUMMARY = '/customer_reviews/stationratingandreviews';
export const REPORT_LIST = '/report_list';
export const REPORT_DOWNLOAD_REQUEST = '/report_download_request';
