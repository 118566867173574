import { GET_DASHBOARD_DATA } from 'actions/dashboard';

const initialState = {
  isLoading: false,
  dashboard: {},
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DASHBOARD_DATA.REQUEST: {
      return { ...state, isLoading: true };
    }

    case GET_DASHBOARD_DATA.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dashboard: payload,
      };
    }

    case GET_DASHBOARD_DATA.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default dashboardReducer;
