import { FETCH_PRIVACY, GET_PRIVACY, CREATE_PRIVACY, UPDATE_PRIVACY, DELETE_PRIVACY } from 'actions/privacy';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchPrivacy(action) {
  try {
    const response = yield call(API.getPrivacy, action.payload);
    yield put({ type: FETCH_PRIVACY.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_PRIVACY.FAIL, payload: { error: e } });
  }
}

function* getPrivacyById(action) {
  try {
    const response = yield call(API.getPrivacyById, action.payload);
    yield put({ type: GET_PRIVACY.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_PRIVACY.FAIL, payload: { error: e } });
  }
}

function* createPrivacy(action) {
  try {
    const response = yield call(API.addPrivacy, action.payload);
    yield put({ type: CREATE_PRIVACY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_PRIVACY.FAIL, payload: { error: e } });
  }
}

function* updatePrivacy(action) {
  try {
    const response = yield call(API.updatePrivacy, action.payload);
    yield put({ type: UPDATE_PRIVACY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_PRIVACY.FAIL, payload: { error: e } });
  }
}

function* deletePrivacy(action) {
  try {
    const response = yield call(API.deletePrivacy, action.payload);
    yield put({ type: DELETE_PRIVACY.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_PRIVACY.FAIL, payload: { error: e } });
  }
}

function* privacy() {
  yield takeLatest(FETCH_PRIVACY.REQUEST, fetchPrivacy);
  yield takeLatest(GET_PRIVACY.REQUEST, getPrivacyById);
  yield takeLatest(CREATE_PRIVACY.REQUEST, createPrivacy);
  yield takeLatest(UPDATE_PRIVACY.REQUEST, updatePrivacy);
  yield takeLatest(DELETE_PRIVACY.REQUEST, deletePrivacy);
}

export default privacy;
