import { get, deleteRequest, patch, post, downloadFile } from 'utils/api';

export const getRfidRequest = (data) => {
  const { page, from, to } = data;
  return post(`/rfid_request/report?page=${page}`, { from, to });
};

export const downloadRfidRequest = (data) => {
  return downloadFile(`/rfid_request/report`, data);
};

export const getRfidRequestById = (id) => {
  return get(`/rfid_request/${id}`);
};

export const updateRfidRequest = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/rfid_request/${id}`, data);
};

export const deleteRfidRequest = (id) => {
  return deleteRequest(`/rfid_request/${id}`);
};

export const rfidRequest = {
  getRfidRequest,
  getRfidRequestById,
  updateRfidRequest,
  deleteRfidRequest,
  downloadRfidRequest,
};
