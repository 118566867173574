import { FETCH_EMAIL, GET_EMAIL, UPDATE_EMAIL, CREATE_EMAIL, DELETE_EMAIL } from 'actions/email';
import { toast } from 'react-toastify';
import _ from 'lodash';

const initialState = {
  isLoading: false,
  email: [],
  emailDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const emailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_EMAIL.REQUEST:
    case GET_EMAIL.REQUEST:
    case CREATE_EMAIL.REQUEST:
    case DELETE_EMAIL.REQUEST: {
      return { ...state, isLoading: true };
    }
    case UPDATE_EMAIL: {
      return { ...state, isLoading: true };
    }

    case FETCH_EMAIL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        email: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_EMAIL.SUCCESS: {
      return {
        ...state,
        emailDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_EMAIL.SUCCESS: {
      toast.success('Email added successfully');
      return {
        ...state,
        email: _.concat(state.email, payload),
        isLoading: false,
      };
    }

    case UPDATE_EMAIL.SUCCESS: {
      toast.success('Email updated successfully');
      return {
        ...state,
        email: state.email.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_EMAIL.SUCCESS: {
      toast.success('Email deleted successfully');
      return {
        ...state,
        email: state.email.filter((data) => data.id !== payload),
      };
    }
    case GET_EMAIL.FAIL:
    case FETCH_EMAIL.FAIL:
    case CREATE_EMAIL.FAIL:
    case UPDATE_EMAIL.FAIL:
    case DELETE_EMAIL.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default emailReducer;
