import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { ADD_TENANT, DELETE_TENANT, FETCH_TENANT, GET_TENANT, UPDATE_TENANT, UPDATE_TENANT_CONTACT_INFORMATION } from 'actions/tenant';

function* fetchTenant(action) {
  try {
    const response = yield call(API.getTenant, action.payload);
    yield put({ type: FETCH_TENANT.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_TENANT.FAIL, payload: { error: e } });
  }
}

function* getTenantById(action) {
  try {
    const response = yield call(API.getTenantById, action.payload);
    yield put({ type: GET_TENANT.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_TENANT.FAIL, payload: { error: e } });
  }
}

function* createTenant(action) {
  try {
    const response = yield call(API.addTenant, action.payload);
    yield put({ type: ADD_TENANT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: ADD_TENANT.FAIL, payload: { error: e } });
  }
}

function* updateTenant(action) {
  try {
    const response = yield call(API.updateTenant, action.payload);
    yield put({ type: UPDATE_TENANT.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_TENANT.FAIL, payload: { error: e } });
  }
}

function* updateTenantContactInformation(action) {
  try {
    const response = yield call(API.updateTenantContactInformation, action.payload);
    yield put({ type: UPDATE_TENANT_CONTACT_INFORMATION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_TENANT_CONTACT_INFORMATION.FAIL, payload: { error: e } });
  }
}

function* deleteTenant(action) {
  try {
    const response = yield call(API.deleteTenant, action.payload);
    yield put({ type: DELETE_TENANT.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_TENANT.FAIL, payload: { error: e } });
  }
}

function* tenant() {
  yield takeLatest(ADD_TENANT.REQUEST, createTenant);
  yield takeLatest(FETCH_TENANT.REQUEST, fetchTenant);
  yield takeLatest(GET_TENANT.REQUEST, getTenantById);
  yield takeLatest(UPDATE_TENANT.REQUEST, updateTenant);
  yield takeLatest(UPDATE_TENANT_CONTACT_INFORMATION.REQUEST, updateTenantContactInformation);
  yield takeLatest(DELETE_TENANT.REQUEST, deleteTenant);
}

export default tenant;
