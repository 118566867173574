import React, { useState, useCallback, useEffect } from 'react';
import ActivityItem from 'components/general/ActivityItem';
import { FiSettings } from 'react-icons/fi';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import UpdateFirmwareModal from './UpdateFirmwareModal';
import moment from 'moment';
import { FETCH_NOTIFICATION, UPDATE_FIRMWARE_NOTIFICATION } from 'actions/notification';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import SessionHeading from 'components/general/SessionHeading';
import ReactPagination from 'components/general/ReactPagination';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { GET_USER_GROUPS, MANAGE_USER_GROUPS } from 'components/common/constant';

const UpdateFirmware = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));

  const allNotification = useSelector((state) => state.notification.notifications);
  const isLoader = useSelector((state) => state.notification.isLoading);
  const page = useSelector((state) => state.notification.page);
  const totalData = useSelector((state) => state.notification.total);
  const limit = useSelector((state) => state.notification.limit);
  const totalPages = useSelector((state) => state.notification.totalPages);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getAllNotification = useCallback((data = {}) => {
    const firmwareData = {
      ...data,
      message_type: 'update_firmware',
    };
    dispatch({ type: FETCH_NOTIFICATION.REQUEST, payload: firmwareData });
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const filterDateType = {
        from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      };
      endDate && getAllNotification(filterDateType);
    } else {
      getAllNotification();
    }
  }, [startDate, endDate]);

  const updateFirmwareNotification = useCallback(
    (id, file) => {
      const notificationData = {
        id: id,
        location: file,
        retries: 2,
        retrieveDate: '2022-02-23T18:25:43.511Z',
        retryInterval: 20,
      };
      dispatch({
        type: UPDATE_FIRMWARE_NOTIFICATION.REQUEST,
        payload: { data: notificationData },
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              setTimeout(() => {
                setShowUpdateModal(false);
              }, 1000);
            }
          }
        },
      });
    },
    [allNotification]
  );

  const handlePageClick = useCallback(
    (page) => {
      if (startDate && endDate) {
        const data = {
          from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
          page: page.selected + 1,
        };
        getAllNotification(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getAllNotification(data);
      }
    },
    [startDate, endDate]
  );

  const getUpdateFirmwarePermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_USER_GROUPS),
      (data) => data.name
    );

  const manageUpdateFirmwarePermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_USER_GROUPS),
      (data) => data.name
    );

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.updateFirmware')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <Card>
              <SessionHeading text="Update Firmware" className="activity-header--block">
                <div className="session-heading-right-content">
                  <Row>
                    <Col xl={12} md={12}>
                      {isLoader ? (
                        <Skeleton height={15} width={100} />
                      ) : (
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={moment(new Date()).startOf('day')}
                          initialValueOfEndDate={moment(new Date()).endOf('day')}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </SessionHeading>
              <div className="update-notification__main">
                {isLoader ? (
                  <div className="loader--block">
                    <Skeleton height={15} width={100} />
                  </div>
                ) : _.isEmpty(allNotification) ? (
                  <div className="empty-data-block">{t('updateFirmware.noData')}</div>
                ) : (
                  !(isLoader || _.isUndefined(isLoader)) && (
                    <>
                      {_.map(allNotification, (notification, index) => (
                        <ActivityItem
                          key={`notification-${index}`}
                          icon={isLoader ? <Skeleton height={15} width={100} /> : <FiSettings color="#E0AF32" />}
                          showUpdateButton={
                            isLoader ? (
                              <Skeleton height={15} width={100} />
                            ) : !_.isEmpty(manageUpdateFirmwarePermissions) ||
                              (!_.isEmpty(getUpdateFirmwarePermissions) && !_.isEmpty(manageUpdateFirmwarePermissions)) ? (
                              true
                            ) : (
                              false
                            )
                          }
                          text={
                            isLoader ? (
                              <Skeleton height={15} width={100} />
                            ) : (
                              <>
                                <span className="meta-info">{_.get(notification, 'message', '')}</span>
                              </>
                            )
                          }
                          date={
                            isLoader ? <Skeleton height={15} width={100} /> : moment(_.get(notification, 'createdAt')).format('dddd, DD MMMM, h:mm A')
                          }
                          onClickButton={() => {
                            setShowUpdateModal(true);
                            updateFirmwareNotification(_.get(notification, 'to_charger.id', ''), _.get(notification, 'file', ''));
                          }}
                        />
                      ))}
                    </>
                  )
                )}
              </div>
              {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allNotification) && (
                <ReactPagination
                  currentPage={page}
                  limit={limit}
                  total={totalData}
                  handlePageClick={(pageVal) => handlePageClick(pageVal)}
                  totalPages={totalPages}
                  marginPagesDisplayed={1}
                />
              )}
            </Card>
          </div>
        </SkeletonTheme>
        {showUpdateModal && (
          <Modal show={showUpdateModal} centered size="lg">
            <UpdateFirmwareModal onClose={() => setShowUpdateModal(false)} />
          </Modal>
        )}
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};
export default UpdateFirmware;
