import { FETCH_COUNTRY, GET_COUNTRY, CREATE_COUNTRY, UPDATE_COUNTRY, DELETE_COUNTRY } from 'actions/address';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  countries: [],
  countryDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const countryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COUNTRY.REQUEST:
    case GET_COUNTRY.REQUEST:
    case CREATE_COUNTRY.REQUEST:
    case DELETE_COUNTRY.REQUEST: {
      return { ...state, isLoading: true, countryDetail: {} };
    }

    case UPDATE_COUNTRY: {
      return { ...state, isLoading: true };
    }

    case FETCH_COUNTRY.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          countries: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          countries: _.concat(state.countries, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_COUNTRY.SUCCESS: {
      return {
        ...state,
        countryDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_COUNTRY.SUCCESS: {
      toast.success('Country added successfully');
      return {
        ...state,
        countries: _.concat(state.countries, payload),
        isLoading: false,
      };
    }

    case UPDATE_COUNTRY.SUCCESS: {
      toast.success('Country updated successfully');
      return {
        ...state,
        countries: state.countries.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_COUNTRY.SUCCESS: {
      toast.success('Country deleted successfully');
      return {
        ...state,
        countries: state.countries.filter((data) => data.id !== payload),
      };
    }

    case FETCH_COUNTRY.FAIL:
    case GET_COUNTRY.FAIL:
    case CREATE_COUNTRY.FAIL:
    case UPDATE_COUNTRY.FAIL:
    case DELETE_COUNTRY.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default countryReducer;
