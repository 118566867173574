import { createActionTypes } from 'utils/action/actionTypes';

export const FETCH_RFID = createActionTypes('FETCH_RFID');
export const GET_RFID = createActionTypes('GET_RFID');
export const CREATE_RFID = createActionTypes('CREATE_RFID');
export const UPDATE_RFID = createActionTypes('UPDATE_RFID');
export const DELETE_RFID = createActionTypes('DELETE_RFID');
export const CREATE_BULK_RFID = createActionTypes('CREATE_BULK_RFID');
export const FETCH_RFID_FOR_FLEET = createActionTypes('FETCH_RFID_FOR_FLEET');
export const CREATE_BULK_RFID_FOR_FLEET = createActionTypes('CREATE_BULK_RFID_FOR_FLEET');
export const UPDATE_VEHICLE_RFID = createActionTypes('UPDATE_VEHICLE_RFID');
export const DELETE_RFID_BY_FLEET = createActionTypes('DELETE_RFID_BY_FLEET');
