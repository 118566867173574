import React from 'react';
import PropTypes from 'prop-types';
import UserImage from 'assets/images/icons/dummyImage.png';

const Avatar = (props) => {
  let { imgSrc, name, className, onClick } = props;

  if (!onClick) {
    onClick = () => {};
  }

  return (
    <div onClick={onClick} className={`avatar ${className ? className : ''}`}>
      <img className={'avatarImg'} src={imgSrc || UserImage} alt={`${name ? name : ''}`} />
    </div>
  );
};

Avatar.propTypes = {
  imgSrc: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
export default Avatar;
