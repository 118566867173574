import React, { useCallback, useState, useEffect, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import * as _ from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { CREATE_COUPON, GET_COUPON, UPDATE_COUPON } from 'actions/coupon';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { useSelector, useDispatch } from 'react-redux';
// import { GET_CHARGER_STATION } from 'actions/chargingStation';
import { FETCH_USER_GROUP } from 'actions/userGroup';
import Button from 'components/inputs/Button';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import CheckBox from 'components/inputs/CheckBox';
import { Modal } from 'react-bootstrap';
import { FETCH_CHARGE_COIN } from 'actions/chargeCoin';
import { useParams } from 'react-router-dom';
import { FETCH_CHARGERS_BY_STATIONS } from 'actions/charger';
import { toast } from 'react-toastify';
import moment from 'moment';

const CouponForm = () => {
  const dispatch = useDispatch();
  const { navigateTo } = useContext(NavContext);
  const { couponId } = useParams();
  const { t } = useTranslation();
  const couponDetail = useSelector((state) => state.coupon.couponDetail);
  const allStations = useSelector((state) => state.chargingStation.chargingStations);
  const chargerStationList = useSelector((state) => state.charger.chargerDetails);
  const allUserGroup = useSelector((state) => state.userGroup.userGroups);
  const userGroupPage = useSelector((state) => state.userGroup.page);
  const userGroupTotalPage = useSelector((state) => state.userGroup.totalPages);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [tempStatusReason, setTempStatusReason] = useState('');
  // const [groupTierType, setGroupTierType] = useState('user_group');
  const chargeCoinDetails = useSelector((state) => state.chargeCoin.results[0] || {});
  const tier = chargeCoinDetails.key_value?.levels || [];
  const [isEditable, setIsEditable] = useState(true);
  const [reasonError, setReasonError] = useState('');

  const [startDateInput, setStartDateInput] = useState('');
  const [endDateInput, setEndDateInput] = useState('');

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return '';
    return moment(dateTimeString).format('YYYY-MM-DDTHH:mm');
  };

  const initialValues =
    couponId && !_.isEmpty(couponDetail)
      ? {
          ...couponDetail,
          charging_stations: couponDetail.charging_stations.length > 0 ? couponDetail.charging_stations : ['All'],
          chargers: couponDetail.chargers.length > 0 ? couponDetail.chargers : ['All'],
          group_tier_type:
            couponDetail.user_group && couponDetail.user_group.length > 0 ? 'user_group' : couponDetail.customer_tier ? 'customer_tier' : 'none_tier',
          user_group: couponDetail.user_group || [],
          customer_tier: couponDetail.customer_tier || '',
          booking_type: Array.isArray(couponDetail.booking_type) ? couponDetail.booking_type : [couponDetail.booking_type],
          redemption_type: couponDetail.redemption_type || 'direct_discount',
          discount_type: couponDetail.discount_type || 'percentage',
          max_discount_amount: couponDetail.max_discount_amount || '',
          total_usage_limit: couponDetail.total_usage_limit || '',
          min_order_value: couponDetail.min_order_value || '',
          start_date_time: formatDateTime(couponDetail.start_date_time),
          end_date_time: formatDateTime(couponDetail.end_date_time),
        }
      : {
          name: '',
          nature: '',
          booking_type: [],
          redemption_type: 'direct_discount',
          discount_value: '',
          no_of_uses_per_user: '',
          status: 'enable',
          status_reason: '',
          discount_type: 'percentage',
          code: '',
          group_tier_type: 'none_tier',
          user_group: [],
          customer_tier: '',
          charging_stations: ['All'],
          chargers: ['All'],
          step_to_avail: '',
          start_date_time: formatDateTime(moment()),
          end_date_time: formatDateTime(moment().add(1, 'day')),
        };

  const addCoupon = useCallback((data) => {
    dispatch({
      type: CREATE_COUPON,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/coupons');
          }, 1000);
        }
      },
    });
  }, []);

  const updateCoupon = useCallback((data) => {
    dispatch({
      type: UPDATE_COUPON,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo('/coupons');
          }, 500);
        }
      },
    });
  }, []);

  const CouponFormSchema = Yup.object({
    name: Yup.string().required(t('couponForm.name')).strict(true).max(100, t('couponForm.maxlimit')),
    nature: Yup.string().required(t('couponForm.nature')),
    booking_type: Yup.array().of(Yup.string()).min(1, 'At least one booking type is required').required('Booking type is required'),
    redemption_type: Yup.string().default('direct_discount').required(t('couponForm.redemption')),
    discount_value: Yup.number().positive('Discount value must be positive').required(t('couponForm.discount')),
    start_date_time: Yup.date()
      .test('start-date-validation', 'Start date cannot be in the past', function (value) {
        const isEditMode = !!this.parent.id; // Check if we're in edit mode
        if (isEditMode) {
          return true; // Always valid in edit mode
        }
        return moment(value).isSameOrAfter(moment(), 'minute');
      })
      .required(t('couponForm.startDate')),
    end_date_time: Yup.date().min(Yup.ref('start_date_time'), 'End date must be after start date').required(t('couponForm.endDate')),
    no_of_uses_per_user: Yup.number().positive('Number of uses must be positive').required(t('couponForm.uses')),
    status: Yup.string().required(t('couponForm.statusField')),
    step_to_avail: Yup.string().required(t('couponForm.stepsAvail')),
    code: Yup.string().required(t('couponForm.code')),
    status_reason: Yup.string().when('status', {
      is: 'disable',
      then: Yup.string().required('Status reason is required when disabling a coupon'),
    }),
    group_tier_type: Yup.string().required('Group/Tier type is required'),
    user_group: Yup.array().when('group_tier_type', {
      is: 'user_group',
      then: Yup.array()
        .min(1, 'At least one user group is required when Group/Tier type is User Group')
        .required('User group is required when Group/Tier type is User Group'),
      otherwise: Yup.array().nullable(),
    }),
    customer_tier: Yup.string().when('group_tier_type', {
      is: 'customer_tier',
      then: Yup.string().required('Customer tier is required when Group/Tier type is Customer Tier'),
      otherwise: Yup.string().nullable(),
    }),
    min_order_value: Yup.number().min(0, 'Minimum order value cannot be negative'),
    total_usage_limit: Yup.number().min(0, 'Total usage limit cannot be negative'),
    max_discount_amount: Yup.number().min(0, 'Maximum discount amount cannot be negative'),
  }).test('either-customer-tier-or-user-group', 'Cannot accept both customer tier and user group', (values) => {
    if (values.group_tier_type === 'user_group' && values.user_group.length > 0 && values.customer_tier) {
      return false;
    }
    if (values.group_tier_type === 'customer_tier' && values.customer_tier && values.user_group.length > 0) {
      return false;
    }
    return true;
  });

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  const getAllUserGroup = useCallback((data = {}) => {
    dispatch({ type: FETCH_USER_GROUP, payload: data });
  }, []);

  const getChargeCoinInfo = useCallback(() => {
    dispatch({ type: FETCH_CHARGE_COIN, payload: null });
  }, []);

  useEffect(() => {
    getAllStation();
    getAllUserGroup();
    getChargeCoinInfo();
  }, []);

  useEffect(() => {
    if (couponId && !_.isEmpty(couponDetail)) {
      setIsEditable(couponDetail.status !== 'disable');
    }
  }, [couponId, couponDetail]);

  const getCouponById = useCallback((id) => {
    dispatch({ type: GET_COUPON, payload: id });
  }, []);

  useEffect(() => {
    if (couponId) {
      getCouponById(couponId);
    }
  }, [couponId]);

  const handleStationChange = (val, setFieldValue) => {
    console.log(val);
    const updatedVal = val || [];
    const stationIds = updatedVal.map((item) => item);
    setFieldValue('charging_stations', stationIds);
    fetchChargesByStations(stationIds);

    setFieldValue('chargers', []);
  };

  const fetchChargesByStations = useCallback(
    (stationIds) => {
      if (stationIds.length > 0 && !stationIds.includes('All')) {
        dispatch({
          type: FETCH_CHARGERS_BY_STATIONS,
          payload: {
            charging_stations: stationIds,
          },
        });
      }
    },
    [dispatch]
  );

  const isReasonValid = (reason) => {
    return reason.trim().length > 0;
  };

  // useEffect(() => {
  //   // Update min datetime every second
  //   const interval = setInterval(() => {
  //     const startTimeInput = document.getElementById('start_date_time');
  //     const endTimeInput = document.getElementById('end_date_time');
  //     if (startTimeInput && endTimeInput) {
  //       const now = moment().format('YYYY-MM-DDTHH:mm');
  //       startTimeInput.min = now;
  //       endTimeInput.min = now;
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    if (couponId && !_.isEmpty(couponDetail)) {
      setStartDateInput(formatDateTime(couponDetail.start_date_time));
      setEndDateInput(formatDateTime(couponDetail.end_date_time));
    } else {
      setStartDateInput(formatDateTime(moment()));
      setEndDateInput(formatDateTime(moment().add(1, 'day')));
    }
  }, [couponId, couponDetail]);

  const handleDateChange = (e, fieldName, setFieldValue, setInputState) => {
    const inputValue = e.target.value;
    setInputState(inputValue);

    if (moment(inputValue, 'YYYY-MM-DDTHH:mm', true).isValid()) {
      const selectedDate = moment(inputValue);
      setFieldValue(fieldName, selectedDate.format('YYYY-MM-DDTHH:mm'));
      if (fieldName === 'start_date_time') {
        document.getElementById('end_date_time').min = inputValue;
      }
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${couponId ? 'Edit' : 'Add'} ${t('header.addCoupons')}`} backTo="/coupons" />
        <div className="page-content-wrapper scrollable">
          <div className="add-coupon-page-main">
            <Card className="coupon-form-card">
              <div className="coupon-form__block">
                <Formik
                  enableReinitialize={!!couponId}
                  initialValues={initialValues}
                  validationSchema={CouponFormSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (!isEditable) {
                      toast.error('This coupon is disabled and cannot be edited.');
                      setSubmitting(false);
                      return;
                    }
                    let formData = _.omit(values, ['group_tier_type', 'stations', 'total_revenue', 'total_discount_availed']);

                    formData.start_date_time = moment(formData.start_date_time).utc().format();
                    formData.end_date_time = moment(formData.end_date_time).utc().format();

                    // Conditionally omit user_group and customer_tier fields
                    if (values.group_tier_type !== 'user_group') {
                      formData = _.omit(formData, ['user_group']);
                    }
                    if (values.group_tier_type !== 'customer_tier') {
                      formData = _.omit(formData, ['customer_tier']);
                    }

                    if (formData.chargers && formData.chargers.length === 1 && formData.chargers[0] === 'All') {
                      formData = _.omit(formData, ['chargers']);
                    }
                    if (formData.charging_stations && formData.charging_stations.length === 1 && formData.charging_stations[0] === 'All') {
                      formData = _.omit(formData, ['charging_stations']);
                    }
                    if (!formData.customer_tier) {
                      formData = _.omit(formData, ['customer_tier']);
                    }
                    if (couponId) {
                      const updateData = _.omit(formData, ['tenant', 'createdAt', 'updatedAt', ['start_date_time']]);
                      updateCoupon(updateData);
                    } else {
                      addCoupon(formData);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, handleSubmit, handleChange, setFieldValue, errors, touched, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col xxl={3} xl={3} lg={3} md={3}>
                          <TextInput
                            isRequired
                            label={t('couponForm.couponName')}
                            placeholder={t('couponForm.enterCouponName')}
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            error={errors.name && touched.name ? errors.name : null}
                          />
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3}>
                          <Select
                            isRequired
                            label={t('couponForm.couponNature')}
                            placeholder={t('couponForm.selectNature')}
                            options={[
                              { label: 'Top-Up', value: 'topup' },
                              { label: 'Transaction', value: 'transaction' },
                            ]}
                            name="nature"
                            value={values.nature}
                            onChange={(val) => setFieldValue('nature', val)}
                            error={errors.nature && touched.nature ? errors.nature : null}
                          />
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6}>
                          <Select
                            isRequired
                            label={t('couponForm.bookingType')}
                            placeholder={t('couponForm.selectBookingType')}
                            options={[
                              { label: 'Mobile', value: 'mobile' },
                              { label: 'RFID', value: 'rfid' },
                              { label: 'Autocharge', value: 'autocharge' },
                            ]}
                            name="booking_type"
                            isMulti
                            value={values.booking_type}
                            onChange={(val) => setFieldValue('booking_type', val)}
                            error={errors.booking_type && touched.booking_type ? errors.booking_type : null}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={3} xl={3} lg={3} md={3}>
                          <Select
                            label={t('couponForm.redemptionType')}
                            placeholder={t('couponForm.selectRedemptionType')}
                            options={[
                              { label: 'Direct Discount', value: 'direct_discount' },
                              { label: 'Cashback', value: 'cashback' },
                              { label: 'ChargeCoins', value: 'charge_coins' },
                            ]}
                            name="redemption_type"
                            value={values.redemption_type}
                            onChange={(val) => setFieldValue('redemption_type', val)}
                            error={errors.redemption_type && touched.redemption_type ? errors.redemption_type : null}
                          />
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3}>
                          <div className="discount-value">
                            <TextInput
                              type="number"
                              label={t('couponForm.discountValue')}
                              placeholder={t('couponForm.enterDiscountValue')}
                              name="discount_value"
                              value={values.discount_value}
                              onChange={handleChange}
                              error={errors.discount_value && touched.discount_value ? errors.discount_value : null}
                              isRequired
                            />
                            <Select
                              label={''}
                              options={[
                                { label: '%', value: 'percentage' },
                                { label: '₹', value: 'amount' },
                              ]}
                              name="discount_type"
                              value={values.discount_type}
                              onChange={(val) => setFieldValue('discount_type', val)}
                              error={errors.discount_type && touched.discount_type ? errors.discount_type : null}
                            />
                          </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6}>
                          <Row>
                            <Col xxl={6} xl={6} lg={6} md={6}>
                              <TextInput
                                label={t('couponForm.startTime')}
                                placeholder={t('couponForm.selectStartDateTime')}
                                type="datetime-local"
                                name="start_date_time"
                                value={startDateInput}
                                onChange={(e) => handleDateChange(e, 'start_date_time', setFieldValue, setStartDateInput)}
                                isRequired
                                disabled={!!couponId} // Disable the field if couponId exists (edit mode)
                                error={errors.start_date_time && touched.start_date_time ? errors.start_date_time : null}
                              />
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6}>
                              <TextInput
                                label={t('couponForm.endTime')}
                                placeholder={t('couponForm.selectEndDateTime')}
                                type="datetime-local"
                                name="end_date_time"
                                value={endDateInput}
                                onChange={(e) => handleDateChange(e, 'end_date_time', setFieldValue, setEndDateInput)}
                                isRequired
                                error={errors.end_date_time && touched.end_date_time ? errors.end_date_time : null}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={3} xl={3} lg={3} md={3}>
                          <TextInput
                            type="number"
                            label={t('couponForm.minOrder')}
                            placeholder={t('couponForm.minOrderValue')}
                            name="min_order_value"
                            min="0"
                            value={values.min_order_value}
                            onChange={handleChange}
                            error={errors.min_order_value && touched.min_order_value ? errors.min_order_value : null}
                          />
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3}>
                          <TextInput
                            type="number"
                            label={t('couponForm.noOfUses')}
                            placeholder={t('couponForm.noUsers')}
                            name="no_of_uses_per_user"
                            value={values.no_of_uses_per_user}
                            onChange={handleChange}
                            min="1"
                            error={errors.no_of_uses_per_user && touched.no_of_uses_per_user ? errors.no_of_uses_per_user : null}
                            isRequired
                          />
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3}>
                          <TextInput
                            type="number"
                            label={t('couponForm.totalUsage')}
                            placeholder={t('couponForm.totalUsageLimit')}
                            name="total_usage_limit"
                            min="0"
                            value={values.total_usage_limit}
                            onChange={handleChange}
                            error={errors.total_usage_limit && touched.total_usage_limit ? errors.total_usage_limit : null}
                          />
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3}>
                          <TextInput
                            type="number"
                            label={t('couponForm.maxDiscount')}
                            placeholder={t('couponForm.maxDiscountAmount')}
                            name="max_discount_amount"
                            value={values.max_discount_amount}
                            onChange={handleChange}
                            min="0"
                            error={errors.max_discount_amount && touched.max_discount_amount ? errors.max_discount_amount : null}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12}>
                          <Select
                            label={t('couponForm.station')}
                            options={[
                              { label: 'All', value: 'All' },
                              ..._.map(allStations, (stations) => {
                                return { label: stations.name, value: stations.id };
                              }),
                            ]}
                            isMulti
                            className="filter-select-box"
                            placeholder={t('placeHolder.selectStations')}
                            name="charging_stations"
                            value={values.charging_stations}
                            onMenuScrollDown={true}
                            onChange={(val) => handleStationChange(val, setFieldValue)}
                            isDisabled={values.nature === 'topup'}
                          />
                        </Col>
                        <Col lg={12} md={12}>
                          <Select
                            label={t('couponForm.chargers')}
                            options={[
                              { label: 'All', value: 'All' },
                              ..._.map(chargerStationList, (item) => {
                                return { label: item.charger_id, value: item.id };
                              }),
                            ]}
                            className="filter-select-box"
                            placeholder={t('placeHolder.selectCharger')}
                            name="chargers"
                            value={values.chargers}
                            onMenuScrollDown={true}
                            isMulti
                            onChange={(val) => {
                              setFieldValue(`chargers`, val);
                            }}
                            isDisabled={values.nature === 'topup'}
                          />
                        </Col>
                        <Col lg={12} md={12}>
                          <Row>
                            <Col lg={6} md={6}>
                              <Select
                                label={t('couponForm.groupTierType')}
                                options={[
                                  { label: t('couponForm.noneTier'), value: 'none_tier' },
                                  { label: t('couponForm.userGroup'), value: 'user_group' },
                                  { label: t('couponForm.customerTier'), value: 'customer_tier' },
                                ]}
                                className="filter-select-box"
                                placeholder={t('couponForm.groupTierType')}
                                name="group_tier_type"
                                value={values.group_tier_type}
                                onChange={(val) => {
                                  setFieldValue('group_tier_type', val);
                                  if (val === 'user_group') {
                                    setFieldValue('customer_tier', '');
                                  } else if (val === 'customer_tier') {
                                    setFieldValue('user_group', []);
                                  }
                                }}
                                error={errors.group_tier_type && touched.group_tier_type ? errors.group_tier_type : null}
                              />
                            </Col>
                            {values.group_tier_type === 'user_group' && (
                              <Col lg={6} md={6}>
                                <Select
                                  label={t('couponForm.userGroup')}
                                  options={_.map(allUserGroup, (item) => {
                                    return { label: item.name, value: item.id };
                                  })}
                                  className="filter-select-box"
                                  placeholder={t('couponForm.selectUserGroup')}
                                  name="user_group"
                                  value={values.user_group}
                                  onMenuScrollDown={true}
                                  page={userGroupPage}
                                  totalPage={userGroupTotalPage}
                                  getDataOnScrollDown={getAllUserGroup}
                                  isMulti
                                  onChange={(val) => {
                                    setFieldValue('user_group', val);
                                  }}
                                  error={errors.user_group && touched.user_group ? errors.user_group : null}
                                />
                              </Col>
                            )}
                            {values.group_tier_type === 'customer_tier' && (
                              <Col lg={6} md={6}>
                                <Select
                                  label={t('couponForm.customerTier')}
                                  options={tier.map((item) => ({
                                    label: item.name,
                                    value: item.name,
                                  }))}
                                  className="filter-select-box"
                                  placeholder={t('placeHolder.selectTier')}
                                  name="customer_tier"
                                  value={values.customer_tier}
                                  onMenuScrollDown={true}
                                  onChange={(val) => {
                                    setFieldValue('customer_tier', val);
                                  }}
                                  error={errors.customer_tier && touched.customer_tier ? errors.customer_tier : null}
                                />
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col lg={12} md={12}>
                          <TextInput
                            isRequired
                            as="textarea"
                            rows="4"
                            inputClass="scrollable"
                            label={t('couponForm.steps')}
                            name="step_to_avail"
                            value={values.step_to_avail}
                            onChange={handleChange}
                            error={errors.step_to_avail && touched.step_to_avail ? errors.step_to_avail : null}
                          />
                        </Col>
                        <Col lg={12} md={12}>
                          <Row>
                            <Col lg={3} md={3}>
                              <TextInput
                                isRequired
                                label={t('couponForm.couponCode')}
                                placeholder={t('couponForm.enterCouponCode')}
                                name="code"
                                value={values.code}
                                onChange={handleChange}
                                error={errors.code && touched.code ? errors.code : null}
                              />
                            </Col>
                            <Col lg={3} md={3}>
                              <label>
                                {t('couponForm.status')}
                                <br />
                              </label>
                              <CheckBox
                                name="status"
                                checked={values.status === 'enable'}
                                onChange={(e) => {
                                  if (!e.target.checked) {
                                    setShowReasonModal(true);
                                  } else {
                                    setFieldValue('status', 'enable');
                                    setIsEditable(true);
                                  }
                                }}
                                disabled={!isEditable}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <div className="app-setting-btn--block">
                        <Button type="submit" className="" disabled={isSubmitting}>
                          {couponId ? 'Edit' : 'Add'} {t('button.addCoupon')}
                        </Button>
                      </div>
                      <div className="couponCodeModal">
                        <Modal
                          className="showReasonModal"
                          show={showReasonModal}
                          centered
                          onHide={() => {
                            setShowReasonModal(false);
                            setFieldValue('status', 'enable');
                          }}
                        >
                          <TextInput
                            isRequired
                            as="textarea"
                            rows="4"
                            inputClass="scrollable"
                            label={t('couponForm.statusReasonModalTitle')}
                            name="tempStatusReason"
                            value={tempStatusReason}
                            onChange={(e) => {
                              setTempStatusReason(e.target.value);
                              setReasonError('');
                            }}
                            error={reasonError || (errors.status_reason && touched.status_reason ? errors.status_reason : null)}
                          />
                          <Row className="couponModal-btn">
                            <Col lg={6}>
                              <Button
                                className="request-model--btn cancel--btn"
                                onClick={() => {
                                  setShowReasonModal(false);
                                  setFieldValue('status', 'enable');
                                  setTempStatusReason('');
                                }}
                              >
                                {t('button.cancel')}
                              </Button>
                            </Col>
                            <Col lg={6}>
                              <Button
                                className="request-model--btn  remove--btn"
                                onClick={() => {
                                  if (isReasonValid(tempStatusReason)) {
                                    setFieldValue('status_reason', tempStatusReason);
                                    setShowReasonModal(false);
                                    setFieldValue('status', 'disable');
                                  } else {
                                    setReasonError('Please provide a reason for disabling the coupon.');
                                  }
                                }}
                              >
                                {t('button.save')}
                              </Button>
                            </Col>
                          </Row>
                        </Modal>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default CouponForm;
