import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function PasswordStrengthBar({ password }) {
  const [strength, setStrength] = useState('weak');

  useEffect(() => {
    let strengthLevel = 'weak';

    if (password.length > 8) {
      strengthLevel = 'moderate';
    }

    if (password.length > 8 && /[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password) && /[!@#$%^&*]/.test(password)) {
      strengthLevel = 'strong';
    }

    setStrength(strengthLevel);
  }, [password]);

  return (
    <div className="password-strength-bar">
      <div className={`strength-bar strength-${strength}`}></div>

      <div className="strength-text"></div>
    </div>
  );
}
PasswordStrengthBar.propTypes = {
  password: PropTypes.string.isRequired,
};
export default PasswordStrengthBar;
