import { post } from 'utils/api';
// import { toast } from 'react-toastify';
import { cookie } from '../utils/cookies/cookies';
import axios from 'axios';

export const login = (data) => {
  let payload = {};
  if (Number.isFinite(parseInt(data?.username))) {
    payload = { phone: parseInt(data?.username.split(" ").join("")), ...data };
  } else {
    payload = data;
  }
  return post(`/auth/login`, payload);
};

export const forgotPassword = (data) => {
  return post(`/auth/forgotPasswordOtp`, data);
};

export const changePassword = (data) => {
  return post(`/auth/change-password`, data);
};

export const resetPassword = (data) => {
  return post(`/auth/changePasswordOtp`, data);
};

export const tenantAdminLogin = (data) => {
  return post(`/auth/loginas`, data);
};

export const tenantAdminLoginAsSupport = (data) => {
  try {
    const token = cookie.get('token');
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/loginas-support-role`, data, { headers: { Authorization: `Bearer ${token}` } });
  } catch (e) {
    console.log(e, 'error message >>>>>>>>>');
  }
};

export const authApi = {
  login,
  forgotPassword,
  changePassword,
  resetPassword,
  tenantAdminLogin,
  tenantAdminLoginAsSupport,
};
