import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import AdminHeader from 'components/header';
import { Col, Row, Modal } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsSearch, BsThreeDots } from 'react-icons/bs';
import Button from 'components/inputs/Button';
// import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import DropDown from 'components/inputs/DropDown';
import { FETCH_OFFERS } from 'actions/offers';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import { roundOfDigit } from 'components/common/utils';
import { DELETE_OFFERS } from 'actions/offers';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE, GET_OFFERS, MANAGE_OFFERS } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const OfferList = () => {
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  // const [allOffersData, setAllOffersData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [offerId, setOfferId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });

  const dispatch = useDispatch();
  const allOffers = useSelector((state) => state.offers.offers);

  const isLoader = useSelector((state) => state.offers.isLoading);
  const page = useSelector((state) => state.offers.page);
  const totalData = useSelector((state) => state.offers.total);
  console.log('totalData', totalData);
  const limit = useSelector((state) => state.offers.limit);
  const totalPages = useSelector((state) => state.offers.totalPages);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getOffer = useCallback(
    (data) => {
      if (searchText) {
        const offerData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_OFFERS.REQUEST, payload: offerData });
      } else {
        dispatch({ type: FETCH_OFFERS.REQUEST, payload: data });
      }
    },
    [searchText]
  );

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setOfferId('');
  };

  const deleteOffer = useCallback(
    () =>
      dispatch({
        type: DELETE_OFFERS.REQUEST,
        payload: offerId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getOffer({ page: 1 });
          }
        },
      }),
    [offerId]
  );

  const getOfferPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_OFFERS),
      (data) => data.name
    );

  const manageOfferPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_OFFERS),
      (data) => data.name
    );

  // useEffect(() => {
  //   setAllOffersData(
  //     _.filter(
  //       allOffers,
  //       (item) =>
  //         startsWith(_.get(item, 'code', ''), searchText) ||
  //         startsWith(_.get(item, 'amount', '').toString(), searchText) ||
  //         startsWith(_.get(item, 'type', ''), searchText) ||
  //         startsWith(_.get(item, 'max_uses_per_user', '').toString(), searchText)
  //     )
  //   );
  // }, [searchText, allOffers]);

  useEffect(() => {
    getOffer();
  }, [searchText]);

  const handleSearch = () => {
    getOffer({});
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        -getOffer(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getOffer(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getOffer(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.offers')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="offer-management-page_main">
              <div className="offer-list-main-block">
                <Card>
                  <div className="offer-list-block">
                    <div className="offer-search-box">
                      <Row>
                        <Col xl={!_.isEmpty(manageOfferPermissions) ? 10 : 12}>
                          <SearchBox
                            preIcon={<BsSearch />}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                handleSearch();
                              }
                            }}
                          />
                        </Col>
                        {(!_.isEmpty(manageOfferPermissions) || (!_.isEmpty(getOfferPermissions) && !_.isEmpty(manageOfferPermissions))) && (
                          <Col xl={2}>
                            <Button className="add-offer-btn" onClick={() => navigateTo('/addOffer')}>
                              {t('button.addOffer')}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </div>
                    <div className="offer-list-table">
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('offerForm.srNo')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('offerForm.codeLabel')}</span>
                                  <span className="ico" onClick={() => handleSorting('code', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('offerForm.amountLabel')}</span>
                                  <span className="ico" onClick={() => handleSorting('amount', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('offerForm.type')}</span>
                                  <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('offerForm.maxUser')}</span>
                                  <span className="ico" onClick={() => handleSorting('max_uses_per_user', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('offerForm.expiryLabel')} </span>
                                  <span className="ico" onClick={() => handleSorting('expiry', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              {(!_.isEmpty(manageOfferPermissions) || (!_.isEmpty(getOfferPermissions) && !_.isEmpty(manageOfferPermissions))) && (
                                <th>
                                  <div>
                                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('offerForm.action')}</span>
                                  </div>
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {isLoader ? (
                              <tr>
                                <td colSpan={10} className="border-0">
                                  <div className="loader--block">
                                    <Skeleton height={15} width={100} />
                                  </div>
                                </td>
                              </tr>
                            ) : _.isEmpty(allOffers) ? (
                              <tr>
                                <td colSpan={8} className="border-0">
                                  <div className="empty-data-block">{t('offerForm.noData')}</div>
                                </td>
                              </tr>
                            ) : (
                              !(isLoader || _.isUndefined(isLoader)) &&
                              _.map(allOffers, (offers, index) => {
                                const serial_num = limit * (page - 1) + index;
                                return (
                                  <tr key={`offers-data-item-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(offers, 'code', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(offers, 'amount', ''), 2)} </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(offers, 'type', '')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(offers, 'max_uses_per_user', '')}</td>
                                    <td>
                                      {isLoader ? <Skeleton height={15} width={100} /> : moment(_.get(offers, 'expiry', '')).format('YYYY-MM-DD')}
                                    </td>
                                    {(!_.isEmpty(manageOfferPermissions) ||
                                      (!_.isEmpty(getOfferPermissions) && !_.isEmpty(manageOfferPermissions))) && (
                                      <td>
                                        <div key={`action-${index}`} className="offer-list">
                                          <span className="list-icon">
                                            {isLoader ? (
                                              <Skeleton height={15} width={100} />
                                            ) : (
                                              <DropDown
                                                popupClass="bottom-left"
                                                trigger={
                                                  <div className={'more-info'}>
                                                    <BsThreeDots />
                                                  </div>
                                                }
                                              >
                                                <ul>
                                                  <li className="item" onClick={() => navigateTo(`/editOffer/${offers.id}`)}>
                                                    {t('button.edit')}
                                                  </li>
                                                  {!_.isEmpty(manageOfferPermissions) ||
                                                  (!_.isEmpty(getOfferPermissions) && !_.isEmpty(manageOfferPermissions)) ? (
                                                    <li
                                                      className="item"
                                                      onClick={() => {
                                                        setShowDeleteModal(true);
                                                        setOfferId(_.get(offers, 'id', ''));
                                                      }}
                                                    >
                                                      {t('button.delete')}
                                                    </li>
                                                  ) : (
                                                    <li className="item">Delete</li>
                                                  )}
                                                </ul>
                                              </DropDown>
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allOffers) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                      // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                    )}
                    {showDeleteModal && (
                      <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                        <DeleteModal title="offers" onClose={handleCloseDeleteModel} onRemove={deleteOffer} />
                      </Modal>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default OfferList;
