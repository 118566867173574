import React from 'react';
import PropTypes from 'prop-types';
import { Offcanvas } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';


const OffCanvas = (props) => {
  const { t } = useTranslation();

  const { show, onClose, children } = props;
  return (
    <Offcanvas show={show} placement="end" onHide={onClose} className="list-filter-canvas__container">
      <div className="filter-canvas__inner scrollable">
        <div className="canvas__header-block">
          <div className="header-name">{t("filters.advancedFilter")}</div>
          <div className="header-close-icon">
            <MdClose size={30} color="#be210b" onClick={onClose} />
          </div>
        </div>
        <div className="canvas__body-block">
          <div className="canvas__body-block--inner">{children}</div>
        </div>
      </div>
    </Offcanvas>
  );
};

OffCanvas.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default OffCanvas;
