import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_CHARGING_STATION_ACCESS_TYPE, UPDATE_CHARGING_STATION_ACCESS_TYPE } from 'actions/stationAccessType';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const StationAccessTypeListForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const stationAccessTypeDetail = useSelector((state) => state.stationAccessType.stationAccessTypeDetail);
  const isLoading = useSelector((state) => state.stationAccessType.isLoading);

  const addStationAccessType = useCallback((data) => {
    dispatch({
      type: CREATE_CHARGING_STATION_ACCESS_TYPE.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateStationAccessType = useCallback((data) => {
    dispatch({
      type: UPDATE_CHARGING_STATION_ACCESS_TYPE.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const StationAccessTypeSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('stationAccessTypeListForm.name'))
      .strict(true)
      .trim(t('stationAccessTypeListForm.space'))
      .matches(/^[A-Za-z\s]+$/, t('stationAccessTypeListForm.matches'))
      .max(100, t('stationAccessTypeListForm.maxlimit')),
  });

  const initialValues = isEdit
    ? stationAccessTypeDetail
    : {
        name: '',
      };
  return (
    <React.Fragment>
      <div className="stationAccessType-form-page__main">
        <Card>
          <div className="stationAccessType-form-header__block">
            <div className="stationAccessType-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('stationAccessTypeListForm.title')}
              </span>
            </div>
            <div className="close---btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="stationAccessType-form-body__block">
            <div className="stationAccessType-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={StationAccessTypeSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateStationAccessType(values);
                  } else {
                    addStationAccessType(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={12} md={12}>
                        <TextInput
                          isRequired
                          label={t('stationAccessTypeListForm.title')}
                          placeholder={t('stationAccessTypeListForm.title')}
                          name="name"
                          value={_.get(values, 'name', '')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                    </Row>
                    <div className="form_btn----block">
                      <Button className="form----btn cancel___btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form----btn">
                        {isEdit ? 'Edit' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
StationAccessTypeListForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default StationAccessTypeListForm;
