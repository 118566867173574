import React from 'react';
import { DatePickerInput } from 'rc-datepicker';
import PropTypes from 'prop-types';
import 'rc-datepicker/lib/style.css';
import ErrorMessage from './ErrorMessage';
import moment from 'moment';

const CustomDatePickerInput = (props) => {
  const { showOnInputClick, placeholder, value, defaultValue, onChange, maxDate, minDate, className, label, labelClass, error, isRequired } = props;

  return (
    <div className="datePicker-input--main">
      {label && (
        <label className={`datePicker-input-label ${labelClass ? labelClass : ''}`} htmlFor={label}>
          {label}
          {isRequired && <span className="require--star">*</span>}
        </label>
      )}
      <div className={`datePicker-input-field `}>
        <DatePickerInput
          {...props}
          placeholder={placeholder}
          displayFormat="DD/MM/YYYY"
          returnFormat="YYYY-MM-DD"
          locale="en_US"
          showOnInputClick={showOnInputClick || true}
          defaultValue={defaultValue || ''}
          value={value}
          maxDate={maxDate || ''}
          minDate={minDate || ''}
          onChange={onChange || function () {}}
          autoClose={true}
          className={`${className ? className : ''}`}
          position="bottom"
        />
      </div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

CustomDatePickerInput.propTypes = {
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  showOnInputClick: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)]),
  className: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  error: PropTypes.string,
};

export default CustomDatePickerInput;
