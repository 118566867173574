import { patch } from "utils/api";

export const updateBooking = (bookingId, StartSoC, StopSoC) => {
  const payload = {};

  if (StartSoC && StopSoC) {
    payload.StartSoC = StartSoC;
    payload.StopSoC = StopSoC;
  }

  return patch(`/charger_bookings/${bookingId}`, payload)
  .then((response) => response.data)
  .catch((error) => {
    throw error;
  });

};

export const updateSocBooking = {
    updateBooking,
  };
  