import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CardLayout = (props) => {
  const { className, bg, body, text, border } = props;

  return (
    <>
      <Card bg={bg} body={body || true} text={text} border={border} className={`main-card-container ${className ? className : ''}`}>
        {props.children}
      </Card>
    </>
  );
};

CardLayout.propTypes = {
  className: PropTypes.string,
  bg: PropTypes.string,
  body: PropTypes.bool,
  text: PropTypes.string,
  border: PropTypes.string,
  children: PropTypes.node,
};
export default CardLayout;
