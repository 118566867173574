import { FETCH_TAGS, CREATE_TAGS, DELETE_TAGS, UPDATE_TAGS, GET_TAG } from 'actions/tagmanagement';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

const initialState = {
  isLoading: false,
  tags: [],
  tagsDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const tagmanagementreducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TAGS.REQUEST:
    case CREATE_TAGS.REQUEST:
    case DELETE_TAGS.REQUEST:
    case GET_TAG.REQUEST: {
      return { ...state, isLoading: true, tagsDetail: {} };
    }

    case UPDATE_TAGS.REQUEST: {
      return { ...state, isLoading: true };
    }
    case FETCH_TAGS.SUCCESS: {
      // if (payload.page === 1) {
      return {
        ...state,
        isLoading: false,
        tags: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
      // } else {
      //   return {
      //     ...state,
      //     isLoading: false,
      //     tags: _.concat(state.countries, payload.results),
      //     page: payload.page,
      //     limit: payload.limit,
      //     totalPages: payload.totalPages,
      //     total: payload.totalResults,
      //   };
      // }
    }

    case GET_TAG.SUCCESS: {
      return {
        ...state,
        tagsDetails: payload,
        isLoading: false,
      };
    }
    case CREATE_TAGS.SUCCESS: {
      toast.success('Tag Added Successfully');
      return {
        ...state,
        isLoading: false,
        tags: _.concat(state.tags, payload),
      };
    }

    case UPDATE_TAGS.SUCCESS: {
      toast.success('Tag Updated Successfully');
      return {
        ...state,
        isLoading: false,
        tags: state.tags.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_TAGS.SUCCESS: {
      toast.success('Tag Deleted Successfully');
      return {
        ...state,
        isLoading: false,
        tags: state.tags.filter((data) => data.id !== payload),
      };
    }

    case FETCH_TAGS.FAIL:
    case CREATE_TAGS.FAIL:
    case DELETE_TAGS.FAIL:
    case UPDATE_TAGS.FAIL:
    case GET_TAG.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default tagmanagementreducer;
