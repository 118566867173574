import { FETCH_VEHICLE_MAKE, GET_VEHICLE_MAKE, CREATE_VEHICLE_MAKE, UPDATE_VEHICLE_MAKE, DELETE_VEHICLE_MAKE } from 'actions/vehicleMake';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchVehicleMake(action) {
  try {
    const response = yield call(API.getVehicleMake, action.payload);
    yield put({ type: FETCH_VEHICLE_MAKE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_VEHICLE_MAKE.FAIL, payload: { error: e } });
  }
}

function* getVehicleMakeById(action) {
  try {
    const response = yield call(API.getVehicleMakeById, action.payload);
    yield put({ type: GET_VEHICLE_MAKE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_VEHICLE_MAKE.FAIL, payload: { error: e } });
  }
}

function* createVehicleMake(action) {
  try {
    const response = yield call(API.addVehicleMake, action.payload);
    yield put({ type: CREATE_VEHICLE_MAKE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_VEHICLE_MAKE.FAIL, payload: { error: e } });
  }
}

function* updateVehicleMake(action) {
  try {
    const response = yield call(API.updateVehicleMake, action.payload);
    yield put({ type: UPDATE_VEHICLE_MAKE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_VEHICLE_MAKE.FAIL, payload: { error: e } });
  }
}

function* deleteVehicleMake(action) {
  try {
    const response = yield call(API.deleteVehicleMake, action.payload);
    yield put({ type: DELETE_VEHICLE_MAKE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_VEHICLE_MAKE.FAIL, payload: { error: e } });
  }
}

function* vehicleMake() {
  yield takeLatest(FETCH_VEHICLE_MAKE.REQUEST, fetchVehicleMake);
  yield takeLatest(GET_VEHICLE_MAKE.REQUEST, getVehicleMakeById);
  yield takeLatest(CREATE_VEHICLE_MAKE.REQUEST, createVehicleMake);
  yield takeLatest(UPDATE_VEHICLE_MAKE.REQUEST, updateVehicleMake);
  yield takeLatest(DELETE_VEHICLE_MAKE.REQUEST, deleteVehicleMake);
}

export default vehicleMake;
