import { FETCH_FAQ, GET_FAQ, CREATE_FAQ, UPDATE_FAQ, DELETE_FAQ } from 'actions/faq';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchFaq(action) {
  try {
    const response = yield call(API.getFaq, action.payload);
    yield put({ type: FETCH_FAQ.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_FAQ.FAIL, payload: { error: e } });
  }
}

function* getFaqById(action) {
  try {
    const response = yield call(API.getFaqById, action.payload);
    yield put({ type: GET_FAQ.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_FAQ.FAIL, payload: { error: e } });
  }
}

function* createFaq(action) {
  try {
    const response = yield call(API.addFaq, action.payload);
    yield put({ type: CREATE_FAQ.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_FAQ.FAIL, payload: { error: e } });
  }
}

function* updateFaq(action) {
  try {
    const response = yield call(API.updateFaq, action.payload);
    yield put({ type: UPDATE_FAQ.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_FAQ.FAIL, payload: { error: e } });
  }
}

function* deleteFaq(action) {
  try {
    const response = yield call(API.deleteFaq, action.payload);
    yield put({ type: DELETE_FAQ.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_FAQ.FAIL, payload: { error: e } });
  }
}

function* faq() {
  yield takeLatest(FETCH_FAQ.REQUEST, fetchFaq);
  yield takeLatest(GET_FAQ.REQUEST, getFaqById);
  yield takeLatest(CREATE_FAQ.REQUEST, createFaq);
  yield takeLatest(UPDATE_FAQ.REQUEST, updateFaq);
  yield takeLatest(DELETE_FAQ.REQUEST, deleteFaq);
}

export default faq;
