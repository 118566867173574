import { CHARGER_STATUS, FETCH_CHARGER_STATUS_WIDGETS, CHARGER_STATUS_DOWNLOAD } from '../actions/chargerStatus';

import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchChargerStatus(action) {
  try {
    const response = yield call(API.getChargerStatus, action.payload);
    yield put({ type: CHARGER_STATUS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHARGER_STATUS.FAIL, payload: { error: e } });
  }
}

function* fetchChargerWidgets(action) {
  try {
    const response = yield call(API.getChargerWidgets, action.payload);
    yield put({ type: FETCH_CHARGER_STATUS_WIDGETS.SUCCESS, payload: response });
    action.cs && action.cs(response);
  } catch (e) {
    yield put({ type: FETCH_CHARGER_STATUS_WIDGETS.FAIL, payload: { error: e } });
  }
}

function* downloadChargerStatus(action) {
  try {
    const response = yield call(API.downloadChargerStatus, action.payload);
    yield put({ type: CHARGER_STATUS_DOWNLOAD.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHARGER_STATUS_DOWNLOAD.FAIL, payload: { error: e } });
  }
}
function* chargerStatus() {
  yield takeLatest(CHARGER_STATUS.REQUEST, fetchChargerStatus);
  yield takeLatest(CHARGER_STATUS_DOWNLOAD.REQUEST, downloadChargerStatus);
  yield takeLatest(FETCH_CHARGER_STATUS_WIDGETS.REQUEST, fetchChargerWidgets);
}
export default chargerStatus;
