import { FETCH_OFFERS, GET_OFFERS, CREATE_OFFERS, UPDATE_OFFERS, DELETE_OFFERS } from 'actions/offers';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  offers: [],
  offerDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const offersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_OFFERS.REQUEST:
    case GET_OFFERS.REQUEST:
    case CREATE_OFFERS.REQUEST:
    case DELETE_OFFERS.REQUEST: {
      return { ...state, isLoading: true, offerDetail: {} };
    }

    case UPDATE_OFFERS.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_OFFERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        offers: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_OFFERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        offerDetail: payload,
      };
    }

    case CREATE_OFFERS.SUCCESS: {
      toast.success(' Offer added successfully');
      return {
        ...state,
        isLoading: false,
        offers: _.concat(state.offers, payload),
      };
    }

    case UPDATE_OFFERS.SUCCESS: {
      toast.success(' Offer updated successfully');
      return {
        ...state,
        offers: state.offers.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_OFFERS.SUCCESS: {
      toast.success(' Offer deleted successfully');
      return {
        ...state,
        isLoading: false,
        offers: state.offers.filter((data) => data.id !== payload),
      };
    }

    case FETCH_OFFERS.FAIL:
    case GET_OFFERS.FAIL:
    case CREATE_OFFERS.FAIL:
    case UPDATE_OFFERS.FAIL:
    case DELETE_OFFERS.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default offersReducer;
