import { FETCH_REPORT_LIST } from 'actions/downloadAdvanceReport';

const initialState = {
  isLoading: false,
  reportList: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};
const reportListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_REPORT_LIST.REQUEST: {
      return { ...state, isLoading: true };
    }
    case FETCH_REPORT_LIST.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reportList: payload?.results,
        page: payload?.page,
        limit: payload?.limit,
        totalPages: payload?.totalPages,
        total: payload?.totalResults,
      };
    }

    case FETCH_REPORT_LIST.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};

export default reportListReducer;
