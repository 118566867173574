import { FETCH_OFFERS, GET_OFFERS, CREATE_OFFERS, UPDATE_OFFERS, DELETE_OFFERS } from 'actions/offers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchOffers(action) {
  try {
    const response = yield call(API.getOffers, action.payload);
    yield put({ type: FETCH_OFFERS.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_OFFERS.FAIL, payload: { error: e } });
  }
}

function* getOffersById(action) {
  try {
    const response = yield call(API.getOffersById, action.payload);
    yield put({ type: GET_OFFERS.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_OFFERS.FAIL, payload: { error: e } });
  }
}

function* createOffers(action) {
  try {
    const response = yield call(API.addOffers, action.payload);
    yield put({ type: CREATE_OFFERS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_OFFERS.FAIL, payload: { error: e } });
  }
}

function* updateOffers(action) {
  try {
    const response = yield call(API.updateOffers, action.payload);
    yield put({ type: UPDATE_OFFERS.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_OFFERS.FAIL, payload: { error: e } });
  }
}

function* deleteOffers(action) {
  try {
    const response = yield call(API.deleteOffers, action.payload);
    yield put({ type: DELETE_OFFERS.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_OFFERS.FAIL, payload: { error: e } });
  }
}

function* Offers() {
  yield takeLatest(FETCH_OFFERS.REQUEST, fetchOffers);
  yield takeLatest(GET_OFFERS.REQUEST, getOffersById);
  yield takeLatest(CREATE_OFFERS.REQUEST, createOffers);
  yield takeLatest(UPDATE_OFFERS.REQUEST, updateOffers);
  yield takeLatest(DELETE_OFFERS.REQUEST, deleteOffers);
}

export default Offers;
