import { get, post, deleteRequest, patch } from 'utils/api';

export const getRfidGroup = (data) => {
  return get(`/rfid_group`, data);
};

export const getRfidGroupById = (id) => {
  return get(`/rfid_group/${id}`);
};

export const addRfidGroup = (data) => {
  return post(`/rfid_group`, data);
};

export const updateRfidGroup = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/rfid_group/${id}`, data);
};

export const deleteRfidGroup = (id) => {
  return deleteRequest(`/rfid_group/${id}`);
};

export const rfidGroup = {
  getRfidGroup,
  getRfidGroupById,
  addRfidGroup,
  updateRfidGroup,
  deleteRfidGroup,
};
