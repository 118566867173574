import { get, post, patch, deleteRequest } from 'utils/api';

export const getCurrency = (data) => {
  return get('/currency', data);
};

export const getCurrencyById = (id) => {
  return get(`/currency/${id}`);
};

export const addCurrency = (data) => {
  return post(`/currency`, data);
};

export const updateCurrency = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/currency/${id}`, data);
};

export const updateCurrencyFirmware = (data) => {
  return post(`/currency/updatefirmware`, data);
};

export const deleteCurrency = (id) => {
  return deleteRequest(`/currency/${id}`);
};

export const currency = {
  getCurrency,
  getCurrencyById,
  addCurrency,
  updateCurrency,
  deleteCurrency,
  updateCurrencyFirmware,
};
