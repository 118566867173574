import { get, post, deleteRequest, patch } from 'utils/api';

export const getSms = (data) => {
  return get(`/sms_template`, data);
};

export const getSmsById = (id) => {
  return get(`/sms_template/${id}`);
};

export const addSms = (data) => {
  return post(`/sms_template`, data);
};

export const updateSms = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/sms_template/${id}`, data);
};

export const deleteSms = (id) => {
  return deleteRequest(`/sms_template/${id}`);
};

export const sms = {
  getSms,
  getSmsById,
  addSms,
  updateSms,
  deleteSms,
};
