import { FETCH_CHARGER_STATE, GET_CHARGER_STATE, CREATE_CHARGER_STATE, UPDATE_CHARGER_STATE, DELETE_CHARGER_STATE } from 'actions/chargerState';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  chargerStates: [],
  chargerStateDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const chargerStateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHARGER_STATE.REQUEST:
    case GET_CHARGER_STATE.REQUEST:
    case CREATE_CHARGER_STATE.REQUEST:
    case DELETE_CHARGER_STATE.REQUEST: {
      return { ...state, isLoading: true, chargerStateDetail: {} };
    }

    case UPDATE_CHARGER_STATE: {
      return { ...state, isLoading: true };
    }

    case FETCH_CHARGER_STATE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        chargerStates: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_CHARGER_STATE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        chargerStateDetail: payload,
      };
    }

    case CREATE_CHARGER_STATE.SUCCESS: {
      toast.success(' Charger state added successfully');
      return {
        ...state,
        isLoading: false,
        chargerStates: _.concat(state.chargerStates, payload),
      };
    }

    case UPDATE_CHARGER_STATE.SUCCESS: {
      toast.success(' Charger state updated successfully');
      return {
        ...state,
        chargerStates: state.chargerStates.map((data) => (data.id === payload.id ? payload : data)),
        isLoading: false,
      };
    }

    case DELETE_CHARGER_STATE.SUCCESS: {
      toast.success(' Charger state deleted successfully');
      return {
        ...state,
        isLoading: false,
        chargerStates: state.chargerStates.filter((data) => data.id !== payload),
      };
    }

    case FETCH_CHARGER_STATE.FAIL:
    case GET_CHARGER_STATE.FAIL:
    case CREATE_CHARGER_STATE.FAIL:
    case UPDATE_CHARGER_STATE.FAIL:
    case DELETE_CHARGER_STATE.FAIL: {
      // toast.error(payload.error.data.message);
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default chargerStateReducer;
