import React, { useState } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
// import { useParams } from 'react-router-dom';
import Card from 'components/inputs/Card';
// import { FaChargingStation } from 'react-icons/fa';
import Setting from './Setting';
import Schedule from './Schedule';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import Footer from 'components/general/Footer';
// import { Spinner } from 'react-bootstrap';
// import { GET_CPO } from 'actions/partnerManagement';

const SchedulingSetting = () => {
  //   const { ocpiId } = useParams();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState('setting');
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');
  //   const dispatch = useDispatch();

  //   const isLoading = useSelector((state) => state.partnerManagement.isLoading);
  //   const ocpiData = useSelector((state) => state.partnerManagement.partnerInfo);

  //   const getEmspData = useCallback(() => {
  //     dispatch({ type: GET_CPO, payload: ocpiId });
  //   }, []);

  //   useEffect(() => {
  //     ocpiId && getEmspData();
  //   }, []);

  const setTab = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.schedulingSetting')} />
        <div className="page-content-wrapper scrollable">
          {
            <div className="station-details__main">
              <Card>
                <div>
                  <div className="tab-block">
                    <div className="tab-nav-block">
                      <a
                        href="#"
                        className={`tab-nav__item ${currentTab === 'setting' ? 'active' : ''}`}
                        onClick={(e) => setTab(e, 'setting')}
                        data-content="tab-setting"
                      >
                        {t('bookingSchedule.settings')}
                      </a>
                      <a
                        href="#"
                        className={`tab-nav__item ${currentTab === 'schedule' ? 'active' : ''}`}
                        onClick={(e) => setTab(e, 'schedule')}
                        data-content="tab-chargers"
                      >
                        {t('bookingSchedule.schedule')}
                      </a>
                    </div>
                    <div className="tab-content-block">
                      <div className="tab-content__item active" id="station-details-tab">
                        {currentTab === 'setting' && <Setting />}
                        {currentTab === 'schedule' && <Schedule />}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          }
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </>
  );
};

export default SchedulingSetting;
