import { createActionTypes } from 'utils/action/actionTypes';

export const FETCH_CUSTOMER = createActionTypes('FETCH_CUSTOMER');
export const FETCH_WIDGETS = createActionTypes('FETCH_WIDGETS');
export const GET_CUSTOMER = createActionTypes('GET_CUSTOMER');
export const CREATE_CUSTOMER = createActionTypes('CREATE_CUSTOMER');
export const UPDATE_CUSTOMER = createActionTypes('UPDATE_CUSTOMER');
export const DELETE_CUSTOMER = createActionTypes('DELETE_CUSTOMER');
export const WALLET_TRANSACTION = createActionTypes('WALLET_TRANSACTION');
export const FETCH_CUSTOMER_PROFILE = createActionTypes('FETCH_CUSTOMER_PROFILE');
export const FETCH_CUSTOMER_BOOKING = createActionTypes('FETCH_CUSTOMER_BOOKING');
export const FETCH_CUSTOMER_TRANSACTION = createActionTypes('FETCH_CUSTOMER_TRANSACTION');
export const FETCH_FAULTY_CUSTOMER_TRANSACTION = createActionTypes('FETCH_FAULTY_CUSTOMER_TRANSACTION');
export const DOWNLOAD_CUSTOMER_BOOKING = createActionTypes('DOWNLOAD_CUSTOMER_BOOKING');
export const FETCH_CHARGE_COIN_TRANSACTION = createActionTypes('FETCH_CHARGE_COIN_TRANSACTION');
export const DOWNLOAD_CUSTOMER_TRANSACTION = createActionTypes('DOWNLOAD_CUSTOMER_TRANSACTION');
export const DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION = createActionTypes('DOWNLOAD_FAULTY_CUSTOMER_TRANSACTION');
export const SYNC_WALLET = createActionTypes('SYNC_WALLET');
export const CALCULATE_CUSTOMER_BOOKING = createActionTypes('CALCULATE_CUSTOMER_BOOKING');
export const CREATE_CUSTOMER_BOOKING = createActionTypes('CREATE_CUSTOMER_BOOKING');
export const RESCHEDULE_CUSTOMER_BOOKING = createActionTypes('RESCHEDULE_CUSTOMER_BOOKING');
export const CANCEL_CUSTOMER_BOOKING = createActionTypes('CANCEL_CUSTOMER_BOOKING');
export const FETCH_ROLES = createActionTypes('FETCH_ROLES');
export const RESET_OTP_LIMIT = createActionTypes('RESET_OTP_LIMIT');
export const UPDATE_CUSTOMER_VEHICLE = createActionTypes('UPDATE_CUSTOMER_VEHICLE');
export const DOWNLOAD_PAYMENT_RECEIPT = createActionTypes('DOWNLOAD_PAYMENT_RECEIPT');
