import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/inputs/Button';
import { RiCoupon3Line } from 'react-icons/ri';
import { MdClose } from 'react-icons/md';

const CouponSelection = ({ selectedCoupon, handleOpenCouponModal, handleRemoveCoupon, t, disabled }) => {
  return (
    <div className="coupon-selection">
      {selectedCoupon ? (
        <div className="selected-coupon">
          <RiCoupon3Line className="coupon-icon" size={18} />
          <div className="coupon-info">
            <span className="coupon-code-sel">{selectedCoupon.code}</span>
          </div>
          <Button className="remove-coupon-sel" onClick={handleRemoveCoupon}>
            <MdClose />
          </Button>
        </div>
      ) : (
        <h5
          onClick={!disabled ? handleOpenCouponModal : null} // Only open if not disabled
          className={`use-coupon-btn ${disabled ? 'disabled' : ''}`} // Add disabled class
          style={{ cursor: disabled ? 'not-allowed' : 'pointer', color: disabled ? '#ccc' : 'initial' }}
        >
          <RiCoupon3Line className="coupon-icon" size={18} />
          &nbsp;{t('button.useCoupon')}
        </h5>
      )}
    </div>
  );
};

CouponSelection.propTypes = {
  selectedCoupon: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  }),
  handleOpenCouponModal: PropTypes.func.isRequired,
  handleRemoveCoupon: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default CouponSelection;
