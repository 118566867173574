import React, { useState, useCallback, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Row, Col, Modal } from 'react-bootstrap';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { FETCH_VEHICLE_MAKE, GET_VEHICLE_MAKE, DELETE_VEHICLE_MAKE } from 'actions/vehicleMake';
import MakeForm from './MakeForm';
import Avatar from 'components/inputs/Avatar';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { AiOutlineCar } from 'react-icons/ai';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const MakeList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  // const [allMakeData, setAllMakeData] = useState([]);
  const [makeId, setMakeId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseMakeData, setPageWiseMakeData] = useState([]);

  const page = useSelector((state) => state.vehicleMake.page);
  const totalData = useSelector((state) => state.vehicleMake.total);
  const limit = useSelector((state) => state.vehicleMake.limit);
  const makeList = useSelector((state) => state.vehicleMake.vehicleMakes);
  const isLoader = useSelector((state) => state.vehicleMake.isLoading);
  const totalPages = useSelector((state) => state.vehicleMake.totalPages);

  const makeData = makeList.filter(({ id: id1 }) => pageWiseMakeData.some(({ id: id2 }) => id2 === id1));

  const getMake = useCallback(
    (data) => {
      if (searchText) {
        const searchMakeData = {
          ...data,
          search: searchText,
        };
        dispatch({
          type: FETCH_VEHICLE_MAKE.REQUEST,
          payload: searchMakeData,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseMakeData(res.data.results);
              }
            }
          },
        });
      } else {
        dispatch({
          type: FETCH_VEHICLE_MAKE.REQUEST,
          payload: data ? data : {},
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                setPageWiseMakeData(res.data.results);
              }
            }
          },
        });
      }
    },
    [pageWiseMakeData, searchText]
  );

  const getMakeDetail = useCallback((id) => {
    dispatch({ type: GET_VEHICLE_MAKE.REQUEST, payload: id });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setMakeId('');
  };

  const deleteMake = useCallback(
    () =>
      dispatch({
        type: DELETE_VEHICLE_MAKE.REQUEST,
        payload: makeId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getMake({ page: 1 });
          }
        },
      }),
    [makeId]
  );

  useEffect(() => {
    getMake();
  }, []);

  const handleSearch = () => {
    getMake({});
  };

  // useEffect(() => {
  //   setAllMakeData(_.filter(makeData, (item) => startsWith(_.get(item, 'name', ''), searchText)));
  // }, [searchText, makeList, pageWiseMakeData]);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getMake(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getMake(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getMake(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseFormMake = () => {
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.evMake')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="makeList-page">
              <Card>
                <div className="makeList-page-wrapper">
                  <Row className="makeList-search-box">
                    <Col xl={10} lg={9} md={8} className="makeList-search-inputBox">
                      <SearchBox
                        value={searchText}
                        preIcon={<BsSearch />}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSearch();
                          }
                        }}
                      />
                    </Col>
                    <Col xl={2} lg={3} md={4} className="makeList-search-boxButton">
                      <Button
                        className="makeList-search-box-Btn"
                        onClick={() => {
                          setShowForm(true);
                          setIsEdit(false);
                        }}
                      >
                        {t('button.addEVMake')}
                      </Button>
                    </Col>
                  </Row>
                  <div className="makeList-table">
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('makeForm.evMakeName')}</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('makeForm.makeLogo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('makeForm.vehicleType')}</span>
                                <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('makeForm.action')}</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <Skeleton height={15} />
                              </td>
                            </tr>
                          ) : _.isEmpty(makeData) ? (
                            <tr>
                              <td colSpan={6} className="border-0">
                                <div className="empty-data-block">{t('makeForm.noData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(makeData, (item, key) => {
                              return (
                                <>
                                  <tr key={`make-${key}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'name', '-')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton circle="true" height={48} width={48} />
                                      ) : _.get(item, 'logo') ? (
                                        <Avatar imgSrc={_.get(item, 'logo', '')} className="make-logo" />
                                      ) : (
                                        <AiOutlineCar className="make-logo" />
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'type', '-')}</td>
                                    <td>
                                      <span
                                        className="makeList-table-editIcon"
                                        onClick={() => {
                                          setShowForm(true);
                                          getMakeDetail(_.get(item, 'id'));
                                          setIsEdit(true);
                                        }}
                                      >
                                        {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <FiEdit2 title="Edit" />}
                                      </span>
                                      <span
                                        className="makeList-table-deleteIcon"
                                        onClick={() => {
                                          setShowDeleteModal(true);
                                          setMakeId(_.get(item, 'id', ''));
                                        }}
                                      >
                                        {isLoader ? <Skeleton circle="true" height={24} width={24} /> : <RiDeleteBinLine title="Delete" />}
                                      </span>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                      {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(makeData) && (
                        <ReactPagination
                          currentPage={page}
                          limit={limit}
                          total={totalData}
                          handlePageClick={(pageVal) => handlePageClick(pageVal)}
                          totalPages={totalPages}
                          marginPagesDisplayed={1}
                        />
                        // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {showForm && (
          <Modal show={showForm} size="lg" centered>
            <MakeForm onClose={handleCloseFormMake} isEdit={isEdit} onSuccess={getMake} />
          </Modal>
        )}
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="EV Make" onClose={handleCloseDeleteModel} onRemove={deleteMake} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};

export default MakeList;
