export const size = (pixels) => `${pixels / 16}rem`;

export const weight = {
  normal: 300,
  bold: 400,
};

export const font = {
  small: size(12),
  normal: size(14),
  header: size(24),
};

export const color = {
  lightGrey: '#F7F7F8',

  grey: '#B9C8D3',

  font: '#1C2A3B',
  fontLight: '#15284B',

  borderGrey: '#EDEDED',
  darkerBorder: '#979797',

  red: '#AD073D',
  brainyGreen: '#00d3cd',
  darkRed: '#a12941',
  // lightRed: '#ff8c8c',
  lightRed: '#dc4949',
  darkBlue: '#1C2A3B',
  greyBlue: '#7C98AB',
  yellow: '#EDC624',
  green: '#129387',

  primary: '#1c2a3b',

  lightBlue: '#608fc9',

  secondary: '#8F8F8F',
};

export const margin = {
  tiny: size(8),
  small: size(16),
  normal: size(26),
  outline: size(28),
};

export const layout = {
  headerHeight: size(64),
  sideBarWidth: size(288),
  toolbarWidth: size(324),
};

export const memberTypesColors = {
  frequent_visitor: '#a12941',
  home: '#8f8f8f',
  jm_office: '#129387',
  m_campus: '#608fc9',
  m_maritime: '#1c2a3b',
  visitor: '#cc173b',
};

export const spacesColors = ['#1c2a3b', '#59636F', '#88AAD3', '#7E8995', '#9DA9B5', '#ededed', '#E3B2BF', '#D096A1', '#BA6B7A', '#a12941'];

export const profileTypeColors = [
  { value: '#1C2A3B' },
  { value: '#363CAA' },
  { value: '#800DA3' },
  { value: '#A12941' },
  { value: '#FB6107' },
  { value: '#F7AD00' },
  { value: '#326771' },
  { value: '#129387' },
  { value: '#608FC9' },
];

export const userGroupsColors = {
  color1: '#365173',
  color2: '#8B1D68',
  color3: '#B941DD',
  color4: '#68CCDF',
  color5: '#565EFC',
  color6: '#F3486A',
  color7: '#30B04D',
  color8: '#BADA4E',
  color9: '#FFCB47',
};

export const brandSecondaryColors = {
  color1: '#42648D',
  color2: '#A12941',
  color3: '#F3486A',
  color4: '#449EAE',
  color5: '#8C56FC',
  color6: '#FF7D00',
  color7: '#30B06B',
  color8: '#00A0E9',
  color9: '#4600D7',
};

export default { font, color, margin, size, layout };
