import { ADD_TENANT, FETCH_TENANT, GET_TENANT, UPDATE_TENANT, DELETE_TENANT } from 'actions/tenant';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  tenants: [],
  tenantDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const tenantReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_TENANT.REQUEST:
    case FETCH_TENANT.REQUEST:
    case GET_TENANT.REQUEST: {
      return { ...state, isLoading: true, tenantDetail: {} };
    }

    case UPDATE_TENANT.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_TENANT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        tenants: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }
    case GET_TENANT.SUCCESS: {
      return {
        ...state,
        tenantDetail: payload,
        page: payload.page,
        limit: payload.limit,
        isLoading: false,
      };
    }

    case ADD_TENANT.SUCCESS: {
      toast.success('Tenant added successfully');
      return {
        ...state,
        tenants: _.concat(state.tenants, payload),
        isLoading: false,
      };
    }

    case UPDATE_TENANT.SUCCESS: {
      // toast.success('Tenant update successfully');
      return {
        ...state,
        isLoading: false,
        tenants: state.tenants.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_TENANT.SUCCESS: {
      toast.success('Tenant deleted successfully');
      return {
        ...state,
        isLoading: false,
        tenants: state.tenants.filter((data) => data.id !== payload),
      };
    }

    case UPDATE_TENANT.FAIL:
    case ADD_TENANT.FAIL:
    case FETCH_TENANT.FAIL:
    case GET_TENANT.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default tenantReducer;
