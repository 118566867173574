import { FETCH_TARIFF, DELETE_TARIFF, UPDATE_TARIFF, CREATE_TARIFF, GET_TARIFF } from 'actions/tariff';
import { toast } from 'react-toastify';
import _ from 'lodash';

const initialState = {
  isLoading: false,
  tariff: [],
  tariffDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const tariffReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_TARIFF.REQUEST:
    case DELETE_TARIFF.REQUEST:
    case FETCH_TARIFF.REQUEST:
    case CREATE_TARIFF.REQUEST:
    case GET_TARIFF.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_TARIFF.SUCCESS: {
      // if (payload.page === 1) {
      //   return {
      //     ...state,
      //     isLoading: false,
      //     tariff: payload.results,
      //     page: payload.page,
      //     limit: payload.limit,
      //     totalPages: payload.totalPages,
      //     total: payload.totalResults,
      //   };
      // } else {
      //   return {
      //     ...state,
      //     isLoading: false,
      //     tariff: _.concat(state.tariff, payload.results),
      //     page: payload.page,
      //     limit: payload.limit,
      //     totalPages: payload.totalPages,
      //     total: payload.totalResults,
      //   };
      // }
      return {
        ...state,
        isLoading: false,
        tariff: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_TARIFF.SUCCESS: {
      return {
        ...state,
        tariffDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_TARIFF.SUCCESS: {
      toast.success('Tariff added successfully');
      return {
        ...state,
        tariff: _.concat(state.tariff, payload),
        isLoading: false,
      };
    }

    case UPDATE_TARIFF.SUCCESS: {
      toast.success('Tariff updated successfully');
      return {
        ...state,
        isLoading: false,
        tariff: state.tariff.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_TARIFF.SUCCESS: {
      toast.success('Tariff deleted successfully');
      return {
        ...state,
        tariff: state.tariff.filter((data) => data.id !== payload),
      };
    }
    case DELETE_TARIFF:
    case UPDATE_TARIFF.FAIL:
    case FETCH_TARIFF.FAIL:
    case CREATE_TARIFF.FAIL:
    case GET_TARIFF.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default tariffReducer;
