import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCG9BkwLJqXtbKFa7Be1XG5WLmNL_K3TEI',
  authDomain: 'charge-zone-83762.firebaseapp.com',
  projectId: 'charge-zone-83762',
  storageBucket: 'charge-zone-83762.appspot.com',
  messagingSenderId: '159326097653',
  appId: '1:159326097653:web:e85aaae9a8adf09c88b5bc',
  measurementId: 'G-NT5R2CNL2G',
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const Token = async (setTokenFound) => {
  await getToken(messaging, {
    vapidKey: 'BMfFkq7UU61j5a5FV0tqAMo7mQRu0PRDr3s1k69UBHjSNy0Iz0RMoe19dF-gDgHACvDJGkze2eWy_CSnfXCl4is',
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('Firebase Token===>', currentToken);
        setTokenFound(currentToken);
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);

      // ...
    });
};

// ForeGround
export const onMessageListener = (onMessageReceived) =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
      onMessageReceived(payload);
      // console.log('Message received====>', payload);
      // const notificationTitle = payload.notification.title;
      // const notificationOptions = {
      //   body: payload.notification.body,
      //   icon: payload.notification.icon,
      // };
      // const notification = new Notification(notificationTitle, notificationOptions);
      // console.log('notification-====', notification);
    });
  });

// const { REACT_APP_VAPID_KEY } = process.env;
// const publicKey = REACT_APP_VAPID_KEY;
