import { FETCH_RFID_GROUP, GET_RFID_GROUP, CREATE_RFID_GROUP, UPDATE_RFID_GROUP, DELETE_RFID_GROUP } from 'actions/rfidGroup';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchRfidGroup(action) {
  try {
    const response = yield call(API.getRfidGroup, action.payload);
    yield put({ type: FETCH_RFID_GROUP.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_RFID_GROUP.FAIL, payload: { error: e } });
  }
}

function* getRfidGroupById(action) {
  try {
    const response = yield call(API.getRfidGroupById, action.payload);
    yield put({ type: GET_RFID_GROUP.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_RFID_GROUP.FAIL, payload: { error: e } });
  }
}

function* createRfidGroup(action) {
  try {
    const response = yield call(API.addRfidGroup, action.payload);
    yield put({ type: CREATE_RFID_GROUP.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_RFID_GROUP.FAIL, payload: { error: e } });
  }
}

function* updateRfidGroup(action) {
  try {
    const response = yield call(API.updateRfidGroup, action.payload);
    yield put({ type: UPDATE_RFID_GROUP.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_RFID_GROUP.FAIL, payload: { error: e } });
  }
}

function* deleteRfidGroup(action) {
  try {
    const response = yield call(API.deleteRfidGroup, action.payload);
    yield put({ type: DELETE_RFID_GROUP.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_RFID_GROUP.FAIL, payload: { error: e } });
  }
}

function* rfidGroup() {
  yield takeLatest(FETCH_RFID_GROUP.REQUEST, fetchRfidGroup);
  yield takeLatest(GET_RFID_GROUP.REQUEST, getRfidGroupById);
  yield takeLatest(CREATE_RFID_GROUP.REQUEST, createRfidGroup);
  yield takeLatest(UPDATE_RFID_GROUP.REQUEST, updateRfidGroup);
  yield takeLatest(DELETE_RFID_GROUP.REQUEST, deleteRfidGroup);
}
export default rfidGroup;
