// export const stationBlueMarker = require('../../assets/images/stationBlueMarker.png');
// export const stationGreenMarker = require('../../assets/images/stationGreenMarker.png');
// export const stationGreyMarker = require('../../assets/images/stationGreyMarker.png');
// export const stationRedMarker = require('../../assets/images/stationRedMarker.png');
// export const privateStationMarker = require('../../assets/images/privateStationMarker.png');


export const stationBlueMarker = require('../../assets/images/icons/stationBlueMarkerNew.png');
export const powerlossStationMarker = require('../../assets/images/icons/stationRedMarkerNew.png');
export const privateStationMarker = require('../../assets/images/icons/stationPrivateMarkerNew.png');
export const stationGreenMarker = require('../../assets/images/icons/stationGreenMarkerNew.png');
export const stationRedMarker = require('../../assets/images/icons/stationOrangeMarkerNew.png');
export const stationGreyMarker = require('../../assets/images/icons/stationGrayMarkerNew.png');