import { post, get, patch, deleteRequest } from 'utils/api';

export const createCommunicationManager = (data) => {
  return post(`/communication_manager`, data);
};

export const fetchCommunicationManager = (data) => {
  return get(`/communication_manager`, data);
};

export const updateCommunicationManager = (data) => {
  const id = data.id;
  const { timeInterval, timeTo, timeFrom } = data.data[0];
  const dataObj = { timeInterval, timeTo, timeFrom };
  return patch(`/communication_manager/${id}`, dataObj);
};

export const deleteYourCommunicationManager = (id) => {
  return deleteRequest(`/communication_manager/${id}`);
};

export const communicationManager = {
  createCommunicationManager,
  fetchCommunicationManager,
  updateCommunicationManager,
  deleteYourCommunicationManager,
};
