import {
  CHANGE_AVAILABILITY,
  GET_CONFIGURATION,
  GET_DIAGNOSTICS,
  CLEAR_CACHE,
  REMOTE_START_TRANSACTION,
  REMOTE_STOP_TRANSACTION,
  RESERVE_NOW,
  RESET,
  SEND_LOCAL_LIST,
  TRIGGER_MESSAGE,
  UNLOCK_CONNECTOR,
  UPDATE_FIRMWARE,
  GET_LOCAL_LIST,
  DATA_TRANSFER_SERVER,
  CANCEL_RESERVATION,
  CHANGE_CONFIGURATION,
  SET_CHARGING_PROFILE,
  CLEAR_CHARGING_PROFILE,
} from 'actions/chargerOcpp';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* cancelReservation(action) {
  try {
    const response = yield call(API.cancelReservation, action.payload);
    yield put({ type: CANCEL_RESERVATION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CANCEL_RESERVATION.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* changeAvailability(action) {
  try {
    const response = yield call(API.changeAvailability, action.payload);
    yield put({ type: CHANGE_AVAILABILITY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHANGE_AVAILABILITY.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* getConfiguration(action) {
  try {
    const response = yield call(API.getConfiguration, action.payload);
    yield put({ type: GET_CONFIGURATION.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_CONFIGURATION.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* changeConfiguration(action) {
  try {
    const response = yield call(API.changeConfiguration, action.payload);
    yield put({ type: CHANGE_CONFIGURATION.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHANGE_CONFIGURATION.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* getDiagnostics(action) {
  try {
    const response = yield call(API.getDiagnostics, action.payload);
    yield put({ type: GET_DIAGNOSTICS.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_DIAGNOSTICS.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* clearCache(action) {
  try {
    const response = yield call(API.clearCache, action.payload);
    yield put({ type: CLEAR_CACHE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CLEAR_CACHE.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* remoteStartTransaction(action) {
  try {
    const response = yield call(API.remoteStartTransaction, action.payload);
    yield put({ type: REMOTE_START_TRANSACTION.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: REMOTE_START_TRANSACTION.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* remoteStopTransaction(action) {
  try {
    const response = yield call(API.remoteStopTransaction, action.payload);
    yield put({ type: REMOTE_STOP_TRANSACTION.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: REMOTE_STOP_TRANSACTION.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* reserveNow(action) {
  try {
    const response = yield call(API.reserveNow, action.payload);
    yield put({ type: RESERVE_NOW.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RESERVE_NOW.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* reset(action) {
  try {
    const response = yield call(API.reset, action.payload);
    yield put({ type: RESET.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RESET.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* sendLocalList(action) {
  try {
    const response = yield call(API.sendLocalList, action.payload);
    yield put({ type: SEND_LOCAL_LIST.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SEND_LOCAL_LIST.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* triggerMessage(action) {
  try {
    const response = yield call(API.triggerMessage, action.payload);
    yield put({ type: TRIGGER_MESSAGE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: TRIGGER_MESSAGE.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* unlockConnector(action) {
  try {
    const response = yield call(API.unlockConnector, action.payload);
    yield put({ type: UNLOCK_CONNECTOR.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UNLOCK_CONNECTOR.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateFirmware(action) {
  try {
    const response = yield call(API.updateFirmware, action.payload);
    yield put({ type: UPDATE_FIRMWARE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_FIRMWARE.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* dataTransferServer(action) {
  try {
    const response = yield call(API.dataTransferServer, action.payload);
    yield put({ type: DATA_TRANSFER_SERVER.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DATA_TRANSFER_SERVER.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* getLocalListVersion(action) {
  try {
    const response = yield call(API.getLocalListVersion, action.payload);
    yield put({ type: GET_LOCAL_LIST.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_LOCAL_LIST.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* setChargingProfile(action) {
  try {
    const response = yield call(API.setChargingProfile, action.payload);
    yield put({ type: SET_CHARGING_PROFILE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SET_CHARGING_PROFILE.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* clearChargingProfile(action) {
  try {
    const response = yield call(API.clearChargingProfile, action.payload);
    yield put({ type: CLEAR_CHARGING_PROFILE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CLEAR_CHARGING_PROFILE.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* chargerOcpp() {
  yield takeLatest(CANCEL_RESERVATION.REQUEST, cancelReservation);
  yield takeLatest(CHANGE_AVAILABILITY.REQUEST, changeAvailability);
  yield takeLatest(GET_CONFIGURATION.REQUEST, getConfiguration);
  yield takeLatest(CHANGE_CONFIGURATION.REQUEST, changeConfiguration);
  yield takeLatest(GET_DIAGNOSTICS.REQUEST, getDiagnostics);
  yield takeLatest(CLEAR_CACHE.REQUEST, clearCache);
  yield takeLatest(REMOTE_START_TRANSACTION.REQUEST, remoteStartTransaction);
  yield takeLatest(REMOTE_STOP_TRANSACTION.REQUEST, remoteStopTransaction);
  yield takeLatest(RESERVE_NOW.REQUEST, reserveNow);
  yield takeLatest(RESET.REQUEST, reset);
  yield takeLatest(SEND_LOCAL_LIST.REQUEST, sendLocalList);
  yield takeLatest(TRIGGER_MESSAGE.REQUEST, triggerMessage);
  yield takeLatest(UNLOCK_CONNECTOR.REQUEST, unlockConnector);
  yield takeLatest(UPDATE_FIRMWARE.REQUEST, updateFirmware);
  yield takeLatest(DATA_TRANSFER_SERVER.REQUEST, dataTransferServer);
  yield takeLatest(GET_LOCAL_LIST.REQUEST, getLocalListVersion);
  yield takeLatest(SET_CHARGING_PROFILE.REQUEST, setChargingProfile);
  yield takeLatest(CLEAR_CHARGING_PROFILE.REQUEST, clearChargingProfile);
}
export default chargerOcpp;
