import {
  UPLOAD_BULK_METER_VALUES_B2C,
  UPLOAD_BULK_METER_VALUES_B2B,
  UPLOAD_BULK_BOOKING_TIME,
  UPLOAD_BULK_SOC_VALUES,
  UPLOAD_BULK_STOP_TRANSACTION,
} from 'actions/updateBulkNegativeValue';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* uploadBulkMeterValuesB2C(action) {
  try {
    const response = yield call(API.addBulkMeterValues, action.payload);
    yield put({ type: UPLOAD_BULK_METER_VALUES_B2C.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPLOAD_BULK_METER_VALUES_B2C.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* uploadBulkMeterValuesB2B(action) {
  try {
    const response = yield call(API.BulkBookingTime, action.payload);
    yield put({ type: UPLOAD_BULK_METER_VALUES_B2B.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPLOAD_BULK_METER_VALUES_B2B.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* uploadBulkBookings(action) {
  try {
    const response = yield call(API.BulkBookingTime, action.payload);
    yield put({ type: UPLOAD_BULK_BOOKING_TIME.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPLOAD_BULK_BOOKING_TIME.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* uploadBulkSOCValues(action) {
  try {
    const response = yield call(API.BulkBookingTime, action.payload);
    yield put({ type: UPLOAD_BULK_SOC_VALUES.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPLOAD_BULK_SOC_VALUES.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* uploadBulkStopTransaction(action) {
  try {
    const response = yield call(API.bulkStopTransaction, action.payload);
    yield put({ type: UPLOAD_BULK_STOP_TRANSACTION.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPLOAD_BULK_STOP_TRANSACTION.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateBulkNegativeValue() {
  yield takeLatest(UPLOAD_BULK_METER_VALUES_B2C.REQUEST, uploadBulkMeterValuesB2C);
  yield takeLatest(UPLOAD_BULK_METER_VALUES_B2B.REQUEST, uploadBulkMeterValuesB2B);
  yield takeLatest(UPLOAD_BULK_BOOKING_TIME.REQUEST, uploadBulkBookings);
  yield takeLatest(UPLOAD_BULK_SOC_VALUES.REQUEST, uploadBulkSOCValues);
  yield takeLatest(UPLOAD_BULK_STOP_TRANSACTION.REQUEST, uploadBulkStopTransaction);
}
export default updateBulkNegativeValue;
