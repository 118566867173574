import { FETCH_CITY, GET_CITY, CREATE_CITY, UPDATE_CITY, DELETE_CITY } from 'actions/address';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  city: [],
  cityDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const cityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CITY.REQUEST:
    case GET_CITY.REQUEST:
    case CREATE_CITY.REQUEST:
    case DELETE_CITY.REQUEST: {
      return { ...state, isLoading: true, cityDetail: {} };
    }

    case UPDATE_CITY.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_CITY.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          city: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          city: _.concat(state.city, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_CITY.SUCCESS: {
      return {
        ...state,
        cityDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_CITY.SUCCESS: {
      toast.success('City added successfully');
      return {
        ...state,
        city: _.concat(state.city, payload),
        isLoading: false,
      };
    }

    case UPDATE_CITY.SUCCESS: {
      toast.success('City updated successfully');
      return {
        ...state,
        isLoading: false,
        city: state.city.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_CITY.SUCCESS: {
      toast.success('City deleted successfully');
      return {
        ...state,
        isLoading: false,
        city: state.city.filter((data) => data.id !== payload),
      };
    }

    case FETCH_CITY.FAIL:
    case GET_CITY.FAIL:
    case CREATE_CITY.FAIL:
    case UPDATE_CITY.FAIL:
    case DELETE_CITY.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default cityReducer;
