import React from 'react';
import { DatePickerInput } from 'rc-datepicker';
import PropTypes from 'prop-types';
import 'rc-datepicker/lib/style.css';
import { FloatingLabel } from 'react-bootstrap';

const DatePickerWithFloatingLabel = (props) => {
  const {
    showOnInputClick,
    placeholderOfStartDate,
    defaultValueOfStartDate,
    onChangeOfStartDate,
    maxDateOfStartDate,
    minDateOfStartDate,
    classNameOfStartDate,
    placeholderOfEndDate,
    defaultValueOfEndDate,
    onChangeOfEndDate,
    maxDateOfEndDate,
    minDateOfEndDate,
    classNameOfEndDate,
    floatingLabelOfStartDate,
    floatingLabelClassNameOfStartDate,
    floatingLabelOfEndDate,
    floatingLabelClassNameOfEndDate,
  } = props;

  return (
    <div className="dataPicker-floating-label-input-group">
      <div className="input-field">
        <FloatingLabel
          label={`${floatingLabelOfStartDate ? floatingLabelOfStartDate : 'From :'}`}
          className={`datepicker-floating-label ${floatingLabelClassNameOfStartDate ? floatingLabelClassNameOfStartDate : ''}`}
        >
          <DatePickerInput
            {...props}
            placeholder={placeholderOfStartDate || 'From...'}
            displayFormat="DD/MM/YYYY"
            returnFormat="YYYY-MM-DD"
            locale="en_US"
            showOnInputClick={showOnInputClick || true}
            defaultValue={defaultValueOfStartDate || ''}
            maxDate={maxDateOfStartDate || ''}
            minDate={minDateOfStartDate || ''}
            onChange={onChangeOfStartDate || function () {}}
            autoClose={true}
            className={`${classNameOfStartDate ? classNameOfStartDate : ''}`}
            position="bottom"
          />
        </FloatingLabel>
      </div>
      <div className="input-field">
        <FloatingLabel
          label={`${floatingLabelOfEndDate ? floatingLabelOfEndDate : 'To :'}`}
          className={`datepicker-floating-label ${floatingLabelClassNameOfEndDate ? floatingLabelClassNameOfEndDate : ''}`}
        >
          <DatePickerInput
            {...props}
            placeholder={placeholderOfEndDate || 'To...'}
            displayFormat="DD/MM/YYYY"
            returnFormat="YYYY-MM-DD"
            showOnInputClick={showOnInputClick || true}
            defaultValue={defaultValueOfEndDate || ''}
            minDate={minDateOfEndDate || ''}
            maxDate={maxDateOfEndDate || ''}
            onChange={onChangeOfEndDate || function () {}}
            autoClose={true}
            className={`${classNameOfEndDate ? classNameOfEndDate : ''}`}
            position="bottom"
          />
        </FloatingLabel>
      </div>
    </div>
  );
};

DatePickerWithFloatingLabel.propTypes = {
  placeholderOfStartDate: PropTypes.string,
  showOnInputClick: PropTypes.bool,
  defaultValueOfStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChangeOfStartDate: PropTypes.func,
  maxDateOfStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  minDateOfStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  classNameOfStartDate: PropTypes.string,
  placeholderOfEndDate: PropTypes.string,
  defaultValueOfEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChangeOfEndDate: PropTypes.func,
  maxDateOfEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  minDateOfEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  classNameOfEndDate: PropTypes.string,
  floatingLabelOfStartDate: PropTypes.string,
  floatingLabelOfEndDate: PropTypes.string,
  floatingLabelClassNameOfStartDate: PropTypes.string,
  floatingLabelClassNameOfEndDate: PropTypes.string,
};

export default DatePickerWithFloatingLabel;
