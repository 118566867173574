import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { CREATE_COUNTRY, UPDATE_COUNTRY } from 'actions/address';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';

const CountryForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const countryDetail = useSelector((state) => state.country.countryDetail);
  const isLoading = useSelector((state) => state.country.isLoading);

  const addCountry = useCallback((data) => {
    dispatch({
      type: CREATE_COUNTRY.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateCountry = useCallback((data) => {
    dispatch({
      type: UPDATE_COUNTRY.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const CountrySchema = Yup.object().shape({
    name: Yup.string()
      .required(t('countryForm.name'))
      .strict(true)
      .trim(t('countryForm.space'))
      .matches(/^[A-Za-z\s]+$/, t('countryForm.matches'))
      .max(100, t('countryForm.maxlimit')),
    country_code: Yup.string()
      .required(t('countryForm.countryCode'))
      // .strict(true)
      .trim(t('countryForm.space'))
      .matches(/^[0-9]+$/, t('countryForm.matchesNumber'))
      .max(100, t('countryForm.maxlimit')),
  });

  const initialValues = isEdit
    ? { ...countryDetail }
    : {
        name: '',
        country_code: '',
      };

  return (
    <React.Fragment>
      <div className="country-form-page__main">
        <Card>
          <div className="country-form-header__block">
            <div className="country-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('countryForm.title')}
              </span>
            </div>
            <div className="close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="country-form-body__block">
            <div className="country-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={CountrySchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateCountry(values);
                  } else {
                    addCountry(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={12}>
                        <TextInput
                          isRequired
                          label={t('countryForm.countryName')}
                          placeholder={t('countryForm.countryName')}
                          name="name"
                          value={_.get(values, 'name', '')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                      <Col lg={12}>
                        <TextInput
                          isRequired
                          label={t('countryForm.countryCodeLabel')}
                          placeholder={t('countryForm.countryCodeLabel')}
                          name="country_code"
                          value={_.get(values, 'country_code')}
                          onChange={handleChange}
                          error={errors.country_code && touched.country_code ? errors.country_code : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn-block">
                      <Button className="country-form-btn cancel_btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="country-form-btn create-btn">
                        {isEdit ? 'Update' : 'Create'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
CountryForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default CountryForm;
