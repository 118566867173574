import { FETCH_MODULE, CREATE_MODULE, DELETE_MODULE, GET_MODULE, UPDATE_MODULE, FETCH_MODULE_SORTED } from 'actions/module';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchModuleSorted(action) {
  try {
    const response = yield call(API.getModulesSorted, action.payload);
    yield put({ type: FETCH_MODULE_SORTED.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_MODULE_SORTED.FAIL, payload: { error: e } });
  }
}

function* fetchModule(action) {
  try {
    const response = yield call(API.getAllModule, action.payload);
    yield put({ type: FETCH_MODULE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_MODULE.FAIL, payload: { error: e } });
  }
}

function* getModuleById(action) {
  try {
    const response = yield call(API.getModuleById, action.payload);
    yield put({ type: GET_MODULE.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_MODULE.FAIL, payload: { error: e } });
  }
}

function* createModule(action) {
  try {
    const response = yield call(API.addModule, action.payload);
    yield put({ type: CREATE_MODULE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_MODULE.FAIL, payload: { error: e } });
  }
}

function* updateModule(action) {
  try {
    const response = yield call(API.updateModuleSuper, action.payload);
    yield put({ type: UPDATE_MODULE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_MODULE.FAIL, payload: { error: e } });
  }
}

function* deleteModule(action) {
  try {
    const response = yield call(API.deleteModule, action.payload);
    yield put({ type: DELETE_MODULE.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_MODULE.FAIL, payload: { error: e } });
  }
}

function* module() {
  yield takeLatest(FETCH_MODULE_SORTED.REQUEST, fetchModuleSorted);
  yield takeLatest(FETCH_MODULE.REQUEST, fetchModule);
  yield takeLatest(GET_MODULE.REQUEST, getModuleById);
  yield takeLatest(CREATE_MODULE.REQUEST, createModule);
  yield takeLatest(UPDATE_MODULE.REQUEST, updateModule);
  yield takeLatest(DELETE_MODULE.REQUEST, deleteModule);
}
export default module;
