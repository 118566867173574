import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import AdminHeader from 'components/header';
import { Col, Row, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Card from 'components/inputs/Card';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
// import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { FETCH_VOUCHERS } from 'actions/voucher';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import { DELETE_VOUCHER } from 'actions/voucher';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE, GET_VOUCHER, MANAGE_VOUCHER } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
// import { FiEdit2 } from 'react-icons/fi';
// import { RiDeleteBinLine } from 'react-icons/ri';
import { FETCH_USER_GROUP } from 'actions/userGroup';
import { FETCH_CUSTOMER } from 'actions/customer';

const VoucherList = () => {
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  // const [allvouchersData, setAllvouchersData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [voucherId, setvoucherId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const allUserGroup = useSelector((state) => state.userGroup.userGroups);
  const allCustomer = useSelector((state) => state.customer.customers);
  const dispatch = useDispatch();
  const allvouchers = useSelector((state) => state.voucher.vouchers);
  const isLoader = useSelector((state) => state.voucher.isLoading);
  const page = useSelector((state) => state.voucher.page);
  const totalData = useSelector((state) => state.voucher.total);
  const limit = useSelector((state) => state.voucher.limit);
  const totalPages = useSelector((state) => state.voucher.totalPages);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getVouchers = useCallback(
    (data) => {
      if (searchText) {
        const voucherData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_VOUCHERS, payload: voucherData });
      } else {
        dispatch({ type: FETCH_VOUCHERS, payload: data });
      }
    },
    [searchText]
  );

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setvoucherId('');
  };

  const deletevoucher = useCallback(
    () =>
      dispatch({
        type: DELETE_VOUCHER,
        payload: voucherId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getVouchers({ page: 1 });
          }
        },
      }),
    [voucherId]
  );

  const getVoucherPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_VOUCHER),
      (data) => data.name
    );

  const manageVoucherPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_VOUCHER),
      (data) => data.name
    );

  const getAllUserGroup = useCallback((data = {}) => {
    dispatch({ type: FETCH_USER_GROUP, payload: data });
  }, []);

  const getAllUsers = useCallback((data) => {
    const userData = {
      ...data,
      limit: 99999,
    };
    dispatch({ type: FETCH_CUSTOMER, payload: userData });
  }, []);

  useEffect(() => {
    getVouchers();
    getAllUserGroup();
    getAllUsers();
  }, []);

  const getUserNamesByIds = useCallback(
    (userGroupIds) => {
      if (typeof userGroupIds === 'string') {
        userGroupIds = userGroupIds.split(',').map((id) => id.trim());
      } else if (!Array.isArray(userGroupIds)) {
        userGroupIds = [userGroupIds];
      }

      return userGroupIds
        .map((id) => {
          const group = allUserGroup.find((group) => group.id === id);
          return group ? group.name : 'Unknown Group';
        })
        .join(', ');
    },
    [allUserGroup]
  );

  const getUserByIds = useCallback(
    (userIds) => {
      if (typeof userIds === 'string') {
        userIds = userIds.split(',').map((id) => id.trim());
      } else if (!Array.isArray(userIds)) {
        userIds = [userIds];
      }

      return userIds
        .map((id) => {
          const group = allCustomer.find((group) => group.id === id);
          return group ? group.name : 'Unknown';
        })
        .join(', ');
    },
    [allCustomer]
  );

  const handleSearch = () => {
    getVouchers({});
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        -getVouchers(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getVouchers(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getVouchers(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.vouchers')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="voucher-management-page_main">
              <div className="voucher-list-main-block">
                <Card>
                  <div className="voucher-list-block">
                    <div className="voucher-search-box">
                      <Row>
                        <Col xl={3}>
                          <div className="voucher-title">{t('header.vouchers')}</div>
                        </Col>
                        <Col xl={!_.isEmpty(manageVoucherPermissions) ? 6 : 12}>
                          <SearchBox
                            preIcon={<BsSearch />}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                handleSearch();
                              }
                            }}
                            placeholder=""
                          />
                        </Col>
                        {(!_.isEmpty(manageVoucherPermissions) || (!_.isEmpty(getVoucherPermissions) && !_.isEmpty(manageVoucherPermissions))) && (
                          <Col xl={3}>
                            <Button className="add-voucher-btn" onClick={() => navigateTo('/addVoucher')}>
                              {t('button.voucher')}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </div>
                    <div className="voucher-list-table">
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherForm.srNo')}</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherForm.voucherName')}</span>
                                  <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherForm.redemptionType')} </span>
                                </div>
                              </th>

                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherForm.voucherCode')}</span>
                                  <span className="ico" onClick={() => handleSorting('code', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherForm.discountAmount')} </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherForm.noOfUsers')} </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherForm.noOfGroups')} </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>{isLoader ? <Skeleton height={15} width={100} /> : t('voucherForm.comments')} </span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoader ? (
                              <tr>
                                <td colSpan={10} className="border-0">
                                  <div className="loader--block">
                                    <Skeleton height={15} width={100} />
                                  </div>
                                </td>
                              </tr>
                            ) : _.isEmpty(allvouchers) ? (
                              <tr>
                                <td colSpan={8} className="border-0">
                                  <div className="empty-data-block">{t('voucherForm.noData')}</div>
                                </td>
                              </tr>
                            ) : (
                              !(isLoader || _.isUndefined(isLoader)) &&
                              _.map(allvouchers, (vouchers, index) => {
                                const serial_num = limit * (page - 1) + index;
                                const userGroup = _.get(vouchers, 'user_group', '');
                                const user = _.get(vouchers, 'user', '');
                                let userGroupCount = 0;
                                let userGroupIds = [];

                                if (typeof userGroup === 'string') {
                                  userGroupIds = userGroup.split(',').filter((group) => group.trim() !== '');
                                  userGroupCount = userGroupIds.length;
                                } else if (Array.isArray(userGroup)) {
                                  userGroupIds = userGroup;
                                  userGroupCount = userGroup.length;
                                } else if (userGroup && typeof userGroup === 'object') {
                                  userGroupIds = Object.keys(userGroup);
                                  userGroupCount = userGroupIds.length;
                                } else if (userGroup) {
                                  userGroupIds = [userGroup];
                                  userGroupCount = 1;
                                }

                                const userGroupNames = getUserNamesByIds(userGroupIds);

                                let userCount = 0;
                                let userIds = [];

                                if (typeof user === 'string') {
                                  userIds = user.split(',').filter((group) => group.trim() !== '');
                                  userCount = userGroupIds.length;
                                } else if (Array.isArray(user)) {
                                  userIds = user;
                                  userCount = user.length;
                                } else if (user && typeof user === 'object') {
                                  userIds = Object.keys(user);
                                  userCount = userIds.length;
                                } else if (user) {
                                  userIds = [user];
                                  userCount = 1;
                                }

                                const userNames = getUserByIds(userIds);

                                return (
                                  <tr key={`vouchers-data-item-${serial_num}`}>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                    <td className="voucherLabel">{isLoader ? <Skeleton height={15} width={100} /> : _.get(vouchers, 'name', '-')}</td>
                                    <td className="natureLabel">
                                      {isLoader ? <Skeleton height={15} width={100} /> : _.get(vouchers, 'redemption_type', '-')}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(vouchers, 'code', '-')}</td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(vouchers, 'discount_amount', '-')}</td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${index}`}>{userNames}</Tooltip>}>
                                          <span>
                                            {userCount} {userCount === 1 || userCount === 0 ? 'User' : 'Users'}
                                          </span>
                                        </OverlayTrigger>
                                      )}
                                    </td>
                                    <td>
                                      {isLoader ? (
                                        <Skeleton height={15} width={100} />
                                      ) : (
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${index}`}>{userGroupNames}</Tooltip>}>
                                          <span>
                                            {userGroupCount} {userGroupCount === 1 || userGroupCount === 0 ? 'Group' : 'Groups'}
                                          </span>
                                        </OverlayTrigger>
                                      )}
                                    </td>
                                    <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(vouchers, 'comment', '-')}</td>

                                    {/* {(!_.isEmpty(manageVoucherPermissions) ||
                                      (!_.isEmpty(getVoucherPermissions) && !_.isEmpty(manageVoucherPermissions))) && (
                                      <td>
                                        <div key={`action-${index}`} className="voucher-list">
                                          <span className="list-icon">
                                            {isLoader ? (
                                              <Skeleton height={15} width={100} />
                                            ) : (
                                              <ul>
                                                <li className="item" onClick={() => navigateTo(`/editvoucher/${vouchers.id}`)}>
                                                  <FiEdit2 title="Edit" size={22} color={'#3c7cdd'} />
                                                </li>
                                                {!_.isEmpty(manageVoucherPermissions) ||
                                                (!_.isEmpty(getVoucherPermissions) && !_.isEmpty(manageVoucherPermissions)) ? (
                                                  <li
                                                    className="item"
                                                    onClick={() => {
                                                      setShowDeleteModal(true);
                                                      setvoucherId(_.get(vouchers, 'id', ''));
                                                    }}
                                                  >
                                                    <RiDeleteBinLine title="Delete" size={22} color={'#be210b'} />
                                                  </li>
                                                ) : (
                                                  <li className="item">
                                                    <RiDeleteBinLine title="Delete" size={22} color="#be210b" />
                                                  </li>
                                                )}
                                              </ul>
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                    )} */}
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allvouchers) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                      // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                    )}
                    {showDeleteModal && (
                      <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                        <DeleteModal title="vouchers" onClose={handleCloseDeleteModel} onRemove={deletevoucher} />
                      </Modal>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default VoucherList;
