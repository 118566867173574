import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { DELETE_RFID_REQUEST, FETCH_RFID_REQUEST, GET_RFID_REQUEST, UPDATE_RFID_REQUEST, DOWNLOAD_RFID_REQUEST } from '../actions/rfidRequest';

function* fetchRfidRequest(action) {
  try {
    const response = yield call(API.getRfidRequest, action.payload);
    yield put({ type: FETCH_RFID_REQUEST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_RFID_REQUEST.FAIL, payload: { error: e } });
  }
}

function* getRfidRequestById(action) {
  try {
    const response = yield call(API.getRfidRequestById, action.payload);
    yield put({ type: GET_RFID_REQUEST.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_RFID_REQUEST.FAIL, payload: { error: e } });
  }
}

function* updateRfidRequest(action) {
  try {
    const response = yield call(API.updateRfidRequest, action.payload);
    yield put({ type: UPDATE_RFID_REQUEST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_RFID_REQUEST.FAIL, payload: { error: e } });
  }
}

function* deleteRfidRequest(action) {
  try {
    const response = yield call(API.deleteRfidRequest, action.payload);
    yield put({ type: DELETE_RFID_REQUEST.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_RFID_REQUEST.FAIL, payload: { error: e } });
  }
}

function* downloadRfidRequest(action) {
  try {
    const response = yield call(API.downloadRfidRequest, action.payload);
    yield put({ type: DOWNLOAD_RFID_REQUEST.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_RFID_REQUEST.FAIL, payload: { error: e } });
  }
}

function* rfidRequest() {
  yield takeLatest(FETCH_RFID_REQUEST.REQUEST, fetchRfidRequest);
  yield takeLatest(GET_RFID_REQUEST.REQUEST, getRfidRequestById);
  yield takeLatest(UPDATE_RFID_REQUEST.REQUEST, updateRfidRequest);
  yield takeLatest(DELETE_RFID_REQUEST.REQUEST, deleteRfidRequest);
  yield takeLatest(DOWNLOAD_RFID_REQUEST.REQUEST, downloadRfidRequest);
}
export default rfidRequest;
