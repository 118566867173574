import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import Select from 'components/inputs/Select';
import PhoneInput from 'react-phone-number-input/input';
import ErrorMessage from 'components/inputs/ErrorMessage';

const PhoneNumberInput = (props) => {
  const { label, placeholder, name, value, onChange, error, disabled, className, wrapperClass, isRequired } = props;
  const [country, setCountry] = useState('IN');

  return (
    <>
      <div className={`phone-number-input__form-group ${wrapperClass ? wrapperClass : ''} ${disabled ? 'input-disabled' : ''}`}>
        {label && (
          <label className="phone-number-input--label" htmlFor="phone-number">
            {label}
            {isRequired && <span className="require--star">*</span>}
          </label>
        )}
        <div id="phone-number" className="phone-number-input--group">
          <CountrySelect name={`country_${name}`} className="country-select-box" value={country} onChange={setCountry} isDisabled={disabled} />
          <div className="vertical--line" />
          <PhoneInput
            {...props}
            international
            className={`phone-number--input-box ${className ? className : ''}`}
            defaultCountry={country}
            placeholder={placeholder}
            name={name}
            value={value ? value.toString() : ''}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    </>
  );
};
PhoneNumberInput.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
};
export default PhoneNumberInput;

const CountrySelect = ({ value, onChange, ...rest }) => (
  <Select
    {...rest}
    value={value}
    onChange={(val) => onChange(val || '')}
    options={_.uniqBy(
      _.map(getCountries(), (country) => {
        return { label: `+${getCountryCallingCode(country)}`, value: country };
      }),
      'label'
    )}
  />
);

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
