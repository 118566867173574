import { CREATE_VEHICLE, FETCH_VEHICLE, GET_VEHICLE, DELETE_VEHICLE, UPDATE_VEHICLE, UPDATE_VRN } from 'actions/vehicle';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  page_no: '',
  vehicles: [],
  vehicleDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const vehicleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_VEHICLE.REQUEST:
    case GET_VEHICLE.REQUEST:
    case DELETE_VEHICLE.REQUEST: {
      return { ...state, isLoading: true, vehicleDetail: {} };
    }

    case FETCH_VEHICLE.REQUEST: {
      return { ...state, isLoading: true, page_no: '' };
    }

    case UPDATE_VEHICLE.REQUEST:
    case UPDATE_VRN.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_VEHICLE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        page_no: payload.config?.params?.page,
        vehicles: payload.data?.results,
        page: payload.data?.page,
        limit: payload.data?.limit,
        totalPages: payload.data?.totalPages,
        total: payload.data?.totalResults,
      };
    }

    case GET_VEHICLE.SUCCESS: {
      return {
        ...state,
        vehicleDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_VEHICLE.SUCCESS: {
      toast.success('Vehicle added successfully');
      return {
        ...state,
        vehicles: _.concat(state.vehicles, payload),
        isLoading: false,
      };
    }

    case UPDATE_VEHICLE.SUCCESS: {
      toast.success('Vehicle updated successfully');
      return {
        ...state,
        isLoading: false,
        vehicles: state.vehicles.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case UPDATE_VRN.SUCCESS: {
      toast.success('VIN Added Successfully');
      return {
        ...state,
      };
    }

    case DELETE_VEHICLE.SUCCESS: {
      toast.success('Vehicle deleted successfully');
      return {
        ...state,
        isLoading: false,
        vehicles: state.vehicles.filter((data) => data.id !== payload),
      };
    }

    case FETCH_VEHICLE.FAIL:
    case CREATE_VEHICLE.FAIL:
    case GET_VEHICLE.FAIL:
    case DELETE_VEHICLE.FAIL:
    case UPDATE_VEHICLE.FAIL:
    case UPDATE_VRN.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default vehicleReducer;
