import {
  PULL_LOCATION,
  HANDSHAKE,

  // VIEW_ENDPOINTS,
  PULL_SESSIONS,
  PULL_TARIFF,
  PULL_TOKEN,
  PULL_CDRS,
  GET_ENDPOINT,
} from 'actions/partnerManagement';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  pullData: '',
  pushData: '',
  credential: '',
  endpoints: '',
  tokenData: '',
};

const ocpiPullRequestReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PULL_LOCATION.REQUEST:
    case PULL_SESSIONS.REQUEST:
    case PULL_TARIFF.REQUEST:
    case PULL_TOKEN.REQUEST:
    case PULL_CDRS.REQUEST:
    case HANDSHAKE.REQUEST:
    case GET_ENDPOINT.REQUEST: {
      return { ...state, isLoading: true };
    }

    case PULL_LOCATION.SUCCESS: {
      toast.success('Location pull successfully');
      return {
        ...state,
        isLoading: false,
        pullData: payload,
      };
    }

    case HANDSHAKE.SUCCESS: {
      toast.success('Handshaking successfully ');
      return {
        ...state,
        isLoading: false,
        credential: payload,
      };
    }

    case GET_ENDPOINT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        endpoints: payload,
      };
    }

    case PULL_SESSIONS.SUCCESS: {
      toast.success('Session pull successfully');
      return {
        ...state,
        isLoading: false,
        pullData: payload,
      };
    }
    case PULL_TARIFF.SUCCESS: {
      toast.success('Tariff pull successfully');
      return {
        ...state,
        isLoading: false,
        pullData: payload,
      };
    }
    case PULL_TOKEN.SUCCESS: {
      toast.success('Token pull successfully');
      return {
        ...state,
        isLoading: false,
        tokenData: payload,
      };
    }
    case PULL_CDRS.SUCCESS: {
      toast.success('Cdrs pull successfully');
      return {
        ...state,
        isLoading: false,
        pullData: payload,
      };
    }

    case PULL_LOCATION.FAIL:
    case PULL_SESSIONS.FAIL:
    case PULL_TARIFF.FAIL:
    case PULL_TOKEN.FAIL:
    case PULL_CDRS.FAIL:
    case HANDSHAKE.FAIL:
    case GET_ENDPOINT.FAIL: {
      return { ...state, isLoading: false };
    }
    default: {
      return { ...state };
    }
  }
};

export default ocpiPullRequestReducer;
