import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import { FaPlus, FaMinus } from 'react-icons/fa'; // Import modern icons

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, zoomPlugin);

const ChargerGraphPopup = ({ show, onHide, graphData, isLoading }) => {
  const chartRef = useRef(null);

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    scales: {
      x: {
        ticks: {
          color: 'white',
          callback: function (value, index, ticks) {
            if (index === 0 || index === ticks.length - 1) {
              return this.getLabelForValue(value);
            }
            return ''; // Hide all other labels
          },
        },
      },
      powerVoltageCurrent: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Power (kW), Voltage (V), Current (A)',
          color: 'white',
        },
        ticks: { color: 'white' },
      },
      tempSoc: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Temperature (°C), SoC (%)',
          color: 'white',
        },
        ticks: { color: 'white' },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'white',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleColor: 'white',
        bodyColor: 'white',
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
          limits: {
            x: {
              min: 'original',
              max: 'original',
              minRange: 60 * 1000, // 1 minute in milliseconds
            },
            y: {
              min: 'original',
              max: 'original',
              minRange: 10, // Minimum range for y-axis
            },
          },
        },
        limits: {
          x: {
            minScale: 0.5, // Maximum zoom out (2x)
            maxScale: 20, // Maximum zoom in (20x)
          },
          y: {
            minScale: 0.5, // Maximum zoom out (2x)
            maxScale: 20, // Maximum zoom in (20x)
          },
        },
      },
    },
  };

  const dataArrays = Object.values(graphData).filter(Array.isArray);
  const isValidGraphData = dataArrays.length > 0;

  const processedData = isValidGraphData
    ? dataArrays.flatMap((dataArray) =>
        dataArray
          .map((item) => {
            const processValue = (value) => {
              if (value === '' || value === undefined) return null;
              const num = Number(value);
              return isNaN(num) ? null : num;
            };

            const findMeterValue = (measurand, location = null) => {
              const meterValue = item.meterValues.find(
                (mv) => mv.measurand === measurand && (location ? mv.location === location : true) && !mv.phase
              );
              return processValue(meterValue?.value);
            };

            return {
              timestamp: moment(item.dateAndTime).format('DD-MMM-YY, hh:mm:ss A'),
              voltage: findMeterValue('Voltage', 'Outlet'),
              currentImport: findMeterValue('Current.Import', 'Outlet'),
              temperature: findMeterValue('Temperature', 'Outlet'),
              soc: findMeterValue('SoC'),
              powerActiveImport: findMeterValue('Power.Active.Import') !== null ? findMeterValue('Power.Active.Import') / 1000 : null,
            };
          })
          .filter(
            (item) =>
              item.voltage !== null ||
              item.currentImport !== null ||
              item.temperature !== null ||
              item.soc !== null ||
              item.powerActiveImport !== null
          )
      )
    : [];

  // Sort processed data by timestamp (ascending order)
  processedData.sort((a, b) => moment(a.timestamp, 'DD-MMM-YY, hh:mm:ss A') - moment(b.timestamp, 'DD-MMM-YY, hh:mm:ss A'));

  const data = {
    labels: processedData.map((item) => item.timestamp),
    datasets: [
      {
        label: 'Voltage (V)',
        data: processedData.map((item) => item.voltage),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'powerVoltageCurrent',
      },
      {
        label: 'Current Import (A)',
        data: processedData.map((item) => item.currentImport),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'powerVoltageCurrent',
      },
      {
        label: 'Power Active Import (kW)',
        data: processedData.map((item) => item.powerActiveImport),
        borderColor: 'rgb(255, 205, 86)',
        backgroundColor: 'rgba(255, 205, 86, 0.5)',
        yAxisID: 'powerVoltageCurrent',
      },
      {
        label: 'Temperature (°C)',
        data: processedData.map((item) => item.temperature),
        borderColor: 'rgb(255, 159, 64)',
        backgroundColor: 'rgba(255, 159, 64, 0.5)',
        yAxisID: 'tempSoc',
      },
      {
        label: 'SoC (%)',
        data: processedData.map((item) => item.soc),
        borderColor: 'rgb(153, 102, 255)',
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
        yAxisID: 'tempSoc',
      },
    ],
  };

  const zoomIn = () => {
    chartRef.current.zoom(1.1);
  };

  const zoomOut = () => {
    chartRef.current.zoom(0.9);
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [processedData]);

  return (
    <Modal show={show} onHide={onHide} centered size="xl" style={{ color: '#fff' }}>
      <Modal.Header closeButton style={{ background: 'transparent', border: 'none', color: 'white' }}>
        <Modal.Title>Charging Session Graph</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ color: '#FFFFFF80' }}>
        {isLoading ? (
          <div className="loader--block">
            <Spinner as="span" animation="border" size="sm" role="status" />
          </div>
        ) : isValidGraphData ? (
          <>
            <Line ref={chartRef} options={options} data={data} />
            <div
              style={{
                textAlign: 'center',
                position: 'absolute',
                left: '0px',
                right: '0px',
                bottom: '0.5em',
                margin: '0 auto',
                display: 'block',
                gap: '20px',
              }}
            >
              <button
                onClick={zoomIn}
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  border: 'none',
                  color: 'unset',
                }}
              >
                <FaPlus />
              </button>
              <button
                onClick={zoomOut}
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  border: 'none',
                  color: 'unset',
                }}
              >
                <FaMinus />
              </button>
            </div>
          </>
        ) : (
          <p>No valid graph data available.</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

ChargerGraphPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  graphData: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        dateAndTime: PropTypes.string.isRequired,
        meterValues: PropTypes.arrayOf(
          PropTypes.shape({
            measurand: PropTypes.string.isRequired,
            location: PropTypes.string,
            value: PropTypes.number,
          })
        ).isRequired,
      })
    )
  ),
  isLoading: PropTypes.bool.isRequired,
};

export default ChargerGraphPopup;
