import { FETCH_STATE, GET_STATE, CREATE_STATE, UPDATE_STATE, DELETE_STATE } from 'actions/address';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  states: [],
  stateDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};
const stateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_STATE.REQUEST:
    case GET_STATE.REQUEST:
    case CREATE_STATE.REQUEST:
    case DELETE_STATE.REQUEST: {
      return { ...state, isLoading: true, stateDetail: {} };
    }

    case UPDATE_STATE.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_STATE.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          states: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          states: _.concat(state.states, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_STATE.SUCCESS: {
      return {
        ...state,
        stateDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_STATE.SUCCESS: {
      toast.success('State added successfully');
      return {
        ...state,
        states: _.concat(state.states, payload),
        isLoading: false,
      };
    }

    case UPDATE_STATE.SUCCESS: {
      toast.success('State updated successfully');
      return {
        ...state,
        isLoading: false,
        states: state.states.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_STATE.SUCCESS: {
      toast.success('State deleted successfully');
      return {
        ...state,
        isLoading: false,
        states: state.states.filter((data) => data.id !== payload),
      };
    }

    case FETCH_STATE.FAIL:
    case GET_STATE.FAIL:
    case CREATE_STATE.FAIL:
    case UPDATE_STATE.FAIL:
    case DELETE_STATE.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default stateReducer;
