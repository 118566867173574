import React, { useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
// import { BsSearch } from 'react-icons/bs';
// import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
// import { AiOutlineDownload } from 'react-icons/ai';
// import { TiArrowUnsorted } from 'react-icons/ti';
import * as _ from 'lodash';
import { roundOfDigit } from 'components/common/utils';
// import SearchBox from 'components/general/SearchBox';
import { MOST_VEHICLE_CHARGED_LIST, DOWNLOAD_MOST_VEHICLE_CHARGED_LIST } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
// import DropDown from 'components/inputs/DropDown';
// import { AiFillCaretDown } from 'react-icons/ai';
import Button from 'components/inputs/Button';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import ReactPagination from 'components/general/ReactPagination';
import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';

const MostVehicleChargedList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  // const [searchText, setSearchText] = useState('');
  // const [showSearchBar, setShowSearchBar] = useState(false);
  // const [fieldValue, setFieldValue] = useState('All');
  // const [allMostVehicleChargedListData, setAllMostVehicleChargedListData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));

  const allMostVehicleChargedList = useSelector((state) => state.mostVehicleChargedList.mostVehicleChargedLists);
  const isLoader = useSelector((state) => state.mostVehicleChargedList.isLoading);
  const page = useSelector((state) => state.mostVehicleChargedList.page);
  const totalData = useSelector((state) => state.mostVehicleChargedList.total);
  const limit = useSelector((state) => state.mostVehicleChargedList.limit);
  const totalPages = useSelector((state) => state.mostVehicleChargedList.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const mostVehicleChargedList = useCallback(
    (value) => {
      // if (searchText) {
      //   const mostVehicleChargedListData = {
      //     ...value,
      //     from: moment(startDate).utc(),
      //     to: moment(endDate).utc(),
      //     search: searchText,
      //   };
      //   dispatch({ type: MOST_VEHICLE_CHARGED_LIST, payload: mostVehicleChargedListData });
      // } else {
      const data = {
        ...value,
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      dispatch({ type: MOST_VEHICLE_CHARGED_LIST.REQUEST, payload: data });
      // }
    },
    [startDate, endDate]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).utc(),
        to: moment(endDate).utc(),
      };
      mostVehicleChargedList(filterDateData);
    }
  }, [startDate, endDate, sortByItem]);

  const downloadMostVehicleChargedList = useCallback(() => {
    const data = {
      excel: true,
      from: moment(startDate).utc(),
      to: moment(endDate).utc(),
    };
    dispatch({
      type: DOWNLOAD_MOST_VEHICLE_CHARGED_LIST.REQUEST,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Most Vehicle Charged List'}.xlsx`);
          }
        }
      },
    });
  }, [startDate, endDate]);

  const downloadFile = () => {
    downloadMostVehicleChargedList();
  };

  // const filterData = [
  //   { label: 'Newest', value: 'Newest' },
  //   { label: 'Oldest', value: 'Oldest' },
  //   { label: 'All', value: 'All' },
  // ];

  // useEffect(() => {
  //   const newestData = {
  //     sortBy: '1',
  //   };
  //   fieldValue === 'Newest' && mostVehicleChargedList(newestData);
  //   const oldestData = {
  //     sortBy: '-1',
  //   };
  //   fieldValue === 'Oldest' && mostVehicleChargedList(oldestData);
  //   fieldValue === 'All' && mostVehicleChargedList();
  // }, [fieldValue]);

  // const searchHandler = (event) => {
  //   const value = event.target.value;
  //   setSearchText(value);
  // };

  // useEffect(() => {
  //   setAllMostVehicleChargedListData(
  //     _.filter(
  //       _.filter(allMostVehicleChargedList, (item) => item),
  //       (item, key) =>
  //         startsWith(_.get(item.user[key], 'email'), searchText) ||
  //         startsWith(_.get(item.user[key], 'name'), searchText) ||
  //         startsWith(roundOfDigit(_.get(item, 'transaction_amount'), 2).toString(), searchText) ||
  //         startsWith(_.get(item, 'transaction_count').toString(), searchText)
  //     )
  //   );
  // }, [searchText, allMostVehicleChargedList]);

  // useEffect(() => {
  //   mostVehicleChargedList();
  // }, [searchText]);

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        endDate && mostVehicleChargedList(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        mostVehicleChargedList(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        endDate && mostVehicleChargedList(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        mostVehicleChargedList(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      }
      // else if (startDate && endDate && searchText) {
      //   const data = {
      //     from: moment(startDate).utc(),
      //     to: moment(endDate).utc(),
      //     search: searchText,
      //     page: page.selected + 1,
      //   };
      //   mostVehicleChargedList(data);
      // }
      else if (startDate && endDate) {
        const data = {
          from: moment(startDate).utc(),
          to: moment(endDate).utc(),
          page: page.selected + 1,
        };
        mostVehicleChargedList(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        mostVehicleChargedList(data);
      }
    },
    [sortByItem, startDate, endDate]
  );

  // const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  // const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={t('header.mostVehicleChargedList')} />
        <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
          <div className="page-content-wrapper scrollable">
            <div className="data-report--main">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      {/* <Col
                      xl={`${showSearchBar ? 4 : 1}`}
                      md={`${showSearchBar ? 12 : 2}`}
                      className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                    >
                      {showSearchBar ? search : searchIcon}
                     </Col>
                    <Col xl={2} md={4}>
                      <DropDown
                        popupClass="main-menu bottom scrollable"
                        trigger={
                          <>
                            <div className="log-filter-block">
                              <div className="log-filter-icon">
                                <BsFilter />
                                <span className="log-filter-label">{fieldValue}</span>
                              </div>
                              <div className="log-arrow__icon">
                                <AiFillCaretDown />
                              </div>
                            </div>
                          </>
                        }
                        triggerClass="log-filter-dropdown"
                      >
                        {_.map(filterData, (item, index) => (
                          <ul key={`${index}`}>
                            <li
                              className="item"
                              onClick={() => {
                                setFieldValue(item.value);
                              }}
                            >
                              {item.label}
                            </li>
                          </ul>
                        ))}
                      </DropDown>
                    </Col> */}
                      <Col
                      // xl={`${showSearchBar ? 6 : 9}`} md={`${showSearchBar ? 10 : 10}`}
                      >
                        <div className="data-report-date_picker">
                          {/* <DatePickerWithFloatingLabel
                          defaultValueOfStartDate={new Date()}
                          defaultValueOfEndDate={new Date()}
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                        /> */}
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment().startOf('month')}
                            initialValueOfEndDate={moment(new Date()).endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">{t('button.search')}</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className="report-excel-icon">
                            {/* <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="table-to-xls"
                            filename="User Charging History"
                            sheet="User Charging History"
                            buttonText={<SiMicrosoftexcel size={28} />}
                          /> */}
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.srNo')}</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.name')}</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.email')}</span>
                                <span className="ico" onClick={() => handleSorting('email', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.mobileNo')}</span>
                                <span className="ico" onClick={() => handleSorting('phone', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.noOfTransaction')}</span>
                                <span className="ico" onClick={() => handleSorting('transaction_count', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.totalTransactions')}</span>
                                <span className="ico" onClick={() => handleSorting('transaction_amount', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('dataReport.unitConsumed')}</span>
                                <span className="ico" onClick={() => handleSorting('unit_consumed', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                                {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="loader--block">
                                  <Skeleton height={15} width={100} />
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(allMostVehicleChargedList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">{t('dataReport.noMostVehicleChargedListData')}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(allMostVehicleChargedList, (item, key) => {
                              const serial_num = limit * (page - 1) + key;
                              return _.map(_.get(item, 'user'), (data) => {
                                return (
                                  <>
                                    <tr key={`most-vehicle-charged-list-${serial_num}`}>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'name', 'Guest User')}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'email', '')}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(data, 'phone', '')}</td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transaction_count', '')}</td>
                                      <td>
                                        {isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'transaction_amount', ''), 2)}
                                      </td>
                                      <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'unit_consumed', '-'), 2)}</td>
                                    </tr>
                                  </>
                                );
                              });
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allMostVehicleChargedList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </SkeletonTheme>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </>
  );
};

export default MostVehicleChargedList;
