import { CREATE_USER, DELETE_USER, FETCH_USER, CHANGE_ROLE, UPDATE_USER } from 'actions/user';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  users: [],
  page_no: '',
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_USER.REQUEST:
    case UPDATE_USER.REQUEST:
    case CHANGE_ROLE.REQUEST:
    case DELETE_USER.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_USER.REQUEST: {
      return { ...state, isLoading: true, page_no: '' };
    }

    case FETCH_USER.SUCCESS: {
      return {
        ...state,
        users: payload.data?.results,
        page_no: payload.config?.params?.page,
        isLoading: false,
        page: payload.data?.page,
        limit: payload.data?.limit,
        totalPages: payload.data?.totalPages,
        total: payload.data?.totalResults,
      };
    }

    case CREATE_USER.SUCCESS: {
      toast.success('Create User successfully');
      return {
        ...state,
        users: _.concat(state.users, payload),
        isLoading: false,
      };
    }

    case UPDATE_USER.SUCCESS:
    case CHANGE_ROLE.SUCCESS: {
      // toast.success(`User's role change successfully`);
      return {
        ...state,
        users: state.users.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case DELETE_USER.SUCCESS: {
      // window.location.reload();
      toast.success(`User deleted successfully`);
      return {
        ...state,
        users: state.users.filter((data) => data.id !== payload),
      };
    }

    case FETCH_USER.FAIL:
    case CREATE_USER.FAIL:
    case DELETE_USER.FAIL:
    case UPDATE_USER.FAIL:
    case CHANGE_ROLE.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default userReducer;
