const GCMData = [
    {
      year: 2022,
      month: "November",
      fileName: "Nov_2022",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_11_2022.xlsx",
    },
    {
      year: 2022,
      month: "October",
      fileName: "Oct_2022",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_10_2022.xlsx",
    },
    {
      year: 2022,
      month: "September",
      fileName: "Sep_2022",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_09_2022.xlsx",
    },
    {
      year: 2022,
      month: "August",
      fileName: "Aug_2022",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_08_2022.xlsx",
    },
    {
      year: 2022,
      month: "July",
      fileName: "Jul_2022",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_07_2022.xlsx",
    },
    {
      year: 2022,
      month: "June",
      fileName: "Jun_2022",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_06_2022.xlsx",
    },
    {
      year: 2022,
      month: "May",
      fileName: "May_2022",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_05_2022.xlsx",
    },
    {
      year: 2022,
      month: "April",
      fileName: "Apr_2022",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_04_2022.xlsx",
    },
    {
      year: 2022,
      month: "March",
      fileName: "Mar_2022",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_03_2022.xlsx",
    },
    {
      year: 2022,
      month: "February",
      fileName: "Feb_2022",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_02_2022.xlsx",
    },
    {
      year: 2022,
      month: "January",
      fileName: "Jan_2022",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_01_2022.xlsx",
    },
    {
      year: 2021,
      month: "December",
      fileName: "Dec_2021",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_12_2021.xlsx",
    },
    {
      year: 2021,
      month: "November",
      fileName: "Nov_2021",
      downloadLink: "https://storage.googleapis.com/chargezone_prod/GCM_11_2021.xlsx",
    },
  ];
  
  export default GCMData;