import { patch, post } from 'utils/api';

export const updateMeterValuesAPI = (chargerId, meterstart, meterstop, booking_start, booking_stop, isChecked) => {
  const payload = {};
  let endpoint;
  if (meterstart && meterstop && isChecked === false) {
    payload.meterStart = meterstart;
    payload.meterStop = meterstop;
    payload.bookingId = chargerId;
    endpoint = `/util/generate_invoice`;
    return post(endpoint, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } else if (meterstart && meterstop && isChecked === true) {
    payload.meterstart = meterstart;
    payload.meterstop = meterstop;
    endpoint = `/charger_bookings/${chargerId}`;
    return patch(endpoint, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } else {
    payload.booking_start = booking_start;
    payload.booking_stop = booking_stop;
    endpoint = `/charger_bookings/${chargerId}`;
    return patch(endpoint, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
};
export const negativeValue = {
  updateMeterValuesAPI,
};
