import { FETCH_RFID_REQUEST, GET_RFID_REQUEST, UPDATE_RFID_REQUEST, DELETE_RFID_REQUEST } from 'actions/rfidRequest';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  rfidRequests: [],
  rfidRequestDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const rfidRequestReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RFID_REQUEST.REQUEST:
    case GET_RFID_REQUEST.REQUEST:
    case DELETE_RFID_REQUEST.REQUEST: {
      return { ...state, isLoading: true, rfidRequestDetail: {} };
    }

    case UPDATE_RFID_REQUEST.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_RFID_REQUEST.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        rfidRequests: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case GET_RFID_REQUEST.SUCCESS: {
      return {
        ...state,
        rfidRequestDetail: payload,
        isLoading: false,
      };
    }

    case UPDATE_RFID_REQUEST.SUCCESS: {
      toast.success('RFID Request updated successfully');
      return {
        ...state,
        isLoading: false,
        rfidRequests: state.rfidRequests.map((data) => {
          if (data.id === payload.id) {
            return payload;
          } else {
            return data;
          }
        }),
      };
    }

    case DELETE_RFID_REQUEST.SUCCESS: {
      toast.success('RFID Request deleted successfully');
      return {
        ...state,
        isLoading: false,
        rfidRequests: state.rfidRequests.filter((data) => data.id !== payload),
      };
    }

    case FETCH_RFID_REQUEST.FAIL:
    case GET_RFID_REQUEST.FAIL:
    case UPDATE_RFID_REQUEST.FAIL:
    case DELETE_RFID_REQUEST.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default rfidRequestReducer;
