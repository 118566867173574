import { CREATE_SCHEDULE, FETCH_SCHEDULE, GET_SCHEDULE, DELETE_SCHEDULE, UPDATE_SCHEDULE } from 'actions/schedule';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  schedule: [],
  scheduleDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const scheduleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SCHEDULE.REQUEST:
    case CREATE_SCHEDULE.REQUEST:
    case GET_SCHEDULE.REQUEST:
    case DELETE_SCHEDULE.REQUEST: {
      return { ...state, isLoading: true, scheduleDetail: {} };
    }

    case UPDATE_SCHEDULE.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_SCHEDULE.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          schedule: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          // schedule: _.concat(state.schedule, payload.results),
          schedule: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_SCHEDULE.SUCCESS: {
      return {
        ...state,
        scheduleDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_SCHEDULE.SUCCESS: {
      toast.success(' Schedule creaded successfully');
      return {
        ...state,
        schedule: _.concat(state.schedule, payload),
        isLoading: false,
      };
    }

    case UPDATE_SCHEDULE.SUCCESS: {
      toast.success('Schedule updated successfully');
      console.log(state.schedule);
      return {
        ...state,
        isLoading: false,
        // schedule: state.schedule.filter((data) => data.id === payload.scheduleId ? payload : data),
        schedule: state.schedule.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_SCHEDULE.SUCCESS: {
      toast.success(' Schedule deleted successfully');
      return {
        ...state,
        isLoading: false,
        schedule: state.schedule.filter((data) => data.id !== payload),
      };
    }

    case FETCH_SCHEDULE.FAIL:
    case CREATE_SCHEDULE.FAIL:
    case GET_SCHEDULE.FAIL:
    case DELETE_SCHEDULE.FAIL:
    case UPDATE_SCHEDULE.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default scheduleReducer;
