import { GET_ALL_MODULES, UPDATE_MODULE } from 'actions/tenantAdminAccess';

const initialState = {
  isLoading: false,
  excluded_tenant: [],
  modules: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const tenantAdminAccessReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_MODULES.REQUEST:
    case UPDATE_MODULE.REQUEST: {
      return { ...state, isLoading: true };
    }

    case GET_ALL_MODULES.SUCCESS: {
      return {
        ...state,
        modules: payload.results,
        isLoading: false,
      };
    }

    case UPDATE_MODULE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        excluded_tenant: state.excluded_tenant.filter((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case GET_ALL_MODULES.FAIL:
    case UPDATE_MODULE.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default tenantAdminAccessReducer;
