import { GET_SMC_PAYMENT_GATEWAY } from 'actions/smcGateway';

const initialState = {
  isLoading: false,
  smcGateway: [],
};

const smcGatewayReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SMC_PAYMENT_GATEWAY.REQUEST: {
      return { ...state, isLoading: true, smcGateway: {} };
    }

    case GET_SMC_PAYMENT_GATEWAY.SUCCESS: {
      return {
        ...state,
        smcGateway: payload,
        isLoading: false,
      };
    }
    case GET_SMC_PAYMENT_GATEWAY.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};

export default smcGatewayReducer;
