import React from 'react';
import PropTypes from 'prop-types';

const LayoutContainer = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

LayoutContainer.propTypes = {
  children: PropTypes.node,
};

export default LayoutContainer;
