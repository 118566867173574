import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { CREATE_AMENITY, DELETE_AMENITY, FETCH_AMENITY, GET_AMENITY, UPDATE_AMENITY } from 'actions/amenity';

function* fetchAmenity(action) {
  try {
    const response = yield call(API.getAmenity, action.payload);
    yield put({ type: FETCH_AMENITY.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_AMENITY.FAIL, payload: { error: e } });
  }
}

function* getAmenityById(action) {
  try {
    const response = yield call(API.getAmenityById, action.payload);
    yield put({ type: GET_AMENITY.SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_AMENITY.FAIL, payload: { error: e } });
  }
}

function* createAmenity(action) {
  try {
    const response = yield call(API.addAmenity, action.payload);
    yield put({ type: CREATE_AMENITY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_AMENITY.FAIL, payload: { error: e } });
  }
}

function* updateAmenity(action) {
  try {
    const response = yield call(API.updateAmenity, action.payload);
    yield put({ type: UPDATE_AMENITY.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_AMENITY.FAIL, payload: { error: e } });
  }
}

function* deleteAmenity(action) {
  try {
    const response = yield call(API.deleteAmenity, action.payload);
    yield put({ type: DELETE_AMENITY.SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_AMENITY.FAIL, payload: { error: e } });
  }
}

function* amenity() {
  yield takeLatest(FETCH_AMENITY.REQUEST, fetchAmenity);
  yield takeLatest(GET_AMENITY.REQUEST, getAmenityById);
  yield takeLatest(CREATE_AMENITY.REQUEST, createAmenity);
  yield takeLatest(UPDATE_AMENITY.REQUEST, updateAmenity);
  yield takeLatest(DELETE_AMENITY.REQUEST, deleteAmenity);
}

export default amenity;
