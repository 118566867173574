import { createActionTypes } from 'utils/action/actionTypes';

export const CREATE_CHARGING_STATION = createActionTypes('CREATE_CHARGING_STATION');
export const FETCH_CHARGING_STATION = createActionTypes('FETCH_CHARGING_STATION');
export const GET_CHARGING_STATION = createActionTypes('GET_CHARGING_STATION');
export const DELETE_CHARGING_STATION = createActionTypes('DELETE_CHARGING_STATION');
export const UPDATE_CHARGING_STATION = createActionTypes('UPDATE_CHARGING_STATION');
export const CHANGE_STATION_STATUS = createActionTypes('CHANGE_STATION_STATUS');
export const FETCH_REVIEWS = createActionTypes('FETCH_REVIEWS');
export const UPDATE_CONTACT_PERSON = createActionTypes('UPDATE_CONTACT_PERSON');
export const GET_STATION_BY_CITY = createActionTypes('GET_STATION_BY_CITY');
export const GET_STATION_WIDGETS = createActionTypes('GET_STATION_WIDGETS');
export const GET_CONSUMPTION = createActionTypes('GET_CONSUMPTION');
export const GET_CHARGER_STATION = createActionTypes('GET_CHARGER_STATION');
