import { get, patch } from 'utils/api';

export const getAllModules = (data) => {
    return get(`/modules`, data);
  };

  export const updateModule = (data) => {
    const id = data.permissionId;
    delete data['permissionId'];
    return patch(`/modules/${id}`, data);
  };

  export const tenantAdminAccess = {
    getAllModules,
    updateModule,
  };