import { get, deleteRequest, post, put, patch } from 'utils/api';

export const getTariff = (data) => {
  return get(`/tarrif`, data);
};

export const addTariff = (data) => {
  return post(`/tarrif`, data);
};

export const updateTariff = (data) => {
  const id = data.id;
  delete data['id'];
  return put(`/tarrif/${id}`, data);
};
export const updateTariffSlot = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/tarrif/${id}`, data);
};
export const deleteTariff = (id) => {
  return deleteRequest(`/tarrif/${id}`);
};

export const getTariffById = (id) => {
  return get(`/tarrif/${id}`);
};

export const tariff = {
  getTariff,
  addTariff,
  updateTariff,
  deleteTariff,
  getTariffById,
  updateTariffSlot,
};
