import React, { useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import EmspInformation from './EMSPInformation';
import Connectivity from './Connectivity';
import Financials from './Financials';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import Footer from 'components/general/Footer';
import { useTranslation } from 'react-i18next';
const AddEmsp = () => {
  const [currentTab, setCurrentTab] = useState('EmspInformation');
  const { ocpiId } = useParams();
  const { t } = useTranslation();
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const setTab = (tab) => {
    setCurrentTab(tab);
  };

  // const infoToConnectivity = () => {
  //   setCurrentTab('Connectivity');
  // };
  // const connectivityToFinancials = () => {
  //   setCurrentTab('Financials');
  // };

  // const onInformation = () => {
  //   setTab('Connectivity');
  // };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${ocpiId ? 'Edit' : 'Add'} Partner`} backTo={`/OCPI-Partner`} />
        <div className="page-content-wrapper scrollable">
          <div className="add-vehicle-page-main">
            <Card>
              <div>
                <div className="tab-block">
                  <div className="tab-nav-block">
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'EmspInformation' ? 'active' : ''}`}
                      onClick={() => setTab('EmspInformation')}
                      data-content="tab-profile"
                    >
                      {t('addEMSP.emspInformation')}
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'Connectivity' ? 'active' : ''}`}
                      onClick={() => setTab('Connectivity')}
                      data-content="tab-bookings"
                    >
                      {t('addEMSP.connectivity')}
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'Financials' ? 'active' : ''}`}
                      onClick={() => setTab('Financials')}
                      data-content="tab-transactions"
                    >
                      {t('addEMSP.financials')}
                    </a>
                  </div>
                </div>
              </div>
            </Card>

            {currentTab === 'EmspInformation' && <EmspInformation setCurrentTab={setCurrentTab} />}
            {currentTab === 'Connectivity' && <Connectivity setCurrentTab={setCurrentTab} />}
            {currentTab === 'Financials' && <Financials />}
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};
export default AddEmsp;
