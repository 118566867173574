import { DELETE_NOTIFICATION, FETCH_NOTIFICATION, UPDATE_FIRMWARE_NOTIFICATION, NOTIFICATION, REMOVE_NOTIFICATION } from 'actions/notification';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  notifications: [],
  notificationCount: 0,
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};
const notificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_NOTIFICATION.REQUEST:
    case DELETE_NOTIFICATION.REQUEST:
    case UPDATE_FIRMWARE_NOTIFICATION.REQUEST: {
      return { ...state, isLoading: true };
    }
    case FETCH_NOTIFICATION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        notifications: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }
    case NOTIFICATION.REQUEST: {
      return {
        ...state,
        notificationCount: payload,
      };
    }

    case REMOVE_NOTIFICATION.REQUEST: {
      return {
        ...state,
        notificationCount: 0,
      };
    }

    case UPDATE_FIRMWARE_NOTIFICATION.SUCCESS: {
      toast.success('Firmware update Successfully');
      return {
        ...state,
        // notifications: state.notifications.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_NOTIFICATION.SUCCESS: {
      return {
        ...state,
        notifications: state.notifications.filter((data) => data.id !== payload),
      };
    }

    case FETCH_NOTIFICATION.FAIL:
    case UPDATE_FIRMWARE_NOTIFICATION.FAIL:
    case DELETE_NOTIFICATION.FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default notificationReducer;
