import { FETCH_INVOICE, FETCH_INVOICE_WIDGETS } from '../actions/invoice';

const initialState = {
  isLoading: false,
  invoices: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const invoiceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_INVOICE.REQUEST:
    case FETCH_INVOICE_WIDGETS.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_INVOICE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        invoices: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
        //invoiceDetail: payload.results2,
      };
    }
    case FETCH_INVOICE_WIDGETS.SUCCESS: {
      return {
        ...state,
        totalAmount: payload.data?.total_amount,
        totalTranstions: payload?.data.total_transactions,
        totalGst: payload.data?.total_gst,
        totalRevenue: payload.data?.total_revenue,
      };
    }
    case FETCH_INVOICE.FAIL:
    case FETCH_INVOICE_WIDGETS.FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default invoiceReducer;
