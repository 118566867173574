import { get, post, deleteRequest, patch } from 'utils/api';

export const getChargeSpeed = (data) => {
  return get(`/charge_speed`, data);
};

export const getChargeSpeedById = (id) => {
  return get(`/charge_speed/${id}`);
};

export const addChargeSpeed = (data) => {
  return post(`/charge_speed`, data);
};

export const updateChargeSpeed = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/charge_speed/${id}`, data);
};

export const deleteChargeSpeed = (id) => {
  return deleteRequest(`/charge_speed/${id}`);
};

export const chargeSpeed = {
  getChargeSpeed,
  getChargeSpeedById,
  addChargeSpeed,
  updateChargeSpeed,
  deleteChargeSpeed,
};
