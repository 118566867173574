import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { SEND_OTP, VERIFY_OTP, RESEND_OTP } from '../actions/otp';

function* sendOtp(action) {
  try {
    const response = yield call(API.sendOtp, action.payload);
    yield put({ type: SEND_OTP.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SEND_OTP.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* verifyOtp(action) {
  try {
    const response = yield call(API.verifyOtp, action.payload);
    action.cb(response);
    yield put({ type: VERIFY_OTP.SUCCESS, payload: response.data });
  } catch (e) {
    action.cb(e);
    yield put({ type: VERIFY_OTP.FAIL, payload: { error: e } });
  }
}

function* resendOtp(action) {
  try {
    const response = yield call(API.resendOtp, action.payload);
    yield put({ type: RESEND_OTP.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RESEND_OTP.FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* otp() {
  yield takeLatest(SEND_OTP.REQUEST, sendOtp);
  yield takeLatest(VERIFY_OTP.REQUEST, verifyOtp);
  yield takeLatest(RESEND_OTP.REQUEST, resendOtp);
}

export default otp;
