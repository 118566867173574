import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { CHANGE_ROLE, CREATE_USER, DELETE_USER, FETCH_USER, UPDATE_USER, FETCH_FLEET_MEMBERS } from 'actions/user';

function* getUsers(action) {
  try {
    const response = yield call(API.getUsers, action.payload);
    yield put({ type: FETCH_USER.SUCCESS, payload: response });
  } catch (e) {
    yield put({ type: FETCH_USER.FAIL, payload: { error: e } });
  }
}

function* createUser(action) {
  try {
    const response = yield call(API.addUser, action.payload);
    yield put({ type: CREATE_USER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_USER.FAIL, payload: { error: e } });
  }
}

function* changeRole(action) {
  try {
    const response = yield call(API.updateUser, action.payload);
    yield put({ type: CHANGE_ROLE.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHANGE_ROLE.FAIL, payload: { error: e } });
  }
}

function* updateUser(action) {
  try {
    const response = yield call(API.updateUser, action.payload);
    yield put({ type: UPDATE_USER.SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_USER.FAIL, payload: { error: e } });
  }
}

function* deleteUser(action) {
  try {
    yield call(API.deleteUser, action.payload);
    yield put({ type: DELETE_USER.SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_USER.FAIL, payload: { error: e } });
  }
}

function* getFleetMembers(action) {
  try {
    const response = yield call(API.getFleetMembers, action.payload);
    yield put({ type: FETCH_FLEET_MEMBERS.SUCCESS, payload: response });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_FLEET_MEMBERS.FAIL, payload: { error: e } });
  }
}

function* user() {
  yield takeLatest(FETCH_USER.REQUEST, getUsers);
  yield takeLatest(CREATE_USER.REQUEST, createUser);
  yield takeLatest(DELETE_USER.REQUEST, deleteUser);
  yield takeLatest(UPDATE_USER.REQUEST, updateUser);
  yield takeLatest(CHANGE_ROLE.REQUEST, changeRole);
  yield takeLatest(FETCH_FLEET_MEMBERS.REQUEST, getFleetMembers);
}

export default user;
