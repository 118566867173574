import { DOWNLOAD_PAYMENT_RECEIPT } from 'actions/customer';

const initialValues = {
  isLoading: true,
  receiptDetails: '',
};

const paymentReceiptReducer = (state = initialValues, { type, payload }) => {
  switch (type) {
    case DOWNLOAD_PAYMENT_RECEIPT.REQUEST: {
      return { ...state, isLoading: true };
    }

    case DOWNLOAD_PAYMENT_RECEIPT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        receiptDetails: payload,
      };
    }

    case DOWNLOAD_PAYMENT_RECEIPT.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};

export default paymentReceiptReducer;
