import {
  CREATE_OCPI_PARTNER_GROUP,
  FETCH_OCPI_PARTNER_GROUP,
  GET_OCPI_PARTNER_GROUP,
  DELETE_OCPI_PARTNER_GROUP,
  UPDATE_OCPI_PARTNER_GROUP,
  ADD_PARTY_IN_OCPI_PARTNER_GROUP,
  DELETE_OCPI_PARTNER,
} from 'actions/ocpiPartnerGroup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  partnerGroups: [],
  partnerGroupDetail: {},
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const ocpiPartnerGroupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_OCPI_PARTNER_GROUP.REQUEST:
    case CREATE_OCPI_PARTNER_GROUP.REQUEST:
    case GET_OCPI_PARTNER_GROUP.REQUEST:
    case DELETE_OCPI_PARTNER_GROUP.REQUEST:
    case ADD_PARTY_IN_OCPI_PARTNER_GROUP.REQUEST:
    case DELETE_OCPI_PARTNER.REQUEST: {
      return { ...state, isLoading: true, partnerGroupDetail: {} };
    }

    case UPDATE_OCPI_PARTNER_GROUP.REQUEST: {
      return { ...state, isLoading: true };
    }

    case FETCH_OCPI_PARTNER_GROUP.SUCCESS: {
      if (payload.page === 1) {
        return {
          ...state,
          isLoading: false,
          partnerGroups: payload.results,
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          partnerGroups: _.concat(state.getOcpiPartnerGroups, payload.results),
          page: payload.page,
          limit: payload.limit,
          totalPages: payload.totalPages,
          total: payload.totalResults,
        };
      }
    }

    case GET_OCPI_PARTNER_GROUP.SUCCESS: {
      return {
        ...state,
        userGroupDetail: payload,
        isLoading: false,
      };
    }

    case CREATE_OCPI_PARTNER_GROUP.SUCCESS: {
      toast.success('Partner group added successfully');
      return {
        ...state,
        partnerGroups: _.concat(state.partnerGroups, payload),
        isLoading: false,
      };
    }

    case UPDATE_OCPI_PARTNER_GROUP.SUCCESS: {
      toast.success(' Partner group updated successfully');
      return {
        ...state,
        isLoading: false,
        partnerGroups: state.partnerGroups.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_OCPI_PARTNER_GROUP.SUCCESS: {
      toast.success('Partner group deleted successfully');
      return {
        ...state,
        isLoading: false,
        partnerGroups: state.partnerGroups.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case DELETE_OCPI_PARTNER.SUCCESS: {
      toast.success('Partner deleted successfully');
      return {
        ...state,
        isLoading: false,
        partnerGroups: state.partnerGroups.filter((data) => data.id !== payload),
      };
    }

    case ADD_PARTY_IN_OCPI_PARTNER_GROUP.SUCCESS: {
      toast.success('Party added successfully');
      return {
        ...state,
        isLoading: false,
        partnerGroups: state.partnerGroups.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case FETCH_OCPI_PARTNER_GROUP.FAIL:
    case CREATE_OCPI_PARTNER_GROUP.FAIL:
    case GET_OCPI_PARTNER_GROUP.FAIL:
    case DELETE_OCPI_PARTNER_GROUP.FAIL:
    case UPDATE_OCPI_PARTNER_GROUP.FAIL:
    case ADD_PARTY_IN_OCPI_PARTNER_GROUP.FAIL:
    case DELETE_OCPI_PARTNER.FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default ocpiPartnerGroupReducer;
