import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import { useParams } from 'react-router-dom';
import Card from 'components/inputs/Card';
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import * as _ from 'lodash';
import Button from 'components/inputs/Button';
import { UPDATE_STATUS_CODE, CREATE_STATUS_CODE } from 'components/common/constant';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import TextInput from 'components/inputs/Input';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { ADD_ELECTRICITY_BILL, UPDATE_ELECTRICITY_BILL, GET_ELECTRICITY_BILL } from 'actions/electricityBillReport';
import moment from 'moment';

const AddElectricityBill = () => {
  const [searchText, setSearchText] = useState('');
  const { billId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigateTo } = useContext(NavContext);
  const customerPage = useSelector((state) => state.customer.page);
  const customerTotalPage = useSelector((state) => state.customer.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const allStation = useSelector((state) => state.chargingStation.chargingStations.results || []);
  const allBillDetails = useSelector((state) => state.electricityBillReport.electricityBillReports);

  let initialValues = {
    consumerNumber: '',
    toDate: '',
    fromDate: '',
    bill_details: '',
    amount: '',
    unitConsumed: '',
  };

  if (billId && !_.isEmpty(allBillDetails)) {
    const billDetail = allBillDetails.find((data) => data.id === billId);
    if (billDetail) {
      initialValues = {
        consumerNumber: _.get(billDetail, 'consumerNumber', ''),
        toDate: moment(_.get(billDetail, 'toDate', '')).format('YYYY-MM-DD'),
        fromDate: moment(_.get(billDetail, 'fromDate', '')).format('YYYY-MM-DD'),
        bill_details: _.get(billDetail, 'station.id', ''),
        amount: _.get(billDetail, 'amount', ''),
        unitConsumed: _.get(billDetail, 'unitConsumed', ''),
      };
    }
  }

  const currentUserName = _.get(profileData, 'name', '');

  const getelectricityBillReport = useCallback(
    (data = {}) => {
      dispatch({ type: GET_ELECTRICITY_BILL.REQUEST, payload: { ...data, limit: 999 } });
    },
    [dispatch]
  );

  useEffect(() => {
    getelectricityBillReport();
  }, [getelectricityBillReport]);

  const updateBill = useCallback(
    (data) => {
      if (!allStation || allStation.length === 0) {
        toast.error('Station data is not available');
        return;
      }

      const selectedStation = allStation.find((check) => check.id === data.bill_details);

      if (!selectedStation) {
        toast.error('Invalid Station');
        return;
      }

      if (data.amount <= 0) {
        toast.error('Amount must be a positive number');
        return;
      }

      const modifiedData = {
        ...data,
        tenant: profileData.tenant.id,
        station_id: selectedStation.station_id,
      };

      dispatch({
        type: ADD_ELECTRICITY_BILL.REQUEST,
        payload: modifiedData,
        cb: (res) => {
          navigateTo('/ElectriciyBillReport');
          toast.success('Bill Details Added Successfully');
          if (res.status === UPDATE_STATUS_CODE) {
            toast.success('Bill Details Updated Successfully');
          }
        },
      });
    },
    [dispatch, navigateTo, allStation, profileData]
  );

  const getAllcharger = useCallback(
    (data = {}) => {
      console.log('********', searchText);
      if (searchText) {
        dispatch({ type: FETCH_CHARGING_STATION.REQUEST, payload: { data, searchText: searchText } });
      } else {
        dispatch({ type: FETCH_CHARGING_STATION.REQUEST, payload: { limit: 999 } });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getAllcharger();
  }, [getAllcharger]);

  const editbillDetail = useCallback(
    (data) => {
      const selectedStation = allStation.find((check) => check.id === data.bill_details);

      const modifiedData = {
        ...data,
        id: billId,
        tenant: '6251713fec1698610d3ee50a',
        station: data.bill_details,
        station_id: selectedStation.station_id,
      };

      dispatch({
        type: UPDATE_ELECTRICITY_BILL.REQUEST,
        payload: modifiedData,
        cb: (res) => {
          navigateTo('/ElectriciyBillReport');
          toast.success('Data Updated Successfully');
          if (_.get(res, 'status') === CREATE_STATUS_CODE) {
            setTimeout(() => {
              navigateTo('/ElectriciyBillReport');
              toast.success('Data Updated Successfully');
            }, 1000);
          }
        },
      });
    },
    [dispatch, navigateTo, getAllcharger]
  );

  const AddbillSchema = Yup.object().shape({
    consumerNumber: Yup.string().required(t('ElectriciyBillReport.customerNumber')),
    toDate: Yup.string().required(t('ElectriciyBillReport.toDate')),
    fromDate: Yup.string().required(t('ElectriciyBillReport.fromDate')),
    bill_details: Yup.string().required(t('ElectriciyBillReport.stationName')),
    amount: Yup.string().required(t('ElectriciyBillReport.amount')),
    unitConsumed: Yup.string().required(t('ElectriciyBillReport.unitConsumed')),
  });

  const EditbillSchema = Yup.object().shape({
    consumerNumber: Yup.string().required(t('ElectriciyBillReport.consumerNumber')),
    toDate: Yup.string().required(t('ElectriciyBillReport.toDate')),
    fromDate: Yup.string().required(t('ElectriciyBillReport.fromDate')),
    bill_details: Yup.string().required(t('ElectriciyBillReport.bill_details')),
    amount: Yup.string().required(t('ElectriciyBillReport.amount')),
    unitConsumed: Yup.string().required(t('ElectriciyBillReport.unitConsumed')),
  });

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${billId ? 'Edit' : 'Add'} ElectricityBill`} backTo="/ElectriciyBillReport" />
        <div className="page-content-wrapper scrollable">
          <Card>
            <div className="user-form-body__block">
              <div className="user-form--block">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={billId ? EditbillSchema : AddbillSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (billId) {
                      editbillDetail(values);
                    } else {
                      updateBill(values);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <TextInput
                            isRequired
                            label={t('ElectriciyBillReport.fromDate')}
                            placeholder={t('ElectriciyBillReport.fromDate')}
                            type="date"
                            name="fromDate"
                            value={values.fromDate}
                            onChange={handleChange}
                            error={errors.fromDate && touched.fromDate ? errors.fromDate : null}
                          />
                        </Col>

                        <Col>
                          <TextInput
                            isRequired
                            label={t('ElectriciyBillReport.toDate')}
                            placeholder={t('ElectriciyBillReport.toDate')}
                            type="date"
                            name="toDate"
                            value={values.toDate}
                            onChange={handleChange}
                            error={errors.toDate && touched.toDate ? errors.toDate : null}
                          />
                        </Col>

                        <Col>
                          <TextInput
                            isRequired
                            label={t('ElectriciyBillReport.customerNumber')}
                            placeholder={t('ElectriciyBillReport.customerNumber')}
                            name="consumerNumber"
                            value={values.consumerNumber}
                            onChange={handleChange}
                            error={errors.consumerNumber && touched.consumerNumber ? errors.consumerNumber : null}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Select
                            isRequired
                            label={t('ElectriciyBillReport.stationName')}
                            options={_.map(allStation, (item) => {
                              return {
                                label: _.get(item, 'name', 'Unknown Station'),
                                value: item.id,
                              };
                            })}
                            placeholder={t('Select Station')}
                            name="bill_details"
                            onMenuScrollDown={true}
                            getDataOnScrollDown={getAllcharger}
                            page={customerPage}
                            totalPage={customerTotalPage}
                            value={values.bill_details}
                            onChange={(val) => {
                              setFieldValue('bill_details', val); // Set only the value in Formik state
                            }}
                            onInputChange={(text) => setSearchText(text)}
                            error={errors.bill_details && touched.bill_details ? errors.bill_details : null}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextInput
                            isRequired
                            label={t('ElectriciyBillReport.amount')}
                            placeholder={t('ElectriciyBillReport.amount')}
                            name="amount"
                            value={values.amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*\.?\d*$/.test(value)) {
                                // Allows digits and a single decimal point
                                handleChange(e);
                              }
                            }}
                            error={errors.amount && touched.amount ? errors.amount : null}
                          />
                        </Col>
                        <Col>
                          <TextInput
                            isRequired
                            label={t('ElectriciyBillReport.unitConsumed')}
                            placeholder={t('placeHolder.chargingStationName')}
                            name="unitConsumed"
                            value={values.unitConsumed}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*\.?\d*$/.test(value)) {
                                // Allows digits and a single decimal point
                                handleChange(e);
                              }
                            }}
                            error={errors.unitConsumed && touched.unitConsumed ? errors.unitConsumed : null}
                          />
                        </Col>
                      </Row>
                      <div className="user-form-btn__block">
                        <Button type="submit" disabled={isSubmitting} className="user-form-btn">
                          {billId ? t('Update') : t('Submit')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Card>
        </div>
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};

export default AddElectricityBill;
