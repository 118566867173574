import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const ProfilePicInput = (props) => {
  const profileDetail = useSelector((state) => state.profile.userProfile);
  const username = profileDetail.name || '';
  const { path = '', showLoader, height, width, fontSize, backgroundColor, marginTop, position } = props;

  const initials = username.charAt(0);

  const imageUrl = `https://dummyimage.com/red/fff&text=`;

  const textStyle = {
    fontSize: `${fontSize}px`,
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <div className="profile-block__main">
      <div className="profile-block__inner">
        <div className="profile-img--inner">
          <div
            className="picture__block"
            style={{
              height: `${height}px`,
              width: `${width}px`,
              position: `${position}`,
              backgroundColor: `${backgroundColor}`,
              marginTop: `${marginTop}`,
            }}
          >
            <img src={path || imageUrl} alt="" />
            {showLoader && (
              <div className="profile-loader--block">
                <div className="spinner-border text-danger" />
              </div>
            )}
            {!showLoader && <div style={textStyle}>{initials}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

ProfilePicInput.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showLoader: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  bgColor: PropTypes.string,
  fontSize: PropTypes.number,
  backgroundColor: PropTypes.any,
  marginTop: PropTypes.any,
  position: PropTypes.any,
};

ProfilePicInput.defaultProps = {
  height: 170,
  width: 170,
  fontSize: 25,
  backgroundColor: '#15212b',
  marginTop: '34px',
  position: 'relative',
};

export default ProfilePicInput;
