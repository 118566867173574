import React, { useCallback, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, FieldArray, getIn } from 'formik';
import Button from 'components/inputs/Button';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_RFID_GROUP } from 'actions/rfidGroup';
import { FETCH_RFID, UPDATE_RFID } from 'actions/rfid';
import * as _ from 'lodash';
import Select from 'components/inputs/Select';
import CheckBox from 'components/inputs/CheckBox';
import TextInput from 'components/inputs/Input';
import { FETCH_MASTER_SETTING } from 'actions/masterSetting';
import moment from 'moment';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { BsDot } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const RfidDetailsModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigateTo } = useContext(NavContext);
  const { onClose = _.noop() } = props;
  const { customerId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [selectedUserGroup, setSelectedUserGroup] = useState('');
  const allRfidGroup = useSelector((state) => state.rfidGroup.rfidGroup);
  const getAllRfid = useSelector((state) => state.rfid.rfid);
  const customerDetail = useSelector((state) => state.customer.customerDetail);
  const allMasterSetting = useSelector((state) => state.masterSetting.masterSettings);
  const rfidGroupPage = useSelector((state) => state.rfidGroup.page);
  const rfidPage = useSelector((state) => state.rfid.page);
  const rfidGroupTotalPage = useSelector((state) => state.rfidGroup.totalPages);
  const rfidTotalPage = useSelector((state) => state.rfid.totalPages);

  const getRfidByGroup = useCallback(
    (data = {}) => {
      setSelectedUserGroup(data.group ? data.group : '');
      if (searchText) {
        const rfidByGroupData = {
          ...data,
          search: searchText,
        };
        dispatch({ type: FETCH_RFID.REQUEST, payload: rfidByGroupData });
      } else {
        dispatch({ type: FETCH_RFID.REQUEST, payload: data });
      }
    },
    [searchText, selectedUserGroup]
  );

  const updateRfid = useCallback((data = {}) => {
    dispatch({
      type: UPDATE_RFID.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
          setTimeout(() => {
            navigateTo(`/customerProfile/${customerId}`);
          }, 1000);
        }
      },
    });
  }, []);

  useEffect(() => {
    if (searchText) {
      getRfidByGroup({ group: selectedUserGroup });
    }
  }, [searchText, selectedUserGroup]);

  const RfidGroups = useCallback((data = {}) => {
    dispatch({ type: FETCH_RFID_GROUP.REQUEST, payload: data });
  }, []);

  useEffect(() => {
    RfidGroups();
    dispatch({ type: FETCH_MASTER_SETTING.REQUEST });
  }, []);

  const assignRfid = _.isEmpty(_.get(customerDetail, 'rfid'));
  const commentMessage = _.head(_.map(customerDetail.rfid, (data) => _.map(data.comments, (item) => item.message)));

  const RfidSchema = Yup.object().shape({
    group: Yup.string().required(t('rfidDetailsModal.group')),
    card_number: Yup.string().required(t('rfidDetailsModal.cardNumber')),
  });

  const ManageRfidSchema = Yup.object().shape({
    comments: Yup.array().of(
      Yup.object().shape({
        message: Yup.string().required(t('rfidDetailsModal.message')),
      })
    ),
  });

  const initialValues = assignRfid
    ? {
        group: '',
        user: '',
        card_number: '',
      }
    : {
        comments: [{ message: '', create: moment(new Date()).format('YYYY-MM-DD') }],
        status: _.head(_.map(customerDetail.rfid, (data) => data.status)),
      };

  const lowBalance = _.filter(allMasterSetting, (item) => item.key_name === 'rfid_settings');
  const lowBalanceAmount = _.map(lowBalance, (item) => _.get(item, 'key_value.low_balance_amt', ''));
  const rfidId = customerDetail.rfid ? _.head(_.map(customerDetail.rfid, (data) => data.id)) : '';
  const rfidStatus = _.head(_.map(customerDetail.rfid, (data) => data.status));
  const allRfid = _.filter(getAllRfid, (item) => item['user'] === undefined || item['user'] === null);

  return (
    <React.Fragment>
      <div className="rfid-details-page__main">
        <Card className="rfid-details-page-inner">
          <div className="rfid-details-header__block">
            <div className="rfid-details-header-block_inner">
              <span>{`${assignRfid ? 'Assign' : 'Manage'} RFID`}</span>
            </div>
            <span className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </span>
          </div>
          <div className="rfid-details-body__block">
            <div className="rfid-details-body-block__inner">
              <div className="content-wrapper">
                <span className="rfid-details-data">{t('rfidDetailsModal.name')}</span>
                <span className="rfid-details-name">{_.get(customerDetail, 'name', '')}</span>
              </div>

              {!assignRfid && (
                <>
                  <div className="content-wrapper">
                    <span className="rfid-details-data">{t('rfidDetailsModal.groupName')}</span>
                    <span className="rfid-details-name">{_.head(_.map(customerDetail.rfid, (data) => _.get(data, 'group.name', '')))}</span>
                  </div>
                  <div className="content-wrapper">
                    <span className="rfid-details-data">{t('rfidDetailsModal.rfidNo')}</span>
                    <span className="rfid-details-name">{_.head(_.map(customerDetail.rfid, (data) => data.code))}</span>
                    {rfidStatus === 'Blocked' && (
                      <div className="rfid-status-block">
                        <BsDot color={'#C79F32'} size="25" />
                        <span className="rfid-status-text">{t('rfidDetailsModal.temporaryDisable')}</span>
                      </div>
                    )}
                  </div>
                  <div className="content-wrapper">
                    <span className="rfid-details-data">{t('rfidDetailsModal.rfidCardNo')}</span>
                    <span className="rfid-details-name">{_.head(_.map(customerDetail.rfid, (data) => _.get(data, 'card_number', 'NA')))}</span>
                  </div>
                </>
              )}
            </div>
            <div className="rfid-balance--block">
              <span className="rfid-balance-text">
                {t('rfidDetailsModal.minLowBalance')} {lowBalanceAmount && `₹${lowBalanceAmount.toString()}`}
              </span>
            </div>
            <div className="rfid-details--block">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={assignRfid ? RfidSchema : rfidStatus === 'Active' ? ManageRfidSchema : ''}
                onSubmit={(values, { setSubmitting }) => {
                  if (assignRfid) {
                    const selectedRfidId = _.get(values, 'card_number', '');
                    const customerId = _.get(customerDetail, 'id', '');
                    const firstname = _.get(customerDetail, 'first_name', '');
                    const lastname = _.get(customerDetail, 'last_name', '');
                    const assignRfidData = _.omit(values, 'card_number');
                    const data = { ...assignRfidData, user: customerId, id: selectedRfidId, first_name: firstname, last_name: lastname };
                    updateRfid(data);
                  } else {
                    if (values.status === 'Active') {
                      const customerId = _.get(customerDetail, 'id', '');
                      const data = { ...values, status: 'Blocked', user: customerId, id: rfidId };
                      updateRfid(data);
                    } else {
                      const customerId = _.get(customerDetail, 'id', '');
                      const comments = _.map(values.comments, (data) => {
                        return {
                          ...data,
                          message: commentMessage ? commentMessage.toString() : '',
                        };
                      });
                      const data = { ...values, comments: comments, status: 'Active', user: customerId, id: rfidId };
                      updateRfid(data);
                    }
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      {assignRfid ? (
                        <>
                          <Col lg={12}>
                            <Select
                              isRequired
                              label={t('rfidDetailsModal.rfidGroup')}
                              options={_.map(allRfidGroup, (group) => {
                                return { label: group.name, value: group.id };
                              })}
                              placeholder={t('placeHolder.selectRFIDGroup')}
                              name="group"
                              value={_.get(values, 'group')}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={RfidGroups}
                              page={rfidGroupPage}
                              totalPage={rfidGroupTotalPage}
                              onChange={(val) => {
                                getRfidByGroup({ group: val });
                                setFieldValue(`group`, val);
                                setFieldValue(`card_number`, '');
                              }}
                              error={errors.group && touched.group ? errors.group : null}
                            />
                          </Col>
                          <Col lg={12}>
                            <Select
                              isRequired
                              label={t('rfidDetailsModal.rfidCardNo')}
                              options={
                                values.group &&
                                _.map(
                                  _.filter(allRfid, (data) => data.card_number),
                                  (item) => {
                                    return { label: _.get(item, 'card_number'), value: item.id };
                                  }
                                )
                              }
                              placeholder={t('placeHolder.selectRFIDCardNo')}
                              name="card_number"
                              value={values.card_number}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getRfidByGroup({ ...data, group: _.get(values, 'group') })}
                              page={rfidPage}
                              totalPage={rfidTotalPage}
                              onInputChange={(text) => setSearchText(text)}
                              onChange={(val) => {
                                setFieldValue('card_number', val);
                              }}
                              error={errors.card_number && touched.card_number ? errors.card_number : null}
                            />
                          </Col>
                        </>
                      ) : rfidStatus === 'Blocked' ? (
                        <Col lg={12}>
                          <div className="show-on-rfid-block">
                            <div className="show-on-rfid-checkbox-input__block">
                              <CheckBox
                                name="status"
                                checked={values.status !== 'Active'}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  if (checked) {
                                    setFieldValue('status', '');
                                  } else {
                                    setFieldValue('status', 'Active');
                                  }
                                }}
                              />
                              <label htmlFor="status" className="show-on-rfid--label">
                                {t('rfidDetailsModal.unblockRFID')}
                              </label>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <FieldArray
                          name="comments"
                          render={() => (
                            <>
                              {_.map(values.comments, (field, index) => (
                                <>
                                  <Row key={`rfid-comment-${index}`}>
                                    <Col lg={12}>
                                      <TextInput
                                        isRequired
                                        placeholder={t('rfidDetailsModal.reason')}
                                        label={t('rfidDetailsModal.temporaryDisable')}
                                        name="message"
                                        value={field.message}
                                        onChange={(e) => setFieldValue(`comments[${index}].message`, e.target.value)}
                                        error={
                                          getIn(errors, `comments[${index}].message`) && getIn(touched, `comments[${index}].message`)
                                            ? getIn(errors, `comments[${index}].message`)
                                            : null
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </>
                              ))}
                            </>
                          )}
                        />
                      )}
                    </Row>
                    <div className="form-btn__block">
                      <Button type="submit" className="form_btn">
                        {t('button.confirm')}
                      </Button>
                      {!assignRfid && rfidStatus !== 'Blocked' && (
                        <div className="unassigned-rfid-btn" onClick={() => updateRfid({ user: null, id: rfidId })}>
                          {t('button.unassign')}
                        </div>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
RfidDetailsModal.propTypes = {
  onClose: PropTypes.func,
};
export default RfidDetailsModal;
