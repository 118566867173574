import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_CUSTOMER, UPDATE_CUSTOMER } from 'actions/customer';
import ProfilePicInput from 'components/inputs/ProfilePicInput';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';

const CustomerForm = (props) => {
  const { isEdit, onClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [phoneError, setPhoneError] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const customerDetail = useSelector((state) => state.customer.customerDetail);

  const addCustomer = useCallback((data) => {
    dispatch({ type: CREATE_CUSTOMER.REQUEST, payload: data });
  }, []);

  const updateCustomer = useCallback((data) => {
    dispatch({ type: UPDATE_CUSTOMER.REQUEST, payload: data });
  }, []);

  const getCountryCode = _.get(parsePhoneNumber(countryCode), 'countryCallingCode', '');
  const getPhoneNumber = _.get(parsePhoneNumber(countryCode), 'nationalNumber', '');

  const CustomerSchema = Yup.object().shape({
    phone: Yup.string().required(t('customerForm.phone')).max(15, t('customerForm.maxlimit15')),
    first_name: Yup.string().required(t('customerForm.firstName')).max(100, t('customerForm.maxlimit')),
    last_name: Yup.string().required(t('customerForm.lastName')).max(100, t('customerForm.maxlimit')),
  });

  const initialValues = isEdit
    ? { ...customerDetail }
    : {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        role: 'admin',
        country_code: '91',
      };
  return (
    <React.Fragment>
      <div className="customer-form-page__main">
        <Card>
          <div className="customer-form-header__block">
            <div className="customer-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {t('customerForm.formTitle')}
              </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#BE210B" onClick={onClose} />
            </div>
          </div>
          <div className="customer-form-body__block">
            <div className="customer-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={CustomerSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (!isValidPhoneNumber(values.phone)) {
                    setPhoneError('Phone number is not valid!');
                  } else {
                    if (isEdit) {
                      updateCustomer(values);
                    } else {
                      addCustomer({
                        ...values,
                        name: values.first_name + ' ' + values.last_name,
                        password: 'Cz123456',
                        role: 'customer',
                        country_code: getCountryCode,
                        phone: getPhoneNumber,
                      });
                    }
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xl={2} lg={3} md={12}>
                        <div className="d-flex justify-content-center">
                          <ProfilePicInput showAdd disabled />
                        </div>
                      </Col>
                      <Col xl={10} lg={9} md={12}>
                        <div className="cutomer-input--field">
                          <Row>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label={t('customerForm.customerFirstName')}
                                placeholder={t('customerForm.customerFirstName')}
                                name="first_name"
                                value={values.first_name}
                                onChange={handleChange}
                                error={errors.first_name && touched.first_name ? errors.first_name : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label={t('customerForm.customerLastName')}
                                placeholder={t('customerForm.customerLastName')}
                                name="last_name"
                                value={values.last_name}
                                onChange={handleChange}
                                error={errors.last_name && touched.last_name ? errors.last_name : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <PhoneNumberInput
                                isRequired
                                label={t('customerForm.customerPhoneNo')}
                                name="phoneNumber"
                                value={values.phone}
                                onChange={(val) => {
                                  setFieldValue(`phone`, val);
                                  setCountryCode(val || '');
                                  if (!_.isEmpty(phoneError)) {
                                    setPhoneError('');
                                  }
                                }}
                                error={errors.phone && touched.phone ? errors.phone : phoneError ? phoneError : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <TextInput
                                label={t('customerForm.customerEmail')}
                                placeholder={t('customerForm.customerEmail')}
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      <Button className="form_btn cancel____btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      <Button type="submit" disabled={isSubmitting} className="form_btn">
                        {isEdit ? 'Edit' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
CustomerForm.propTypes = {
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default CustomerForm;
